import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import * as _ from 'lodash';
import { AppRestEndPoint } from 'src/app/app-restEndPoint';


@Component({
	selector: 'app-help-icon',
	templateUrl: './help-icon.component.html',
	styleUrls: ['./help-icon.component.scss']
})
export class HelpIconComponent implements OnInit {

	@Input() public textLabel: any;
	@Input() public code: any;
	helpPages: any = [];
	tooltipData:any;

	constructor(public apiService: ApiService) {
	}

	ngOnInit(): void {
		this.setToolTip();
	}


	ngOnChanges(changes: SimpleChanges) {
		this.getAllHelpPages();
	}

	getAllHelpPages() {
		if (this.helpPages.length == 0) {
			let url = AppRestEndPoint.DASHBOARD.HELP_PAGES;
			this.apiService.getCall(url, '').subscribe(data => {
				this.helpPages = data.help_pages;
				this.setToolTip();
			});
		}else{

		}

	}


	setToolTip() {
		let self = this;
		let data = _.find(this.helpPages, function (val) {
			return val.code == self.code;
		});
		if(data){
			this.tooltipData = data.help_text;
		}
	}
}
