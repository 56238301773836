<div class="container">
    <div class="row">
        <div class="col-md-6 col-xs-10 col-sm-12 m-auto">
            <div class="card  border-radius-10 border-0 shadow-sm p-2">
                <div class="card-body pb-1">
                    <form [formGroup]="tokenForm" novalidate>
                        <h2 class="text-left mb-0 mt-2">CODE ENTRY</h2>
                        <hr class="header-hr my-1">
                        <div class="pb-2 pt-3">
                            Please enter the code below.
                        </div>
                        <div class="pb-3 pt-3">
                            <label class="text-uppercase">Security Code</label>
                            <input #myinput id="security" value='' type="text" name="security" placeholder=""
                                [(ngModel)]="phoneToken" formControlName="security" class="form-control form-input p-2"
                                required />
                            <div *ngIf="tokenFormSubmitted" class="text-danger" role="alert">
                                <div *ngIf="formControl.security.errors?.required">Security Code is mandatory.</div>
                            </div>
                        </div>
                        <div class="pt-3 pb-3">
                            <div class="input-group mb-3">
                                <div>
                                    <input type="checkbox" formControlName="rememberDevice" [(ngModel)]="rememberDevice"
                                        aria-label="Checkbox for following text input">
                                    <!-- <span class="ml-1 text-uppercase">Remember this Device </span> -->
                                    <label class="text-uppercase ml-2">Remember this Device</label>
                                </div>

                            </div>
                        </div>
                        <div *ngIf="isSmsToken">
                            <div>Didn't receive a code?</div>
                            <div class="pb-5">
                                <div class="float-left">
                                    <button type="button"
                                        class="btn btn-link font-14 float-left text-uppercase row color-purple"
                                        (click)="resendToken(false)" appButtonDisable>
                                        TEXT ME
                                    </button>
                                    <button type="button"
                                        class="btn btn-link font-14 float-left text-uppercase row color-purple ml-1"
                                        (click)="resendToken(true)" appButtonDisable>
                                        CALL ME
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="pb-5">
                            <div class="float-left" *ngIf="isSmsToken">
                                <button type="button"
                                    class="btn btn-link font-14 float-left text-uppercase row color-purple"
                                    (click)="openAuthyApp()">
                                    <u>DOWNLOAD AUTHY APP.</u>
                                </button>
                            </div>
                            <div class="float-right">
                                <button class="btn btn-primary btn-sm rounded-pill" (click)="verifyToken()" [formvalid]="tokenForm.valid" appButtonDisable>
                                    SUBMIT
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>