import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DocumentsComponent } from './documents/documents.component';
import { PlaidComponent } from './plaid/plaid/plaid.component';
import { ProfileComponent } from './profile/profile.component';
import { AuthGuard } from '../services/auth.guard';
import { TasksComponent } from './tasks/tasks.component';


const routes: Routes = [
	{
		path: 'dashboard',
		component: DashboardComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'documents',
		component: DocumentsComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'profile',
		component: ProfileComponent,
		canActivate: [AuthGuard]
	},
	{
        path: 'plaidOAuth',
		component: PlaidComponent,
		canActivate: [AuthGuard]
    },
	{
        path: 'tasks',
		component: TasksComponent,
		canActivate: [AuthGuard]
    }
];
@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
	providers: [AuthGuard]
})
export class DashboardRoutingModule { }
