import { Directive, Input, SimpleChanges, ElementRef, HostListener, Output } from '@angular/core';
import { NgModel } from '@angular/forms';
import { EventEmitter } from '@angular/core';
import { CurrencyPipe } from '@angular/common'



@Directive({
	selector: '[appFormatter]',
	providers: [NgModel]
})
export class FormatterDirective {

	@Input('appFormatter') appFormatter: any;
	el: any;
	@Output() ngModelChange = new EventEmitter();


	constructor(private ngModel: NgModel, private elementRef: ElementRef, private cp: CurrencyPipe
	) {
		this.el = this.elementRef.nativeElement;

	}

	@HostListener("focusout", ["$event.target.value"])
	ngOnInit(value) {
		if (this.el.value != 0 && this.appFormatter == 'currency') {
			this.el.value = Number(this.el.value.replace(/[^0-9.-]+/g, ""));
			this.el.value = this.cp.transform(this.el.value, 'USD', 'symbol', '1.0-0');
			this.ngModelChange.emit(this.el.value);
		}else if(this.el.value != 0 && this.appFormatter == 'percentage'){
			this.el.value = this.el.value.replace('%','');
			this.el.value  =this.el.value + '%';
			this.ngModelChange.emit(this.el.value);
		}
	}

}
