<ng-template #modal>
    <div class="modal-header">
        <div class="row w-100">
            <div class="col-md-11">
                <h5 class="modal-title">{{ modalConfig.modalTitle }}</h5>
            </div>
            <div class="col-md-1 pr-0">
                <button type="button" class="close float-right text-white pr-0" aria-label="Close" (click)="dismiss()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
    </div>
    <div class="modal-body">
        <ng-content></ng-content>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-link" (click)="dismiss()"
            *ngIf="modalConfig.hideDismissButton === undefined || !modalConfig.hideDismissButton()"
            [disabled]="modalConfig.disableDismissButton !== undefined && modalConfig.disableDismissButton()">
            {{ modalConfig.dismissButtonLabel }}
        </button>
        <button type="button" class="btn btn-primary btn-sm rounded-pill" (click)="submit()"
            *ngIf="modalConfig.hideCloseButton === undefined || !modalConfig.hideCloseButton()"
            [disabled]="modalConfig.disableCloseButton !== undefined && modalConfig.disableCloseButton()">
            {{ modalConfig.closeButtonLabel }}
        </button>
    </div>
</ng-template>