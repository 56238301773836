import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppRestEndPoint } from 'src/app/app-restEndPoint';
import { ToastService } from 'src/app/components/toaster/toast-service';
import { existingUsernameValidator } from 'src/app/directives/valid-name.directive';
import { ApiService } from 'src/app/services/api.service';
import { CommonService } from 'src/app/services/common.service';
import { LoginService } from 'src/app/services/login.service';
import { environment } from 'src/environments/environment';
import { PhoneFormatterPipe } from 'src/app/directives/phone-formatter/phone-formatter.pipe';
import { UtilityService } from 'src/app/services/utility.service';
import { Validator } from 'src/app/services/validator';
import { EventEmitterService } from 'src/app/services/event-emitter.service';


@Component({
	selector: 'app-signup',
	templateUrl: './signup.component.html',
	styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

	signUpFormSubmitted: any;
	signUpForm: FormGroup;
	signup: any = {};
	invalid: any = [];
	firm: any = {};
	selectedType: any;
	showConsentCheckbox: boolean = false;
	firmName: any;
	firmWebsite:any;
	textMessageConsent:any;

	constructor(private formBuilder: FormBuilder,
		private apiService: ApiService,
		public router: Router,
		private activatedRoute: ActivatedRoute,
		private commonService: CommonService,
		private toastService: ToastService,
		private loginService: LoginService,
		public utilityService: UtilityService,
		private eventEmitter: EventEmitterService
	) {
		this.signUpForm = this.formBuilder.group({
			'firstName': [null, Validators.required, existingUsernameValidator(this.apiService, '')],
			'phoneNumber': [null, [Validators.required]],
			'phoneType': [null, Validators.required],
			'email': [null, [Validators.required, Validators.pattern('^[A-Z0-9a-z\\._%+-]+@([A-Za-z0-9-]+\\.)+[A-Za-z]{2,4}$')]],
			'password': [null, Validators.compose([
				Validators.required,
				Validator.patternValidator(/[a-z]/, { hasSmallCase: true }),
				Validator.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
				Validator.patternValidator(/\d/, { hasNumber: true }),
				Validator.patternValidator(/[$@$!%*#?&]/, { hasSpecialCharacters: true }),
				Validators.minLength(8)
			])],
			'confirmPassword': [null, Validators.required],
			'terms': [null, Validators.required],
			'consent': [null]
		});
	}

	ngOnInit(): void {
		this.selectedType = this.activatedRoute.snapshot.paramMap.get('type');
		this.signup.phoneType = 3;
		// this value is set in email component
		if (sessionStorage.getItem('is_questionnaire_required_email')) {
			this.signup.email = sessionStorage.getItem('is_questionnaire_required_email');
		}
		if (this.commonService.getDomainDetails().firm_name) {
			this.firmName = this.commonService.getDomainDetails().firm_name;
			this.firm = this.commonService.getDomainDetails().firm;
			this.firmWebsite = this.commonService.getDomainDetails().firm.firm_website;
			this.showConsentCheckbox = this.commonService.getDomainDetails().firm.display_consent_for_text_message;
			this.textMessageConsent = this.commonService.getDomainDetails().firm.text_message_consent;
			if(this.showConsentCheckbox){
				this.signup.agreed_to_receive_text_message = true;
			}
		}else{
			this.eventEmitter.subscribe("Domain", (domainObj) => { 
				if (domainObj){
					this.firmName = domainObj.firm_name;
					this.firm = domainObj.firm;
					this.firmWebsite = domainObj.firm_website;
					this.showConsentCheckbox = domainObj.firm.display_consent_for_text_message;
					this.textMessageConsent = this.commonService.getDomainDetails().firm.text_message_consent;
					if(this.showConsentCheckbox){
						this.signup.agreed_to_receive_text_message = true;
					}
				}
			});
		}
	}

	get formControl() {
		return this.signUpForm.controls;
	}

	submitSignUp() {
		this.signUpFormSubmitted = true;
		this.invalid = [];
		let is_questionnaire_required = true;
		if (this.signup.password !== this.signup.confirmPassword) {
			this.signUpForm.controls['confirmPassword'].setErrors({ 'invalidMatch': true });
			return;
		}

		if (sessionStorage.getItem('is_questionnaire_required') && sessionStorage.getItem('is_questionnaire_required') == "false") {
			is_questionnaire_required = false;
		}

		if (this.signUpForm.valid) {
			let reqObj;
			reqObj = {
				"user": {
					"full_name": this.signup.firstName,
					"email": this.signup.email,
					"password": this.signup.password,
					"password_confirmation": this.signup.confirmPassword,
					"terms_and_conditions": this.signup.terms_and_conditions,
					"is_questionnaire_required": is_questionnaire_required
				}
			};
			if(this.signup.phoneType == 1){
				reqObj.user.phone1 = this.signup.phoneNumber;
				reqObj.user.phone1_type = this.signup.phoneType;
				reqObj.user.primary_phone_type = this.signup.phoneType;
			}else if(this.signup.phoneType == 3){
				reqObj.user.phone3 = this.signup.phoneNumber;
				reqObj.user.phone3_type = this.signup.phoneType;
				reqObj.user.primary_phone_type = this.signup.phoneType;
			}
			if (sessionStorage.getItem('advisor_admin_user_id')) {
				reqObj.user.advisor_admin_user_id = sessionStorage.getItem('advisor_admin_user_id');
			}
			if (sessionStorage.getItem('current_selected_household')) {
				reqObj.household_id = sessionStorage.getItem('current_selected_household');
			}
			if(this.showConsentCheckbox && this.signup.agreed_to_receive_text_message){
				reqObj.user.agreed_to_receive_text_message = true;
			}
			let url = AppRestEndPoint.LOGIN.SIGN_UP + `?client_id=${environment.client_id}&client_secret=${environment.client_secret}&firm_id=${this.commonService.getDomainDetails().firm_id}`; 
			this.loginService.postCall(url, reqObj).subscribe(response => {
				this.utilityService.gtmPush({'event': 'Client Onboarding - Account Details - Establish your profile'});
				if (response.message) {					
					this.toastService.show(response.message, {
						classname: 'bg-danger text-light', delay: 3000
					});
				} else if (response.password) {
					let len = response.password.length;
					for (let i = 0; i < len; i++) {
						this.invalid[i] = "Password " + response.password[i];
					}
				} else {					
					if (response.access_token) {
						let user = JSON.stringify(response.user);
						let user_id = response.user.id;
						sessionStorage.setItem('token', response.access_token.token);
						sessionStorage.setItem('current_user', user);
						sessionStorage.setItem('environment', response.environment);
						sessionStorage.setItem('knowledge_base_url', response.user.firm.is_knowledge_base_url_present);
						if (sessionStorage.getItem('current_selected_household') == '' || sessionStorage.getItem('current_selected_household') == null || sessionStorage.getItem('current_selected_household') == undefined) {
							sessionStorage.setItem('current_selected_household', response.user.selected_household_id);
						}
						if (response.access_token.session_sleep_time) {
							let idleTimeoutTimer;
							idleTimeoutTimer = parseInt(response.access_token.session_sleep_time) * 60000;
							sessionStorage.setItem('idleTimeoutTimer', idleTimeoutTimer);
							this.commonService.setIdleTimeout();
						}
						this.saveState(user_id);
					}
					this.router.navigate([this.selectedType+"/basic-info"]);
				}
			});
		}
	}

	saveState(user_id){
		let pageUrl = this.router.url.split('/');
		let currentState = pageUrl[pageUrl.length - 1];
		let reqObj;
		reqObj = {
            'user': { "gettingstarted_step": '{"info_type" :"' + this.selectedType + '","state" :"' + currentState + '"}' }
        };
		let url = AppRestEndPoint.LOGIN.SAVE_STATE + "/" + user_id;
		this.apiService.updateCall(url, reqObj);       
	}

}
