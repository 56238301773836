<app-bread-crumb-options></app-bread-crumb-options>
<div class="container">
    <div class="text-break text-center font-30 mt-5 mb-4">
        Your Financial Picture Like You've Never Seen It Before
    </div>
    <div class="color-555 text-center font-17 mb-3">
        The next step is to link your financial accounts. It's easier to make a decision when you have all the pertinent
        information.
    </div>
    <div class="color-555 text-center font-17 mb-3">
        By the same token, we build a better plan when we can see your entire financial picture.
    </div>
    <hr class="line-width">
    <div class="col-md-12 col-sm-12 col-xs-12 text-center font-16 mt-5 d-md-flex d-sm-flex margin-left-4-pct-desktop">
        <div class="col-md-3 col-sm-3 col-xs-12">
            <div>
                <img class="asset-img" src="../../../assets//images/secure_1x.png">
                <p class="mt-3">We do not store, share or have direct access to the username and password
                    information you use to link the accounts.</p>
            </div>
        </div>
        <div class="col-md-1 col-sm-1"></div>
        <div class="col-md-3 col-sm-3 col-xs-12">
            <div>
                <img class="asset-img" src="../../../assets//images/bank_1x.png">
                <p class="mt-3">Information is sent directly to your banking and financial institutions to verify
                    your login information.</p>
            </div>
        </div>
        <div class="col-md-1 col-sm-1"></div>
        <div class="col-md-3 col-sm-3 col-xs-12">
            <div>
                <img class="asset-img" src="../../../assets//images/chargebank_1x.png">
                <p class="mt-3">There is no charge for linking accounts from your banking or financial
                    institutions.</p>
            </div>
        </div>
    </div>
    <div>
         <!-- Web -->
        <app-buttonbar class="d-none d-sm-block" (clickedprev)="gotoPreviousState()" (clickedstop)="setPageNavigation()" (clickednext)="gotoAccountsPage()" showstopbtn="true"></app-buttonbar>
    </div>
    <div>
        <!-- Mobile -->
        <app-buttonbar class="d-block d-sm-none" (clickedprev)="gotoPreviousState()" (clickeddone)="setPageNavigation()" (clickednext)="gotoAccountsPage()" showdonebtn="true"></app-buttonbar>
    </div>
</div>