<div class="text-center border-top p-4 d-none d-sm-block">
    <button class="btn btn-grn rounded-pill btn-lg-custom my-2 mx-3" (click)="handleprevpage()" [hidden]="hidebackbtn">
        <i class="fa fa-long-arrow-left mr-3 align-middle font-24"></i>Go Back
    </button>
    <button class="btn btn-primary rounded-pill btn-lg-custom my-2 mx-4" (click)="handlestoppage()" [hidden]="!showstopbtn">
        Stop
    </button>
    <button class="btn btn-primary rounded-pill btn-lg-custom my-2 mx-4" (click)="handleupload()" [hidden]="!showuploadbtn">
        Upload Statements
    </button>
    <button class="btn btn-primary rounded-pill btn-lg-custom my-2 mx-4" (click)="handlenextpage()" [hidden]="hidenextbtn">
        Continue<i class="fa fa-long-arrow-right ml-3 align-middle font-24"></i>
    </button>
    <button class="btn btn-primary rounded-pill btn-lg-custom my-2 mx-4" (click)="handlenextpage()" [hidden]="!showdonebtn">
        All done for now
    </button>
    <button class="btn keep-going-btn rounded-pill btn-lg-custom my-2 mx-4" (click)="handledone()" [hidden]="!showdonebtn">
        Keep going<i class="fa fa-long-arrow-right ml-3 align-middle font-24"></i>
    </button>
</div>
<div class="border-top d-block d-sm-none col-sm-12 text-center px-0">
    <button class="btn btn-grn rounded-pill btn-sm mt-4 mb-4 float-left" (click)="handleprevpage()" [hidden]="hidebackbtn">
        Back
    </button>
    <button class="btn btn-grn rounded-pill btn-sm mt-4 mb-4 text-center" [ngClass]="{'float-left':hidebackbtn}" (click)="handledone()" [hidden]="!showdonebtn">
        Done
    </button>
    <button class="btn btn-primary rounded-pill btn-sm mt-4 mb-4 float-right" (click)="handlenextpage()" [hidden]="hidenextbtn">
        Next
    </button>
</div>