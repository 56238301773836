import { JsonPipe } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { AppRestEndPoint } from 'src/app/app-restEndPoint';
import { ToastService } from 'src/app/components/toaster/toast-service';
import { ApiService } from 'src/app/services/api.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
	selector: 'app-income-expense-modal',
	templateUrl: './income-expense-modal.component.html',
	styleUrls: ['./income-expense-modal.component.scss']
})
export class IncomeExpenseModalComponent implements OnInit {
	@Input() incomeExpenseLoadData;
	incomeForm: FormGroup;
	incomeFormSubmitted: Boolean = false;
	disableBtn: Boolean = false;
	incomeExpenseData: any;

	constructor(public activeModal: NgbActiveModal,
		private formBuilder: FormBuilder,
		private apiService: ApiService,
		private toastService: ToastService,
		public utilService: UtilityService,
		private cdr: ChangeDetectorRef
	) {
		this.incomeForm = this.formBuilder.group({
			'incomeArr': this.formBuilder.array([]),
			'expenseArr': this.formBuilder.array([])
		});
	}

	ngOnInit(): void {
		this.incomeExpenseData = JSON.parse(JSON.stringify(this.incomeExpenseLoadData));
		this.incomeExpenseData.monthly_income.income.forEach(element => {
			this.newIncomeInfo();
		});

		this.incomeExpenseData.monthly_expense.expense.forEach(element => {
			this.newExpenseInfo();
		});

		// adding 3 empty rows if array is empty
		let incomelen = this.incomeExpenseData.monthly_income.income.length;
		if(incomelen == 0){
			for(let i=0;i<3;i++){
				this.addRow('income');
			}
		}
		let expenselen = this.incomeExpenseData.monthly_expense.expense.length;
		if(expenselen == 0){
			for(let i=0;i<3;i++){
				this.addRow('expense');
			}
		}
	}

	ngAfterViewChecked(){
		this.cdr.detectChanges();
	}

	newIncomeInfo() {
		const control = <FormArray>this.incomeForm.controls['incomeArr'];
		control.push(this.addIncomeInfo());
	}

	addIncomeInfo(): FormGroup {
		return this.formBuilder.group({
			'description': [null],
			'annual_amount': [null],
			'amount': [null]
		});
	}

	get incomeArr(): FormArray {
		return this.incomeForm.get('incomeArr') as FormArray;
	}

	newExpenseInfo() {
		const control = <FormArray>this.incomeForm.controls['expenseArr'];
		control.push(this.addExpenseInfo());
	}

	addExpenseInfo(): FormGroup {
		return this.formBuilder.group({
			'expense_description': [null],
			'expense_amount': [null]
		});
	}

	get expenseArr(): FormArray {
		return this.incomeForm.get('expenseArr') as FormArray;
	}

	get formControl() {
		return this.incomeForm.controls;
	}
	
	get formControlIncomeArr() {
		return this.incomeForm.get('incomeArr')['controls'];
	}

	get formControlExpenseArr() {
		return this.incomeForm.get('expenseArr')['controls'];
	}

	addRow(type){
		if(type == 'income'){
			this.incomeExpenseData.monthly_income.income.push({
				'description':'',
				'annual_amount':'',
				'amount':''
			});
			this.newIncomeInfo();
		}else if(type == 'expense'){
			this.incomeExpenseData.monthly_expense.expense.push({
				'description':'',
				'amount':''
			});
			this.newExpenseInfo();
		}
	}

	deleteSelected(type, index) {
		if (type == 'income') {
			if (this.incomeExpenseData.monthly_income.income[index].id) {
				this.incomeExpenseData.monthly_income.income[index]._destroy = true;
			} else {
				this.incomeExpenseData.monthly_income.income.splice(index, 1);
				const control = <FormArray>this.incomeForm.controls['incomeArr'];
        		control.removeAt(index);
			}
		} else if (type == 'expense') {
			if (this.incomeExpenseData.monthly_expense.expense[index].id) {
				this.incomeExpenseData.monthly_expense.expense[index]._destroy = true;
			} else {
				this.incomeExpenseData.monthly_expense.expense.splice(index, 1);
				const control = <FormArray>this.incomeForm.controls['expenseArr'];
        		control.removeAt(index);
			}
		}
	}

	closeModal(data:any = '') {
		this.activeModal.close(data);
	}

	saveIncomeExpense() {
		this.incomeFormSubmitted = true;
		let incomelen = this.incomeExpenseData.monthly_income.income.length;
		for(let i=0;i<incomelen;i++){
			if(this.incomeExpenseData.monthly_income.income[i].description != '' && this.incomeExpenseData.monthly_income.income[i].description != null){
				this.formControlIncomeArr[i].controls.annual_amount.setValidators(Validators.required);
				this.formControlIncomeArr[i].controls.amount.setValidators(Validators.required);
			}else{
				this.formControlIncomeArr[i].controls.annual_amount.clearValidators();
				this.formControlIncomeArr[i].controls.amount.clearValidators();
				this.incomeExpenseData.monthly_income.income.splice(i,1);
			}
			this.formControlIncomeArr[i].controls.annual_amount.updateValueAndValidity();
			this.formControlIncomeArr[i].controls.amount.updateValueAndValidity();
		}
		let expenselen = this.incomeExpenseData.monthly_expense.expense.length;
		for(let i=0;i<expenselen;i++){
			if(this.incomeExpenseData.monthly_expense.expense[i].description != '' && this.incomeExpenseData.monthly_expense.expense[i].description != null){
				this.formControlExpenseArr[i].controls.expense_amount.setValidators(Validators.required);
			}else{
				this.formControlExpenseArr[i].controls.expense_amount.clearValidators();
				this.incomeExpenseData.monthly_expense.expense.splice(i,1);
			}
			this.formControlExpenseArr[i].controls.expense_amount.updateValueAndValidity();
		}
		
		if(this.incomeForm.valid){
			let incomeData = JSON.parse(JSON.stringify(this.incomeExpenseData.monthly_income.income));
			let expenseData = JSON.parse(JSON.stringify(this.incomeExpenseData.monthly_expense.expense));
			this.disableBtn = true;
			let reqObj = {
				"household": {					
					"user_incomes_attributes": this.formatIncomeReqObj(incomeData),
					"user_expenses_attributes": this.formatExpenseReqObj(expenseData)
				}
			};
			let url = AppRestEndPoint.DASHBOARD.INCOME_EXPENSE;
			this.apiService.updateCall(url, reqObj, '').subscribe(data => {
				this.disableBtn = false;
				if(data && data.incomeAndExpenses){
					this.toastService.show('Saved Successfully.', {
						classname: 'bg-success text-light', delay: 3000
					});
					let responseData:any = {};
					responseData.monthly_cash_flow = data.incomeAndExpenses.monthly_cash_flow;
					responseData.yearly_cash_flow = data.incomeAndExpenses.yearly_cash_flow;
					responseData.yearly_expense = data.incomeAndExpenses.yearly_expense;
					responseData.yearly_income = data.incomeAndExpenses.yearly_income;
					responseData.income_and_expense	= {};
					responseData.income_and_expense.last_updated_at = data.incomeAndExpenses.last_updated_at;
					responseData.income_and_expense.monthly_income = {};
					responseData.income_and_expense.monthly_expense = {};
					responseData.income_and_expense.monthly_income.total_monthly_income = data.incomeAndExpenses.monthlyIncome;
					responseData.income_and_expense.monthly_expense.total_monthly_expense = data.incomeAndExpenses.monthlyExpenses;
					responseData.income_and_expense.monthly_income.income = [];
					responseData.income_and_expense.monthly_expense.expense = [];
					data.incomeAndExpenses.incomeDetails.forEach(element => {
						responseData.income_and_expense.monthly_income.income.push({
							"description": element.income.source,
							"amount": element.income.value,
							"annual_amount": element.income.annual_amount,
							"id": element.income.id
						});
					});
			
					data.incomeAndExpenses.expenseDetails.forEach(element => {
						responseData.income_and_expense.monthly_expense.expense.push({
							"description": element.expense.source,
							"amount": element.expense.value,
							"id": element.expense.id
						});
					});
					this.closeModal({ 'income_expense_data': responseData });
				}else{
					this.toastService.show('Save Unsuccessful', {
						classname: 'bg-danger text-light', delay: 3000
					});
					this.closeModal();
				}
			});
		}
	}

	formatIncomeReqObj(data){
		let incomelen = data.length;
		for(let i=0;i<incomelen;i++){
			if(data[i].annual_amount != '' && data[i].annual_amount != null){
				data[i].annual_amount = Number(data[i].annual_amount.replace(/[^0-9\.-]+/g, ""));
				
			}
			if(data[i].amount != '' && data[i].amount != null){
				data[i].amount = Number(data[i].amount.replace(/[^0-9\.-]+/g, ""));
			}
		}
		return data;
	}

	formatExpenseReqObj(data){
		let expenselen = data.length;
		for(let i=0;i<expenselen;i++){
			if(data[i].amount != '' && data[i].amount != null){
				data[i].amount = Number(data[i].amount.replace(/[^0-9\.-]+/g, ""));
			}
		}
		return data;
	}
}
