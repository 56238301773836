import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-external-advisor-contact',
  templateUrl: './external-advisor-contact.component.html',
  styleUrls: ['./external-advisor-contact.component.scss']
})
export class ExternalAdvisorContactComponent implements OnInit {
  fullname: any;
  email: any;

  constructor(
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    let params = this.activatedRoute.snapshot.queryParams;
    this.email = params.email;
    this.fullname = localStorage.getItem('username');
    localStorage.removeItem('username');
  }

}
