<app-bread-crumb-options *ngIf="!isDocusignBreadCrumb"></app-bread-crumb-options>
<app-breadcrumb-options-docu-sign *ngIf="isDocusignBreadCrumb"></app-breadcrumb-options-docu-sign>
<div class="container">
    <div class="panel panel-default mt-5">
        <div class="panel-heading">
            <h3>Income & Expense Details</h3>
            <h5>Tell us about your monthly income and expenses.</h5>
        </div>
        <div class="panel-body">
            <div class="ques-block-row">
                <div class="ques-block-inr w-100 d-lg-flex d-md-flex  text-muted">
                    <div class="des">
                        MONTHLY INCOME
                        <span class="cur pt-2">{{incomeExpense.monthlyIncome | currency:'USD':'symbol':'1.0-0'}}</span>
                    </div>
                    <div class="des">
                        MONTHLY EXPENSES
                        <span
                            class="cur pt-2">{{incomeExpense.monthlyExpenses | currency:'USD':'symbol':'1.0-0'}}</span>
                    </div>
                    <div class="des">
                        MONTHLY NET CASH FLOW
                        <span
                            class="cur pt-2">{{incomeExpense.netMonthlyCashFlow | currency:'USD':'symbol':'1.0-0'}}</span>
                    </div>
                    <div class="des">
                        ANNUAL NET CASH FLOW
                        <span
                            class="cur pt-2">{{incomeExpense.netYearlyCashFlow | currency:'USD':'symbol':'1.0-0'}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <form>
        <div class="row mt-4">
            <div class="left-pane col-md-6 col-sm-12 col-xs-12">
                <h6 class="text-center text-sm text-muted">Monthly Income Sources (after-tax income)</h6>
                <div *ngFor="let incomeInput of incomeExpense.incomeDetails;let i = index">
                    <div class="slider-row mb-2" *ngIf="!incomeInput._destroy">
                        <div class="col-md-6 no-padding-right">
                            <input type="text" name="income{{i}}" class="form-control input-round"
                                [(ngModel)]="incomeInput.source" placeholder="Add Income" />
                            <!-- <div ng-messages="cashFlowForm['income'+$index].$error" ng-if="incomeInput.error" class="slider-row error-text error-msg" role="alert">
                                  <div ng-message="invalidIncome" class="error-text">Please enter Description.</div>
                              </div> -->
                        </div>
                        <div class="slider-mast col-md-3">
                            <input type="range" min="0" max="{{incomeInput.maxValue}}" [(ngModel)]="incomeInput.value"
                                value="{{incomeInput.value<=incomeInput.inputValue?incomeInput.value:incomeInput.inputValue}}"  name="rangeIncome{{i}}"
                                (input)="incomeInput.inputValue = $event.target.value; incomeExpenseSum('income'); incomeInput.maxValue=25000; incomeInput.step=250;" 
                                step="{{incomeInput.step}}" (click)="incomeInput.showEdit = false;">
                        </div>
                        <div class="slider-val col-md-3 text-right padding-right-20 no-padding-left">
                            <span *ngIf="!incomeInput.showEdit"
                                (click)="incomeInput.showEdit = !incomeInput.showEdit; incomeInput.step=''">{{incomeInput.value | currency:'USD':'symbol':'1.0-0'}}</span>

                            <input type="text" name="inputVal" class="form-control input-round"
                                *ngIf="incomeInput.showEdit" [(ngModel)]="incomeInput.inputValue"
                                (ngModelChange)="incomeExpenseSum('income'); incomeInput.inputValue<=25000?incomeInput.value=incomeInput.inputValue:incomeInput.value=25000"
                                (blur)="incomeInput.inputValue<=25000?incomeInput.maxValue=25000:'';" appFormatter="currency"
                                placeholder="$0">

                        </div>
                        <i class="fa fa-trash-o cursor-pointer" (click)="deleteElement(i,'income');"></i>
                    </div>
                </div>
                <div class="text-center mt-3">
                    <a class="btn btn-outline-primary  cashflow-btn rounded-pill btn-lg font-14"
                        (click)="addSource('income');sendMixPanelEvent({'event': 'Client Onboarding - Income Expense Details - Add Income Source'});"><i class="fa fa-plus color-purple"></i>&nbsp; Add Income
                        Source</a>
                </div>
            </div>

            <div class="right-pane col-md-6 col-xs-12 col-sm-12">
                <h6 class="text-center text-muted mt-sm-2 mt-xs-2">Monthly Expenses</h6>
                <div *ngFor="let expenseInput of  incomeExpense.expenseDetails ;let i = index">
                    <div class="slider-row mb-2" *ngIf="!expenseInput._destroy">
                        <div class="col-md-6 no-padding-right">
                            <input type="text" name="expense{{i}}" class="form-control input-round"
                                [(ngModel)]="expenseInput.source" placeholder="Add Expense" />
                            <!-- <div ng-messages="cashFlowForm['expense'+index].$error" ng-if="expenseInput.error" class="slider-row error-text error-msg" role="alert">
                                  <div ng-message="invalidExpense" class="error-text">Please enter Description.</div>
                              </div> -->
                        </div>
                        <div class="slider-mast col-md-3">
                            <input type="range" min="0" max="{{expenseInput.maxValue}}" [(ngModel)]="expenseInput.value"
                                [value]="expenseInput.value<=expenseInput.inputValue?expenseInput.value:expenseInput.inputValue" name="rangeExpense{{i}}"
                                (input)="expenseInput.inputValue = $event.target.value; incomeExpenseSum('expense'); expenseInput.maxValue=25000; expenseInput.step=250;"
                                step="{{expenseInput.step}}" (click)="expenseInput.showEdit = false;">
                        </div>
                        <div class="slider-val col-md-3 text-right padding-right-20 no-padding-left">
                            <span *ngIf="!expenseInput.showEdit"
                                (click)="expenseInput.showEdit = !expenseInput.showEdit; expenseInput.step=''">{{expenseInput.value | currency:'USD':'symbol':'1.0-0'}}</span>

                            <input type="text" name="inputVal" class="form-control input-round"
                                *ngIf="expenseInput.showEdit" [(ngModel)]="expenseInput.inputValue"
                                (ngModelChange)="incomeExpenseSum('expense'); expenseInput.inputValue<=25000?expenseInput.value=expenseInput.inputValue:expenseInput.value=25000"
                                (blur)="expenseInput.inputValue<=25000?expenseInput.maxValue=25000:'';"
                                appFormatter="currency" placeholder="$0">
                        </div>
                        <i class="fa fa-trash-o cursor-pointer" (click)="deleteElement(i,'expense');"></i>
                    </div>
                </div>
                <div class="text-center mt-3">
                    <a class="btn btn-outline-primary rounded-pill btn-lg font-14 cashflow-btn"
                        (click)="addSource('expense');sendMixPanelEvent({'event': 'Client Onboarding - Income Expense Details - Add Expense'});"><i class="fa fa-plus color-purple"></i>&nbsp; Add Expense</a>
                </div>
            </div>
        </div>
        <div class="pt-5">
            <app-buttonbar (clickedprev)="gotoPreviousState()" (clickednext)="saveIncomeExpense()"></app-buttonbar>
        </div>
    </form>
  
</div>