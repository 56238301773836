import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppRestEndPoint } from 'src/app/app-restEndPoint';
import { ApiService } from 'src/app/services/api.service';

@Component({
	selector: 'app-signup-complete',
	templateUrl: './signup-complete.component.html',
	styleUrls: ['./signup-complete.component.scss']
})
export class SignupCompleteComponent implements OnInit {

	selectedType: any;
	currentUser: any;
	advisor: any = {};

	constructor(
		private apiService: ApiService, 
		private router: Router,
		private activatedRoute: ActivatedRoute
	) { }

	ngOnInit(): void {
		this.selectedType = this.activatedRoute.snapshot.paramMap.get('type');
		this.currentUser = JSON.parse(sessionStorage.getItem('current_user'));
		this.saveState();
		this.getFamilyInfo();
	}

	getFamilyInfo() {
		let url = AppRestEndPoint.BASIC_INFO.FAMILY_INFO, param = '';
		this.apiService.getCall(url, param).subscribe(data => {
			if (data) {
				let familyData = data.household_users.household;
				this.advisor = {
					fullname: familyData.advisor_user_attributes.admin_user_fullname,
					email: familyData.advisor_user_attributes.admin_user_email
				};
			}
		});
	}

	saveState(){
		var reqObj = {
			'user': {"gettingstarted_step":'{"info_type" :"' +this.selectedType+'","state" :"signupComplete"}'}
		};
		let url = AppRestEndPoint.LOGIN.SAVE_STATE+'/'+this.currentUser.id;
		this.apiService.updateCall(url, reqObj, '').subscribe(data => {
		});
	}

	gotoDashboard() {
        this.router.navigate(['/dashboard']);  
    }

}
