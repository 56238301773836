<div class="modal-header">
    <div class="row w-100">
        <div class="col-md-11">
            <h5 class="modal-title">Banks</h5>
        </div>
        <div class="col-md-1 pr-0">
            <button type="button" class="close float-right text-white pr-0" aria-label="Close"
                (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
</div>

<div class="modal-body">
    <div class="bank-list">
        <div class="font-16">These are the banks you currently have linked to your account. You can fix any errors by
            clicking the Fix Bank button.</div>
        <div *ngIf="linkedBanks.length > 0" class="overflow-scroll-y-auto ml-2 mr-1 mb-2 mt-4">
            <div class="row pb-3" *ngFor="let bank of linkedBanks;let i = index">
                <div class="col-md-6">
                    <!--                <img src="{{bank.logo|| './shared/images/DAG.png' }}" class="list-img">-->
                    <div class="mt-2">{{bank.description}} <sup *ngIf="bank.timeframe_message"><i class="fa fa-question-circle cursor-pointer pl-1"  tooltipClass="tooltipData"
                                ngbTooltip="{{bank.timeframe_message}}" placement="top"></i></sup></div>
                </div>
                <div class="col-md-6 text-right">
                    <form #yodleeForm [action]="fastlinkUrl" method="POST" name="yodleeForm" target="abc" novalidate>
                        <input type="hidden" name="app" value="10003600" />
                        <input type="hidden" name="rsession" [value]="rsessionToken" class="rsession" />
                        <input type="hidden" name="token" [value]="fastLinkToken" class="token" />
                        <input type="hidden" name="redirectReq" value="true" />
                        <input type="hidden" name="extraParams" [value]="callBackUrl" />
                    </form>                   
                    <button class="btn btn-primary mr-1 cursor-pointer rounded-pill rl-working-btn font-14"
                        *ngIf="bank.status_color == 'green'">Working</button>

                    <button class="btn btn-primary mr-1 cursor-pointer rounded-pill font-14 link-err-btn"
                        *ngIf="bank.status_color == 'red'" (click)="fixBank(bank)"
                        [disabled]="i == clickedIndex || disableAddAccount" data-toggle="modal">Fix Bank &nbsp;
                        <span *ngIf="i == clickedIndex" class="loader-status">
                            <div class="loader loader-spinner top-10"></div>
                        </span>
                    </button>

                    <button class="btn btn-primary mr-1 cursor-pointer rounded-pill font-14 refresh-btn"
                        *ngIf="bank.status_color == 'orange'" (click)="fixBank(bank, 'refresh')"
                        [disabled]="i == clickedIndex || disableAddAccount" data-toggle="modal">Refresh Bank &nbsp;
                        <span *ngIf="i == clickedIndex" class="loader-status">
                            <div class="loader loader-spinner top-10"></div>
                        </span>
                    </button>

                    <button class="btn btn-primary mr-1 cursor-pointer rounded-pill rl-non-fix-btn font-14"
                        *ngIf="(bank.status!=0 || bank.status) && bank.not_fixable_bank"
                        (click)="showBankStatus(bank)">Bank Error</button>
                    <span>
                    <button class="btn btn-primary delete-btn-icon ml-1"
                        (click)="getSelectedLinkedAccount(bank)" data-toggle="modal"><i class="fa fa-1x fa-trash"></i>
                    </button> 
                </span>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-link" (click)="closeModal()">
        CANCEL
    </button>   
</div>
