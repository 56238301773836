<app-bread-crumb-options *ngIf="!isExternalPage"></app-bread-crumb-options>
<div class="container">
    <div class="row mx-5 margin-top-btm-3-pct">
        <div class="col-md-12 col-sm-6 col-xs-12 my-4">
            <div class="color-purple font-weight-600 font-20 mb-3">Question #{{questionNumber}}</div><br>
            <div class="text-left font-24 text-dark line-height-1pt3 font-weight-300 mb-3">
                {{questionList[questionNumber-1].description}} </div>
            <!-- Button bar -->
            <div class="col-sm-12 col-xs-12" *ngIf="questionList[questionNumber-1].question_type == 'Buttons'">
                <div class="d-inline-block m-2"
                    *ngFor="let option of questionList[questionNumber-1].investor_profile_answers; let i = index">
                    <button [disabled]="option.disabled" class="btn btn-md btn-default font-15"
                        [ngClass]="option.selected?'selected':''"
                        (click)="getSelectedAnswer(option)">{{option.description}}</button>
                </div>
            </div>
            <!-- Block -->
            <div class="d-flex flex-wrap mt-5 col-sm-12 col-xs-12"
                *ngIf="questionList[questionNumber-1].question_type == 'Blocks'">
                <div class="ques-block-inr ques-block-inr-width cursor-pointer" (click)="getSelectedAnswer(option)"
                    [ngClass]="{divdisabled: option.disabled, selected: option.selected}"
                    *ngFor="let option of questionList[questionNumber-1].investor_profile_answers; let i = index">
                    <div [hidden]="!option.title" class="text-center font-16 font-weight-bold">{{option.title}}</div>
                    <div class="desc font-15">{{option.description}}</div>
                    <span class="tick1" [ngClass]="option.selected?'selected':''"><i class="fa fa-check"></i></span>
                </div>
            </div>
            <!-- Image Block-->
            <div class="ques-block-display-flex flex-wrap mt-5 col-sm-12 col-xs-12"
                *ngIf="questionList[questionNumber-1].question_type == 'Images'">
                <div class="ques-block-inr ques-img-padding ques-block-inr-width-20 cursor-pointer"
                [ngClass]="{divdisabled: option.disabled, selected: option.selected}"
                    (click)="getSelectedAnswer(option)" [ngClass]="option.selected? 'selected': ''"
                    *ngFor="let option of questionList[questionNumber-1].investor_profile_answers; let i = index">
                    <div [hidden]="!option.title" class="text-center font-16 font-weight-bold">{{option.title}}</div>
                    <div class="question-img-padding"><img src="{{option.image.url}}" class="question-img"></div>
                    <span class="tick1" [ngClass]="option.selected? 'selected': ''"><i class="fa fa-check"></i></span>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="rtqEmail == 'true'">
        <app-buttonbar *ngIf="questionNumber>1" (clickedprev)="gotoPreviousState()" hidenextbtn="true"></app-buttonbar>
    </div>
    <div *ngIf="rtqEmail == null">
        <app-buttonbar (clickedprev)="gotoPreviousState()" hidenextbtn="true"></app-buttonbar>
    </div>
</div>