<div class="container">
    <div class="row">
        <div class="col-md-6 col-xs-10 col-sm-12 m-auto">
            <div class="card  border-radius-10 border-0 shadow-sm p-2">
                <div class="card-body pb-1">
                    <form [formGroup]="enableAuthyForm" novalidate>
                        <h2 class="text-left text-uppercase mb-0 mt-3">Enable Two-Factor Authentication</h2>
                        <hr class="header-hr my-1">
                        <div class="pb-3 pt-3">
                            <label class="text-uppercase">Location</label>
                            <select name="countryCode" placeholder="Select Country" class="form-control form-input p-2"
                                [(ngModel)]="country" formControlName="country" required="">
                                <option [ngValue]="undefined" selected>Select Country</option>
                                <option *ngFor="let country of countries" [ngValue]="country">{{country.label}}
                                </option>
                            </select>
                            <div *ngIf="enableAuthyFormSubmitted" class="text-danger pt-1" role="alert">
                                <div *ngIf="formControl.country.errors?.required">Country code is mandatory.</div>
                            </div>
                        </div>
                        <div class="pt-3 pb-5">
                            <label class="text-uppercase" for="authy-cellphone">Phone Number</label>
                            <input id="authy-cellphone" type="text" name="cellphone" formControlName="cellPhone"
                                placeholder="(000)000-0000" class="form-control form-input p-2" [(ngModel)]="cellPhone" appPhoneMask 
                                required="">
                            <div *ngIf="enableAuthyFormSubmitted" class="text-danger pt-1" role="alert">
                                <div *ngIf="formControl.cellPhone.errors?.required">Cell Phone number is mandatory.
                                </div>
                                <div  *ngIf="formControl.cellPhone.errors?.incorrect">
                                    Phone number must be at least 10 characters long.</div>
                            </div>
                        </div>
                        <div class="pb-5">
                            <div class="float-left">
                                <a class="cursor-pointer pt-2" (click)="gotoMfaPage()" tooltipClass="tooltipData"
                                ngbTooltip="Authy is an alternative Two-Factor Authentication service."
                                tooltip>Use Authy Instead</a>
                            </div>
                            <div class="float-right">
                                <button class="btn btn-primary btn-sm rounded-pill" (click)="enableAuthy(false)" [formvalid]="enableAuthyForm.valid" appButtonDisable>
                                    SUBMIT
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>