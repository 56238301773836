import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoginModule } from './login/login.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { GetStartedModule } from './get-started/get-started.module';
import { ValidNameDirective } from './directives/valid-name.directive';
import { DashboardModule } from './dashboard-main/dashboard.module';
import { AppInterceptor } from './app-http-interceptor';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { EmailLinkComponent } from './email-link/email-link.component';
import { DocuSignModule } from './docu-sign/docu-sign.module';
import { DeviceDetectorService } from 'ngx-device-detector';

@NgModule({
	declarations: [
		AppComponent,
		ValidNameDirective,
		EmailLinkComponent,

	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		SharedModule,
		NgbModule,
		LoginModule,
		GetStartedModule,
		DocuSignModule,
		DashboardModule,
		HttpClientModule,
		BsDatepickerModule.forRoot()
		

	],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
		DeviceDetectorService
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
