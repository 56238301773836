<div class="container">
    <div class="row">
        <div class="col-md-6 col-xs-10 col-sm-12 m-auto">
            <div class="card border-radius-10 border-0 shadow-sm p-2">
                <div class="card-body pb-1">
                    <form [formGroup]="forgotPwdForm" novalidate>
                        <h2 class="text-left mb-0 mt-3">Forgot Password</h2>
                        <hr class="header-hr my-1">
                        <div class="pt-3 pb-5">
                            <label class="form-label text-uppercase">Email</label>
                            <input name="email" type="email" autocomplete="off" class="form-control form-input p-2"
                                autofocus="true" (trim)="true" [(ngModel)]="emailId" formControlName="forgotPwdEmail"
                                (input)="($event.charCode==13)? forgotPassword() : ''">
                            <div *ngIf="forgotPwdSubmitted" class="text-danger" role="alert">
                                <div *ngIf="formControl.forgotPwdEmail.errors?.required">Email is required</div>
                                <div *ngIf="formControl.forgotPwdEmail.errors?.email">Email must be a valid email
                                    address
                                </div>
                            </div>
                        </div>
                        <div class="pb-5">
                            <div class="float-left">
                                <button type="button"
                                    class="btn font-15 float-left row color-purple"
                                    (click)="gotoLogin()">
                                    <u>Back To Login</u>
                                </button>
                            </div>
                            <div class="float-right">
                                <button class="btn btn-primary btn-sm rounded-pill"
                                    (click)="forgotPassword()" [formvalid]="forgotPwdForm.valid" appButtonDisable>
                                    Reset Password
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>