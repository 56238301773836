import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { AppRestEndPoint } from 'src/app/app-restEndPoint';
import * as _ from 'lodash';
import { ModalConfig } from 'src/app/components/modal/modal.config';
import { ToastService } from 'src/app/components/toaster/toast-service';
import { UtilityService } from 'src/app/services/utility.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UploadDocumentModalComponent } from '../upload-document-modal/upload-document-modal.component';
import { EventEmitterService } from 'src/app/services/event-emitter.service';

@Component({
	selector: 'app-documents',
	templateUrl: './documents.component.html',
	styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {

	completedDocumentData: any = [];
	documentData: any = [];
	taxDocumentData: any = [];
	docGroup: any;
	documentCount: any;
	complianceDocumentData:any = [];
	currentUser:any;

	constructor(
		private apiService: ApiService,
		private toastService: ToastService,
		public utilityService: UtilityService,
		public modalService: NgbModal,
		private eventEmitter: EventEmitterService,
	) { }

	ngOnInit(): void {
		this.currentUser = JSON.parse(sessionStorage.getItem('current_user'));
		this.loadDocument();
		if(this.currentUser.mfa_enabled && this.currentUser.is_authy_enabled){
			this.loadTaxDocuments();
		}
	}

	loadDocument() {
		let url = AppRestEndPoint.DASHBOARD.DOCUMENT_LIST;
		this.apiService.getCall(url, '&user_document_type_id=99').subscribe(data => {
			this.documentData = [];
			this.docGroup = [];
			let userDoc = [];
			if (data) {
				if(data.user_documents){
					userDoc = data.user_documents;
					this.documentData = _.mapValues(_.groupBy(userDoc, 'created_at_year'));
				}
				this.documentCount = data.user_document_notification_count;
				this.setDocumentCount();
			}
			let completedDocData = []
			if(this.currentUser.mfa_enabled && this.currentUser.is_authy_enabled){
				this.apiService.getCall(url, '&status=complete&document_type=ips_iaa').subscribe(data => {
					if (data) {
						if(data.user_documents){
							completedDocData = data.user_documents;
							this.completedDocumentData = _.mapValues(_.groupBy(completedDocData, 'created_at_year'));
						}
						this.documentCount = data.user_document_notification_count;
						this.setDocumentCount();
					}
				});
			}
			if(this.currentUser.firm.show_compliance_documents_folder){
				this.apiService.getCall(AppRestEndPoint.DASHBOARD.COMPLAINCE_DOCUMENTS, '').subscribe(data => {
					this.complianceDocumentData = [];
					if (data && data.documents) {
						this.complianceDocumentData = data.documents;
					}
				});
			}
		});
	}

	loadTaxDocuments() {
		let url = AppRestEndPoint.DASHBOARD.DOCUMENT_LIST;
		this.apiService.getCall(url, '&document_type=tax_document').subscribe(data => {
			this.taxDocumentData = [];
			if (data && data.user_documents) {
				if(data.user_documents){
					this.taxDocumentData = _.mapValues(_.groupBy(data.user_documents, 'tax_year'))
					this.documentCount = data.user_document_notification_count;
				}
				this.setDocumentCount();
			}
		});
	}

	orderByDesc = (a, b) => {
		return a.key > b.key ? -1 : (b.key > a.key ? 1 : 0);
	}

	public uploadDocModalConfig: ModalConfig = {
		modalTitle: "Add Document",
		onSubmit: () => {
			//this.saveDocument();
			return true;
		},
		dismissButtonLabel: "Cancel",
		closeButtonLabel: "Add Document",

	}

	setDocumentCount(){
		this.eventEmitter.broadcast("updateDocumentCount", this.documentCount);
		sessionStorage.setItem('user_document_notification_count', JSON.stringify(this.documentCount));
	}


	openDocument(document, index, year, docType) {
		//if unread decrement the count and remove highlight css
		let param = ''
		if (document.is_read == false) {
			if (docType == 'complete') {
				this.completedDocumentData[year][index].is_read = true;
				this.documentCount = this.documentCount - 1;
				this.setDocumentCount();
			} else if(docType == 'tax'){
				this.taxDocumentData[year][index].is_read = true;
				this.documentCount = this.documentCount - 1;
				this.setDocumentCount();
			}else{
				this.documentData[year][index].is_read = true;
				this.documentCount = this.documentCount - 1;
				this.setDocumentCount();
			}


		}
		docType == 'tax' ?param =  "&document_type=tax_document":'';
		let url = AppRestEndPoint.DASHBOARD.DOWNLOAD_DOCUMENT.replace(':id', document.id);
		this.apiService.getCall(url, param).subscribe(data => {
			if (data && data.url) {
				window.open(data.url, '_blank');
			} else {
				this.toastService.show('Document URL does not exist.', {
					classname: 'bg-danger text-light', delay: 3000
				});
			}
		});
	};

	openUploadDocumentModal() {
		this.modalService.open(UploadDocumentModalComponent,
			{
				windowClass: 'modal-width-850',
				backdrop: 'static'
			}
		).result.then((result) => {
			if (result == 'docUpload') {
				this.loadDocument();
			}
		}, (reason) => {
		});

	}

	openComplianceDocument (url){
        window.open(url, '_blank');
    }

}
