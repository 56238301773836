<app-navbar></app-navbar>
<div resize-window>
    <div class="float-right border-bottom pb-2">
        <button class="btn btn-primary btn-sm rounded-pill uploacDocBtn"
            (click)="openUploadDocumentModal()">Upload Document</button>
    </div>
    <div class="container-fluid ml-5 mr-5 margin-top-90">
        <div class="pt-5 ml-5 mr-5">
            <ngb-tabset [destroyOnHide]="false">
                <ngb-tab>
                    <ng-template ngbTabTitle>Shared</ng-template>
                    <ng-template ngbTabContent>
                        <div class="row">
                            <div class="container col-md-12 mt-5 text-center" *ngIf="documentData.length==0">No records
                                returned</div>
                            <div class="container col-md-12 mt-5">
                                <div id="accordion" class="mb-2"
                                    *ngFor="let document of documentData| keyvalue: orderByDesc ; let j = index ">
                                    <div class="card">
                                        <div class="card-header bg-white" id="headingOne">
                                            <h4 class="mb-0 float-left w-100">
                                                <button class="btn btn-link text-dark font-18 w-100"
                                                    data-toggle="collapse" [attr.data-target]="'#collapseAdv'+j"
                                                    aria-expanded="true" aria-controls="collapseAdv" (click)="document.expanded = !document.expanded">
                                                    <span class="float-left">{{document.key}}</span>
                                                    <i class="fa fa-chevron-down float-right mt-2 color-gray" aria-hidden="true"  aria-expanded="true" aria-controls="collapseAdv" [attr.data-target]="'#collapseAdv'+j" data-toggle="collapse"  *ngIf="!document.expanded"></i>
                                                    <i class="fa  fa-chevron-up float-right mt-2 color-gray" aria-hidden="true"   aria-expanded="true" aria-controls="collapseAdv" [attr.data-target]="'#collapseAdv'+j" data-toggle="collapse"  *ngIf="document.expanded"></i>
                                                </button>
                                            </h4>
                                        </div>
                                        <div [id]="'collapseAdv'+j" class="collapse" aria-labelledby="headingOne"
                                            data-parent="#accordion">
                                            <div class="card-body">
                                                <table class="table">
                                                    <tbody>
                                                        <tr *ngFor="let documentValue of document.value;let k = index"
                                                            [ngClass]="{'highlight-row':!documentValue.is_read}">
                                                            <td width="70%" class="border-top-0 border-bottom">
                                                                <i class="fa fa-file-text-o mr-4"
                                                                    aria-hidden="true"></i><span class="cursor-pointer"
                                                                    (click)="openDocument(documentValue,k,document.key, 'shared')">{{documentValue.description}}</span>
                                                            </td>
                                                            <td class="border-top-0 border-bottom">
                                                                <span
                                                                    *ngIf="documentValue.created_by">{{documentValue.created_by.name?documentValue.created_by.name:documentValue.user.name}}
                                                                </span>
                                                            </td>
                                                            <td class="border-top-0 border-bottom">
                                                                <span>{{documentValue.created_at | date:'MM/dd/yyyy'}}</span>
                                                            </td>
                                                            <td class="border-top-0 border-bottom">
                                                                <i class="fa fa-download text-success cursor-pointer float-right"
                                                                    aria-hidden="true" tooltipClass="tooltipData" 
                                                                    ngbTooltip="Download"
                                                                    (click)="openDocument(documentValue,k,document.key, 'shared' )"></i> 

                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </ng-template>
                </ngb-tab>
                <ngb-tab title="Tax">
                    <ng-template ngbTabContent>
                        <div class="row" *ngIf="!currentUser.mfa_enabled || !currentUser.is_authy_enabled">
                            <div class="container col-md-12 mt-5 text-center">In order to see your Tax Documents, you must have Two-Factor Authentication enabled.</div>
                        </div>
                        <div class="row" *ngIf="currentUser.mfa_enabled && currentUser.is_authy_enabled">
                            <div class="container col-md-12 mt-5 text-center" *ngIf="taxDocumentData.length==0">No
                                records returned</div>
                            <div class="container col-md-12 mt-5">
                                <div id="taxaccordion" class="mb-2"
                                    *ngFor="let document of taxDocumentData | keyvalue: orderByDesc; let i= index">
                                    <div class="card">
                                        <div class="card-header bg-white" id="headingOne">
                                            <h4 class="mb-0 float-left w-100">
                                                <button class="btn btn-link text-dark font-18  w-100" (click)="document.expanded = !document.expanded"
                                                    data-toggle="collapse" [attr.data-target]="'#collapseTaxDoc'+i"
                                                    aria-expanded="true" [attr.aria-controls]="'collapseTaxDoc'+i">
                                                    <span class="float-left">{{document.key}}</span>
                                                    <i class="fa fa-chevron-down float-right mt-2 color-gray" aria-hidden="true"  aria-expanded="true" aria-controls="collapseTaxDoc" [attr.data-target]="'#collapseTaxDoc'+i" data-toggle="collapse"  *ngIf="document.expanded"></i>
                                                    <i class="fa  fa-chevron-up float-right mt-2 color-gray" aria-hidden="true"  aria-expanded="true" aria-controls="collapseTaxDoc" [attr.data-target]="'#collapseTaxDoc'+i" data-toggle="collapse" *ngIf="!document.expanded"></i>
                                                </button>
                                            </h4>
                                        </div>
                                        <div [id]="'collapseTaxDoc'+i" class="collapse show" aria-labelledby="headingOne"
                                            data-parent="#taxaccordion">
                                            <div class="card-body">
                                                <table class="table">
                                                    <thead>
                                                        <tr>
                                                            <th>Document</th>
                                                            <th>Account</th>
                                                            <th>Type</th>
                                                            <th>Source</th>
                                                            <th>Tax Year</th>
                                                            <th class="text-right">Production</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let documentValue of document.value;let i = index"
                                                            [ngClass]="{'highlight-row':!documentValue.is_read}">
                                                            <td class="border-top-0 border-bottom">
                                                                <i class="fa fa-file-text-o mr-4"
                                                                    aria-hidden="true"></i><span class="cursor-pointer"
                                                                    (click)="openDocument(documentValue,i,document.key, 'tax')">{{documentValue.description}}</span>
                                                            </td>
                                                            <td class="border-top-0 border-bottom">
                                                                {{documentValue.account?.account_group_member.name}}
                                                                <span *ngIf="documentValue.account"> ({{documentValue.account?.account_group_member.key}})</span>
                                                            </td>
                                                            <td nowrap class="border-top-0 border-bottom">
                                                                {{documentValue.account?.account_type.description}}
                                                            </td>
                                                            <td nowrap class="border-top-0 border-bottom text-capitalize">
                                                               <span>{{documentValue.account?.account_custodian?.integration_type}}</span>
                                                            </td>
                                                            <td nowrap class="border-top-0 border-bottom">
                                                                {{documentValue.tax_year}}
                                                            </td>
                                                            <td class="border-top-0 border-bottom text-right">
                                                                {{documentValue.created_at | date:'MM/dd/yyyy'}}
                                                            </td>
                                                            <td class="border-top-0 border-bottom">
                                                                <i class="fa fa-download text-success cursor-pointer float-right"
                                                                   ngbTooltip="Download" tooltipClass="tooltipData" 
                                                                    (click)="openDocument(documentValue, i,document.key, 'tax')"></i>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </ngb-tab>
                <ngb-tab title="Completed" *ngIf="currentUser.mfa_enabled && currentUser.is_authy_enabled">
                    <ng-template ngbTabContent>
                        <div class="row">
                            <div class="container col-md-12 mt-5 text-center" *ngIf="completedDocumentData.length==0">No
                                records returned</div>
                            <div class="container col-md-12 mt-5">
                                <div id="accordion" class="mb-2"
                                    *ngFor="let document of completedDocumentData | keyvalue: orderByDesc; let i= index">
                                    <div class="card">
                                        <div class="card-header bg-white" id="headingOne">
                                            <h4 class="mb-0 float-left w-100">
                                                <button class="btn btn-link text-dark font-18  w-100" (click)="document.expanded = !document.expanded"
                                                    data-toggle="collapse" [attr.data-target]="'#collapseDoc'+i"
                                                    aria-expanded="true" [attr.aria-controls]="'collapseDoc'+i">
                                                    <span class="float-left">{{document.key}}</span>
                                                    <i class="fa fa-chevron-down float-right mt-2 color-gray" aria-hidden="true"  aria-expanded="true" aria-controls="collapseDoc" [attr.data-target]="'#collapseDoc'+i" data-toggle="collapse"  *ngIf="!document.expanded"></i>
                                                    <i class="fa  fa-chevron-up float-right mt-2 color-gray" aria-hidden="true"  aria-expanded="true" aria-controls="collapseDoc" [attr.data-target]="'#collapseDoc'+i"  data-toggle="collapse" *ngIf="document.expanded"></i>
                                                </button>
                                            </h4>


                                        </div>
                                        <div [id]="'collapseDoc'+i" class="collapse" aria-labelledby="headingOne"
                                            data-parent="#accordion">
                                            <div class="card-body">
                                                <table class="table">
                                                    <tbody>
                                                        <tr *ngFor="let documentValue of document.value;let i = index"
                                                            [ngClass]="{'highlight-row':!documentValue.is_read}">
                                                            <td width="70%" class="border-top-0 border-bottom">
                                                                <i class="fa fa-file-text-o mr-4"
                                                                    aria-hidden="true"></i><span class="cursor-pointer"
                                                                    (click)="openDocument(documentValue,i,document.key, 'complete')">{{documentValue.description}}</span>
                                                            </td>
                                                            <td class="border-top-0 border-bottom">
                                                                <span>{{documentValue.created_by.name?documentValue.created_by.name:documentValue.user.name}}
                                                                </span>
                                                            </td>
                                                            <td class="border-top-0 border-bottom">
                                                                <span>{{documentValue.created_at | date:'MM/dd/yyyy'}}</span>
                                                            </td>
                                                            <td class="border-top-0 border-bottom">
                                                                <i class="fa fa-download text-success cursor-pointer float-right"
                                                                   ngbTooltip="Download" tooltipClass="tooltipData" 
                                                                    (click)="openDocument(documentValue, i,document.key, 'complete')"></i>

                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </ngb-tab>
                <ngb-tab title="Compliance" *ngIf="currentUser.firm.show_compliance_documents_folder">
                    <ng-template ngbTabContent>
                        <div class="row">
                            <div class="container col-md-12 mt-5 text-center" *ngIf="complianceDocumentData.length==0">No
                                records returned</div>
                            <div class="container col-md-12 mt-3">
                                <div id="accordion" class="mb-2">
                                    <div class="card">
                                       
                                        <div aria-labelledby="headingOne">
                                            <div class="card-body">
                                                <table class="table">
                                                    <tbody>
                                                        <tr *ngFor="let documentValue of complianceDocumentData;let i = index">
                                                            <td width="70%" class="border-top-0 border-bottom">
                                                                <i class="fa fa-file-text-o mr-4"
                                                                    aria-hidden="true"></i><span class="cursor-pointer"
                                                                    (click)="openComplianceDocument(documentValue.url)">{{documentValue.description}}</span>
                                                            </td>
                                                            <td class="border-top-0 border-bottom">
                                                            </td>
                                                            <td class="border-top-0 border-bottom">
                                                            </td>
                                                            <td class="border-top-0 border-bottom">
                                                                <i class="fa fa-download text-success cursor-pointer float-right"
                                                                   ngbTooltip="Download" tooltipClass="tooltipData" 
                                                                    (click)="openComplianceDocument(documentValue.url)"></i>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </ngb-tab>
                
            </ngb-tabset>
        </div>
    </div>
</div>
