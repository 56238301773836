import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
import { PhoneFormatterPipe } from 'src/app/directives/phone-formatter/phone-formatter.pipe';

@Directive({
	selector: '[appPhoneMask]'
})
export class PhoneMaskDirective {

	constructor(public ngControl: NgControl, public phoneFormatter: PhoneFormatterPipe) { }

	@HostListener('ngModelChange', ['$event'])
	onModelChange(event) {
		this.onInputChange(event, false);
	}

	@HostListener('keydown.backspace', ['$event'])
	keydownBackspace(event) {
		this.onInputChange(event.target.value, true);
	}

	onInputChange(event, backspace) {
		if (event) {
			let newVal = event.toString().replace(/\D/g, '');
			this.ngControl.valueAccessor.writeValue(this.phoneFormatter.transform(newVal));
		}
	}


}
