import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AppRestEndPoint } from 'src/app/app-restEndPoint';
import { ToastService } from 'src/app/components/toaster/toast-service';
import { CommonService } from 'src/app/services/common.service';
import { EventEmitterService } from 'src/app/services/event-emitter.service';
import { LoginService } from 'src/app/services/login.service';
import { PusherService } from 'src/app/services/pusher.service';
import { UtilityService } from 'src/app/services/utility.service';
import { environment } from 'src/environments/environment';


@Component({
    selector: 'app-login-form',
    templateUrl: './login-form.component.html',
    styleUrls: ['./login-form.component.scss']
})

export class LoginFormComponent implements OnInit {
    environment: any;

    @ViewChild('NgbdDatepicker') d: NgbDateStruct;
    birthdate: NgbDateStruct;
    loginForm: FormGroup;
    loginFormSubmitted: boolean;
    login: any = {};
    countReCalProj: any;
    authyServerBind: boolean = false;
    redirect_to_state: any;
    hideLogin: boolean = false;
    firmTermsUrl: any;
    password_token: any;
    auto_login: any;

    constructor(
        private loginService: LoginService,
        private formBuilder: FormBuilder,
        private utilService: UtilityService,
        private router: Router,
        private toastService: ToastService,
        private commonService: CommonService,
        private pusherService: PusherService,
        private activatedRoute: ActivatedRoute,
        private eventEmitter: EventEmitterService) {
        this.environment = environment;
        this.loginForm = this.formBuilder.group({
            userEmail: ['', [Validators.required, Validators.email]],
            userPassword: ['', [Validators.required]]
        });
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('current_user');
        sessionStorage.removeItem('current_index');
        sessionStorage.removeItem('household_username');
        sessionStorage.removeItem('environment');
        sessionStorage.removeItem('countReCalProjVal');
        sessionStorage.removeItem('adminUsers');
    }

    ngOnInit(): void {
        //throw new Error('Method not implemented.');
        //For Reclaculate Retirement Projection 
        this.countReCalProj = 0;

        // set root firm setting based on url firm code
        if (this.activatedRoute.snapshot.paramMap.get('firmCode')) {
            this.utilService.setRootScopeFirm(this.activatedRoute.snapshot.paramMap.get('firmCode'));
        } 
        //Fetching the firm related details
        if (this.commonService.getDomainDetails().firm) {
			let firm = this.commonService.getDomainDetails().firm;           
            this.firmTermsUrl = firm? firm.terms_of_service_url:'';
            this.emailRedirectToPage();
		}else{
            this.eventEmitter.subscribe("Domain", (domainObj) => { 
				if (domainObj){
                    let firm = domainObj.firm;	
                    this.firmTermsUrl = firm? firm.terms_of_service_url:'';
                    this.emailRedirectToPage();
                }
            });
		}       

    }


    emailRedirectToPage(){
        let params = this.activatedRoute.snapshot.queryParams;
        if (params.state == 'rtq') {
            this.hideLogin = true;
        } else {
            this.hideLogin = false;
        }
        this.password_token = params['token'];
        this.auto_login = params['auto_login']; 
        switch (params.state) {
            case 'rtq':
                this.redirect_to_state = 'risk-tolerance';
                break;
            case 'onboarding_invitation':
                this.redirect_to_state = 'basic-info';
                break;
            case 'portfolio':
                this.redirect_to_state = 'portfolio';
                break;

            case 'docusign':
                this.redirect_to_state = 'docusign';
                break;
            case 'posted_document':
                this.redirect_to_state = 'dashboard';
                break;
            case 'identify_info_required':
                this.redirect_to_state = 'additional-information';
                break;
            case 'request_account_info':
                this.redirect_to_state = 'docusign';
                break;
            case 'task':
                this.redirect_to_state = 'tasks';
                break; 
        }

        //rtq redirect - if login type= 'reset password' resdirect to rtq page after login.
        if (params.state === undefined && sessionStorage.getItem("rtq_redirect") === 'true') {
            this.redirect_to_state = 'risk-tolerance';
        } else if (params.state === undefined && sessionStorage.getItem("onboarding_invitation") === 'true') {
            this.redirect_to_state = 'basic-info';
        }
        // if the url contains and auto_login kicker we do that using the token
        if (params.auto_login && params.token) {
            let reqObj;
            reqObj = {
                "client_id": environment.client_id,
                "client_secret": environment.client_secret
            }

            let url = AppRestEndPoint.LOGIN.TOKEN_SIGN_IN + "?token=" + params.token + "&firm_id=" + this.commonService.getDomainDetails().firm_id;
            this.loginService.postCall(url, reqObj).subscribe(response => {
                if (response.success) {
                    var result = response;
                    if (result.access_token) {
                        this.loginResponseSet(result);
                        if (this.redirect_to_state) {
                            this.commonService.returnLastSavedState(this.redirect_to_state);
                        }
                        else {
                            this.router.navigate(['dashboard']);
                        }
                    }
                }
                else if (!response.success) {
                    this.toastService.show(response.msg, {
                        classname: 'bg-danger text-light', delay: 3000
                    });
                    if (this.commonService.getDomainDetails().firm_code) {
                        this.router.navigateByUrl('/login/firm/' + this.commonService.getDomainDetails().firm_code);
                    } else {
                        this.router.navigateByUrl('/login');
                    }
                    //window.location.reload();

                }
            });
        }
    }

    submitLogin() {
        let loginValues = {
            user_name: this.login.email,
            password: this.login.password,
            client_id: environment.client_id,
            client_secret: environment.client_secret,
            firm_id: this.commonService.getDomainDetails().firm_id,
            device_id: this.utilService.getDeviceCookie(),
            user_agent: this.utilService.getUserAgent()
        }

        this.loginService.postCall(AppRestEndPoint.LOGIN.SIGN_IN, loginValues).subscribe(data => {
            this.loginFormSubmitted = true;
            if (this.loginForm.valid) {
                if (data.success) {
                    if (data.access_token) {
                        this.loginResponseSet(data);
                        if (data.user.is_questionnaire_required == false) {
                            let is_questionnaire_required = "false";
                            sessionStorage.setItem('is_questionnaire_required', is_questionnaire_required);
                        } else if (data.user.is_questionnaire_required == true) {
                            let is_questionnaire_required = "true";
                            sessionStorage.setItem('is_questionnaire_required', is_questionnaire_required);
                        }

                        if (sessionStorage.getItem('rtq_redirect') == null) {
                            sessionStorage.setItem('login_rtq_id', data.user.rtq_id);
                        }
                        this.utilService.gtmIdentify(data.user.id,{});

                        this.utilService.gtmProfile({
                            'userEmail' : data.user.email,
                            'userName' : data.user.name,
                            'userFirmId' : data.user.firm.id,
                            'userFirm' : data.user.firm.description,
                            'userId': data.user.id
                        });   

                        this.utilService.gtmPush({'event': 'Login Success', 'method': 'username_password'});
                        this.utilService.gtmPush({'event': 'Login Redirected to Home Page'})   

                       // this.redirect_to_state = false;
                        if (this.redirect_to_state) {
                            //TO-DO Need to return to the last saved state
                            this.commonService.returnLastSavedState(this.redirect_to_state);
                        }
                        else if (data.user.firm.mfa_enabled == true && data.user.mfa_enabled == false) {
                            this.loginResponseSet(data);
                            localStorage.setItem('user_id', data.user.id);
                            this.router.navigate(['enableMFASplash']);
                        }
                        else {
                            this.router.navigate(['dashboard']);
                        }

                    } else {
                        //Already Authy Enabled for the user
                        var user_id;
                        if (data.authy_enabled) {
                            this.utilService.gtmPush({'event': 'Login Redirected to MFA Entry'});
                            this.pusherService.subscribeAuthyServer(data.authy_id);
                            user_id = data.user_id;
                            localStorage.setItem('user_id', user_id);
                            localStorage.setItem('authy_type',data.authy_type);
                            this.initiateAuthyPusher(data);
                            if (!this.authyServerBind) {
                                if(this.redirect_to_state){
                                    this.router.navigate(['enterToken', { info: data.is_remember_device ,emailParams: this.redirect_to_state}]);
                                }else{
                                    this.router.navigate(['enterToken', { info: data.is_remember_device}]);
                                }
                            }

                        }
                        //Registration block
                        else {
                            this.utilService.gtmPush({'event': 'Login Redirected to MFA Setup'});
                            this.router.navigate(['enableMFA']);
                            user_id = data.user_id;
                            localStorage.setItem('user_id', user_id);
                            sessionStorage.setItem('token', data.token);
                        }
                    }
                } else {
                    this.utilService.gtmPush({
                        'event': 'Login Failed',
                        'userId' : null,
                        'userName' : null,
                        'userEmail' : this.login.email,
                        'userFirmId' : null,
                        'userFirm' : null
                    });
                    this.toastService.show(data.msg.replace(/<\/?[^>]+(>|$)/g, "\n"), {
                        classname: 'bg-danger text-light', delay: 7000
                    });
                }
            }

        },

            // loginService.postCall threw an error (probably bad, not sure)
            (err) => {


                // this is good example of when we need to NOT throw an error to the global event handler
                // this will naturally return 404 on bad credentials and we want to handle that ourselves
                // throw err;

            });

    }

    loginResponseSet(result) {

        let user = JSON.stringify(result.user);
        let is_questionnaire_required = result.user.is_questionnaire_required;
        sessionStorage.setItem('token', result.access_token.token);
        sessionStorage.setItem('current_user', user);
        sessionStorage.setItem('environment', result.environment);
        sessionStorage.setItem('firm_Name', result.user.firm.name);
        sessionStorage.setItem('countReCalProjVal', this.countReCalProj);
        sessionStorage.setItem('knowledge_base_url', result.user.firm.is_knowledge_base_url_present);
        if (result.access_token.session_sleep_time) {
            let idleTimeoutTimer;
            idleTimeoutTimer = parseInt(result.access_token.session_sleep_time) * 60000;
            sessionStorage.setItem('idleTimeoutTimer', idleTimeoutTimer);
            this.commonService.setIdleTimeout();
        }
    };

    initiateAuthyPusher(result) {
        this.pusherService.bind(result.uuid, function (data) {
            this.authyServerBind = true;
            this.verifyAuthyServer(result);
        }.bind(this));
    }

    verifyAuthyServer(result) {
        let params = {          
            client_id: environment.client_id,
            client_secret: environment.client_secret,
            firmId: this.commonService.getDomainDetails().firm_id,
            "authy_id": result.authy_id,
            "uuid": result.uuid
        }
        this.loginService.postCall(AppRestEndPoint.LOGIN.VERIFY_ONE_TOUCH, params).subscribe(data => {
            if (data.success) {
                this.loginResponseSet(data);
                this.router.navigate(['dashboard']);
            }
            else {
                this.toastService.show(data.msg.replace(/<\/?[^>]+(>|$)/g, "\n"), {
                    classname: 'bg-danger text-light', delay: 7000
                });
            }

        })
    }

    gotoForgotPwd() {
        this.router.navigate(['forgotPassword']);
    }


    get formControl() {
        return this.loginForm.controls;
    }

    sendMixpanelEvent(eventObj){
        this.utilService.gtmPush(eventObj);
    }
   
}



