import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-breadcrumb-options-docu-sign',
	templateUrl: './breadcrumb-options-docu-sign.component.html',
	styleUrls: ['./breadcrumb-options-docu-sign.component.scss']
})
export class BreadcrumbOptionsDocuSignComponent implements OnInit {

	currentIndex: number;
	infoType: any;

	constructor(public router: Router,
		private activatedRoute: ActivatedRoute) { }

	ngOnInit(): void {
		this.infoType = this.activatedRoute.snapshot.paramMap.get('type');
		let pageUrl = document.URL.split('/');
		let currentActiveTab = pageUrl[pageUrl.length - 1];
		if (currentActiveTab.includes("?")) {
			currentActiveTab = currentActiveTab.split('?')[0];
		}
		this.pageNavigation(currentActiveTab);
	}

	pageNavigation(type) {

		switch (type) {
			case "docusign": this.currentIndex = 0; break;
			case "upload-statements": this.currentIndex = 1; break;
			case "additional-information": this.currentIndex = 2; break;
			case "docusign-accounts": this.currentIndex = 3; break;
			case "docusign-other-assets": this.currentIndex = 3; break;
			case "docusign-cash-flow": this.currentIndex = 4; break;
		}
	}

	redirectToPage(index) {
		switch (index) {
			case 0: this.router.navigate([this.infoType + "/docusign"]); break;
			case 1: this.router.navigate([this.infoType + "/upload-statements"]); break;
			case 2: this.router.navigate([this.infoType + "/additional-information"]); break;
			case 3: this.router.navigate([this.infoType + "/docusign-accounts"]); break;
			case 4: this.router.navigate([this.infoType + "/docusign-cash-flow"]); break;
		}
	}
}
