import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppRestEndPoint } from 'src/app/app-restEndPoint';
import { ApiService } from 'src/app/services/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UploadStatementModalComponent } from '../upload-statement-modal/upload-statement-modal.component';
import { ConfirmationDialogService } from 'src/app/services/confirmation-dialog.service';
import { ToastService } from 'src/app/components/toaster/toast-service';
import { NavigationService } from 'src/app/services/navigation.service';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-upload-statement',
	templateUrl: './upload-statement.component.html',
	styleUrls: ['./upload-statement.component.scss']
})
export class UploadStatementComponent implements OnInit {

	documentData: any = [];
	selectedType: any;
	constructor(private apiService: ApiService,
		private modalService: NgbModal,
		public confirmationDialogService: ConfirmationDialogService,
		public toastService: ToastService,
		public navigationService: NavigationService,
		public activatedRoute: ActivatedRoute
	) {
		this.selectedType = this.activatedRoute.snapshot.paramMap.get('type');
	}

	ngOnInit(): void {
		this.loadDocuments();
	}


	loadDocuments() {

		let url = AppRestEndPoint.DOCUSIGN.DOCUMENT_LIST;
		this.apiService.getCall(url, "&user_document_type_id=" + environment.statement_user_document_type_id).subscribe(data => {
			if (data && data.user_documents) {
				this.documentData = data.user_documents;
			}
		});

	}

	openUploadModal() {
		const openUploadModalRef = this.modalService.open(UploadStatementModalComponent, { windowClass: 'modal-width-600', backdrop: 'static' });

		openUploadModalRef.result.then((data) => {
			if (data) {
				this.loadDocuments();
			}
		});
	}

	gotoPreviousState() {
		var reqObj = { "info_type": this.selectedType, "state": "upload-statements" };
		this.navigationService.getPreviousPage(reqObj);
	}

	gotoNextState() {
		var reqObj = { "info_type": this.selectedType, "state": "upload-statements" };
		this.navigationService.getNextPage(reqObj);
	}

	openDeleteModal(document) {
		this.confirmationDialogService.confirm('CONFIRM', 'Are you sure you want to delete ' + document.description + '?')
			.then((confirmed) => {
				if (confirmed) {
					const apiurl = AppRestEndPoint.DOCUSIGN.DOCUMENT_LIST + '/' + document.id;
					this.apiService.deleteCall(apiurl, '').subscribe(data => {
						if (data) {

							this.toastService.show('Deleted successfully', {
								classname: 'bg-success text-light', delay: 3000
							});
							this.loadDocuments();
						}
					})

				}
			})
			.catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
	}


}
