import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppRestEndPoint } from 'src/app/app-restEndPoint';
import { ToastService } from 'src/app/components/toaster/toast-service';
import { ApiService } from 'src/app/services/api.service';
import { isDefined } from '@angular/compiler/src/util';

@Component({
  selector: 'app-external-risk-tolerance',
  templateUrl: '../../risk-tolerance/risk-tolerance.component.html',
  styleUrls: ['../../risk-tolerance/risk-tolerance.component.scss']
})
export class ExternalRiskToleranceComponent implements OnInit {
  token: any;
  household_id: any;
  loadRtqId: any;
  setQuestionNumber: any;
  selectedAnswer: Boolean = false;
  loadQuestionData: any = {};
  selectedAnsArr: any = [];
  questionArrLength: Number = 0;
  questionNumber: any;
  questionList: any = [];
  rtqEmail:any = 'true';
  isExternalPage:boolean = true;
  userEmail: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private toastService: ToastService,
    public router: Router
  ) { }

  ngOnInit(): void {
    let params = this.activatedRoute.snapshot.queryParams;
    this.token = params.token;
    this.household_id = params.household_id;
    this.loadRtqId = params.rtq_id;
    this.userEmail = params.email;
    switch (this.setQuestionNumber) {
			case "question1": this.questionNumber = 2; break;
			case "question2": this.questionNumber = 3; break;
			case "question3": this.questionNumber = 4; break;
			case "question4": this.questionNumber = 5; break;
			case "question5": this.questionNumber = 6; break;
			case "question6": this.questionNumber = 7; break;
			default: this.questionNumber = 1; break;
		}
    this.loadQuestions();
  }

  loadQuestions() {
    this.questionList[0] = {};
    let url = AppRestEndPoint.EXTERNAL.QUESTION_LIST + '?token=' + this.token, param = "&household_id=" + this.household_id + "&rtq_id=" + this.loadRtqId;
    this.apiService.externalGetCall(url, param).subscribe(response => {
      if (response) {
        let loadQuestionData = response;
        this.questionList = loadQuestionData.investor_profile_questions;
        this.questionArrLength = loadQuestionData.investor_profile_questions.length;
        this.questionList[0].investor_profile_answers.disabled = false;
        localStorage.setItem("username",response.user_name);
      }
    });
  }

  saveAnswer() {
    if (this.questionList[this.questionNumber - 1].userOption) {
      var reqObj = {
        "investor_profile": {
          "onboarding": "true",
          "rtq_id": this.loadRtqId,
          "investor_profile_question_id": this.questionList[this.questionNumber - 1].userOption.investor_profile_question_id,
          "investor_profile_answer_id": this.questionList[this.questionNumber - 1].userOption.id
        }
      };

      let url = AppRestEndPoint.EXTERNAL.SAVE_INVESTOR_PROFILE + "?token=" + this.token + "&household_id=" + this.household_id;
      this.apiService.externalPostCallQuery(url, reqObj).subscribe(response => {
        if (isDefined(response.success) && response.success == false) {
          this.toastService.show(response.message, {
            classname: 'bg-danger text-light', delay: 3000
          });
        } else {
          this.gotoNextState();
        }
      });
    }
  }

  clearSelection(){
		this.questionList[this.questionNumber-1].investor_profile_answers.forEach(function (option) {
			option.selected = false; 
			option.disabled = true;                       
		});
	}

  getSelectedAnswer(option) {
    this.clearSelection();
		option.selected = true;
		this.questionList[this.questionNumber - 1].userOption = option;
		this.saveAnswer();
  }

  gotoNextState() {
    if (this.questionList[this.questionNumber - 1].userOption) {
      if (this.questionNumber < this.questionArrLength) {
        this.questionNumber = this.questionNumber + 1;
      } else if (this.questionNumber == this.questionArrLength) {
        this.router.navigate(['/tpp/advisor-contact']);
        this.router.navigateByUrl("tpp/advisor-contact?name=" + this.household_id + "&email=" + this.userEmail);
      }
    }

  }

  gotoPreviousState() {
    if (this.questionNumber != 1) {
      this.questionNumber = this.questionNumber - 1;
      this.questionList[this.questionNumber - 1].investor_profile_answers.forEach(function (option) {
        option.disabled = false;
      });
    }
  }

}
