export const Domains = {
    "clientux.owlinvest.com": {
        "firm_id": "2",
        "css": "clientux.css",
        "api_url": "https://test.owlinvest.com",
    },
    "clientux2.owlinvest.com": {
        "firm_id": "15",
        "css": "clientux2.css",
        "api_url": "https://test.owlinvest.com",
    },
    "qac.owlinvest.com": {
        "firm_id": "2",
        "css": "forumfin.css",
        "api_url": "https://qa.owlinvest.com",
    },
    "bstc.owlinvest.com": {
        "firm_id": "5",
        "css": "bluestone.css",
        "api_url": "https://bstl.owlinvest.com",
    },
    "client.forumfin.com": {
        "firm_id": "2",
        "css": "forumfin.css",
        "api_url": "https://accounts.forumfin.com",
    },
    "forum.advisorhubservices.com": {
        "firm_id": "2",
        "css": "forumfin.css",
        "api_url": "https://accounts.forumfin.com",
    },
    "mckinley.advisorhubservices.com": {
        "firm_id": "6",
        "css": "bluestone.css",
        "api_url": "https://accounts.forumfin.com",
    },
    "prwc.advisorhubservices.com": {
        "firm_id": "7",
        "css": "bluestone.css",
        "api_url": "https://accounts.forumfin.com",
    },
    "kendall.advisorhubservices.com": {
        "firm_id": "10",
        "css": "kendall.css",
        "api_url": "https://accounts.forumfin.com",
    },
    "pacificwealth.advisorhubservices.com": {
        "firm_id": "9",
        "css": "bluestone.css",
        "api_url": "https://accounts.forumfin.com",
    },
    "align.advisorhubservices.com": {
        "firm_id": "8",
        "css": "bluestone.css",
        "api_url": "https://accounts.forumfin.com",
    },
    "uac.owlinvest.com": {
        "firm_id": "2",
        "css": "forum.css",
        "api_url": "https://uat.owlinvest.com"
    },
    "frc.owlinvest.com": {
        "firm_id": "2",
        "css": "forum.css",
        "api_url": "https://fr.owlinvest.com"
    },
    "gdc.advisorhubservices.com": {
        "firm_id": "11",
        "css": "forum.css",
        "api_url": "https://accounts.forumfin.com"
    },
    "pwc.advisorhubservices.com": {
        "firm_id": "12",
        "css": "forum.css",
        "api_url": "https://accounts.forumfin.com"
    },
    "bwc.advisorhubservices.com": {
        "firm_id": "13",
        "css": "forum.css",
        "api_url": "https://accounts.forumfin.com"
    },
    "yfc.advisorhubservices.com": {
        "firm_id": "14",
        "css": "forum.css",
        "api_url": "https://accounts.forumfin.com"
    },
    "lpc.advisorhubservices.com": {
        "firm_id": "15",
        "css": "forum.css",
        "api_url": "https://accounts.forumfin.com"
    },
    "iw.advisorhubservices.com": {
        "firm_id": "16",
        "css": "forum.css",
        "api_url": "https://accounts.forumfin.com"
    },
    "wsc.advisorhubservices.com": {
        "firm_id": "18",
        "css": "forum.css",
        "api_url": "https://accounts.forumfin.com"
    },
    "tfc.advisorhubservices.com": {
        "firm_id": "20",
        "css": "forum.css",
        "api_url": "https://accounts.forumfin.com"
    },
    "glc.advisorhubservices.com": {
        "firm_id": "21",
        "css": "forum.css",
        "api_url": "https://accounts.forumfin.com"
    },
    "bpwc.advisorhubservices.com": {
        "firm_id": "23",
        "css": "forum.css",
        "api_url": "https://accounts.forumfin.com"
    },
    "lwmc.advisorhubservices.com": {
        "firm_id": "24",
        "css": "forum.css",
        "api_url": "https://accounts.forumfin.com"
    },
    "hbcc.advisorhubservices.com": {
        "firm_id": "25",
        "css": "forum.css",
        "api_url": "https://accounts.forumfin.com"
    },
    "ffc.advisorhubservices.com": {
        "firm_id": "26",
        "css": "forum.css",
        "api_url": "https://accounts.forumfin.com"
    },
    "bsc.advisorhubservices.com": {
        "firm_id": "27",
        "css": "forum.css",
        "api_url": "https://accounts.forumfin.com"
    },
    "opfc.advisorhubservices.com": {
        "firm_id": "29",
        "css": "forum.css",
        "api_url": "https://accounts.forumfin.com"
    },
    "prlvc.advisorhubservices.com": {
        "firm_id": "30",
        "css": "forum.css",
        "api_url": "https://accounts.forumfin.com"
    },
    "ktc.advisorhubservices.com": {
        "firm_id": "31",
        "css": "forum.css",
        "api_url": "https://accounts.forumfin.com"
    },
    "prodc.owlinvest.com": {
        "firm_id": "2",
        "css": "forum.css",
        "api_url": "https://prod.owlinvest.com"
    },
    "localhost:8080": {
        "firm_id": "2",
        "css": "forumfin.css",
        "api_url": "https://test.owlinvest.com",
    },
    "dev-ubuntu:8080": {
        "firm_id": "2",
        "css": "forumfin.css",
        "api_url": "http://dev-ubuntu:3000",
    },
    "dev-ubuntu:8081": {
        "firm_id": "2",
        "css": "forumfin.css",
        "api_url": "http://dev-ubuntu:3000",
    },
    "dev-neubauer:8081": {
        "firm_id": "2",
        "css": "forumfin.css",
        "api_url": "http://dev-neubauer:3000",
    },
    "dev-dugger:8081": {
        "firm_id": "2",
        "css": "forumfin.css",
        "api_url": "http://dev-dugger:3000",
    },
    "localhost:8888": {
        "firm_id": "2",
        "css": "forumfin.css",
        "api_url": "https://test.owlinvest.com",
    },
    "localhost:8081": {
        "firm_id": "2",
        "css": "forumfin.css",
        "api_url": "https://test.owlinvest.com",
    },
    "localhost:8082": {
        "firm_id": "2",
        "css": "forumfin.css",
        "api_url": "https://test.owlinvest.com",
    },
    "localhost:4200": {
        "firm_id": "2",
        "css": "forumfin.css",
        "api_url": "https://test.owlinvest.com",
    },
    "192.168.168.204": {
        "firm_id": "2",
        "css": "forumfin.css",
        "api_url": "https://test.owlinvest.com",
    },
    "qacn.owlinvest.com": {
        "firm_id": "2",
        "css": "forumfin.css",
        "api_url": "https://qa.owlinvest.com",
    }
};