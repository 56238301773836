import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { AppRestEndPoint } from 'src/app/app-restEndPoint';
import { ApiService } from 'src/app/services/api.service';
import { ToastService } from 'src/app/components/toaster/toast-service';

@Component({
	selector: 'app-schedule-meeting-modal',
	templateUrl: './schedule-meeting-modal.component.html',
	styleUrls: ['./schedule-meeting-modal.component.scss']
})
export class ScheduleMeetingModalComponent implements OnInit {

	@Input() advisorFullName;
	@Input() advisorFirstName;
	@Input() advisoryTeam;
	@Input() eventList;
	meeting_subject: any;
	meeting_date: any
	meeting_message: any;
	bsConfig?: Partial<BsDatepickerConfig>;
	selectedTimeZone: any;
	minDate;
	maxDate;
	scheduleMeetingForm: FormGroup;
	meeting_time: any;
	time: Date = new Date();
	//contact_advisor: any = '';
	scheduleMeetingFormSubmitted: boolean = false;

	constructor(
		private activeModal: NgbActiveModal,
		private toastService: ToastService,
		private formBuilder: FormBuilder,
		private apiService: ApiService) {
		this.scheduleMeetingForm = this.formBuilder.group({
			meetingSubject: ['', Validators.required],
			meetingMessage: ['', Validators.required],
			meetingDate: ['', Validators.required],
			meetingTimezone: ['', Validators.required],
			meetingTime: [null],
			meetingTime1: [null],
			//contactAdvisor: ['']


		});
		let todayDate = new Date();
		this.minDate = new Date(todayDate.setDate(todayDate.getDate() + 1));
		this.maxDate = new Date(todayDate.setDate(todayDate.getDate() + 30));
	}

	ngOnInit(): void {
		this.bsConfig = Object.assign({}, {
			adaptivePosition: true,
			containerClass: 'theme-dark-blue',
			showWeekNumbers:false,
			dateInputFormat: 'MM/DD/YYYY'
		});
	}

	closeModal() {
		this.activeModal.close();
	}

	setMaxDate() {
		if (this.meeting_date > new Date()) {
			this.meeting_date = new Date();
		}
	}

	scheduleMeeting() {
		this.scheduleMeetingFormSubmitted = true;
		let scheduleTime: any;
		if (this.scheduleMeetingForm.valid) {
			if (this.eventList && this.eventList.length <= 0) {
				this.meeting_date = this.meeting_date ? moment(this.meeting_date).format('MM/DD/YYYY') : '';
				/*let timefact = this.time; */
				let timeComponent = this.meeting_date + " " + this.time.getHours() + ':' + this.time.getMinutes();
				scheduleTime = moment(timeComponent).format('YYYY-MM-DD HH:mm a');

			} else {
				this.meeting_date = this.meeting_date ? moment(this.meeting_date).format('MM/DD/YYYY') : '',
					scheduleTime = this.meeting_date + " " + this.meeting_time;
			}
			let reqObj: any = {
				"description": this.meeting_message,
				"subject": this.meeting_subject,
				"date": scheduleTime,
				"timezone": this.selectedTimeZone
			};
			// if (this.contact_advisor) {
			// 	reqObj.admin_user_id = this.contact_advisor;
			// }

			this.apiService.postCall(AppRestEndPoint.DASHBOARD.SCHEDULE_MEETING, reqObj).subscribe(data => {
				if (data.success) {
					this.activeModal.close(data);
				} else {
					this.toastService.show("Message sent.", {
						classname: 'bg-danger text-light', delay: 3000
					});
				}
			}, function (err) {
				this.toastService.show('Error in sending Message.', {
					classname: 'bg-danger text-light', delay: 3000
				});
			});
		} else {
			this.toastService.show('Please fill all the fields.', {
				classname: 'bg-danger text-light', delay: 3000
			});
		}
	};


	get formControl() {
		return this.scheduleMeetingForm.controls;
	}

}
