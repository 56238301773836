<app-bread-crumb-options></app-bread-crumb-options>
<div class="container">
    <div class="margin-top-10-pct pl-3 min-ht-300">
        <form [formGroup]="personalInfoForm" novalidate>
            <!-- div for bigger screens-->
            <div class=" d-none d-sm-block d-md-block">
            <div class="row justify-content-md-center ">
                <div class="font-30 mr-2">I am </div>
                <div class="mr-2">
                    <select class="form-control cursor-pointer" formControlName="maritalStatus" [(ngModel)]="maritalStatus"
                        (change)="changeSpouseLabels()" required>
                        <option ng-selected="maritalStatus == item.id" value="{{item.id}}"
                            *ngFor="let item of relationshipStatus">
                            {{item.value}}</option>
                    </select>
                </div>
                <div class="font-30 mr-2">with</div>
                <div class="mr-2 pl-0">
                    <input type="number" min="0" max="99" autocomplete="off" class="form-control p-2"
                        [(ngModel)]="childrenCount" formControlName="childrenCount"
                        (change)="getChildrenInfo(childrenCount)">
                </div>
                <div class="font-30">children under age 25.</div>
            </div>
            </div>
              <!-- div for smaller screens-->
            <div class="row justify-content-md-center d-xs-block d-sm-none d-md-none">
                <div class="font-30 mr-2">I am </div>
                <div class="mr-2">
                    <select class="form-control cursor-pointer" formControlName="maritalStatus" [(ngModel)]="maritalStatus"
                        (change)="changeSpouseLabels()" required>
                        <option ng-selected="maritalStatus == item.id" value="{{item.id}}"
                            *ngFor="let item of relationshipStatus">
                            {{item.value}}</option>
                    </select>
                </div>
                <div class="font-30 mr-2">with</div>
            </div>
            <div class="row mt-3 d-xs-block d-sm-none d-md-none">
                <div class="mt-2 d-flex">
                    <input type="number" min="0" max="99" autocomplete="off" class="form-control p-2 w-10 cursor-pointer"
                        [(ngModel)]="childrenCount" formControlName="childrenCount"
                        (change)="getChildrenInfo(childrenCount)" />
                        <span class="font-30 pl-2">children under age 25.</span>
                </div>
            </div>
            <div class="row justify-content-md-center mt-4">
                <div class="font-30 mr-2">I was born on</div>
                <div class="col-md-3 col-sm-3 col-xs-6 d-flex pl-0" *ngIf="isPrimary">
                    <input type="text" placeholder="MM/DD/YYYY" class="form-control" #dp="bsDatepicker" bsDatepicker
                        [required]="isPrimary" [bsConfig]="bsConfig" formControlName="primarybirthday"
                        [(ngModel)]="personalInfo.birthday" [minDate]="minDate" [maxDate]="maxDate" (blur)="setMaxDate('primary')">
                    <span class="cal-icon cursor-pointer" (click)="dp.toggle()"><i class="fa fa-calendar"
                            aria-hidden="true"></i></span>
                </div>
                <div class="col-md-3 col-sm-3 col-xs-6 d-flex" *ngIf="spousePrimary">
                    <input type="text" placeholder="MM/DD/YYYY" class="form-control" #dp="bsDatepicker" bsDatepicker
                        [required]="spousePrimary" [bsConfig]="bsConfig" formControlName="primarybirthday"
                        [(ngModel)]="spouseInfo.birthDate" [minDate]="minDate" [maxDate]="maxDate" (blur)="setMaxDate('spouse')">
                    <span class="cal-icon cursor-pointer" (click)="dp.toggle()"><i class="fa fa-calendar"
                            aria-hidden="true"></i></span>
                </div>
                <div class="col-md-3 col-sm-3 col-xs-6 d-flex" *ngIf="userDataIndex != -1">
                    <input type="text" placeholder="MM/DD/YYYY" class="form-control" #dp="bsDatepicker" bsDatepicker
                        [required]="userDataIndex != -1" [bsConfig]="bsConfig" formControlName="primarybirthday"
                        [(ngModel)]="childrenInfo[userDataIndex].birthday" [minDate]="minDate" [maxDate]="maxDate" (blur)="setMaxDate('userDataIndex')">
                    <span class="cal-icon cursor-pointer" (click)="dp.toggle()"><i class="fa fa-calendar"
                            aria-hidden="true"></i></span>
                </div>
            </div>
            <div *ngIf="personalInfoFormSubmitted" class="text-danger text-center margin-left-6-pct" role="alert">
                <div *ngIf="formControl.primarybirthday.errors?.required">Birth date is mandatory</div>
                <div *ngIf="formControl.primarybirthday.errors?.invalid">Please enter birthdate in MM/DD/YYYY format.
                </div>
            </div>
            <!-- showing Primary user as Spouse if other user has logged in -->
            <div class="d-flex flex-wrap mt-5 pb-5 font-18">
                <div class="col-xs-12 ques-block-inr about-block ques-block-inr-width mt-4"
                    *ngIf="!isPrimaryEdit && !isPrimary">
                    <span class="title">{{spouselabel}}</span>
                    <div (click)="isPrimaryEdit = true">
                        <div class="caption">{{spousePlaceholder}}'s Full Name</div>
                        <div class="value">{{personalInfo.fullname}}</div>
                        <div class="caption">Born on</div>
                        <div class="value">{{personalInfo.birthday | date:'MM/dd/yyyy'}}</div>
                        <div class="caption">Email</div>
                        <div class="value word-break-all"><a>{{personalInfo.email}}</a></div>
                    </div>
                </div>

                <div class="col-xs-12 ques-block-inr about-block font-18 ques-block-inr-width mt-4"
                    *ngIf="isMarried && !isSpouseEdit && !spousePrimary">
                    <div>
                        <span class="title">{{spouselabel}}</span>
                        <div (click)="isSpouseEdit = true">
                            <div class="caption">{{spousePlaceholder}}'s Full Name</div>
                            <div class="value">{{spouseInfo.fullname}}</div>
                            <div class="caption">Born on</div>
                            <div class="value">{{spouseInfo.birthDate | date:'MM/dd/yyyy'}}</div>
                            <div class="caption">Email</div>
                            <div class="value work-break-all"><a>{{spouseInfo.email}}</a></div>
                        </div>
                    </div>
                </div>

                <!-- showing Primary user as Spouse if other user has logged in -->
                <div class="col-xs-12 ques-block-inr about-block ques-block-inr-width mt-4"
                    *ngIf="isPrimaryEdit && !isPrimary">
                    <span class="title">{{spouselabel}}</span>
                    <div class="caption">{{spousePlaceholder}}'s Full Name</div>
                    <input type="text" name="spousePrimaryName" class="form-control" formControlName="spousePrimaryName"
                        [(ngModel)]="personalInfo.fullname" placeholder="{{spousePlaceholder}}'s Full Name"
                        [required]="isPrimaryEdit && !isPrimary">
                    <div *ngIf="personalInfoFormSubmitted" class="text-danger font-14" role="alert">
                        <div *ngIf="formControl.spousePrimaryName.errors?.required">Name is mandatory</div>
                        <div *ngIf="formControl.spousePrimaryName.errors?.usernameExists">Please enter your first and
                            last name.
                        </div>
                    </div>
                    <div class="mr-2">Born on</div>
                    <div class="inner-addon">
                        <input type="text" placeholder="MM/DD/YYYY" class="form-control" #dp="bsDatepicker" bsDatepicker
                            [required]="isPrimaryEdit && !isPrimary" [bsConfig]="bsConfig"
                            formControlName="spousePrimaryBirthday" [(ngModel)]="personalInfo.birthday"
                            [minDate]="minDate" [maxDate]="maxDate" (blur)="setMaxDate('primary')">
                        <span class="cal-icon-custom cursor-pointer" (click)="dp.toggle()"><i class="fa fa-calendar"
                                aria-hidden="true"></i></span>
                    </div>
                    <div *ngIf="personalInfoFormSubmitted" class="text-danger font-14" role="alert">
                        <div *ngIf="formControl.spousePrimaryBirthday.errors?.required">Birth date is mandatory</div>
                        <div *ngIf="formControl.spousePrimaryBirthday.errors?.invalid">Please enter birthdate in
                            MM/DD/YYYY format.
                        </div>
                    </div>
                    <div class="caption">Email</div>
                    <input type="text" formControlName="spousePrimaryEmail" placeholder="Email" class="form-control"
                        [(ngModel)]="personalInfo.email">
                    <div *ngIf="personalInfoFormSubmitted" class="text-danger font-14" role="alert">
                        <div *ngIf="formControl.spousePrimaryEmail.errors?.required">Email is mandatory.</div>
                        <div *ngIf="formControl.spousePrimaryEmail.errors?.pattern">Enter a valid email.</div>
                    </div>
                </div>

                <div class="col-xs-12 ques-block-inr about-block ques-block-inr-width mt-4"
                    *ngIf="isMarried && isSpouseEdit && !spousePrimary">
                    <span class="title font-18">{{spouselabel}}</span>
                    <div class="caption">{{spousePlaceholder}}'s Full Name</div>
                    <input type="text" formControlName="spouseName" class="form-control"
                        [(ngModel)]="spouseInfo.fullname" placeholder="{{spousePlaceholder}}'s Full Name"
                        [required]="isMarried && isSpouseEdit && !spousePrimary" (blur)="validName('spouseName')">
                    <div *ngIf="personalInfoFormSubmitted" class="text-danger font-14" role="alert">
                        <div *ngIf="formControl.spouseName.errors?.required">Name is mandatory</div>
                        <div *ngIf="formControl.spouseName.errors?.usernameExists">Please enter your first and last
                            name.
                        </div>
                    </div>
                    <div class="caption">Born on</div>
                    <div class="inner-addon">
                        <input type="text" placeholder="MM/DD/YYYY" class="form-control" #dp="bsDatepicker" bsDatepicker
                            [required]="isMarried && isSpouseEdit && !spousePrimary" [bsConfig]="bsConfig"
                            formControlName="spouseBirthday" [(ngModel)]="spouseInfo.birthDate" [minDate]="minDate"
                            [maxDate]="maxDate" (blur)="setMaxDate('spouse')">
                        <span class="cal-icon-custom cursor-pointer" (click)="dp.toggle()"><i class="fa fa-calendar"
                                aria-hidden="true"></i></span>
                    </div>
                    <div *ngIf="personalInfoFormSubmitted" class="text-danger font-14" role="alert">
                        <div *ngIf="formControl.spouseBirthday.errors?.required">Birth date is mandatory</div>
                        <div *ngIf="formControl.spouseBirthday.errors?.invalid">Please enter birthdate in MM/DD/YYYY
                            format.
                        </div>
                    </div>
                    <div class="caption">Email</div>
                    <input type="text" formControlName="spouseEmail" placeholder="Email" class="form-control"
                        [(ngModel)]="spouseInfo.email">
                    <div *ngIf="personalInfoFormSubmitted" class="text-danger font-14" role="alert">
                        <div *ngIf="formControl.spouseEmail.errors?.required">Email is mandatory.</div>
                        <div *ngIf="formControl.spouseEmail.errors?.pattern">Enter a valid email.</div>
                    </div>
                </div>
                
                <div formArrayName="childInfoArr" class="display-contents">
                    <div class="col-xs-12"
                        [ngClass]="{'ques-block-inr about-block ques-block-inr-width mt-4':obj.isChildEdit && !obj._destroy && i != userDataIndex}"
                        *ngFor="let obj of childrenInfo; let i=index" [formGroupName]="i">
                        <div *ngIf="obj.isChildEdit && !obj._destroy && i != userDataIndex">
                            <span class="title">CHILD</span>
                            <div class="caption">Child's Full Name</div>
                            <input type="text" class="form-control" formControlName="childName"
                                [(ngModel)]="obj.fullname" placeholder="Child's Full Name"  (blur)="validName('childName', i)" 
                                [required]="obj.isChildEdit && !obj._destroy && i != userDataIndex">
                            <div *ngIf="personalInfoFormSubmitted" class="text-danger font-14" role="alert">
                                <div *ngIf="formControlArr[i].controls.childName.errors?.required">Name is mandatory</div>
                                <div *ngIf="formControlArr[i].controls.childName.errors?.usernameExists">Please enter your first and
                                    last name.
                                </div>
                            </div>
                            <div class="caption">Born on</div>
                            <div class="inner-addon">
                                <input type="text" placeholder="MM/DD/YYYY" class="form-control" #dp="bsDatepicker"
                                    bsDatepicker [required]="obj.isChildEdit && !obj._destroy && i != userDataIndex"
                                    [bsConfig]="bsConfig" formControlName="childBirthday" [(ngModel)]="obj.birthday"
                                    [minDate]="minDate" [maxDate]="maxDate" (blur)="setMaxDate('child',i)">
                                <span class="cal-icon-custom cursor-pointer" (click)="dp.toggle()"><i class="fa fa-calendar"
                                        aria-hidden="true"></i></span>
                            </div>
                            <div *ngIf="personalInfoFormSubmitted" class="text-danger font-14" role="alert">
                                <div *ngIf="formControlArr[i].controls.childBirthday.errors?.required">Birth date is mandatory</div>
                                <div *ngIf="formControlArr[i].controls.childBirthday.errors?.invalid">Please enter birthdate in
                                    MM/DD/YYYY format.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12"
                    [ngClass]="{'ques-block-inr about-block ques-block-inr-width mt-4':!obj1.isChildEdit && !obj1._destroy && i != userDataIndex}"
                    *ngFor="let obj1 of childrenInfo; let i=index">
                    <div *ngIf="!obj1.isChildEdit && !obj1._destroy && i != userDataIndex">
                        <span class="title">CHILD</span>
                        <div (click)="obj1.isChildEdit = true">
                            <div class="caption">Child's Full Name</div>
                            <div class="value">{{obj1.fullname}}</div>
                            <div class="caption">Born on</div>
                            <div class="value">{{obj1.birthday | date:'MM/dd/yyyy'}}</div>
                        </div>
                    </div>
                </div>
            </div>

        </form>
    </div>
    <div>
        <app-buttonbar (clickedprev)="gotoPreviousState()" (clickednext)="submitBasicInfo()" ></app-buttonbar>
    </div>
</div>