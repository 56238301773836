import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppRestEndPoint } from 'src/app/app-restEndPoint';
import { ApiService } from 'src/app/services/api.service';
import { ToastService } from '../../toaster/toast-service';

@Component({
	selector: 'app-contact-us-modal',
	templateUrl: './contact-us-modal.component.html',
	styleUrls: ['./contact-us-modal.component.scss']
})
export class ContactUsModalComponent implements OnInit {
	@Input() currentPage;
	@Input() advisorData;
	@Input() advisoryTeam;
	@Input() selectedType;
	@Input() inWithdrawal;
	@ViewChild("subject") subject: ElementRef;
	@ViewChild("message") message: ElementRef;
	
	contactUsForm: FormGroup;
	contactUsFormSubmitted: boolean = false;
	disableBtn: boolean = false;
	contact_message: any;
	contact_subject: any;
	contact_advisor:any = '';
	
	constructor(private formBuilder: FormBuilder,
		private apiService: ApiService,
		private toastService: ToastService,
		public activeModal: NgbActiveModal,
		public router: Router) {
			this.contactUsForm = this.formBuilder.group({
				contactSubject: ['', Validators.required],
				contactMessage: ['', Validators.required],
				contactAdvisor: ['']
			});	
		}

	ngOnInit(): void {
		if(!this.currentPage){
			let pageUrl = this.router.url.split('/');
			this.currentPage = pageUrl[pageUrl.length - 1];
		}
		if(this.selectedType){
			this.contactUsForm.controls['contactSubject'].disable();
			if (this.selectedType === 'cashFlow') {
                this.contact_subject = "Wealth Portal – Edit My Annual Cash Flow";
            } else if (this.selectedType === 'waterfall') {
                if (this.inWithdrawal) {
                    this.contact_subject = "Wealth Portal – Edit My Withdrawal Plan";
                } else {
                    this.contact_subject = "Wealth Portal – Edit My Savings Waterfall";
                }
            } else if(this.selectedType === 'profile'){
				this.contact_subject = "Wealth Portal - Edit my personal information";
			}
		}
	}

	ngAfterViewInit(){
		if(this.selectedType){
			this.message.nativeElement.focus();
		}else{
			this.subject.nativeElement.focus();
		}
	}

	sendMessage(){
		this.contactUsFormSubmitted = true;
        if (this.contactUsForm.valid) {
			this.disableBtn = true;
			let url, reqObj;
			if(this.currentPage !== 'dashboard' && this.currentPage !== 'profile' && this.currentPage !== 'documents'){
				url = AppRestEndPoint.LOGIN.CONTACT_US;
				reqObj = {
					"contact_us": {
						"message": this.contact_message,
						"subject": this.contact_subject,
					}
				};
			}else{
				url = AppRestEndPoint.DASHBOARD.SEND_EMAIL;
				reqObj = {
					"description": this.contact_message,
					"subject": this.contact_subject,
				};
				if(this.contact_advisor){
					reqObj.admin_user_id = this.contact_advisor;
				}
			}
            this.apiService.postCall(url, reqObj).subscribe(response => {
				this.disableBtn = false;
				this.toastService.show('Message sent.', {
					classname: 'bg-success text-light', delay: 3000
				});
				this.activeModal.close();
            },
			err => {
				this.disableBtn = false;
				this.toastService.show('Error in sending Message.', {
					classname: 'bg-danger text-light', delay: 3000
				})
				this.activeModal.close();
			});
        }
		
	}

	closeModal(){
		this.activeModal.close();
	}

	get formControl() {
		return this.contactUsForm.controls;
	}

}
