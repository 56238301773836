import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoginService } from 'src/app/services/login.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppRestEndPoint } from 'src/app/app-restEndPoint';
import { ToastService } from 'src/app/components/toaster/toast-service';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/services/common.service';
import { UtilityService } from 'src/app/services/utility.service';
import { Validator } from 'src/app/services/validator';

@Component({
	selector: 'app-reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
	reset_password_message: any;
	reset_password_button: any;
	disablePwdBtn: any;
	changePasswordFormSubmitted: any;
	changePasswordForm: FormGroup;
	showResetPwdMsg: any;
	invalid: any = [];
	changePasswordToken: any; 
	password: string;
	confirmPassword: string;

	constructor(
		private formBuilder: FormBuilder,
		private loginService: LoginService,
		public utilService: UtilityService,
		private activatedRoute: ActivatedRoute,
		private toastService: ToastService,
		private commonService: CommonService,
		public router: Router
	) {
		this.changePasswordForm = this.formBuilder.group({
			changePasswordNew: [null, Validators.compose([
				Validators.required,
				Validator.patternValidator(/[a-z]/, { hasSmallCase: true }),
				Validator.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
				Validator.patternValidator(/\d/, { hasNumber: true }),
				Validator.patternValidator(/[$@$!%*#?&]/, { hasSpecialCharacters: true }),
				Validators.minLength(8)
			])],
			confirmChangePassword: ['', Validators.required],
		});
	}

	ngOnInit(): void {
		this.reset_password_message = 'Change My Password';
		this.reset_password_button = 'Change My Password';
		this.activatedRoute.queryParams.subscribe(params => {
			this.changePasswordToken = params.token;
		});
		let params = this.activatedRoute.snapshot.queryParams;
		switch (params.state) {

            case 'welcome':
                this.reset_password_message = 'Welcome, please choose a password';
                this.reset_password_button = 'Set My Password';
                break;

            case 'change_password':
                this.reset_password_message = 'Choose a Password';
                break;
		}
	}

	changePassword() {
		this.changePasswordFormSubmitted = true;
		sessionStorage.removeItem('reset_pwd_redirect');
		if (this.password !== this.confirmPassword) {
			this.changePasswordForm.controls['confirmChangePassword'].setErrors({ 'invalidMatch': true });
			return;
		}
	
		let validateSpace = new RegExp("[\\s]");
		let passwordVal = validateSpace.test(this.password);
		let confirmPasswordVal = validateSpace.test(this.confirmPassword);
		if (passwordVal || confirmPasswordVal) {
			this.changePasswordForm.controls['confirmChangePassword'].setErrors({ 'spaceNotAllowed': true });
			this.changePasswordForm.controls['changePasswordNew'].setErrors({ 'spaceNotAllowed': true });
			return;
		}
		if (this.changePasswordForm.valid) {
			let url = AppRestEndPoint.LOGIN.RESET_PASSWORD + "?password_token=" + this.changePasswordToken + "&firm_id="+this.commonService.getDomainDetails().firm_id;
			let reqObj = {
				"client_id": environment.client_id,
				"client_secret": environment.client_secret,
				"password": this.password,
				"confirm_password": this.confirmPassword
			};
			this.loginService.postCall(url, reqObj).subscribe(data => {
				if (data.access_token) {
					let user = JSON.stringify(data.user);
					sessionStorage.setItem('token', data.access_token.token);
					sessionStorage.setItem('current_user', user);
					sessionStorage.setItem('environment', data.environment);
					if (data.success) {
						this.utilService.gtmIdentify(data.user.id,{});
						this.utilService.gtmPush({
							'event': 'Reset Password Success',
							'userId' : data.user.id,
							'userFirstName' :  data.user.first_name,
							'userLastName' : data.user.last_name,
							'userEmail' :  data.user.email,
							'userFirmId' :  data.user.firm.id,
						});
						if (sessionStorage.getItem('change_pwd_redirect')) {
							if (data.access_token.session_sleep_time) {
								let idleTimeoutTimer;
								idleTimeoutTimer = parseInt(data.access_token.session_sleep_time) * 60000;
								sessionStorage.setItem('idleTimeoutTimer', idleTimeoutTimer);
								this.commonService.setIdleTimeout();
							}
							sessionStorage.removeItem('change_pwd_redirect');
							this.router.navigate(['/dashboard']); 
						} else {
							this.toastService.show('Password changed successfully!', {
								classname: 'bg-success text-light', delay: 3000
							});
							this.router.navigate(['/login']); 
						}
					} else {
						this.utilService.gtmIdentify(data.id,{});
						this.utilService.gtmPush({
							'event': 'Reset Password Failed',
							'userId' :  data.id,
							'userFirstName' :  '',
							'userLastName' : '',
							'userEmail' :  '',
							'userFirmId' :   data.firm_id,
						});
						this.toastService.show(data.msg, {
							classname: 'bg-danger text-light', delay: 3000
						});

					}
				} else if (data.msg && data.msg.password) {
					this.utilService.gtmIdentify(data.id,{});
					this.utilService.gtmPush({
						'event': 'Reset Password Failed',
						'userId' :  data.id,
						'userFirstName' :  '',
						'userLastName' : '',
						'userEmail' :  '',
						'userFirmId' :   data.firm_id,
					});
					let len = data.msg.password.length;
					for (let i = 0; i < len; i++) {
						this.invalid[i] = "Password " + data.msg.password[i];
					}
					this.toastService.show(this.invalid[0], {
						classname: 'bg-danger text-light', delay: 3000
					});
				} else {
					this.utilService.gtmIdentify(data.id,{});
					this.utilService.gtmPush({
						'event': 'Reset Password Failed',
						'userId' :  data.id,
						'userFirstName' :  '',
						'userLastName' : '',
						'userEmail' :  '',
						'userFirmId' :   data.firm_id,
					});
					this.toastService.show(data.msg, {
						classname: 'bg-danger text-light', delay: 3000
					});
				}
			});
		}
	}

	get formControl() {
		return this.changePasswordForm.controls;
	}



}
