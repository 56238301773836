import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoginService } from 'src/app/services/login.service';
import { environment } from 'src/environments/environment';
import { AppRestEndPoint } from 'src/app/app-restEndPoint';
import { ToastService } from 'src/app/components/toaster/toast-service';
import { CommonService } from 'src/app/services/common.service';
import { Router } from '@angular/router';
import { UtilityService } from 'src/app/services/utility.service';


@Component({
	selector: 'app-forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

	forgotPwdSubmitted: any;
	forgotPwdForm: FormGroup;
	emailId: any;

	constructor(private formBuilder: FormBuilder, 
				private loginService: LoginService, 
				public utilService: UtilityService,
				private toastService: ToastService, 
				private commonService: CommonService,
				private router: Router){			
				this.forgotPwdForm = this.formBuilder.group({
				forgotPwdEmail: ['', [Validators.required, Validators.email]],
			});
	}

	ngOnInit(): void {
	}


	forgotPassword() {
		this.forgotPwdSubmitted = true;
		if (this.forgotPwdForm.valid) {
			let url = AppRestEndPoint.LOGIN.FORGOT_PASSWORD + "?client_id=" + environment.client_id + "&client_secret=" + environment.client_secret + "&email=" + this.emailId + "&firm_id=" + this.commonService.getDomainDetails().firm_id;
			this.loginService.postCall(url, '').subscribe(data => {
				if (data.success) {
					this.utilService.gtmIdentify(data.id,{});
					this.utilService.gtmPush({
                        'event': 'Forgot Password Success',
                        'userId' : data.id,
                        'userFirstName' :  data.first_name,
                        'userLastName' : data.last_name,
                        'userEmail' :  data.email,
                        'userFirmId' :  data.firm_id,
                    });
					this.toastService.show(data.message.replace(/<\/?[^>]+(>|$)/g, "\n"), {
						classname: 'bg-success text-light', delay: 7000
					});
				} else {
					this.utilService.gtmPush({
						'event': 'Forgot Password Failed',
						'userId' : null,
						'userName' :  null,
						'userEmail' :  this.emailId,
						'userFirmId' :  null,
					});
					this.toastService.show(data.message.replace(/<\/?[^>]+(>|$)/g, "\n"), {
						classname: 'bg-success text-light', delay: 7000
					});
				}
				this.gotoLogin();
			});
		}

	};

	gotoLogin() {
		this.forgotPwdSubmitted = false;
		this.forgotPwdForm.reset();	
		if(this.commonService.getDomainDetails().firm_code){
			this.router.navigate(['/login/firm/'+this.commonService.getDomainDetails().firm_code]);
		}else{
			this.router.navigate(['/login']);
		}
	};

	get formControl() {
		return this.forgotPwdForm.controls;
	}
}
