<div class="container">
    <div class="row">
        <h2 class="mx-auto">Ready to get started? </h2>
    </div>
    <div class="card border-right-0 border-left-0 bg-transparent p-5">
        <div class="card-body">
            <div class="d-none d-sm-block">
                <div class="row">
                    <div class="col-md-6">
                        <button class="btn btn-primary  btn-sm padding-left-right-70 border-radius-15 float-right"
                            (click)="gotoSignup()" role="tab">Yes, Let's start</button>
                    </div>
                    <div class="col-md-6 contactFirmName">
                        <button class="btn btn-pink btn-sm padding-left-right-70 border-radius-15"
                            (click)="openContactModal()">
                            {{buttonLabel}}</button>
                    </div>
                </div>
            </div>
            <div class="d-block d-sm-none ">
                <div class="letStartBtn">
                    <a class="btn btn-primary btn-md btn-sm  btn-sm  border-radius-15 font-12"
                        (click)="gotoSignup()">Yes,
                        Let's start</a>
                </div>
                <div class="letStartBtn">
                    <a class="btn btn-pink  btn-sm  btn-sm contactBtn border-radius-15 font-12 mt-4"
                        (click)="openContactModal()">
                        {{buttonLabel}}</a>
                </div>
            </div>
            <div>
                <div class="row p-3"><a (click)="goToLogin()" class="mx-auto pt-3 cursor-pointer">I already have an
                        account</a></div>
                <div class="row p-2"><a href="{{firm.terms_of_service_url}}" target="_blank" class="mx-auto">Wealth
                        Portal Terms of Service</a></div>
            </div>
        </div>
    </div>
</div>
<app-modal #modal [modalConfig]="modalConfig">
    <!--  body of the modal  -->
    <form [formGroup]="submitContactForm" novalidate>
        <div class="pb-4">
            <label class="form-label">Full Name</label>
            <input name="contactName" type="text" autocomplete="off" class="form-control" placeholder="Full Name"
                [(ngModel)]="contact.name" formControlName="contactName">
            <div *ngIf="contactFormSubmitted" class="text-danger" role="alert">
                <div *ngIf="formControl.contactName.errors?.required">Full Name is mandatory.</div>
            </div>
        </div>
        <div class="pb-4">
            <label class="form-label">Phone Number</label>
            <input name="contactPhone" type="text" autocomplete="off" class="form-control" placeholder="Phone Number" 
                [(ngModel)]="contact.phone" formControlName="contactPhone" appPhoneMask>
            <div *ngIf="contactFormSubmitted" class="text-danger" role="alert">
                <div *ngIf="formControl.contactPhone.errors?.required">Phone number is mandatory.</div>
            </div>
        </div>
        <div class="pb-4">
            <label class="form-label">Email</label>
            <input name="contactEmail" type="text" autocomplete="off" class="form-control" placeholder="Email"
                [(ngModel)]="contact.email" formControlName="contactEmail">
            <div *ngIf="contactFormSubmitted" class="text-danger" role="alert">
                <div *ngIf="formControl.contactEmail.errors?.required">Email is mandatory.</div>
                <div *ngIf="formControl.contactEmail.errors?.email">Enter a valid email.
                </div>
            </div>
        </div>
    </form>
</app-modal>