import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DocusignFaaComponent } from './docusign-faa/docusign-faa.component';
import { UploadStatementComponent } from './upload-statement/upload-statement.component';
import { AdditionalInformationComponent } from './additional-information/additional-information.component';
import { AccountsComponent } from '../get-started/assets-and-liabilities/accounts/accounts.component';
import { CashFlowComponent } from '../get-started/cash-flow/cash-flow.component';
import { OtherAssetsComponent } from '../get-started/assets-and-liabilities/other-assets/other-assets.component';
import { AdvisorContactComponent } from '../get-started/advisor-contact/advisor-contact.component';


const routes: Routes = [
  {
		path: ':type/docusign',
		component: DocusignFaaComponent
  },
  {
		path: ':type/upload-statements',
		component: UploadStatementComponent
  },
  {
		path: ':type/additional-information',
		component: AdditionalInformationComponent
  },
  {
		path: ':type/docusign-accounts',
		component: AccountsComponent
  },
  {
		path: ':type/docusign-cash-flow',
		component: CashFlowComponent
  },
  {
    path:':type/docusign-other-assets',
    component: OtherAssetsComponent
  },
  {
    path:':type/review-advisor-contact',
    component: AdvisorContactComponent
  }

  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DocuSignRoutingModule { }
