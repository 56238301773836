import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { CommonService } from './common.service';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {
	constructor(
		private apiService: ApiService,
		private router: Router,
		private commonService: CommonService) { }
	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if(state.url == '/dashboard'){
			let rtqEmail = sessionStorage.getItem("rtq_redirect");
			if(rtqEmail){
				this.commonService.logOut();
				return;
			}
		}
		let token = this.apiService.getToken(),isAuthenticated;
		if (!token) {
			this.router.navigate(['/login']);
			isAuthenticated = false;
		}else{
			isAuthenticated = true;
		}
		return isAuthenticated;
	}

}
