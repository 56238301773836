export const pageRouteMap = [
    {
        "financial-info"    : [
            {
                "template"      : "signup",
                "child_pages"   : []
            },
            {
                "template"      : "basic-info",
                "child_pages"   : [
                    {
                        "template"      : "basic-info/personal-info",
                        "child_pages"   : []
                    },
                    {
                        "template"      : "basic-info/investment-info",
                        "child_pages"   : []
                    }
                ]
            },
            {
                "template"      : "risk-tolerance"
            },
            {
                "template"      : "information-submitted"
            },
            {
                "template" : "assets-and-liabilities",
                "child_pages" : [
                    {
                        "template"      : "accounts",
                        "child_pages"   : []
                    },
                    {
                        "template"      : "other-assets",
                        "child_pages"   : []
                    }
                ]
            },
            {
                "template"      : "cash-flow",
                "child_pages"   : []
            },
            {
                "template"      : "advisor-contact",
                "child_pages"   : []
            },
            {
                "template"      : "docusign"
            },
            {
                "template"     : "upload-statements",
            },
            {
                "template"     : "additional-information",
            },
            {
                "template"     : "docusign-accounts",
            },
            {
                "template"     : "docusign-other-assets",
            },
            {
                "template"     : "docusign-cash-flow",
            },
            {
                "template"      : "review-advisor-contact"
            },
            {
                "template"      : "accountOpening"
            },
            {
                "template"      : "accountConfirmation"
            },
            {
                "template"      : "accountSuccess"
            },
            {
                "template"      : "dashboard"
            }
        ]
    }
]