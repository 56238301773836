<div class="container">
    <div class="ques-block-row pt-3">
        <div class="ques-block-inr mx-auto">
            <img src="../../../assets/images/avatar.png" class="avatar-hexa">
            <div class="text-center text-dark">{{fullname}}</div>
            <a class="color-999 font-weight-light">{{email}}</a>
        </div>
    </div>
    <div class="sub-txt-big text-left color-999 font-16 mt-5  ml-5 pb-5">
        <h2>Congratulations!</h2>
        <br>
        <p>We’ve got it from here. Our team will review your information to make sure everything is in good
            order and then move the process along.</p>
        <br>
        <p>Thank you.</p>
        <br>
        <p>Please close this tab if you are finished with the session.</p>
    </div>
    <p class="small-border mx-auto"></p>
</div>