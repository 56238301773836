// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    "production": false,
    "client_id": "165260fe0fe5cc748d410b28de8a0507b71867303540ca954965a73ef24b286e",
    "client_secret": "ed6dfa321221783e7963855a2b8d47d46cdcc879e79463c3f3b40f2dadc825e7",
    "environment": "staging",
    "pusher_app_key": "0bd42d661aeccfe75b58",
    "pusher_app_cluster": "mt1",
    "statement_document_id": 1,
    "statement_user_document_type_id": 99,
    "accounts_user_document_type_id": 3,
    "docusign_user_document_type_id": [4, 5],
    "gtm_file": "gtm_qa.js",
    "api_server": "",
    "fusion_chart_key":"rpI3xgkA3D5B3E5B4G3B3B11B5B5D2C2E1hH-8sC11C2dbhvcF3H3znE-13F4E2D3E1D4A3A9B1C6C5B1G4E3D1J2B3nqsD1B1G4cA32B13B9vulG4H3D3jnA-21A5A1E3B-9fbA1A7A4A6A2B4C2D3H1F2C2emoE6E1f1H4akzA5E6F4kzH3MC2D5vpaB2E3C1I3B8A6B5B3D3D3E3C2G2B11y=="
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
