import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginFormComponent } from './login-form/login-form.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { EnableMfasplashComponent } from './enable-mfasplash/enable-mfasplash.component';
import { EnableAuthyComponent } from './enable-authy/enable-authy.component';
import { EnterTokenComponent } from './enter-token/enter-token.component';
import { EnableAuthyMfaComponent } from './enable-authy-mfa/enable-authy-mfa.component';

const loginRoutes: Routes = [
    {
        path: 'login',
        component: LoginFormComponent
    },
    {
        path: 'forgotPassword',
        component: ForgotPasswordComponent
    },
    {
        path: 'resetPassword',
        component: ResetPasswordComponent
    },
    {
        path: 'changePassword/firm/:firmCode',
        component: ResetPasswordComponent
    },
    {
        path: 'enableMFASplash',
        component: EnableMfasplashComponent
    },

    {
        path: 'enableMFA',
        component: EnableAuthyComponent
    },
    {
        path: 'enterToken',
        component: EnterTokenComponent
    },
    {
        path: 'enableAuthyMFA',
        component: EnableAuthyMfaComponent
    },








];


@NgModule({
    imports: [RouterModule.forChild(loginRoutes)],
    exports: [RouterModule]
})
export class LoginRoutingModule { }
