import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { AppRestEndPoint } from 'src/app/app-restEndPoint';
import { ScheduleMeetingModalComponent } from 'src/app/dashboard-main/schedule-meeting-modal/schedule-meeting-modal.component';
import { ScheduleOnceMeetingModalComponent } from 'src/app/dashboard-main/schedule-once-meeting-modal/schedule-once-meeting-modal/schedule-once-meeting-modal.component';
import { SendFilesModalComponent } from 'src/app/dashboard-main/send-files-modal/send-files-modal.component';
import { ApiService } from 'src/app/services/api.service';
import { CommonService } from 'src/app/services/common.service';
import { EventEmitterService } from 'src/app/services/event-emitter.service';
import { ContactUsModalComponent } from './contact-us-modal/contact-us-modal.component';
import { environment } from 'src/environments/environment';
import { ToastService } from '../toaster/toast-service';
import { UtilityService } from 'src/app/services/utility.service';
declare var Calendly;

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
	access_token: any;
	websiteLogo: any;
	websiteUrl: any;
	currentPage: any;
	currentUser: any;
	advisorData: any;
	schedulingIntegration: any;
	showScheduleOnce: Boolean = false;
	disabledSchBtn: Boolean = false;
	scheduleonceUrl: any;
	eventList: any = [];
	advisorFullName: any;
	advisorFirstName: any;
	advisorId: any;
	bookingUrl: any;
	showNewsIcon:any;
	tamaracObj:any = [];
	currentIndex:any;
	environment:any;
	advisoryTeam:any = [];
	rtqEmail: any = null;

	constructor(private eventEmitter: EventEmitterService,
		private commonService: CommonService,		
		private toastService: ToastService,
		public modalService: NgbModal,
		public router: Router,
		public utilService: UtilityService,
		private sanitizer: DomSanitizer,
		private apiService: ApiService) {

		this.router.events.subscribe((e) => {
			if (e instanceof NavigationEnd) {
				this.access_token = sessionStorage.getItem('token');
				let pageUrl = e.url.split('/');
				this.currentPage = pageUrl[pageUrl.length - 1];
				if(this.currentPage == 'dashboard' || this.currentPage == 'profile' || this.currentPage == 'documents' || this.currentPage == 'tasks'){
					this.eventEmitter.subscribe("adminUsers", (data) => { 
						if (data){
							this.advisoryTeam = [];
							this.advisorData = data.advisor;
							this.advisoryTeam.push(data.advisor);
							if(data.csa_advisors.length > 0){
								this.advisoryTeam.push(data.csa_advisors[0]);
							}
							if(data.secondary_advisors.length > 0){
								this.advisoryTeam.push(data.secondary_advisors[0]);
							}
							this.advisorFullName = data.advisor.name;
							this.advisorFirstName = data.advisor.first_name;        
							this.advisorId = 'SOIBTN_'+this.advisorFirstName;       
							this.schedulingIntegration = data.advisor.scheduling_integration;
							this.bookingUrl = data.advisor.booking_url;
							this.identifyScheduleTool();
						}
					});
				}
				this.currentUser = JSON.parse(sessionStorage.getItem('current_user'));
			}
		});
	}

	ngOnInit(): void {
		this.rtqEmail = sessionStorage.getItem("rtq_redirect");
		this.eventEmitter.subscribe("Rtqemail", (flag) => { 
			this.rtqEmail = flag;
		});
		this.environment = environment.environment;
		if (this.commonService.getDomainDetails().domainLogo) {
			this.websiteLogo = this.commonService.getDomainDetails().domainLogo;
			this.websiteUrl = this.commonService.getDomainDetails().firm_website;
		}else{
			this.eventEmitter.subscribe("Domain", (domainObj) => { 
				if (domainObj){
					this.websiteLogo = domainObj.domainLogo;
					this.websiteUrl = domainObj.firm_website;
				} 
			});
		}
	}

	openContactUsModal() {

		const contactUsModalRef = this.modalService.open(ContactUsModalComponent, 
			{
				windowClass: 'modal-width-850',
				backdrop: 'static'
			}
		);
		contactUsModalRef.componentInstance.currentPage = this.currentPage;
		if(this.advisorData){
			contactUsModalRef.componentInstance.advisorData = this.advisorData;
			contactUsModalRef.componentInstance.advisoryTeam = this.advisoryTeam;
		}
	}

	logOut() {
		this.commonService.logOut();
	}

	identifyScheduleTool(){
        if(this.schedulingIntegration === 'None' || this.schedulingIntegration === null || this.schedulingIntegration === ''){
            this.showScheduleOnce = false;
        }else if(this.schedulingIntegration === 'Calendly'){
            this.showScheduleOnce = false;         
        }else if(this.schedulingIntegration === 'ScheduleOnce' || this.schedulingIntegration === 'Zoom'){          
            this.showScheduleOnce = true;
            let schedule = this.advisorData.booking_url+'?name='+this.currentUser.name+'&email='+this.currentUser.email+'&skip=1';
			this.scheduleonceUrl =  this.sanitizer.bypassSecurityTrustResourceUrl(schedule);	
        }
    }

	getSchedule(){
		this.disabledSchBtn = true;
		if (this.schedulingIntegration === 'None'|| this.schedulingIntegration === null || this.schedulingIntegration === '') {
            this.eventList = [];
            let date = moment().format('MM/DD/YYYY');
            this.apiService.getCall(AppRestEndPoint.DASHBOARD.GET_SCHEDULE, "&date=" + date).subscribe(response => {
                this.disabledSchBtn = false;
				this.openScheduleMeetingModal(response);
                if (response.data && response.data.success) {
                    this.eventList = response.data.events;
                }
            });
        }
        else if (this.schedulingIntegration === 'ScheduleOnce') {
			this.disabledSchBtn = false;
            this.openScheduleOncePopUp();
        }
        
        else if(this.schedulingIntegration === 'Calendly') {
			this.disabledSchBtn = false;
            this.openCalendlyPopUp();
        }

		else if (this.schedulingIntegration === 'Zoom') {
			this.openZoomLink();
		}

	}

	openSendFilesModal(){
		this.sendMixPanelEvent({'event': 'Dashboard - Send Files'});
		const sendFilesModalRef = this.modalService.open(SendFilesModalComponent, 
			{
				windowClass: 'modal-width-850',
				backdrop: 'static'
			}
		);
		sendFilesModalRef.componentInstance.advisorData = this.advisorData;
		sendFilesModalRef.componentInstance.advisoryTeam = this.advisoryTeam;
		
	}

	openScheduleMeetingModal(response){	
		this.sendMixPanelEvent({'event': 'Dashboard - Schedule a Meeting'});
		const openscheduleModalRef = this.modalService.open(ScheduleMeetingModalComponent, { windowClass: 'modal-width-850',backdrop: 'static' });	
		openscheduleModalRef.componentInstance.advisorFullName = this.advisorFullName,
		openscheduleModalRef.componentInstance.advisorFirstName = this.advisorFirstName,
		openscheduleModalRef.componentInstance.eventList = this.eventList	,
		openscheduleModalRef.componentInstance.advisoryTeam = this.advisoryTeam;
		openscheduleModalRef.result.then((data) => {
			if (data && data.success == true) {
				this.toastService.show("Message sent.", {
					classname: 'bg-success text-light', delay: 3000
				});
			}
		});
	}

	openScheduleOncePopUp(){
		const openscheduleOnceModalRef = this.modalService.open(ScheduleOnceMeetingModalComponent, { windowClass: 'modal-width-850',backdrop: 'static' });	
		openscheduleOnceModalRef.componentInstance.currentUserName = this.currentUser.name,
		openscheduleOnceModalRef.componentInstance.currentUserEmail = this.currentUser.email,
		openscheduleOnceModalRef.componentInstance.bookingUrl = this.bookingUrl
		openscheduleOnceModalRef.result.then((data) => {
			if (data && data.reload === true) {
				
			}
		});

	}

	openCalendlyPopUp()
	{
		var urlData = this.bookingUrl+'?name='+this.currentUser.name+'&email='+this.currentUser.email;            
        Calendly.initPopupWidget({url: urlData});     
	}

	openZoomLink() {
		window.open(this.scheduleonceUrl.changingThisBreaksApplicationSecurity, '_blank');
	}

	setCurrentNav(currentNav) {
		this.sendMixPanelEvent({ 'event' : currentNav});
		switch (currentNav) {
			case 'Dashboard':
				this.router.navigateByUrl('/dashboard');
				break;
			case 'Tasks':
				this.router.navigateByUrl('/tasks');
				break;
			case 'Profile':
				this.router.navigateByUrl('/profile');
				break;
			case 'Documents':
				this.router.navigateByUrl('/documents');
				break;
			default:
				break;
		}
	}

	openPortfolio() {
		this.sendMixPanelEvent({ 'event' : 'Portfolio'});
		let url = AppRestEndPoint.LOGIN.PORTFOLIO + "?access_token=" + this.apiService.getToken();
		window.open(url);
	}

	openKnowledgeBase() {
		let url = AppRestEndPoint.LOGIN.KNOWLEDGE_BASE + "?access_token=" + this.apiService.getToken();
		window.open(url);
	}

	sendMixPanelEvent(obj){
		this.utilService.gtmPush(obj);
	}
}
