import { Injectable } from '@angular/core';
import { pageRouteMap } from '../constants/pageRouteMap';
import * as _ from 'lodash';
import { Router } from '@angular/router';

@Injectable({
	providedIn: 'root'
})
export class NavigationService {
	pageMap = pageRouteMap;
	newState: any;
	info_type: any;
	mainIndex: any;

	constructor(public router: Router) { }
	public getNextPage(currentPage){
		this.info_type = this.pageMap[0][currentPage.info_type];
		this.mainIndex = _.findIndex(this.info_type, {template : currentPage.state});

		if(sessionStorage.getItem('is_questionnaire_required') && sessionStorage.getItem('is_questionnaire_required')=="false"){		   
			var index1 = _.findIndex(this.info_type, {template : 'risk-tolerance'});
			if(currentPage.state === 'investment-info' && index1 > -1 ){
				this.info_type.splice(index1,1);
			}
			var index2 = _.findIndex(this.info_type, {template : 'information-submitted'});
			if(currentPage.state === 'investment-info' && index2 > -1){
				this.info_type.splice(index2,1);
			}
		}
		if(this.mainIndex > -1){
			this.newState = this.info_type[this.mainIndex+1].template;
		}
		else{
			_.forEach(this.info_type, function(val){
				var index = _.findIndex(val.child_pages, {template :currentPage.state});
				if(index > -1){
					if(index < val.child_pages.length -1){
						this.newState = val.child_pages[index+1].template;
						return false;
					}
					else{
						var parentIndex = _.findIndex(this.info_type, function(obj){
							return obj == val;
						});
						this.newState = this.info_type[parentIndex+1].template;
						return false;
					}
				}
			}.bind(this));
		}
		/* if(sessionStorage.getItem('is_questionnaire_required') && sessionStorage.getItem('is_questionnaire_required')!=null && sessionStorage.getItem('is_questionnaire_required')!='' && this.newState=="advisorContact"){
			if(env[config]==='bridge'){
				$state.go('signupComplete',{type: currentPage.info_type}); 
				return;
			}
		   
		} */
		this.router.navigate([currentPage.info_type+"/"+this.newState]);	
	}

	public getPreviousPage(currentPage){
		this.info_type = this.pageMap[0][currentPage.info_type];
		if(sessionStorage.getItem('is_questionnaire_required') && sessionStorage.getItem('is_questionnaire_required')=="false"){
			var index1 = _.findIndex(this.info_type, {template : 'risk-tolerance'});
			if(currentPage.state === 'assets' && index1 > -1){
				this.info_type.splice(index1,1);
			}
			var index2 = _.findIndex(this.info_type, {template : 'information-submitted'});
			if(currentPage.state === 'assets' && index2 > -1){
				this.info_type.splice(index2,1);
			}
		}
		this.mainIndex = _.findIndex(this.info_type, {template : currentPage.state});
		if(this.mainIndex > -1){
			var isChildPages = this.info_type[this.mainIndex-1].child_pages; 
			if(isChildPages){
				this.newState = isChildPages[isChildPages.length-1].template;
			}else{
				this.newState = this.info_type[this.mainIndex-1].template;
			}
		}
		else{
			_.forEach(this.info_type, function(val){
				var index = _.findIndex(val.child_pages, {template :currentPage.state});
				if(index > -1){
					if(index == 0){
						var parentIndex = _.findIndex(this.info_type, function(obj){
							return obj == val;
						});
						this.newState = this.info_type[parentIndex].template;
						return false;
					}
					else{
						this.newState = val.child_pages[index-1].template;
						return false;
					}
				}
			}.bind(this));
		}
		this.router.navigate([currentPage.info_type+"/"+this.newState]);	
	};
}
