<app-bread-crumb-options></app-bread-crumb-options>
<div class="container" [hidden]="!allocationList">
    <div class="text-break text-center font-24 mt-5">
        CONGRATULATIONS! YOU’RE ALL DONE WITH THE BASICS.
    </div>
    <div class="col-md-12 col-sm-12 col-xs-12 mt-4 mb-5 d-md-flex d-sm-flex">
        <div class="col-md-6 col-sm-6 col-xs-12">
            <div class="font-18 text-break">Below is the preliminary plan on how your savings should be allocated. Your
                advisor will help to finalize an investment plan designed for you.</div>
            <div class="ques-block-row">
                <div class="col-md-12 col-sm-12 col-xs-12 px-0 d-md-flex d-sm-flex mt-4">
                    <div class="col-md-6 col-sm-6 col-xs-12 mapInfocenter margin-top-6em p-0">
                        <fusioncharts width="100%" class="float-left" type="doughnut2d" dataFormat="json"
                            [dataSource]="allocationListGraphData">
                        </fusioncharts>
                    </div>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <table class="table cont-table">
                            <thead>
                                <tr>
                                    <th>
                                        <div class="bullet">HOLDINGS</div>
                                    </th>
                                    <th class="text-right">TARGET%</th>
                                    <th class="text-right">VALUE</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let value of allocationList; let i = index">
                                    <td>
                                        <div class="bullet">
                                            <span [ngStyle]="{'background':colorRange[i]}"></span>{{value.label}}
                                        </div>
                                    </td>
                                    <td class="text-right">{{roundTo(value.weight)}}%</td>
                                    <td class="text-right"><span>{{value.invest | currency : 'USD' : 'symbol' :
                                            '1.0-0'}}</span></td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>
                                        <div class="bullet">Total</div>
                                    </td>
                                    <td class="text-right">{{roundTo(totalWeight)}}%</td>
                                    <td class="text-right"><span>{{totalInvest | currency : 'USD' : 'symbol' :
                                            '1.0-0'}}</span></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 col-sm-6 col-xs-12">
            <div class="font-18 text-break">To build a full financial plan, your advisor will need to know a bit more.
                You may either provide that information now or bring it to your next meeting.</div>
            <div class="ques-block-row ml-5">
                <div class="col-md-12 col-sm-12 col-xs-12 px-0 d-md-flex d-sm-flex mt-5">
                    <div class="col-md-6 col-sm-8 col-xs-10">
                        <img src="../../../assets/images/dollar.png" class="list-img">
                        <div class="font-18 mt-3 mb-3 line-height-1pt1">ASSETS</div>
                    </div>
                    <div class="col-md-6 col-sm-4 col-xs-2"></div>
                </div>
                <div class="col-md-12 col-sm-12 col-xs-12 px-0 d-md-flex d-sm-flex mt-5">
                    <div class="col-md-4 col-sm-2 col-xs-2"></div>
                    <div class="col-md-6 col-sm-10 col-xs-10">
                        <img src="../../../assets/images/dollar.png" class="list-img">
                        <div class="font-18 mt-3 mb-3 line-height-1pt1">LIABILITIES</div>
                    </div>
                </div>
                <div class="col-md-12 col-sm-12 col-xs-12 px-0 d-md-flex d-sm-flex mt-5">
                    <div class="col-md-6 col-sm-8 col-xs-10">
                        <img src="../../../assets/images/dollar.png" class="list-img">
                        <div class="font-18 mt-3 mb-3 line-height-1pt1">INCOME</div>
                    </div>
                    <div class="col-md-6 col-sm-4 col-xs-2"></div>
                </div>
                <div class="col-md-12 col-sm-12 col-xs-12 px-0 d-md-flex d-sm-flex mt-5">
                    <div class="col-md-4 col-sm-2 col-xs-2"></div>
                    <div class="col-md-6 col-sm-10 col-xs-10">
                        <img src="../../../assets/images/dollar.png" class="list-img">
                        <div class="font-18 mt-3 mb-3 line-height-1pt1">EXPENSES</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="clear"></div>
    <div>
        <!-- Web -->
        <div *ngIf="rtqEmail == 'true'" class="d-none d-sm-block col-md-12 text-center">
            <button class="btn btn-primary rounded-pill btn-lg-custom my-2 mx-4" (click)="logOut()">
                All done for now
            </button>
        </div>
        <app-buttonbar *ngIf="rtqEmail == null" class="d-none d-sm-block" (clickeddone)="gotoNextState()" (clickednext)="logOut()" hidebackbtn="true" showdonebtn="true" hidenextbtn="true"></app-buttonbar>
    </div>
    <div>
        <!-- Mobile -->
        <div *ngIf="rtqEmail == 'true'" class="d-block d-sm-none col-md-12 text-center">
            <button class="btn btn-grn rounded-pill btn-sm mt-4 mb-4" (click)="logOut()">
                Done
            </button>
        </div>
        <app-buttonbar *ngIf="rtqEmail == null" class="d-block d-sm-none" (clickeddone)="logOut()" (clickednext)="gotoNextState()" hidebackbtn="true" showdonebtn="true"></app-buttonbar>
    </div>
</div>