import { Domains } from 'src/constants/Domains';
import { environment } from './../environments/environment';

//const env = environment.apiUrl + '/apiclient/v1';
let apiUrl;
if(environment.api_server){
    apiUrl = environment.api_server;
}else{
    apiUrl = Domains[document.location.host].api_url;
}
const env = apiUrl + '/apiclient/v1';
export const AppRestEndPoint = {
    CURRENT_ENV: env,
    CURRENT_API_URL: apiUrl,
    LOGIN: {
        SIGN_IN: env + '/users/sign_in',
        TOKEN_SIGN_IN: env + '/users/token_sign_in',
        FORGOT_PASSWORD: env + '/users/forgot_password',
        RESET_PASSWORD: env + '/users/reset_password',
        SIGN_UP: env + '/users/account_info',
        SIGN_OUT: env + '/users/sign_out',
        SAVE_STATE: env + '/users',
        CONTACT_US: env + '/users/contact_us.json',
        USER_LIST: env + '/users',
        SEND_CONTACT_FORM: env + '/firms/contact_us',
        PORTFOLIO: env + '/users/portfolio',
        KNOWLEDGE_BASE: env + '/users/knowledge_base',
        ENABLE_AUTHY: env + '/users/enable_authy',
        VERIFY_TOKEN: env + '/users/verify_token',
        SEND_TOKEN: env + '/users/send_token',
        VERIFY_ONE_TOUCH: env + '/users/verify_one_touch',
        UPDATE_SESSION_TIME: env + '/users/update_session_time',
        AUTHY_QR_CODE: env + '/users/:user_id/authy_qr_code'
    },
    BASIC_INFO: {
        ADVISOR_LIST: env + '/admin_users',
        RETIREMENT_YEAR: env + '/users/get_retirement_status',
        FAMILY_INFO: env + '/households/get_family_info',
        SAVE_FAMILY_INFO: env + '/households/family_info',
        SAVE_INFO_TYPE: env + '/households'
    },
    RISK_TOLERANCE: {
        QUESTION_LIST: env + '/investor_profiles/show_questions',
        SAVE_INVESTOR_PROFILE: env + '/investor_profiles',
        GET_ALLOCATION_DATA: env + '/investor_profiles/get_allocation_data'
    },
    DASHBOARD: {
        DASHBOARD_LOAD: env + '/dashboard',
        NET_WORTH: env + '/dashboard/net_worth',
        ESTATE_PLAN:env + '/households/estate_planning',
        DROPDOWN_VALUES:env + '/households/dropdown_values',
        SCENARIOS: env + '/scenarios',
        DASHBOARD_RANGE: env + '/scenarios',
        CAL_RETIRE: env + '/scenarios/run_calc',
        GET_SCENARIO: env + '/scenarios',
        HOUSEHOLD_LIST: env + '/users/:id/get_households',
        RESET_SCENARIO: env + '/scenarios/reset',
        SEND_FILES: env + '/households/send_attachment_with_email',
        SEND_EMAIL: env + '/households/send_email',
        DOCUMENT_LIST: env + '/user_documents',
        COMPLAINCE_DOCUMENTS: env + '/firms/compliance_documents',
        GET_TICKER: env + '/positions/search_ticker',
        GET_POSITIONS: env + '/positions',
        REMOVE_ACCOUNT: env + '/accounts',
        REMOVE_ALL_ACCOUNT: env + '/accounts/destroy_accounts',
        SCHEDULE_MEETING: env + '/households/schedule_meeting',
        GET_SCHEDULE: env + '/households/get_schedule',
        REMOVE_BANK: env + '/banks',
        REFRESH_ACCOUNT: env + '/yodlee/refresh',
        REFRESH_ALL: env + '/yodlee/refresh_all',
        SAVE_MULTIPLE_DOCUMENT: env + '/user_documents/upload_documents.json',
        DOWNLOAD_DOCUMENT: env + '/user_documents/:id/url',
        HELP_PAGES: env + '/help_pages',
        UPDATE_TODAY_DOLLAR: env + '/dashboard/change_todays_dollars',
        DASHBOARD_REBALANCING: env + '/dashboard/rebalancing_group',
        DASHBOARD_REBALANCING_DROPDOWN: env + '/dashboard/account_groups_dropdown',
        DASHBOARD_REBALANCING_ACCOUNT_DETAIL: env + '/dashboard/account_group_details',
        SUGGESTED_PROVIDERS: env + '/firms/suggested_providers',
        INCOME_EXPENSE: env + '/households/user_income_and_monthly_expenses',
        TASKS: env + '/tasks',
        TASK_COMPLETED: env + '/tasks/:id',
        TASK_WORKFLOW: env + '/company_workflows',

    },
    ASSET: {      
        GET_TOKEN: env + '/yodlee/fastlink_details',
        GET_ACC_DETAILS: env + '/banks',
        NET_WORTH: env + '/households/net_worth',
        NETWORTH_FORM: env + '/accounts/get_form',
        ACCOUNTS: env + '/accounts',
        CREATE_ASSET: env + '/accounts/create_assets',
        DROPDOWN_VALUES: env + '/accounts/dropdown_values',
        REMOVE_ACCOUNT: env + '/accounts/:account_id',
        REMOVE_ALL_ACCOUNT: env + '/accounts/destroy_accounts',
    },
    DOCUSIGN: {
        DOCUMENT_LIST: env + '/user_documents'
    },
    PROFILE:
    {
        CHANGE_PASSWORD: env + '/users/change_password',
        ACTIVATE_MFA: env + '/users/change_mfa',
        GET_USER_DEVICES: env + '/users/get_user_devices',
        DELETE_USER_DEVICE: env + '/users/delete_device',
        VERIFY_TOKEN: env + '/users/verify_authy',
        SEND_TOKEN: env + '/users/send_token',
        DEACTIVATE_MFA: env + '/users/deactive_mfa',
        GET_MFA_SETTING: env + '/users/mfa_enabled_status'
    },
    ADDITIONAL_INFO: {
        ADDITIONAL_INFO_LOAD: env + '/households/get_additional_info', 
        DROPDOWN_VALUES: env + '/users/dropdown_values',
        SAVE_ADDITIONAL_INFO: env + '/households/additional_info',
        VALIDATE_ADDRESS: env + '/addresses/validate',
        ADDRESS_LOOKUP: env + '/addresses/address_lookup'
    },
    PLAID: {
        GET_LINK_TOKEN: env + '/plaid/get_link_token',
        UPSERT_BANK: env + '/plaid/upsert_bank',
        PLAID_EXIT: env + '/plaid/plaid_exit_callback'
    },
    CASH_FLOW: {
        DROPDOWN: env + '/users/dropdown_values',
        INCOME_EXPENSE: env + '/expenses' 
    },
    UPLOAD: {
        DOCS_LOAD: env + '/user_documents'
    },
    EXTERNAL: {
        QUESTION_LIST: apiUrl + '/external/show_questions',
        SAVE_INVESTOR_PROFILE: apiUrl + '/external/create_investor_profile'
    }
}




