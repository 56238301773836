<div class="container">
    <div class="row">
        <div class="col-md-6 col-xs-10 col-sm-12 m-auto">
            <div class="card  border-radius-10 border-0 shadow-sm p-2">
                <div class="card-body pb-1">
                    <form novalidate>
                        <div class="col-md-12 text-center">
                            <img src="../assets/images/splash-screen.png" class="splash-img" alt="Responsive image">
                        </div>
                        <div class="col-md-12 mt-4 mb-5">
                            <h6>Two-Factor Authentication is required to access your Wealth Portal.</h6>
                        </div>

                        <div class="mt-4 mb-5 text-center text-center">
                            <button class="btn btn-primary border-radius-15 btn-lg-custom" role="tab"
                                (click)="registerTwoFactor()">SET UP NOW</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>