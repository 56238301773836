import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { countries } from './../../constants/countries';
import { ApiService } from 'src/app/services/api.service';
import { ToastService } from 'src/app/components/toaster/toast-service';
import { AppRestEndPoint } from 'src/app/app-restEndPoint';
import { UtilityService } from 'src/app/services/utility.service';
import { ConfirmationDialogService } from 'src/app/services/confirmation-dialog.service';
import { CommonService } from 'src/app/services/common.service';
import { Router, NavigationEnd } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactUsModalComponent } from 'src/app/components/header/contact-us-modal/contact-us-modal.component';
import { EventEmitterService } from 'src/app/services/event-emitter.service';
import { Validator } from 'src/app/services/validator';
import { LoginService } from 'src/app/services/login.service';


@Component({
	selector: 'app-profile',
	templateUrl: './profile.component.html',
	styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

	changePassword: any = {};
	changePasswordFormSubmitted: any;
	changePasswordForm: FormGroup;
	countries: Array<any>;
	authyDetail: any = {};
	invalid: any = [];
	currentUser: any;
	disable: any = {};
	userDevices: any = [];
	authyStatus: any = {};
	codeEntry: any = {};
	tokenFormSubmitted: any;
	tokenForm: FormGroup;
	enableAuthyFormSubmitted: any;
	authyDetailForm: FormGroup;
	additionalInfo: any;
	currentPage: any;
	advisorData: any;
	firmName: any;
	showQrCode: boolean = false;
	qrcode: any;
  	secretKey: any;
	twilio_entity_sid: any;
	twilio_factor_sid: any;
	authy_type: any = '';

	constructor(
		private formBuilder: FormBuilder,
		private apiService: ApiService,
		private toastService: ToastService,
		private confirmationDialogService: ConfirmationDialogService,
		private utilService: UtilityService,
		private commonService: CommonService,
		public modalService: NgbModal,
		public eventEmitter: EventEmitterService,
		private loginService: LoginService,
		public router: Router) {
		this.changePasswordForm = this.formBuilder.group({
			passwordOld: ['', Validators.required],
			passwordNew: [null, Validators.compose([
				Validators.required,
				Validator.patternValidator(/[a-z]/, { hasSmallCase: true }),
				Validator.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
				Validator.patternValidator(/\d/, { hasNumber: true }),
				Validator.patternValidator(/[$@$!%*#?&]/, { hasSpecialCharacters: true }),
				Validators.minLength(8)
			])],
			passwordReenter: ['', [Validators.required]]
		});

		this.tokenForm = this.formBuilder.group({
			'security': [null, Validators.required],
			'rememberDevice': [null]
		});

		this.authyDetailForm = this.formBuilder.group({
			'countryCode': [null],
			'phoneNumber': [null, Validators.required]
		});
	}


	ngOnInit(): void {
		this.countries = countries;
		this.currentUser = JSON.parse(sessionStorage.getItem('current_user'));
		this.getAuthorizedDevices();
		this.loadMFAStatus();
		//this.getPersonalInformation();
		this.firmName = sessionStorage.getItem('firm_Name');
		if (sessionStorage.getItem("adminUsers")) {
			let data = JSON.parse(sessionStorage.getItem("adminUsers"));
			this.advisorData = data.advisor;
		} else {
			this.eventEmitter.subscribe("adminUsers", (adminUsers) => {
				if (adminUsers) {
					this.advisorData = adminUsers.advisor;
				}
			});
		}
	}

	get formControl() {
		return this.changePasswordForm.controls;
	}

	get authyFormControl() {
		return this.authyDetailForm.controls;
	}

	changeProfilePassword() {
		this.changePasswordFormSubmitted = true;
		let validateSpace = new RegExp("[\\s]");
		let oldPasswordVal = validateSpace.test(this.changePassword.passwordOld);
		let newPasswordVal = validateSpace.test(this.changePassword.passwordNew);
		let confirmPasswordVal = validateSpace.test(this.changePassword.passwordReenter);
		if (oldPasswordVal || newPasswordVal || confirmPasswordVal) {
			this.changePasswordForm.controls['passwordOld'].setErrors({ 'spaceNotAllowed': true });
			this.changePasswordForm.controls['passwordNew'].setErrors({ 'spaceNotAllowed': true });
			this.changePasswordForm.controls['passwordReenter'].setErrors({ 'spaceNotAllowed': true });
			return;
		}
		if (this.changePassword.passwordNew !== this.changePassword.passwordReenter) {
			this.changePasswordForm.controls['passwordReenter'].setErrors({ 'invalidMatch': true });
			return;
		}

		if (this.changePasswordForm.valid) {
			this.disable.disablePwdBtn = true;
			let reqObj = {
				"current_password": this.changePassword.passwordOld,
				"password": this.changePassword.passwordNew,
				"confirm_password": this.changePassword.passwordReenter

			};

			let url = AppRestEndPoint.PROFILE.CHANGE_PASSWORD;
			this.apiService.updateCall(url, reqObj, '').subscribe(data => {
				this.disable.disablePwdBtn = false;
				if (data.success) {
					this.toastService.show("Password changed successfully!", {
						classname: 'bg-success text-light', delay: 3000
					});
					if (this.commonService.getDomainDetails().firm_code) {
						this.router.navigate(['/login/firm/' + this.commonService.getDomainDetails().firm_code]);
					} else {
						this.router.navigate(['/login']);
					}

				} else if (data.msg && data.msg.password) {
					let len = data.msg.password.length;
					for (let i = 0; i < len; i++) {
						this.invalid[i] = "Password " + data.msg.password[i];
					}
				} else {
					this.toastService.show(data.msg, {
						classname: 'bg-danger text-light', delay: 3000
					});
				}

			});
		}
	}

	deactivateMfa() {
		this.codeEntry.phoneToken = '';
		let url = AppRestEndPoint.PROFILE.DEACTIVATE_MFA;
		this.apiService.updateCall(url, '').subscribe(data => {
			this.disable.disablePwdBtn = false;
			if (data) {
				this.authyDetail.cellPhone = "";
				this.authyDetail.countryCode = "";
				this.authyDetail.countrylabel = "Select Country";
				if (data.success) {
					this.toastService.show(data.message, {
						classname: 'bg-success text-light', delay: 3000
					});
					this.disable.deactivatedMfabutton = false;
					this.authyStatus.showMfaStatus = false;
					this.authyStatus.enterToken = false;
					this.authyStatus.maskedPhoneNumber = ''
					this.currentUser.is_authy_enabled = false;
					this.currentUser.mfa_enabled = false;
					sessionStorage.setItem('current_user', JSON.stringify(this.currentUser));
				} else {

					this.toastService.show(data.message, {
						classname: 'bg-danger text-light', delay: 3000
					});
					this.disable.deactivatedMfabutton = true;
					this.authyStatus.showMfaStatus = true;
					this.authyStatus.enterToken = true;
				}
				this.showQrCode = false;
			}
		});

	};

	getAuthorizedDevices() {
		let url = AppRestEndPoint.PROFILE.GET_USER_DEVICES;
		this.apiService.getCall(url, '').subscribe(data => {
			this.userDevices = data.user_devices;
		});
	};

	deleteDevice(deviceId) {
		let url = AppRestEndPoint.PROFILE.DELETE_USER_DEVICE + "?id=" + deviceId;
		this.apiService.deleteCall(url, deviceId).subscribe(data => {
			if (data) {
				this.toastService.show(data.message, {
					classname: 'bg-success text-light', delay: 3000
				});
				this.getAuthorizedDevices();
			}
		});

	};

	loadMFAStatus(setStatus:any='') {
		let url = AppRestEndPoint.PROFILE.GET_MFA_SETTING;
		this.apiService.getCall(url, '').subscribe(data => {
			if(data.status){
				this.authyStatus.showMfaStatus = data.status;
				this.authy_type = data.authy_type;
				if(data.mfa_phone){
					this.authyStatus.mfaPhonenum = data.mfa_phone;
					this.authyStatus.maskedPhoneNumber = "*******"+data.mfa_phone.substr(data.mfa_phone.length - 3);
				}
				if(setStatus){
					this.currentUser.mfa_enabled = true;
					sessionStorage.setItem('current_user', JSON.stringify(this.currentUser));
				}
			}
		});
	};

	activateMfa(flag, requestPhoneCall) {
		this.enableAuthyFormSubmitted = true;
		this.authyDetail.phoneToken = '';
		
		if (this.authyDetail.countryCode) {
			this.authyDetailForm.controls['countryCode'].setErrors(null);
		}else{
			this.authyDetailForm.controls['countryCode'].setErrors({ 'required': true });
		}

		if (this.authyDetail.cellPhone) {
			let cellPhone = this.authyDetail.cellPhone.replace(/[^\d]/g, "");
			if (cellPhone.length < 10) {
				this.authyDetailForm.controls['phoneNumber'].setErrors({ 'incorrect': true });
			}else{
				this.authyDetailForm.controls['phoneNumber'].setErrors(null);
			}
		}


		if (this.authyDetailForm.valid) {
			this.disable.disableActivateBtn = true;
			let url = '';
			if (requestPhoneCall) {
				url = AppRestEndPoint.PROFILE.ACTIVATE_MFA + "?enable_mfa=" + flag + "&request_call=" + requestPhoneCall + "&authy_type=sms";
			}
			else {
				url = AppRestEndPoint.PROFILE.ACTIVATE_MFA + "?enable_mfa=" + flag + "&authy_type=sms";
			}
			let reqObj = {
				"phone": this.authyDetail.cellPhone,
				"country_code": this.authyDetail.countryCode,
				"enable_mfa": flag

			};

			this.apiService.updateCall(url, reqObj, flag).subscribe(data => {
				this.disable.disableActivateBtn = false;
				this.enableAuthyFormSubmitted = false;
				if (data.status) {
					this.authyDetail.maskedPhone = data.cellphone;
					this.authyStatus.showMfaStatus = false;
					this.authyStatus.enterToken = true;
				}
				else {

					this.toastService.show(data.message, {
						classname: 'bg-success text-light', delay: 3000
					});
					this.authyStatus.showMfaStatus = true;
					this.authyStatus.enterToken = false;
				}

			});

		}
	};

	activateQrcodeMfa(flag) {
		this.disable.disableActivateBtn = true;
		let url = AppRestEndPoint.PROFILE.ACTIVATE_MFA + "?enable_mfa=" + flag + '&authy_type=qr_code';
		
		let reqObj = {
			"enable_mfa": flag,
			"twilio_entity_sid": this.twilio_entity_sid,
			"twilio_factor_sid": this.twilio_factor_sid
		};

		this.apiService.updateCall(url, reqObj, flag).subscribe(data => {
			this.disable.disableActivateBtn = false;
			this.enableAuthyFormSubmitted = false;
			if (data.status) {
				this.authyStatus.showMfaStatus = false;
				this.authyStatus.enterToken = true;
			}
			else {
				this.toastService.show(data.message, {
					classname: 'bg-success text-light', delay: 3000
				});
				this.authyStatus.showMfaStatus = true;
				this.authyStatus.enterToken = false;
				this.showQrCode = false;
			}
		});
	}

	setCountry(country) {
		this.authyDetail.countryCode = country.code;
		this.authyDetail.countrylabel = country.label;
	}

	resendToken(requestPhoneCall) {
		let params = requestPhoneCall ? `&id=${this.currentUser.id}&request_call=${requestPhoneCall}` : `&id=${this.currentUser.id}`;
		let url = AppRestEndPoint.PROFILE.SEND_TOKEN;
		this.apiService.getCall(url, params).subscribe(data => {
			if (data.success) {
				this.toastService.show(data.msg, {
					classname: 'bg-success text-light', delay: 3000
				});

			} else {
				this.toastService.show(data.message, {
					classname: 'bg-danger text-light', delay: 3000
				});

			}
		});
	};


	verifyToken(phone_token) {
		this.tokenFormSubmitted = true;
		if (this.tokenForm.valid) {
			let guid = this.utilService.getDeviceCookie();
			let userAgent = this.utilService.getUserAgent();
			this.disable.disableVerifyBtn = true;
			if (this.codeEntry.rememberDevice == false || this.codeEntry.rememberDevice === null) {
				this.codeEntry.rememberDevice = false;
			}
			let url = AppRestEndPoint.PROFILE.VERIFY_TOKEN;
			let param = "&token=" + phone_token + "&device_id=" + guid + "&user_agent=" + userAgent + "&remember_me=" + this.codeEntry.rememberDevice + "&is_web=true";
			if(this.showQrCode){
				param = param + `&create_record=true`;
			}
			this.apiService.getCall(url, param).subscribe(data => {
				this.disable.disableVerifyBtn = false;
				if (data.success) {
					this.currentUser.is_authy_enabled = true;
					sessionStorage.setItem('current_user', JSON.stringify(this.currentUser));
					this.loadMFAStatus(true);
					this.authyStatus.showMfaStatus = true;
					this.toastService.show(data.msg, {
						classname: 'bg-success text-light', delay: 3000
					});
				}
				else {
					this.invalid = data.msg;
				}

			});
		}
	};

	public openDeactivateMFAConfirmationDialog() {
		this.confirmationDialogService.confirm('CONFIRM', 'Are you sure you want to disable Two-Factor Authentication?')
			.then((confirmed) => {
				if (confirmed) { this.deactivateMfa(); }
			})
			.catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
	}

	public openDeleteDeviceConfirmationDialog(id) {
		this.confirmationDialogService.confirm('CONFIRM', 'Are you sure you want to delete this record?')
			.then((confirmed) => {
				if (confirmed) { this.deleteDevice(id); }
			})
			.catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
	}

	getPersonalInformation() {
		let url = AppRestEndPoint.ADDITIONAL_INFO.ADDITIONAL_INFO_LOAD;
		let param = '';
		this.apiService.getCall(url, param).subscribe(data => {
			//  addtionalInfoService.getInformationList(access_token).then(function (response) {

			if (data && data.household_users) {
				this.additionalInfo = data.household_users.household_users_attributes;
				let employeeCity = "", employerCity;

				for (let a = 0; a < this.additionalInfo.length; a++) {
					this.additionalInfo[a].user_attributes.birthday = this.additionalInfo[a].user_attributes.birthday;
					let empAddr = this.additionalInfo[a].user_attributes;
					this.additionalInfo[a].user_attributes.displayFirstName = this.additionalInfo[a].user_attributes.first_name;
					if (this.additionalInfo[a].user_attributes.middle_initial && this.additionalInfo[a].user_attributes.middle_initial != "" && this.additionalInfo[a].user_attributes.middle_initial != null) {
						this.additionalInfo[a].user_attributes.displayFullName = this.additionalInfo[a].user_attributes.first_name + " " + this.additionalInfo[a].user_attributes.middle_initial + " " + this.additionalInfo[a].user_attributes.last_name;
					} else {
						this.additionalInfo[a].user_attributes.displayFullName = this.additionalInfo[a].user_attributes.first_name + " " + this.additionalInfo[a].user_attributes.last_name;
					}
					this.additionalInfo[a].user_attributes.address1 = empAddr.address1;
					this.additionalInfo[a].user_attributes.employer_address = empAddr.employer_address;

					if (empAddr.city == "" || empAddr.city == null) {
						employeeCity = "";
					} else if (empAddr.state == null || empAddr.state == "") {
						employeeCity = empAddr.city;
					} else {
						employeeCity = empAddr.city + ",";
					}
					if (empAddr.employer_city == null || empAddr.employer_city == "") {
						employerCity = "";
					} else if (empAddr.employer_state == null || empAddr.employer_state == "") {
						employerCity = empAddr.employer_city;
					} else {
						employerCity = empAddr.employer_city + ",";
					}
					if (empAddr.address1 == "" || empAddr.address1 == null) {
						empAddr.address1 = "";
					}
					if (empAddr.address2 == "" || empAddr.address2 == null) {
						empAddr.address2 = "";
					}
					if (empAddr.state == "" || empAddr.state == null) {
						empAddr.state = "";
					}
					if (empAddr.address1 == "" || empAddr.address1 == null) {
						empAddr.address1 = "";
					}
					if (empAddr.zipcode == "" || empAddr.zipcode == null) {
						empAddr.zipcode = "";
					}
					if (empAddr.employer_address == "" || empAddr.employer_address == null) {
						empAddr.employer_address = "";
					}
					if (empAddr.employer_state == "" || empAddr.employer_state == null) {
						empAddr.employer_state = "";
					}
					if (empAddr.employer_zipcode == "" || empAddr.employer_zipcode == null) {
						empAddr.employer_zipcode = "";
					}
					this.additionalInfo[a].user_attributes.employee_address1 = empAddr.address1;
					this.additionalInfo[a].user_attributes.employee_address2 = empAddr.address2;
					this.additionalInfo[a].user_attributes.employee_city = employeeCity;
					this.additionalInfo[a].user_attributes.employee_state = empAddr.state;
					this.additionalInfo[a].user_attributes.employee_zipcode = empAddr.zipcode;

					this.additionalInfo[a].user_attributes.employer_address = empAddr.employer_address;
					this.additionalInfo[a].user_attributes.employer_city = employerCity;
					this.additionalInfo[a].user_attributes.employer_state = empAddr.employer_state;
					this.additionalInfo[a].user_attributes.employer_zipcode = empAddr.employer_zipcode;



					this.additionalInfo[a].user_attributes.complete_employee_address = empAddr.address1 + " " + empAddr.address2 + " " + employeeCity + empAddr.state + " " + empAddr.zipcode;
					this.additionalInfo[a].user_attributes.complete_employer_address = empAddr.employer_address + " " + employerCity + empAddr.employer_state + " " + empAddr.employer_zipcode;

				}
			}
		});
	};

	openContactUsModal(value) {
		const contactUsModalRef = this.modalService.open(ContactUsModalComponent,
			{
				windowClass: 'modal-width-850',
				backdrop: 'static'
			}
		);
		contactUsModalRef.componentInstance.selectedType = value;
		contactUsModalRef.componentInstance.advisorData = this.advisorData;

	}



	getQrcode(){
		// call api to get qrcode
		let url = AppRestEndPoint.LOGIN.AUTHY_QR_CODE.replace(':user_id', this.currentUser.id);
		let param = '';
		this.apiService.getCall(url, param).subscribe(data => {
			if(data && data.qr_code){
				this.qrcode = data.qr_code;
				this.secretKey = data.secret_key;
				this.twilio_entity_sid = data.twilio_entity_sid;
				this.twilio_factor_sid = data.twilio_factor_sid;
				this.showQrCode = true;
			}else{
				this.disable.disableActivateBtn = false;
			}
		});

	}

	hideQrcodeSection(){
		this.showQrCode = false;
		this.disable.disableActivateBtn = false;
	}

	copyLinkToaster(){
		this.toastService.show('Key copied to clipboard.', {
			classname: 'bg-success text-light', delay: 3000
		});
	}

	openAuthyLink() {
		window.open('https://authy.com/download/', '_blank');
	}
}
