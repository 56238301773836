import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppRestEndPoint } from 'src/app/app-restEndPoint';
import { ApiService } from 'src/app/services/api.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
	selector: 'app-basic-info',
	templateUrl: './basic-info.component.html',
	styleUrls: ['./basic-info.component.scss']
})
export class BasicInfoComponent implements OnInit {
	currentUser: any;

	constructor(
		public router: Router,
		private apiService: ApiService,
		public utilityService: UtilityService
	) { }

	ngOnInit(): void {
		this.currentUser = JSON.parse(sessionStorage.getItem('current_user'));
	}

	saveInfoType(type){
		let reqObj = {"household":{"household_type":parseInt(type)}};

		this.apiService.updateCall(AppRestEndPoint.BASIC_INFO.SAVE_INFO_TYPE+'/'+this.currentUser.selected_household_id, reqObj).subscribe(data => {
			if (data) {	
				if(type == 1){
					this.sendMixPanelEvent({'event': 'Client Onboarding - Basic Information - I am an individual'});
					this.router.navigate(["financial-info/basic-info/personal-info"]);
				}else if(type == 2){
					this.sendMixPanelEvent({'event': 'Client Onboarding - Basic Information - I am signing up for an institution'});
					sessionStorage.setItem('signupInstitution','true');
					this.router.navigate(["basic-flow/advisor-contact"]);
				}
			}
		});
	}

	sendMixPanelEvent(eventObj){
		this.utilityService.gtmPush(eventObj);
	}

}
