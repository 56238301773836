import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-bank-status-modal',
	templateUrl: './bank-status-modal.component.html',
	styleUrls: ['./bank-status-modal.component.scss']
})
export class BankStatusModalComponent implements OnInit {

	@Input() statusDetails
	

	constructor(private activeModal: NgbActiveModal) { }

	ngOnInit(): void {
	}

	cancel() {
		this.activeModal.close();
	}
}
