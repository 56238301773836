<div class="modal-header">
    <div class="row w-100">
        <div class="col-md-11">
            <h5 class="modal-title">Add Account</h5>
        </div>
        <div class="col-md-1 pr-0">
            <button type="button" class="close float-right text-white pr-0" aria-label="Close"
                (click)="activeModal.dismiss()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
</div>
<div class="modal-body">
    <form [formGroup]="addManualAccForm" novalidate>
        <div class="pb-4">
            <label class="text-uppercase">Name</label>
            <input type="text" class="form-control" name="name" formControlName="accountName"
                placeholder="Account Name or Description" [(ngModel)]="selectedRow.accountName" />
            <div *ngIf="addManualAccFormSubmitted" class="text-danger" role="alert">
                <div *ngIf="formControl.accountName.errors?.required">Please enter the Account
                    Name.</div>
            </div>
        </div>

        <div class="pb-4">
            <label class="text-uppercase">Type</label>
            <select class="form-control p-2" [(ngModel)]="selectedRow.acctype" formControlName="accountType"
                (change)="setInputElement(selectedRow.acctype)">
                <optgroup *ngFor="let group of accTypesGroup" label="{{group.type}}">
                    <option value="{{option.id}}" *ngFor="let option of group.groupItem">
                        {{option.name}}</option>
                </optgroup>
            </select>
        </div>

        <div *ngIf="!selectedRow.isManaged">
            <div class="pb-4" *ngFor="let field of fieldType">
                <div *ngIf="displayBeneficiary && field.isObject">
                    <label class="text-uppercase">Account Holder</label>
                    <select [(ngModel)]="accountHolder" formControlName="accountHolderName" name="accHolder"
                        class="form-control p-2">
                        <option *ngFor="let acc of field.data" value="acc[1]">{{acc[0]}}</option>
                    </select>
                </div>

                <div *ngIf="displayBeneficiary && field.isString && field.label != 'Interest Rate'">
                    <label class="text-uppercase">{{field.label}}</label>
                    <div>
                        <input type="text" class="form-control" [(ngModel)]="field.data" formControlName="balance"
                            appFormatter="currency" placeholder="$0">
                    </div>
                </div>

                <div *ngIf="displayBeneficiary && field.isString && field.label == 'Interest Rate'">
                    <label class="text-uppercase">{{field.label}}</label>
                    <div>
                        <input type="text" class="form-control" [(ngModel)]="field.data" formControlName="interestRate"
                            maxlength="3" decimalval="3" placeholder="0.000%" appFormatter="percentage">
                        <div *ngIf="formControl.interestRate.errors" class="error-text col-md-8 error-msg" role="alert">
                            <div *ngIf="formControl.interestRate.errors.maxlength" class="text-danger">Interest rate
                                cannot be more than 3 digits.</div>
                        </div>
                    </div>
                </div>

                <div *ngIf="displayEmployer && field.isBoolean">
                    <input type="checkbox" [(ngModel)]="field.data" formControlName="accountEmployer" />
                    <span class="pl-1">{{field.label}}</span>
                </div>

            </div>
        </div>
    </form>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-link" (click)="closeModal()">
        CANCEL
    </button>
    <button type="button" class="btn btn-primary btn-sm rounded-pill" (click)="createManualAccount()">
        Save
    </button>
</div>