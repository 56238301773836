<app-bread-crumb-options></app-bread-crumb-options>
<div class="container">
    <div class="row margin-top-10-pct">
        <div class="m-auto col-md-12 col-sm-12 col-xs-12 text-center">
            <form [formGroup]="investmentForm" novalidate>
                <div class="ques-box clearfix sh-50">
                    <div class="about-row pb-3 d-md-flex d-lg-flex">
                        <div class="font-30">I am looking to invest </div>
                        <div class="font-30 investment-input amountSmallScreen">
                            <input type="text" name="investment" class="form-control border-radius-10 cursor-pointer" 
                                [placeholder]="investmentPlaceholder" [(ngModel)]="investmentInfo.investment" (blur)="utilityService.formatValue($event,'currency')"
                                formControlName="investment">
                            <div *ngIf="investmentFormSubmitted" class="text-danger" role="alert">
                                <div *ngIf="formControl.investment.errors?.required || formControl.investment.errors?.invalidinvestment" class="font-16 pt-1 float-left">Amount is mandatory.</div>
                            </div>
                        </div>
                        <div class="font-30"> and plan to retire at </div>
                        <div class="font-30 investment-input">
                            <select id="retirement-age" name="retirementAge" [(ngModel)]="investmentInfo.retirementAge"
                                formControlName="retirementAge" class="form-control border-radius-10 cursor-pointer">
                                <option *ngFor="let retirement of retirementYearList" value="{{retirement.year}}">{{retirement.age}}</option>
                            </select>
                            <div *ngIf="investmentFormSubmitted" class="text-danger" role="alert">
                                <div *ngIf="formControl.retirementAge.errors?.required" class="font-16 pt-1">Retirement Age is mandatory.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="showAdvisorSentence">
                        <div class="about-row d-md-flex d-lg-flex">
                            <div class="font-30">I want to work with </div>
                            <div class="font-30 advisorDropDown investment-input">
                                <input type="text" placeholder="{{objModal.showAdvisorText}}"
                                    class="form-control border-radius-10 cursor-pointer" formControlName="advisor"
                                    id="typeahead-format" [(ngModel)]="investmentInfo.advisor"
                                    [ngbTypeahead]="searchAdvisor" [resultFormatter]="formatAdvisor"  
                                    [inputFormatter]="formatAdvisor" autocomplete="off" [required]="showAdvisorSentence">
                            </div>

                            <div class="font-30 investment-input">or</div>
                            <div class="font-30 investment-input">
                                <a class="btn btn-primary btn-sm-margin"
                                    (click)="updateAdvisorbtn()">{{advisorBtnLabel}}</a>
                            </div>
                            <div class="font-30 investment-input">as my advisor.</div>
                        </div>
                        <div *ngIf="investmentFormSubmitted" class="text-danger" role="alert">
                            <div *ngIf="formControl.advisor.errors?.required"  class="font-16 pt-1">You must select an advisor or click on the
                                pick one for me.</div>
                        </div>
                    </div>
                </div>
            </form>
            <div>
                <app-buttonbar (clickedprev)="gotoPreviousState()" (clickednext)="submitInvestment()"></app-buttonbar>
            </div>
        </div>
    </div>
</div>