import { Directive, HostListener, Input } from '@angular/core';

@Directive({
	selector: '[appButtonDisable]'
})
export class ButtonDisableDirective {
	@Input('formvalid') formvalid: any;
	disabled:Boolean = false;
	constructor() { }
	@HostListener('click', ['$event'])
	clickEvent(event) {
		if(this.formvalid == false){
			return;
		}
		event.srcElement.setAttribute('disabled', true);
		setTimeout(() => {
			event.srcElement.removeAttribute('disabled');
		}, 5000);
	}

}
