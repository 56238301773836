import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-scenario-account-and-group-modal',
	templateUrl: './scenario-account-and-group-modal.component.html',
	styleUrls: ['./scenario-account-and-group-modal.component.scss']
})
export class ScenarioAccountAndGroupModalComponent implements OnInit {

	@Input() selected_managed_assets_account_groups = [];
	constructor(public activeModal: NgbActiveModal, ) { }

	ngOnInit(): void {
	}

	saveGroups() {
		this.activeModal.close({ 'account_groups': this.selected_managed_assets_account_groups });
	}

}
