<app-header></app-header>
<router-outlet (activate)="changeOfRoutes()"></router-outlet>
<app-toasts aria-live="alert" aria-atomic="true" class="mr-auto"></app-toasts>
<div class="built-app" [ngClass]="{'built-app-class-web': isDesktopDevice,'built-app-class-mobile': isMobile}"
    *ngIf='(currentSiteUrl!= "openLinkOptions") && (currentSiteUrl == "login"  || currentSiteUrl == "enterToken" || currentSiteUrl == "dashboard" || currentSiteUrl == "forgotPassword") '
    [hidden]="hideDashguideBanner">
    <span class="built-app-close float-right" (click)="closeBanner()">x</span>
    <div *ngIf="isDesktopDevice" class="mt-2">
        <img src="./../assets/images/builtapp.png" width="95%">
    </div>
    <div class="col-md-12 px-0 discover-img" *ngIf="isMobile || isDesktopDevice">
        <a href='./../assets/files/Introducing DashGuide_July2021.pdf' target="_blank"><img
                src="./../assets/images/discover.png" width="70%" *ngIf="isDesktopDevice"></a>
        <div class="col-md-3 col-sm-3 col-xs-3 margin-top-3" [hidden]="isDesktopDevice">
            <img src="./../assets/images/appIcon.png" width="100%" height="35px">
        </div>
        <div class="col-md-4 col-sm-4 col-xs-4 no-padding-right no-padding-left" *ngIf="isMobile">
            <a href="itms-apps://apps.apple.com/us/app/dashguide/id1478282339#?platform=iphone')"><img
                    src="./../assets/images/downloadappstore.png" width="100%" height="40px" /></a>
        </div>
        <div class="col-md-4 col-sm-4 col-xs-4 no-padding-left no-padding-right" *ngIf="isMobile">
            <a href='https://play.google.com/store/apps/details?id=com.owlinvest.ios.prod.client.ux'
                *ngIf="isMobile"><img src="./../assets/images/downloadplaystore.png" width="100%"
                    height="40px" /></a>
        </div>
    </div>
</div>