import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { AppRestEndPoint } from 'src/app/app-restEndPoint';
import { ApiService } from 'src/app/services/api.service';
import { states } from './../../constants/states';
import { NavigationService } from 'src/app/services/navigation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { isUndefined } from 'util';
import { isDefined } from '@angular/compiler/src/util';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { NgbModal, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { ContactUsModalComponent } from 'src/app/components/header/contact-us-modal/contact-us-modal.component';
import { EventEmitterService } from 'src/app/services/event-emitter.service';
import * as moment from 'moment';
import { UtilityService } from 'src/app/services/utility.service';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, tap } from 'rxjs/operators';
import * as _ from 'lodash';
import { ShowUspsAddressModalComponent } from '../show-usps-address-modal/show-usps-address-modal.component';
import { melissacodes } from 'src/app/constants/melissa-codes';
import { Validator } from 'src/app/services/validator';

@Component({
	selector: 'app-additional-information',
	templateUrl: './additional-information.component.html',
	styleUrls: ['./additional-information.component.scss']
})
export class AdditionalInformationComponent implements OnInit {
	@ViewChild('tabset')
	tabset: any;
	additionalInfo: any = [];
	empIndustryCodesArr: any;
	empOccupationCodesArr: any;
	statusList: any;
	stateList: any;
	melissaCodeList: any
	selectedType: any;
	informationFormSubmitted: any;
	bsConfig?: Partial<BsDatepickerConfig>;
	additionalInfoForm: FormGroup;
	ssnRegEx = /^(\d{3}\-{1}\d{2}\-{1}\d{4})$/;
	firmName: any;
	advisorData: any;
	editPersonalInfo: any = false;
	advisoryTeam: any = [];
	addressList: any;
	searching: any;
	addressIndex = 0;
	addressError: any = [];
	providedAddress: { mailing_address1: any; mailing_address2: any; mailing_city: any; mailing_state: any; mailing_zipcode: any; address_header: any };
	validAddressData: { mailing_address1: any; mailing_address2: any; mailing_city: any; mailing_state: any; mailing_zipcode: any; };
	employerProvidedAddress: { mailing_address1: any; mailing_address2: any; mailing_city: any; mailing_state: any; mailing_zipcode: any; address_header: any};
	employerValidAddress: { mailing_address1: any; mailing_address2: any; mailing_city: any; mailing_state: any; mailing_zipcode: any; };
	employerAddress = false;

	constructor(public apiService: ApiService,
		private navigationService: NavigationService,
		private fb: FormBuilder,
		private router: Router,
		public cdref: ChangeDetectorRef,
		public modalService: NgbModal,
		public eventEmitter: EventEmitterService,
		private activatedRoute: ActivatedRoute,
		private utilService: UtilityService ) {
		this.additionalInfoForm = fb.group({
			'additionalInfoArr': this.fb.array([]),
		});
	}

	ngOnInit(): void {
		this.stateList = states;
		this.melissaCodeList = melissacodes;
		this.selectedType = this.activatedRoute.snapshot.paramMap.get('type');
		this.bsConfig = Object.assign({}, {
			adaptivePosition: true,
			containerClass: 'theme-dark-blue',
			showWeekNumbers:false,
			dateInputFormat: 'MM/DD/YYYY'
		});
		this.getAdditionalInformation();
		this.loadDropdownValue();
		this.firmName = sessionStorage.getItem('firm_Name');
		if (sessionStorage.getItem("adminUsers")) {
			let adminUsers = JSON.parse(sessionStorage.getItem("adminUsers"));
			this.createAdvisoryTeamData(adminUsers);
		} else {
			this.eventEmitter.subscribe("adminUsers", (adminUsers) => {
				if (adminUsers) {
					this.createAdvisoryTeamData(adminUsers);
				}
			});
		}
		this.selectedType == 'financial-info' ? this.editPersonalInfo = true : this.editPersonalInfo = false;
	}

	ngAfterContentChecked() {
		this.cdref.detectChanges();
	}

	createAdvisoryTeamData(adminUsers) {
		this.advisorData = adminUsers.advisor;
		this.advisoryTeam = [];
		this.advisorData = adminUsers.advisor;
		this.advisoryTeam.push(adminUsers.advisor);
		if (adminUsers.csa_advisors.length > 0) {
			this.advisoryTeam.push(adminUsers.csa_advisors[0]);
		}
		if (adminUsers.secondary_advisors.length > 0) {
			this.advisoryTeam.push(adminUsers.secondary_advisors[0]);
		}
	}

	get additionalInfoFormMessageArr(): FormArray {
		return this.additionalInfoForm.get('additionalInfoArr') as FormArray;
	}

	searchAddress = (text$: Observable<string>) =>
		text$.pipe(
			debounceTime(400),
			distinctUntilChanged(),
			tap(() => this.searching = true),
			map(term => term.length < 1 ? [] : this.addressList && this.addressList.filter(v => v.Address.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
		)

	formatAddress = (result: any) => result.Address;

	inputAddressFormat = (result: any) => {
		if (result && result.Address1) {
			return result.Address1;
		} else {
			return result;
		}
	}

	selectedAddress(item, i) {
		item.preventDefault();
		let state = this.stateList.find(i => i.code && i.code == item.item.AdministrativeArea);
		this.additionalInfo[i].user_attributes.state = state.name;
		this.additionalInfo[i].user_attributes.city = item.item.Locality;
		this.additionalInfo[i].user_attributes.zipcode = item.item.PostalCode;
		this.additionalInfo[i].user_attributes.address1 = item.item.Address1;
	}

	selectedEmployerAddress(item, i) {
		item.preventDefault();
		let state = this.stateList.find(i => i.code && i.code == item.item.AdministrativeArea);
		this.additionalInfo[i].user_attributes.employer_state = state.name;
		this.additionalInfo[i].user_attributes.employer_city = item.item.Locality;
		this.additionalInfo[i].user_attributes.employer_zipcode = item.item.PostalCode;
		this.additionalInfo[i].user_attributes.employer_address = item.item.Address1;
	}

	getAddressList(eve) {
		let url = AppRestEndPoint.ADDITIONAL_INFO.ADDRESS_LOOKUP;
		let param = '';
		this.apiService.getAddress(url, param, eve.currentTarget.value).subscribe(data => {
			let dataList = this.utilService.formatData(data.Results, 'Address');
			this.addressList = [];
			this.addressList = dataList;
		})
	}


	getAdditionalInformation() {
		let url = AppRestEndPoint.ADDITIONAL_INFO.ADDITIONAL_INFO_LOAD;
		let param = '';
		this.apiService.getCall(url, param).subscribe(data => {
			if (data && data.household_users) {
				this.additionalInfo = data.household_users.household_users_attributes;
				for (let k = 0; k < this.additionalInfo.length; k++) {
					this.additionalInfo[k].user_attributes.birthday = this.additionalInfo[k].user_attributes.birthday?moment(this.additionalInfo[k].user_attributes.birthday).format('MM/DD/YYYY'):'';
					this.additionalInfo[k].user_attributes.social = this.utilService.formatSsn(this.additionalInfo[k].user_attributes.social);
					if(this.additionalInfo[k].user_attributes.middle_initial && this.additionalInfo[k].user_attributes.middle_initial != "" && this.additionalInfo[k].user_attributes.middle_initial != null){
                        this.additionalInfo[k].user_attributes.displayFullName = this.additionalInfo[k].user_attributes.first_name + " " +this.additionalInfo[k].user_attributes.middle_initial + " " + this.additionalInfo[k].user_attributes.last_name;
                    }else{
                        this.additionalInfo[k].user_attributes.displayFullName = this.additionalInfo[k].user_attributes.first_name + " " + this.additionalInfo[k].user_attributes.last_name;
                    }
					this.newAdditionalInfoArr();
				}
				if(this.editPersonalInfo){
					this.additionalInfo.push({
						user_attributes: {
							address1: '',
							address2: '',
							birthday: '',
							category: '',
							city: '',
							email: '',
							employer_address: '',
							employer_address2: '',
							employer_city: '',
							employer_industry: '',
							employer_name: '',
							employer_occupation: '',
							employer_state: '',
							employer_status: '',
							employer_zipcode: '',
							first_name: '',
							last_name: '',
							phone1: '',
							phone1_type: '',
							social: '',
							social_encrypt: '',
							state: '',
							user_employer_industry_id: '',
							user_employer_industry_name: '',
							user_employer_occupation_id: '',
							user_employer_occupation_name: '',
							zipcode: ''
						}
					});
					this.newAdditionalInfoArr();
				}
			}
		});
	};

	loadDropdownValue() {
		let url = AppRestEndPoint.ADDITIONAL_INFO.DROPDOWN_VALUES;
		let param = "";
		this.apiService.getCall(url, param).subscribe(response => {
			if (response.dropdown_values) {
				this.statusList = response.dropdown_values.employee_statuses;
				this.empOccupationCodesArr = response.dropdown_values.user_employer_occupations;
				this.empIndustryCodesArr = response.dropdown_values.user_employer_industries;

			}

		});
	}

	checkMelissaAddressCode(response){
		let validMelissaAddress;
		if (response.Response.Records.ResponseRecord.Results != '' && response.Response.Records.ResponseRecord.Results != null) {
			var codesArr = response.Response.Records.ResponseRecord.Results.split(',');
			this.addressError = [];
			for (var i = 0; i < codesArr.length; i++) {
				var codeFound = this.melissaCodeList.find(item => item.code && item.code == codesArr[i]);
				if (codeFound && codeFound != '' && codeFound != null) {
					this.addressError.push(codeFound);
				}
				if (codeFound) {
					validMelissaAddress = false;
				}else{
					validMelissaAddress = true;
				}
			}
		}
		return validMelissaAddress;
		
	}

	findTab(tabId) {
		return this.tabset.tabs._results.findIndex(item => item.id == tabId);
	}

	validateAddress(n_nextStep?) {
		let nextStep = n_nextStep;
		if (nextStep) {
			this.addressIndex = this.findTab(this.tabset.activeId);
		}
		let url = AppRestEndPoint.ADDITIONAL_INFO.VALIDATE_ADDRESS;
		this.addressError = "";
		let state = this.stateList.find(i => i.name == this.additionalInfo[this.addressIndex].user_attributes.state);
		let employerState = this.stateList.find(i => i.name == this.additionalInfo[this.addressIndex].user_attributes.employer_state);
		if (!this.employerAddress) {
			var addressReqObj = {
				"line_one": this.additionalInfo[this.addressIndex].user_attributes.address1,
				"line_two": this.additionalInfo[this.addressIndex].user_attributes.address2,
				"city": this.additionalInfo[this.addressIndex].user_attributes.city,
				"state": state && state.code,
				"zip": this.additionalInfo[this.addressIndex].user_attributes.zipcode
			};
			this.providedAddress = {
				"mailing_address1": this.additionalInfo[this.addressIndex].user_attributes.address1,
				"mailing_address2": this.additionalInfo[this.addressIndex].user_attributes.address2,
				"mailing_city": this.additionalInfo[this.addressIndex].user_attributes.city,
				"mailing_state": state && state.code,
				"mailing_zipcode": this.additionalInfo[this.addressIndex].user_attributes.zipcode,
				"address_header": "Contact"
			}
		} else {
			var addressReqObj = {
				"line_one": this.additionalInfo[this.addressIndex].user_attributes.employer_address,
				"line_two": this.additionalInfo[this.addressIndex].user_attributes.employer_address2,
				"city": this.additionalInfo[this.addressIndex].user_attributes.employer_city,
				"state": employerState && employerState.code,
				"zip": this.additionalInfo[this.addressIndex].user_attributes.employer_zipcode
			};
			this.providedAddress = {
				"mailing_address1": this.additionalInfo[this.addressIndex].user_attributes.employer_address,
				"mailing_address2": this.additionalInfo[this.addressIndex].user_attributes.employer_address2,
				"mailing_city": this.additionalInfo[this.addressIndex].user_attributes.employer_city,
				"mailing_state": employerState && employerState.code,
				"mailing_zipcode": this.additionalInfo[this.addressIndex].user_attributes.employer_zipcode,
				"address_header": "Employer"
			}	
		}
		this.apiService.validateAddress(url, addressReqObj).subscribe(response => {
			if (response && response.Response && response.Response.Records && response.Response.Records.ResponseRecord) {
				var validAddress1 = true, validAddress2 = true, validCity = true, validState = true, validZipcode = true;
				if (addressReqObj && addressReqObj['line_one'] != '' && addressReqObj['line_one'] != null) {
					if ((addressReqObj['line_one'].toLowerCase() != response.Response.Records.ResponseRecord.AddressLine1.toLowerCase())) {
						validAddress1 = false;
					}
				}
				if (addressReqObj && addressReqObj['line_two'] != '' && addressReqObj['line_two'] != null) {
					if ((response.Response.Records.ResponseRecord.AddressLine2 == null || response.Response.Records.ResponseRecord.AddressLine2 == '') || (response.Response.Records.ResponseRecord.AddressLine2 != '' && response.Response.Records.ResponseRecord.AddressLine2 != null && (addressReqObj['line_two'].toLowerCase() != response.Response.Records.ResponseRecord.AddressLine2.toLowerCase()))) {
						validAddress2 = false;
					}
				}
				if (addressReqObj && addressReqObj['city'] != '' && addressReqObj['city'] != null) {
					if ((response.Response.Records.ResponseRecord.City == null || response.Response.Records.ResponseRecord.City == '') || (response.Response.Records.ResponseRecord.City != '' && response.Response.Records.ResponseRecord.City != null && (addressReqObj['city'].toLowerCase() != response.Response.Records.ResponseRecord.City.toLowerCase()))) {
						validCity = false;
					}
				}
				if (addressReqObj && addressReqObj['state'] != '' && addressReqObj['state'] != null) {
					if ((response.Response.Records.ResponseRecord.State == null || response.Response.Records.ResponseRecord.State == '') || (response.Response.Records.ResponseRecord.State != '' && response.Response.Records.ResponseRecord.State != null && (addressReqObj['state'].toLowerCase() != response.Response.Records.ResponseRecord.State.toLowerCase()))) {
						validState = false;
					}
				}
				if (addressReqObj && addressReqObj['zip'] != '' && addressReqObj['zip'] != null) {
					if ((response.Response.Records.ResponseRecord.PostalCode == null || response.Response.Records.ResponseRecord.PostalCode == '') || (response.Response.Records.ResponseRecord.PostalCode != '' && response.Response.Records.ResponseRecord.PostalCode != null && (addressReqObj['zip'].toLowerCase() != response.Response.Records.ResponseRecord.PostalCode.toLowerCase()))) {
						validZipcode = false;
					}
				}
				var validMelissaAddress = this.checkMelissaAddressCode(response);
                        if (validAddress1 && validAddress2 && validCity && validState && validZipcode && validMelissaAddress) {
							if (!this.employerAddress) {
								this.employerAddress = true;
								this.validateAddress(nextStep);
							} else {
								this.employerAddress = false;
								if (nextStep) {
									this.submitInformation();
								}
							}                            
                        } else {
                            this.validAddressData = {
                                mailing_address1: response.Response.Records.ResponseRecord.AddressLine1,
                                mailing_address2: response.Response.Records.ResponseRecord.AddressLine2,
                                mailing_city: response.Response.Records.ResponseRecord.City,
                                mailing_state: response.Response.Records.ResponseRecord.State,
                                mailing_zipcode: response.Response.Records.ResponseRecord.PostalCode
                            }
                            this.openShowUspsAddressModal(nextStep);
                        }
                    }
				});
	}

	openShowUspsAddressModal(p_nextStep?) {
		let nextStep = p_nextStep;
		const addressModalRef = this.modalService.open(ShowUspsAddressModalComponent,
			{
				windowClass: 'modal-width-850',
				backdrop: 'static'
			}
		)
		addressModalRef.componentInstance.providedAddress = this.providedAddress;
		addressModalRef.componentInstance.validAddressData = this.validAddressData;
		addressModalRef.componentInstance.addressError = this.addressError;
		addressModalRef.componentInstance.employerProvidedAddress = this.employerProvidedAddress;
		addressModalRef.componentInstance.employerAddress = this.employerAddress;
		addressModalRef.componentInstance.addressIndex = this.addressIndex;
		addressModalRef.result.then((data) => {
			if (data && data.val && data.val == 'confirm' && data.addressValue == '2' && data.employerAddress) {
				let state = this.stateList.find(i => i.code == this.validAddressData.mailing_state);
				this.additionalInfo[data.addressIndex].user_attributes.address1 = this.validAddressData.mailing_address1;
				this.additionalInfo[data.addressIndex].user_attributes.city = this.validAddressData.mailing_city;
				this.additionalInfo[data.addressIndex].user_attributes.state = state.name;
				this.additionalInfo[data.addressIndex].user_attributes.zipcode = this.validAddressData.mailing_zipcode;
				this.employerAddress = data.employerAddress;
				if (this.employerAddress) {
					this.addressIndex = data.addressIndex;
					this.validateAddress(nextStep);
				}
			}
			if (data && data.val && data.val == 'confirm' && data.addressValue == '2' && !data.employerAddress) {
				let employerState = this.stateList.find(i => i.code == this.validAddressData.mailing_state);
				this.additionalInfo[data.addressIndex].user_attributes.employer_address = this.validAddressData.mailing_address1;
				this.additionalInfo[data.addressIndex].user_attributes.employer_city = this.validAddressData.mailing_city;
				this.additionalInfo[data.addressIndex].user_attributes.employer_state = employerState.name;
				this.additionalInfo[data.addressIndex].user_attributes.employer_zipcode = this.validAddressData.mailing_zipcode;
				if (nextStep) {
					this.submitInformation();
				}
			}
		})
	}

	submitInformation() {
		this.informationFormSubmitted = true;
		let len = this.additionalInfo.length;
		for (let k = 0; k < len; k++) {
			if (isDefined(this.additionalInfo[k].user_attributes.social) && this.additionalInfo[k].user_attributes.social != null && this.additionalInfo[k].user_attributes.social != '') {
				this.additionalInfo[k].user_attributes.social = this.utilService.removeSsnFormat(this.additionalInfo[k].user_attributes.social);
			} else {
				this.additionalInfo[k].user_attributes.social = '';
			}
			if (isDefined(this.additionalInfo[k].user_attributes.primary_phone) && this.additionalInfo[k].user_attributes.primary_phone != null && this.additionalInfo[k].user_attributes.primary_phone != '') {
				if(this.additionalInfo[k].user_attributes.id){
					if(this.additionalInfo[k].user_attributes.phone_type == 1){
						this.additionalInfo[k].user_attributes.phone1 = this.additionalInfo[k].user_attributes.primary_phone;
						this.additionalInfo[k].user_attributes.phone1_type = this.additionalInfo[k].user_attributes.phone_type;
					}else if(this.additionalInfo[k].user_attributes.phone_type == 2){
						this.additionalInfo[k].user_attributes.phone2 = this.additionalInfo[k].user_attributes.primary_phone;
						this.additionalInfo[k].user_attributes.phone2_type = this.additionalInfo[k].user_attributes.phone_type;
					}else if(this.additionalInfo[k].user_attributes.phone_type == 3){
						this.additionalInfo[k].user_attributes.phone3 = this.additionalInfo[k].user_attributes.primary_phone;
						this.additionalInfo[k].user_attributes.phone3_type = this.additionalInfo[k].user_attributes.phone_type;
					}else if(this.additionalInfo[k].user_attributes.phone_type == 4){
						this.additionalInfo[k].user_attributes.phone4 = this.additionalInfo[k].user_attributes.primary_phone;
						this.additionalInfo[k].user_attributes.phone4_type = this.additionalInfo[k].user_attributes.phone_type;
					}else{
						this.additionalInfo[k].user_attributes.phone3 = this.additionalInfo[k].user_attributes.primary_phone;
						this.additionalInfo[k].user_attributes.phone3_type = 3;
						this.additionalInfo[k].user_attributes.primary_phone_type = "Mobile";
					}
				}else{
					this.additionalInfo[k].user_attributes.phone3 = this.additionalInfo[k].user_attributes.primary_phone;
					this.additionalInfo[k].user_attributes.phone3_type = 3;
					this.additionalInfo[k].user_attributes.primary_phone_type = "Mobile";
				}
			}
		}


		let reqObjUpdated = [];
		if (this.additionalInfoForm.valid) {
			for (let i = 0; i < len; i++) {
				if (isUndefined(this.additionalInfo[i].id)) {
					this.additionalInfo[i].household_user_type = "Dependent";
				}
				if (this.additionalInfo[i].user_attributes.first_name && this.additionalInfo[i].user_attributes.last_name) {
					reqObjUpdated.push(this.additionalInfo[i]);
				}

			}
			if (reqObjUpdated.length > 0) {
				let reqObj = { "household_users": { "household_users_attributes": reqObjUpdated } };

				this.apiService.postCall(AppRestEndPoint.ADDITIONAL_INFO.SAVE_ADDITIONAL_INFO, reqObj).subscribe(data => {
					if (sessionStorage.getItem('docusign')) {
						sessionStorage.removeItem('docusign');
						this.router.navigate(['dashboard']);
					} else {
						this.gotoNextState();
					}
				});

			} else {
				if (sessionStorage.getItem('docusign')) {
					sessionStorage.removeItem('docusign');
					this.router.navigate(['dashboard']);
				} else {
					this.gotoNextState();
				}
			}
		}
	};
	newAdditionalInfoArr() {
		let control;
		control = <FormArray>this.additionalInfoForm.controls['additionalInfoArr'];
		control.push(this.addAddtionalInfo());
	}

	addAddtionalInfo(): FormGroup {
		return this.fb.group({
			'address1': [null],
			'address2': [null],
			'birthday': [null],
			'category': [null],
			'city': [null],
			'email': [null],
			'employer_address': [null],
			'employer_address2': [null],
			'employer_city': [null],
			'employer_industry': [null],
			'employer_name': [null],
			'employer_occupation': [null],
			'employer_state': [null],
			'employer_status': [null],
			'employer_zipcode': [null],
			'first_name': [null],
			'last_name': [null],
			'phone1': [null, Validator.validatePhoneLength],
			'phone1_type': [null],
			'social': [null, Validators.pattern(this.ssnRegEx)],
			'social_encrypt': [null],
			'state': [null],
			'user_employer_industry_id': [null],
			'user_employer_industry_name': [null],
			'user_employer_occupation_id': [null],
			'user_employer_occupation_name': [null],
			'zipcode': [null]
		});
	}

	onTabChange(event) {
		this.addressIndex = this.findTab(event.activeId);
		this.employerAddress = false;
		this.validateAddress();
	}

	clickedInfo(index) {
		this.additionalInfoFormMessageArr.controls[index].get('first_name').setValidators(Validators.required);
		this.additionalInfoFormMessageArr.controls[index].get('last_name').setValidators(Validators.required);
		this.additionalInfoFormMessageArr.controls[index].get('birthday').setValidators(Validators.required);
	}

	gotoPreviousState() {

		let reqObj = { "info_type": this.selectedType, "state": "additional-information" };
		this.navigationService.getPreviousPage(reqObj);
	}

	gotoNextState() {
		let reqObj = { "info_type": this.selectedType, "state": "additional-information" };
		this.navigationService.getNextPage(reqObj);
	}

	openContactUsModal(value) {
		const contactUsModalRef = this.modalService.open(ContactUsModalComponent,
			{
				windowClass: 'modal-width-850',
				backdrop: 'static'
			}
		);
		contactUsModalRef.componentInstance.selectedType = value;
		contactUsModalRef.componentInstance.advisorData = this.advisorData;
		contactUsModalRef.componentInstance.advisoryTeam = this.advisoryTeam;

	}
}
