import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
export class Validator {

    static passwordValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
        const password = control.get('password');
        const confirmPassword = control.get('confirmPassword');

        if (password && confirmPassword && password.value !== confirmPassword.value) {
            return { 'passwordMismatch': true };
        }
        if (!password || !confirmPassword) {
            return null;
        }
        return null;

    };

    static validatePhone(AC: AbstractControl) {
        const phnoRegEx = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/;
        if (AC && AC.value && phnoRegEx.test(AC.value)) {
            return null;
        }
        return { 'invalidPhone': true };
    };

    static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            if (!control.value) {
                // if control is empty return no error
                return error;
            }

            // test the value of the control against the regexp supplied
            const valid = regex.test(control.value);

            // if true, return no error (no error), else return error passed in the second parameter
            return valid ? null : error;
        };
    }

    static validatePhoneLength(control: AbstractControl): { [key: string]: any } {
        if (control && control.value) {
            let val = control.value.replace(/[^\d]/g, "");
            if(val && val.length < 10){
                return { 'invalidPhoneLength': true };
            }
        }
        return null;
    }

}


