import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { ModalConfig } from 'src/app/components/modal/modal.config';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoginService } from 'src/app/services/login.service';
import { CommonService } from 'src/app/services/common.service';
import { ToastService } from 'src/app/components/toaster/toast-service';
import { AppRestEndPoint } from 'src/app/app-restEndPoint';
import { EventEmitterService } from 'src/app/services/event-emitter.service';

@Component({
	selector: 'app-get-started',
	templateUrl: './get-started.component.html',
	styleUrls: ['./get-started.component.scss']
})
export class GetStartedComponent implements OnInit {

	buttonLabel: any = 'Contact ';
	firm: any = {};
	contact: any = {};
	access_token: any;
	submitContactForm: FormGroup;
	contactFormSubmitted: any;
	flag: any = false;
	@ViewChild('modal') public modal: ModalComponent

	constructor(
		private router: Router,
		private formBuilder: FormBuilder,
		private loginService: LoginService,
		private commonService: CommonService,
		private toastService: ToastService,
		private eventEmitter: EventEmitterService
	) {
		this.submitContactForm = this.formBuilder.group({
			contactName: ['', Validators.required],
			contactPhone: ['', [Validators.required]],
			contactEmail: ['', [Validators.required, Validators.email]]
		});
	}

	ngOnInit(): void {
		// you may disable getting started entirely with a config setting
		if (this.commonService.getDomainDetails().firm_name) {
			this.buttonLabel = this.buttonLabel + this.commonService.getDomainDetails().firm_name;
			this.firm = this.commonService.getDomainDetails().firm;
			if(this.commonService.getDomainDetails().disable_getting_started){
				if(this.commonService.getDomainDetails().firm_code){
					this.router.navigate(['/login/firm/'+this.commonService.getDomainDetails().firm_code]);
				}else{
					this.router.navigate(['/login']);
				}
			}
		}else{
			this.eventEmitter.subscribe("Domain", (domainObj) => { 
				if (domainObj){
					this.buttonLabel = this.buttonLabel + domainObj.firm_name;
					this.firm = domainObj.firm;
					if(domainObj.disable_getting_started){
						if(domainObj.firm_code){
							this.router.navigate(['/login/firm/'+domainObj.firm_code]);
						}else{
							this.router.navigate(['/login']);
						}
					}
				}
			});
		}
	}


	public modalConfig: ModalConfig = {
		modalTitle: "Please leave your details for us to contact you.",
		onSubmit: () => {
			this.submitContactDetails();
			return true;
		},
		dismissButtonLabel: "Cancel",
		closeButtonLabel: "Submit",

	}


	async openContactModal() {
		return await this.modal.open();
	}

	gotoSignup() {
		this.access_token ? this.router.navigate(['financial-info/basic-info']) : this.router.navigate(['financial-info/signup'])
	}

	submitContactDetails() {
		this.contactFormSubmitted = true;
		if (this.submitContactForm.valid) {
			let reqObj = {
				"contact_us": {
					"full_name": this.contact.name,
					"phone_no": this.contact.phone,
					"email": this.contact.email,
					"firm_id": this.commonService.getDomainDetails().firm_id
				}
			};

			let url = AppRestEndPoint.LOGIN.SEND_CONTACT_FORM;
			this.loginService.postCall(url, reqObj).subscribe(data => {
				if (data) {
					this.contact = {};
					this.toastService.show(data.message, {
						classname: 'bg-success text-light', delay: 3000
					});
					this.modal.close();
				}
			});
		}

	}

	goToLogin() {
		this.router.navigate(['login']);
	}


	get formControl() {
		return this.submitContactForm.controls;
	}
}
