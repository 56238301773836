<div class="container pt-4">
    <div class="ques-block-row">
        <div class="ques-block-inr m-auto ques-block-inr-width">
            <img src="../../../assets/images/avatar.png" class="avatar-hexa">
            <div class="text-center font-16 mb-1">{{advisor.fullname}}</div>
            <a class="text-break color-999 font-weight-300">{{advisor.email}}</a>
        </div>
    </div>
    <div class="color-999 text-left width-80 mt-5 mb-5 sub-txt-big">
        <div class="font-30">Sign-Up Complete!</div>
        <br>
        <p class="font-16">Thank you for completing our on-boarding survey. Please press the Goto Dashboard button below to view your
            dashboard.</p>
    </div>
    <div class="text-center mb-5">
        <a class="btn btn-primary btn-lg rounded-pill font-weight-light font-18" (click)="gotoDashboard()">Go to Dashboard </a>
    </div>
</div>