import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as $ from 'jquery';
import * as moment from 'moment';
import { EventEmitterService } from 'src/app/services/event-emitter.service';

@Component({
    selector: 'app-tree-view',
    template: `
    <ul *ngIf="items && items.length > 0" class="pl-3">
      <li id="{{item.id}}" class="list-style-none mr-2" *ngFor="let item of items" [hidden]="item.hideFlag || item.is_not_applicable">
      <input type='checkbox' class="size-20" color="primary" [(ngModel)]="item.is_completed" (ngModelChange)="enableDisableNext(item)" [disabled]="item.disableFlag || disableTaskFlag" />
      <label class="font-14 vertical-align-text-top pl-3" [ngClass]="{'custom-disabled':item.disabled}">{{item.name}}</label>
      <app-tree-view *ngIf="item[key]?.length" [key]="key" [data]="item[key]" [disableTaskFlag]="disableTaskFlag" [originalWorkflowList]="originalWorkflowList" [savedWorkflowStepList]="savedWorkflowStepList" [workFlowReqObj]="workFlowReqObj" [stepCount]="stepCount"></app-tree-view>
      </li>
    </ul>
  `,
    styleUrls: ['./tree-view.component.scss']
})
export class TreeViewComponent implements OnInit {
    @Input('data') items: any;
    @Input('disableTaskFlag') disableTaskFlag: any;
    @Input('key') key: string;
    @Input('originalWorkflowList') originalWorkflowList: any;
    @Input('savedWorkflowStepList') savedWorkflowStepList: any;
    @Input('workFlowReqObj') workFlowReqObj: any;
    @Input('stepCount') stepCount: any;
    @Output() updatedStepCount = new EventEmitter<any>();


    siblingObj = [];
    constructor(private eventEmitter: EventEmitterService) {

     }

    ngOnInit() {
    }

    getSiblingObjects(data, item) {
        for (let i = 0; i < data.length; i++) {
            if (data[i].parent_id == item.parent_id) {
                this.siblingObj.push(data[i]);
            }
            if (data[i].company_workflow_steps.length > 0) {
                this.getSiblingObjects(data[i].company_workflow_steps, item);
            }
        }
    }

    enableDisableNext(item) {
        if(!this.workFlowReqObj){
            this.workFlowReqObj = [];
        }
        const len = item.company_workflow_steps.length;
        if (item.is_completed == true) {
            item.completed_date = moment().format('YYYY-MM-DD');
            if (this.workFlowReqObj.length > 0) {
                const index1 = this.getIndex(item.id);
                if (index1 == -1) {
                    this.workFlowReqObj.push({ 'company_workflow_step_id': item.id, 'status': 'Completed', 'completed_date': item.completed_date });
                } else {
                    this.workFlowReqObj[index1].status = 'Completed';
                    this.workFlowReqObj[index1].completed_date = item.completed_date;
                }
            } else {
                this.workFlowReqObj.push({ 'company_workflow_step_id': item.id, 'status': 'Completed', 'completed_date': item.completed_date });
            }
            if (item.company_workflow_steps.length == 0) {
                this.stepCount = this.stepCount - 1;
            } else if (item.company_workflow_steps.length > 1) {
                this.stepCount = this.stepCount + (item.company_workflow_steps.length - 1);
            }
            // make children visible
            if (item.company_workflow_steps.length > 0) {
                for (let i = 0; i < len; i++) {
                    item.company_workflow_steps[i].hideFlag = false;
                }
            }
            // disable the parent
            if (item.parent_id != null) {
                $('#' + item.parent_id).find('input:first').prop('disabled', true);
                $('#' + item.parent_id).find('label:first').addClass('custom-disabled');
            }
            this.eventEmitter.broadcast("updatedStepCount", {'stepCount':this.stepCount});
        } else {
            item.completed_date = '';
            const index2 = this.getIndex(item.id);
            if (index2 > -1) {
                this.workFlowReqObj[index2].status = 'Started';
                this.workFlowReqObj[index2].completed_date = '';
            }
            if (item.company_workflow_steps.length == 0) {
                this.stepCount = this.stepCount + 1;
            } else if (item.company_workflow_steps.length > 1) {
                this.stepCount = this.stepCount - (item.company_workflow_steps.length - 1);
            }
            if (item.company_workflow_steps.length > 0) {
                for (let i = 0; i < len; i++) {
                    item.company_workflow_steps[i].hideFlag = true;
                }
            }
            if (item.parent_id != null) {
                // check all children are unchecked and enable parent
                // loop to get the children ids
                this.siblingObj = [];
                for (let i = 0; i < this.originalWorkflowList.length; i++) {
                    this.getSiblingObjects(this.originalWorkflowList[i].company_workflow_steps, item);
                }
                let checkedFlag = false;
                for (let j = 0; j < this.siblingObj.length; j++) {
                    if (this.siblingObj[j].is_completed) {
                        checkedFlag = true;
                    }
                }
                // enable parent
                if (!checkedFlag) {
                    $('#' + item.parent_id).find('input').prop('disabled', false);
                    $('#' + item.parent_id).find('label').removeClass('custom-disabled');
                }
            }
            this.eventEmitter.broadcast("updatedStepCount", {'stepCount':this.stepCount});
        }
    }

    getIndex(currentId) {
        let index = -1;
        index = this.workFlowReqObj.findIndex(value => value.company_workflow_step_id == currentId);
        return index;
    }

}
