<div class="modal-header">
    <div class="row w-100">
        <div class="col-md-11">           
            <h5 class="modal-title pull-left" *ngIf="statusDetails.status_code_name">{{statusDetails.status_code_name}}({{statusDetails.status_code}})</h5>
            <h5 class="modal-title pull-left" *ngIf="!statusDetails.status_code_name">Bank Status</h5>
        </div>
        <div class="col-md-1 pr-0">
            <button type="button" class="close float-right text-white pr-0" aria-label="Close"
                (click)="cancel()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
</div>


<div class="modal-body">
    <div class="mt-2 pt-2 pb-2" >
        <table class="table">
            <tbody>        
                <tr>
                    <td>{{statusDetails.status_code_description}}</td>           
                </tr>
                <tr>
                    <td>{{statusDetails.status_code_action}}</td>           
                </tr>      
            </tbody>
        </table>
    </div>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-link" (click)="cancel()">
        CANCEL
    </button>
</div>
