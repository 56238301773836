<div class="container px-0 wizard d-none d-sm-block">
    <ul class="nav nav-tabs">
        <li class="nav-item">
            <a class="nav-link cursor-pointer" 
                (click)="redirectToPage(0)">
                <span class="round-tab" [ngClass]="{'tabActive':currentIndex == 0, 'tabDone':currentIndex > 0}">
                    <i class="fa fa-user-o my-auto"></i>
                </span>
                <span class="arrow"></span>
                <span class="wiz-txt">DocuSign FAA & IPS</span>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link cursor-pointer">
                <span class="round-tab" [ngClass]="{'tabActive':currentIndex ==1, 'tabDone':currentIndex >1}"
                    (click)="redirectToPage(1)">
                    <i class="fa fa-file-text-o"></i>
                </span>
                <span class="arrow"></span>
                <span class="wiz-txt">Upload Documents</span>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link cursor-pointer">
                <span class="round-tab" [ngClass]="{'tabActive':currentIndex ==2, 'tabDone':currentIndex >2}"
                    (click)="redirectToPage(2)">
                    <i class="fa fa-clock-o"></i>
                </span>
                <span class="arrow"></span>
                <span class="wiz-txt">Additional Information</span>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link cursor-pointer">
                <span class="round-tab" [ngClass]="{'tabActive':currentIndex ==3, 'tabDone':currentIndex >3}"
                    (click)="redirectToPage(3)">
                    <i class="fa fa-briefcase"></i>
                </span>
                <span class="arrow"></span>
                <span class="wiz-txt">Assets & Liabilities</span>
            </a>
        </li>
        <li class="nav-item" >
            <a class="nav-link cursor-pointer">
                <span class="round-tab" [ngClass]="{'tabActive':currentIndex ==4, 'tabDone':currentIndex >4}"
                    (click)="redirectToPage(4)">
                    <i class="fa fa-money"></i>
                </span>
                <span class="arrow"></span>
                <span class="wiz-txt">Cash Flow</span>
            </a>
        </li>
    </ul>
</div>