import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PubSubscribeService {

  constructor() { }

  private subjectObj = new Subject<any>();

	publishData(data: any) {
		this.subjectObj.next(data);
	}

	getObservable(): Subject<any> {
		return this.subjectObj;
	}


}
