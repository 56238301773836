import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoginFormComponent } from './login-form/login-form.component';
import { LoginRoutingModule } from './login-routing.module';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { BrowserModule } from '@angular/platform-browser';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SharedModule } from '../shared/shared.module';
import { EnableMfasplashComponent } from './enable-mfasplash/enable-mfasplash.component';
import { EnableAuthyComponent } from './enable-authy/enable-authy.component';
import { EnterTokenComponent } from './enter-token/enter-token.component';
import { LogonComponent } from './logon/logon.component';
import { EnableAuthyMfaComponent } from './enable-authy-mfa/enable-authy-mfa.component';
import { QRCodeModule } from 'angularx-qrcode';
import { ClipboardModule } from 'ngx-clipboard';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        LoginRoutingModule,
        SharedModule,
        QRCodeModule,
        ClipboardModule

    ],
    declarations: [
        LoginFormComponent, 
        ForgotPasswordComponent, 
        ResetPasswordComponent, 
        EnableMfasplashComponent, 
        EnableAuthyComponent, 
        EnterTokenComponent, 
        LogonComponent, 
        EnableAuthyMfaComponent
    ],
})
export class LoginModule {
}
