import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { NgbTabsetModule, NgbTooltipModule, NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { DocuSignRoutingModule } from './docu-sign-routing.module';
import { DocusignFaaComponent } from './docusign-faa/docusign-faa.component';
import { UploadStatementComponent } from './upload-statement/upload-statement.component';
import { UploadStatementModalComponent } from './upload-statement-modal/upload-statement-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ShowUspsAddressModalComponent } from './show-usps-address-modal/show-usps-address-modal.component'


@NgModule({
	declarations: [DocusignFaaComponent, UploadStatementComponent, UploadStatementModalComponent, ShowUspsAddressModalComponent],
	imports: [
		CommonModule,
		SharedModule,
		FormsModule,
		ReactiveFormsModule,
		NgbModule,
		NgbTabsetModule,
		DocuSignRoutingModule,


	]
})
export class DocuSignModule { }
