import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppRestEndPoint } from 'src/app/app-restEndPoint';
import { ApiService } from 'src/app/services/api.service';
import { CommonService } from 'src/app/services/common.service';
import { UtilityService } from 'src/app/services/utility.service';
import { ToastService } from '../toaster/toast-service';
import { LoginService } from 'src/app/services/login.service';
import { EventEmitterService } from 'src/app/services/event-emitter.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
	selectedPage: any = {};
	currentIndex: any;
	showNewsIcon:any;
	documentCount;
	taskPendingCount;
	
	constructor(private router: Router,
		private apiService: ApiService,
		private utilService: UtilityService,
		private loginService: LoginService,
		private toastService: ToastService,
		public eventEmitter: EventEmitterService,
		private commonService: CommonService) { }

	ngOnInit(): void {
		this.selectedPage.title = this.router.url;
		this.showNewsIcon = JSON.parse(sessionStorage.getItem('knowledge_base_url')) === true ? true : false;
		let documentUnreadCount = JSON.parse(sessionStorage.getItem('user_document_notification_count'));
		if(documentUnreadCount){
			this.documentCount = documentUnreadCount;
		}
		this.eventEmitter.subscribe("updateDocumentCount", (data) => { 
			if (data){
				this.documentCount = data;
			}
		});
		let taskCount = JSON.parse(sessionStorage.getItem('unread_task_count'));
		if(taskCount){
			this.taskPendingCount = taskCount;
		}
		this.eventEmitter.subscribe("unreadTaskCount", (data) => { 
			if (data){
				this.taskPendingCount = data;
			}
		});
		
	}

	setCurrentNav(currentNav) {
		switch (currentNav) {
			case 'Dashboard':
				this.router.navigateByUrl('/dashboard');
				break;
			case 'Tasks':
				this.router.navigateByUrl('/tasks');
				break;
			case 'Profile':
				this.router.navigateByUrl('/profile');
				break;
			case 'Documents':
				this.router.navigateByUrl('/documents');
				break;
			default:
				break;
		}
	}

	openPortfolio() {
		this.utilService.gtmPush({
            'event' : 'Portfolio'
        });
		let url = AppRestEndPoint.LOGIN.PORTFOLIO + "?access_token=" + this.apiService.getToken();
		window.open(url);
	}

	openKnowledgeBase() {
		let url = AppRestEndPoint.LOGIN.KNOWLEDGE_BASE + "?access_token=" + this.apiService.getToken();
		window.open(url);
	}

	logOut() {
		this.commonService.logOut();
	}

}
