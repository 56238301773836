import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { OperatorFunction, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, tap, switchMap } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { AppRestEndPoint } from 'src/app/app-restEndPoint';
import { isDefined } from '@angular/compiler/src/util';
import { isUndefined } from 'util';
import { UtilityService } from 'src/app/services/utility.service';
import { ActivatedRoute } from '@angular/router';
import { NavigationService } from 'src/app/services/navigation.service';
import * as moment from 'moment';

@Component({
	selector: 'app-investment-info',
	templateUrl: './investment-info.component.html',
	styleUrls: ['./investment-info.component.scss']
})
export class InvestmentInfoComponent implements OnInit {

	objModal: any = {};
	selectedType: any;
	currentUser: any;
	advisorBtnLabel: any;
	disableAdvisor: Boolean = false;
	investmentInfo: any = {};
	investmentForm: FormGroup;
	scenarioData: any;
	retirementYearList: any;
	personalInfo: any = {};
	household_users: any;
	isMarried: any;
	investmentFormSubmitted: any;
	searching: any;
	household: any = {};
	advisorList: any;
	pickOneForMeObj: any;
	showAdvisorSentence: Boolean = false;
	investmentPlaceholder: any = "";

	constructor(
		private formBuilder: FormBuilder, 
		private apiService: ApiService, 
		public utilityService: UtilityService,
		private activatedRoute: ActivatedRoute,
		private navigationService: NavigationService
		) {
		this.investmentForm = this.formBuilder.group({
			'investment': [null, Validators.required],
			'retirementAge': [null, Validators.required],
			'advisor': [null],

		});
	}

	ngOnInit(): void {
		this.objModal.showAdvisorText = "Type an Advisor's Name";
		this.advisorBtnLabel = "Pick one for me";
		this.selectedType = this.activatedRoute.snapshot.paramMap.get('type');
		this.currentUser = JSON.parse(sessionStorage.getItem('current_user'));
		this.getFamilyInfo();
	}

	getRetirementList() {
		let url = AppRestEndPoint.BASIC_INFO.RETIREMENT_YEAR;
		this.apiService.getCall(url, '').subscribe(data => {
			let setRetirementAge;
			if (data) {
				this.retirementYearList = data;
				let self = this;
				if (isDefined(this.scenarioData) && isDefined(this.scenarioData.retirement_age)) {
					setRetirementAge = _.find(this.retirementYearList, function (item) {
						return item.age === self.scenarioData.retirement_age;
					});
					if (isUndefined(setRetirementAge)) {
						setRetirementAge = data[0] && data[0].year ? data[0].year : "";
						this.investmentInfo.retirementAge = setRetirementAge;
					} else {
						this.investmentInfo.retirementAge = setRetirementAge.year;
					}
				} else {
					setRetirementAge = data[0] && data[0].year ? data[0].year : "";
					this.investmentInfo.retirementAge = this.personalInfo.retirement_status ? this.personalInfo.retirement_status : setRetirementAge;
				}
			}
		});

	}

	updateAdvisorbtn() {
		//$scope.popUpShown = true;
		this.sendMixPanelEvent({'event': 'Client Onboarding - Basic Information - '+this.advisorBtnLabel});
		if (this.advisorBtnLabel == "Pick one for me") {
			this.advisorBtnLabel = "Let me pick one";
			this.disableAdvisor = true;
			this.investmentForm.controls['advisor'].disable();
			this.investmentInfo.advisor = this.pickOneForMeObj;
		} else if (this.advisorBtnLabel == "Let me pick one") {
			this.advisorBtnLabel = "Pick one for me";
			this.investmentForm.controls['advisor'].enable();
			this.investmentInfo.advisor = "";
		}
	};


	searchAdvisor = (text$: Observable<string>) =>
		text$.pipe(
			debounceTime(300),
			distinctUntilChanged(),
			tap(() => this.searching = true),
			map(term => term.length < 2 ? []
				: this.advisorList.filter(v => v.fullname.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
		)

	formatAdvisor = (result: any) => result.fullname;

	get formControl() {
		return this.investmentForm.controls;
	}

	getFamilyInfo() {
		let url = AppRestEndPoint.BASIC_INFO.FAMILY_INFO, param = '';
		this.apiService.getCall(url, param).subscribe(data => {
			if (data) {
				let familyData = data;
				this.scenarioData = data.scenario;
				this.household_users = familyData.household_users;
				this.household = this.household_users.household;
				this.isMarried = familyData.household_users.married;
				this.personalInfo = familyData.household_users.household.primary_user_attributes.user_attributes;
				this.personalInfo.birthday = familyData.household_users.household.primary_user_attributes.user_attributes.birthday ? moment(familyData.household_users.household.primary_user_attributes.user_attributes.birthday).format('MM/DD/YYYY') : "";
				if (familyData.household.cached_managed_value && parseFloat(familyData.household.cached_managed_value) != 0) {
					this.investmentInfo = {
						investment: this.utilityService.getFormattedValue(familyData.household.cached_managed_value,'currency')
					};
				} else {
					this.investmentInfo = {
						investment: this.utilityService.getFormattedValue(this.household.investment_amount,'currency')
					};
				}
				this.investmentPlaceholder = "$30000";
				sessionStorage.setItem('login_rtq_id', data.rtq_id);
				this.getAdvisorList();
				this.getRetirementList();
			}
		});
	}

	submitInvestment() {
		this.investmentFormSubmitted = true;
		if(this.showAdvisorSentence){
			if((this.investmentInfo.advisor === undefined) || (this.investmentInfo.advisor.id === undefined && this.disableAdvisor !=true)){
				this.investmentForm.controls['advisor'].setErrors({'required': true});
			}else{
				this.investmentForm.controls['advisor'].clearValidators();
				this.investmentForm.controls.advisor.setErrors(null);
			}
		}else{
			this.investmentForm.controls['advisor'].clearValidators();
			this.investmentForm.controls.advisor.setErrors(null);
		}
		let formattedInvestment = this.investmentInfo.investment?parseFloat(this.utilityService.formatCurrency(this.investmentInfo.investment)):'';

		if (formattedInvestment == 0 || formattedInvestment == '') {
			this.investmentForm.controls['investment'].setErrors({'invalidinvestment': true});
			return;
		}else{
			this.investmentForm.controls['investment'].clearValidators();
			this.investmentForm.controls.investment.setErrors(null);
		}
		this.investmentForm.controls.investment.updateValueAndValidity();

		if (this.investmentForm.valid) {
			let self = this;
			let ageObj: any = _.find(this.retirementYearList, function (item) {
				return item.year == self.investmentInfo.retirementAge;
			});

			let requestData: any = {
				"married": this.household_users.married,
				"household": {
					"investment_amount": this.investmentInfo.investment,
					"primary_user_attributes": {
						"user_attributes": {
							"fullname": this.personalInfo.fullname,
							"retirement_status": this.investmentInfo.retirementAge,
							"retirement_age": ageObj.age,
							"birthday": moment(this.personalInfo.birthday).format('MM/DD/YYYY'),
							"id": this.personalInfo.id

						},
						"id": this.household.primary_user_attributes.id,
					},
					"advisor_user_attributes": {

					}
				},
				"dependents": this.household_users.dependents,
				"id": this.household_users.id
			};
			if (this.investmentInfo.advisor.id) {
				requestData.household.primary_user_attributes.user_attributes.advisor_admin_user_id = this.investmentInfo.advisor.id;
				requestData.household.advisor_user_attributes.admin_user_id = this.investmentInfo.advisor.id
			}
			let spouse_attributes = {};
			if (this.household.spouse_user_attributes && this.household.spouse_user_attributes.user_attributes) {
				let spouse_attributes: any = {
					"user_attributes": {
						"fullname": this.household.spouse_user_attributes.user_attributes.fullname,
						"birthday": this.household.spouse_user_attributes.user_attributes.birthday,
						"email": this.household.spouse_user_attributes.user_attributes.email,
						"id": this.household.spouse_user_attributes.user_attributes.id

					},
					"id": this.household.spouse_user_attributes.id
				};
				if (this.investmentInfo.advisor.id) {
					spouse_attributes.user_attributes.advisor_admin_user_id = this.investmentInfo.advisor.id;
				}
				requestData.household.spouse_user_attributes = spouse_attributes;
			}


			let children_attributes = {};
			if (this.household.children_attributes && this.household.children_attributes[0]) {
				for (let x in this.household.children_attributes) {
					children_attributes["child_" + x] = {
						"fullname": this.household.children_attributes[x].fullname,
						"birthday": this.household.children_attributes[x].birthday,
						"_destroy": this.household.children_attributes[x]._destroy,
						"id": this.household.children_attributes[x].id
					};
					if (this.investmentInfo.advisor.id) {
						children_attributes["child_" + x].advisor_admin_user_id = this.investmentInfo.advisor.id;
					}
				}

				requestData.household.children_attributes = children_attributes;
			}

			let url = AppRestEndPoint.BASIC_INFO.SAVE_FAMILY_INFO
			this.apiService.postCall(url, requestData).subscribe(data => {
				this.saveState();
				this.gotoNextState();
			});

		}
	}
	getAdvisorList() {
		this.apiService.getCall(AppRestEndPoint.BASIC_INFO.ADVISOR_LIST, '').subscribe(data => {
			this.advisorList = [];

			if (data) {
				this.advisorList = data;
			}

			this.pickOneForMeObj = _.find(this.advisorList, function (item) {
				return item.fullname === "Pick one for me";
			});

			if (this.advisorList && this.advisorList.length > 0) {
				if((this.advisorList.length === 1 && this.advisorList[0].is_default_advisor === true) 
				|| (this.advisorList.length === 2 && this.advisorList[0].is_default_advisor === true && this.advisorList[1].is_default_advisor === false)){
					this.showAdvisorSentence = false;
				}else{
					this.showAdvisorSentence = true;
				}
				// if (this.advisorList.length > 1) {
				//  	this.showAdvisorSentence = true;
				// }else if(this.advisorList.length == 1){
				// 	this.investmentInfo.advisor = this.pickOneForMeObj;
				// }
				if(this.advisorList.length == 1){
					this.investmentInfo.advisor = this.pickOneForMeObj;
				}

				if (this.investmentInfo === undefined) {
					this.investmentInfo = {};
				}

				if (isDefined(sessionStorage.getItem('advisor_admin_user_id')) && sessionStorage.getItem('advisor_admin_user_id') != null) {

					let value = _.filter(this.advisorList, function (item) {
						return item.id == sessionStorage.getItem('advisor_admin_user_id');
					});

					if (value && value.length > 0) {
						this.investmentInfo.advisor = {
							id: value[0].id,
							fullname: value[0].fullname
						};
					}
				} else if (this.household.advisor_user_attributes && this.household.advisor_user_attributes.admin_user_id) {
					this.investmentInfo.advisor = {
						id: this.household.advisor_user_attributes.admin_user_id,
						fullname: this.household.advisor_user_attributes.admin_user_fullname
					}
				} else {
					if(this.showAdvisorSentence == true){
						let fullname;
						if (this.advisorList[0].fullname === 'Pick one for me' && this.advisorList[0].is_default_advisor === true) {
							this.objModal.showAdvisorText = 'Type an Advisor\'s Name';
							fullname = '';
						}
						else {
							fullname = this.advisorList[0].fullname;
						}
						this.investmentInfo.advisor = {
							id: this.advisorList[0].id,
							fullname: fullname
						}
					}else{
						if(this.advisorList.length === 1 && this.advisorList[0].is_default_advisor === true){
							this.investmentInfo.advisor = {
								id: this.advisorList[0].id,
								fullname: ''
							}
						}else if(this.advisorList.length === 2 && this.advisorList[0].is_default_advisor === true && this.advisorList[1].is_default_advisor === false){
							this.investmentInfo.advisor = {
								id: this.advisorList[1].id,
								fullname: this.advisorList[1].fullname
							}
						}
						
					}
					
					
				}

			}
		});
	}

	gotoPreviousState() {
		this.sendMixPanelEvent({'event': 'Client Onboarding - Investment Information - Go Back'});
		let reqObj = {"info_type" : this.selectedType, "state": "basic-info/investment-info"};
		this.navigationService.getPreviousPage(reqObj);
	}

	gotoNextState(){
		this.sendMixPanelEvent({'event': 'Client Onboarding - Investment Information - Continue'});
		let reqObj = {"info_type" : this.selectedType, "state": "basic-info/investment-info"};
		this.navigationService.getNextPage(reqObj);
	}

	saveState(){
		let reqObj = {
			'user': {"gettingstarted_step":'{"info_type" :"' +this.selectedType+'","state" :"investment"}'}
		};
		let url = AppRestEndPoint.LOGIN.SAVE_STATE+'/'+this.currentUser.id;
		this.apiService.updateCall(url, reqObj, '').subscribe(data => {
		});
	}

	sendMixPanelEvent(eventObj){
        this.utilityService.gtmPush(eventObj);
    }

}
