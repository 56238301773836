<div class="container">
    <div *ngIf="this.currentPage == 'advisor-contact'">
        <div *ngIf="!signupInstitution">
            <div class="ques-block-row pt-3">
                <div class="ques-block-inr mx-auto">
                    <img src="../../../assets/images/avatar.png" class="avatar-hexa">
                    <div class="text-center text-dark">{{advisor.fullname}}</div>
                    <a class="color-999 font-weight-light">{{advisor.email}}</a>
                </div>
            </div>
            <div class="sub-txt-big text-left color-999 font-16 mt-5  ml-5 pb-5">
                <h2>Congratulations!</h2>
                <br>
                <p>We’ve got it from here. Our team will review your information to make sure everything is in good
                    order
                    and then move the process along.</p>
                <br>
                <p>Thank you.</p>
                <br>
                <p>Please close this tab if you are finished with the session.</p>
            </div>
            <p class="small-border mx-auto"></p>
        </div>
        <div *ngIf="signupInstitution">
            <div class="ques-block-row pt-3">
                <div class="ques-block-inr mx-auto">
                    <img src="../../../assets/images/avatar.png" class="avatar-hexa">
                    <div class="text-center text-dark">{{advisor.fullname}}</div>
                    <a class="color-999 font-weight-light">{{advisor.email}}</a>
                </div>
            </div>
            <div class="text-left color-999 font-16 mt-5  ml-5 pb-5">
                <br>
                <p>The institutional IPS for Forum Financial is currently not available online. Your advisor will
                    connect
                    with you to provide this form to you in paper or PDF form.</p>
                <br>
                <p>Thank you.</p>
                <div class="text-center"> <a
                        class="btn btn-primary btn-lg rounded-pill  ml-md-4 ml-lg-4 font-weight-light"
                        (click)="gotoLogin()">Go to Login </a>
                </div>
            </div>
            <p class="small-border mx-auto"></p>
        </div>
    </div>
    <div *ngIf="this.currentPage != 'advisor-contact'">
        <div class="ques-block-row pt-3">
            <div class="ques-block-inr mx-auto">
                <img src="../../../assets/images/avatar.png" class="avatar-hexa">
                <div class="text-center text-dark">{{advisor.fullname}}</div>
                <a class="color-999 font-weight-light">{{advisor.email}}</a>
            </div>
        </div>
        <div class="sub-txt-big text-center color-999 font-16 mt-5  ml-5 pb-5">
            <p>I’ve got it from here! We will review your information to make sure everything is in good order and then
                move the process along.
            </p>
        </div>
        <div class="mb-5 text-center">
            <a class="btn btn-primary btn-lg rounded-pill font-weight-300" (click)="logOut()">All Done</a>
        </div>

        <div class="wizard">
            <div class="wizard-inner">
                <ul class="nav nav-tabs" role="tablist">
                    <li role="signup" class="active">
                        <a data-toggle="tab" aria-controls="step1" role="tab" title="Step 1">
                            <span class="round-tab">
                                <i class="fa fa-user-o"></i>
                            </span>
                            <span class="arrow"></span>
                            <span class="wiz-txt">We have received your information</span>
                        </a>
                    </li>
                    <li role="signup" class="active">
                        <a data-toggle="tab" aria-controls="step2" role="tab" title="Step 2">
                            <span class="round-tab">
                                <i class="fa fa-file-text-o"></i>
                            </span>
                            <span class="arrow"></span>
                            <span class="wiz-txt">You approved your financial plan.</span>
                        </a>
                    </li>
                    <li role="signup" class="underLineText-disabled">
                        <a data-toggle="tab" aria-controls="step3" role="tab" title="Step 3">
                            <span class="round-tab">
                                <i class="fa fa-clock-o"></i>
                            </span>
                            <span class="arrow"></span>
                            <span class="wiz-txt">Your account opening and transfer documents will be prepared for your
                                signature.</span>
                        </a>
                    </li>
                    <li role="signup" class="underLineText-disabled">
                        <a data-toggle="tab" aria-controls="complete" role="tab" title="Complete">
                            <span class="round-tab">
                                <i class="fa fa-briefcase"></i>
                            </span>
                            <span class="arrow"></span>
                            <span class="wiz-txt width-82">After signing, your accounts will be opened and securities,
                                if any, will be transferred, in-kind.</span>
                        </a>
                    </li>
                </ul>
            </div>


        </div>
    </div>
</div>