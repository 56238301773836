import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppRestEndPoint } from 'src/app/app-restEndPoint';
import { ToastService } from 'src/app/components/toaster/toast-service';
import { ApiService } from 'src/app/services/api.service';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-enable-mfa',
  templateUrl: './enable-authy-mfa.component.html',
  styleUrls: ['./enable-authy-mfa.component.scss']
})
export class EnableAuthyMfaComponent implements OnInit {

  qrcode: any;
  secretKey: any;
  userId: any;

  constructor(
    private toastService: ToastService,
    private loginService: LoginService,
    private router: Router,
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
    this.userId = localStorage.getItem('user_id');
    this.getQrcode();
  }

  copyLinkToaster(){
    this.toastService.show('Key copied to clipboard.', {
      classname: 'bg-success text-light', delay: 3000
    });
    let params = `?id=${this.userId}&action_type=copy`;
    const url = AppRestEndPoint.LOGIN.ENABLE_AUTHY + params;
    this.loginService.postCall(url, '').subscribe(data => {});
  }

  enableAuthy() {
    localStorage.setItem('create_authy_record', 'true');
    let params = `?id=${this.userId}`;
    const url = AppRestEndPoint.LOGIN.ENABLE_AUTHY + params;
    this.loginService.postCall(url, '').subscribe(data => {
      if (data.success) {
        this.router.navigate(['enterToken']);
      }
    });
	}

  openAuthyLink() {
		window.open('https://authy.com/download/', '_blank');
	}

  gotoEnableMFA(){
    let params = `?id=${this.userId}&action_type=cancel`;
    const url = AppRestEndPoint.LOGIN.ENABLE_AUTHY + params;
    this.loginService.postCall(url, '').subscribe(data => {
      this.router.navigate(['enableMFA']);
    });
  }

  getQrcode(){
		// call api to get qrcode
		let url = AppRestEndPoint.LOGIN.AUTHY_QR_CODE.replace(':user_id', this.userId);
		let param = '';
		this.apiService.getCall(url, param).subscribe(data => {
			if(data && data.qr_code){
				this.qrcode = data.qr_code;
				this.secretKey = data.secret_key;
        localStorage.setItem('twilio_entity_sid', data.twilio_entity_sid);
        localStorage.setItem('twilio_factor_sid', data.twilio_factor_sid);
			}
		});
	}
}
