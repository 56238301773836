<div class="modal-header pb-0">
    <div class="row w-100">
        <div class="col-md-11">
            <h5 class="modal-title">Task</h5>
            <ul class="nav nav-tabs taskTabs" role="tablist">
                <li class="nav-item pb-1 cursor-pointer" [ngClass]="{'active': taskTabs.showGeneralTab}">
                    <a class="nav-link pl-0" data-toggle="tab" role="tab" (click)="setActiveTab(0)">General</a>
                </li>
                <li class="nav-item pb-1 cursor-pointer" [ngClass]="{'active': taskTabs.showChecklistTab}">
                    <a class="nav-link" data-toggle="tab" role="tab" (click)="setActiveTab(1)">Checklist
                        <div class="display-inline-block task-number-div"
                            *ngIf="workflowChecklistObj.workflowStepCount!=0 && workflowChecklistObj.workflowStepCount>0">
                            {{workflowChecklistObj.workflowStepCount}}
                        </div>
                    </a>
                </li>
                <li class="nav-item pb-1 cursor-pointer" [ngClass]="{'active': taskTabs.showDocumentsTab}">
                    <a class="nav-link" data-toggle="tab" role="tab" (click)="setActiveTab(3)">Documents
                        <div class="display-inline-block task-number-div" *ngIf="uploadDocObj.files.length > 0">
                            {{uploadDocObj.files.length}}
                        </div>
                    </a>
                </li>
            </ul>
        </div>
        <div class="col-md-1 pr-0">
            <button type="button" class="close float-right text-white pr-0" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
</div>
<div class="modal-body">
    <div *ngIf="taskTabs.showGeneralTab">
        <div class="form-group col-md-12 col-sm-12 col-xs-12">
            <div class="col-md-12 col-sm-12 pl-0 pr-0">
                <span class="font-weight-bold font-16">{{taskData.name}}</span>
            </div>
        </div>
        <div class="form-group col-md-12 col-sm-12 col-xs-12">
            <div class="work-flow-height">
                <table class="table cont-table-2 font-14">
                    <thead>
                        <tr class="">
                            <th class="col-md-4 taskTableNoteHeading pl-0">SUBJECT</th>
                            <th class="col-md-1  taskTableNoteHeading text-right pr-0">CREATED</th>
                            <th class="col-md-4  taskTableNoteHeading ">UPDATED BY</th>
                            <th class="col-md-2  taskTableNoteHeading text-right">UPDATED</th>
                            <th class="col-md-1  taskTableNoteHeading text-right"
                                *ngIf="taskData.status != 'Completed'"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let note of taskData.notes;let i= index">
                            <tr class="content-table cursor-pointer" *ngIf="note.status != 'Deleted'">
                                <td class="col-md-4 pl-0" (click)="openTaskNoteModal(note)">
                                    {{note.subject}}
                                </td>
                                <td class="col-md-1 text-right pr-0" (click)="openTaskNoteModal(note)">
                                    {{note.created_at | date:'MM/dd/yyyy'}}</td>
                                <td class="col-md-4" (click)="openTaskNoteModal(note)">
                                    <span *ngIf="note.user.id==null">{{note.updated_by_admin_user.first_name}}
                                        {{note.updated_by_admin_user.last_name}}</span>
                                    <span *ngIf="note.user.id">{{note.user.name}}</span>
                                </td>
                                <td class="col-md-2 text-right" (click)="openTaskNoteModal(note)">
                                    {{note.updated_at | date:'MM/dd/yyyy'}}</td>
                                <td class="col-md-1 pl-0 pr-0" *ngIf="taskData.status != 'Completed'">
                                    <div class="pull-right add-edit-div text-decoration-none" *ngIf="note.user.id">
                                        <span class="color-blue pr-2" data-toggle="tooltip" title="Edit"
                                            (click)="note.user.id?openTaskNoteModal(note):''"><i class="fa fa-pencil"
                                                aria-hidden="true"></i>
                                        </span>
                                        <span class="color-red1" data-toggle="tooltip" title="Delete"
                                            (click)="deleteNote(note, i)"><i class="fa fa-times"
                                                aria-hidden="true"></i></span>
                                    </div>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
            <div>
                <a class="cursor-pointer" (click)="openTaskNoteModal('')" *ngIf="taskData.status != 'Completed'">Add
                    Note</a>
            </div>
        </div>
    </div>
    <div *ngIf="taskTabs.showWorkflowTab">
        <div class="min-ht-300">
            <app-tree-view [data]="workflowChecklistObj.workflowStepList" [key]="workflowChecklistObj.key"
                [originalWorkflowList]="workflowChecklistObj.workflowStepList"
                [savedWorkflowStepList]="workflowChecklistObj.savedWorkflowStepList"
                [workFlowReqObj]="workflowChecklistObj.workFlowReqObj"
                [disableTaskFlag]="taskData.status == 'Completed'"
                [stepCount]="workflowChecklistObj.workflowStepCount">
            </app-tree-view>
        </div>
    </div>
    <div *ngIf="taskTabs.showDocumentsTab">
        <div class="work-flow-height">
            <div class="col-md-12 col-sm-12 col-xs-12 pl-0 pr-0">
                <ng-container *ngFor="let file of uploadDocObj.files;let i= index">
                    <div class="form-group pb-2" *ngIf="!file.destroy">
                        <div class="color-black font-15 pr-3">
                            <span class="text-link" (click)="downloadDoc(file)">{{file.description}}</span>
                            <i class="fa fa-times crossIcon color-red cursor-pointer pl-1" aria-hidden="true"  *ngIf="taskData.status != 'Completed'"
                                (click)="removeFiles(i)"></i>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="col-md-12 col-sm-12 mb-2" *ngIf="taskData.status != 'Completed'">
            <label class="cursor-pointer font-weight-bold color-purple">
                <input type="file" class="d-none" ng-model="uploadFile" id="fileUpload" name="uploadFile"
                    multiple="multiple" (change)="getFileDetails($event.target)" />
                Attach Document <i class="fa fa-paperclip font-16 color-brown cursor-pointer pl-1"></i>
            </label>
        </div>
    </div>
</div>
<div class="modal-footer">
    <span *ngIf="workflowChecklistObj.errorMessage" class="text-danger checklistErrMessage" role="alert">Please Complete
        the Checklist </span>
    <button type="button" class="btn btn-link" (click)="closeModal()" *ngIf="taskData.status != 'Completed'">
        CANCEL
    </button>
    <button type="button" class="btn btn-primary btn-sm rounded-pill" *ngIf="taskData.status != 'Completed'"
        (click)="completeTask(false);taskData.noteModal = false" appButtonDisable>Save for Later</button>

    <button type="button" class="btn btn-primary btn-sm rounded-pill" *ngIf="taskData.status != 'Completed'"
        (click)="completeTask(true);taskData.noteModal = false" appButtonDisable>
        Complete
    </button>

    <button type="button" class="btn btn-primary btn-sm rounded-pill" *ngIf="taskData.status == 'Completed'"
        (click)="closeModal()">
        Close
    </button>
</div>