import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppRestEndPoint } from 'src/app/app-restEndPoint';
import { ApiService } from 'src/app/services/api.service';
declare var Plaid;

@Component({
	selector: 'app-plaid',
	templateUrl: './plaid.component.html',
	styleUrls: ['./plaid.component.scss'],
})
export class PlaidComponent implements OnInit {
	bankId: any;
	receivedRedirectUri: any;
	plaid_link_token: any;
	handler: any;

	constructor(
		private apiService: ApiService,
		public router: Router
	) { }

	ngOnInit() {
		this.receivedRedirectUri = document.location.origin + "/plaidOAuth?"+document.location.href.split('?')[1];
		this.bankId = sessionStorage.getItem('plaid_bank_id');
		if(this.bankId){
			this.plaid_link_token = sessionStorage.getItem('plaid_link_token');
			this.openPlaid();
		}
	}

	// Plaid
    openPlaid(bankId: any = '') {
        let self = this;
		this.handler = Plaid.create({
			token: this.plaid_link_token,
			receivedRedirectUri: this.receivedRedirectUri,
			onSuccess: function (public_token, metadata) {
				let reqObj = {
					"public_token": public_token,
					"meta_data": metadata
				};
				self.apiService.postCall(AppRestEndPoint.PLAID.UPSERT_BANK, reqObj).subscribe(response => {
					self.router.navigate(['dashboard']);
				});
			},

			onExit: function (err, metadata) {
				let reqObj = {
					"error": err,
					"meta_data": metadata
				};
				if (bankId) {
					self.apiService.postCallQuery(AppRestEndPoint.PLAID.PLAID_EXIT + "?bank_id=" + bankId, reqObj).subscribe(response => {

					});
				} else {
					self.apiService.postCall(AppRestEndPoint.PLAID.PLAID_EXIT, reqObj).subscribe(response => {

					});
				}
				if (err != null && err.error_code === 'INVALID_LINK_TOKEN') {
					self.handler.destroy();
					self.openPlaid();
				}
				console.log('user closed');
				self.router.navigate(['dashboard']);
			}
		});
		this.handler.open();          

    }

}
