<div class="modal-header">
    <div class="row w-100">
        <div class="col-md-11">
            <h5 class="modal-title">{{mailing_header}} Mailing Address</h5>
        </div>
        <div class="col-md-1 pr-0">
            <button type="button" class="close float-right text-white pr-0" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
</div>

<div class="modal-body">
    <div class="col-md-12 col-sm-12 col-xs-12 form-group">
        <label class="main-ques1">The data provided has been processed and a proposed address is shown as the recommended postal address.</label>
    </div>
    <div class="col-md-12 col-sm-12 col-xs-12 no-padding-left display-flex" >
        <div class="col-md-6 col-sm-6 col-xs-6 no-padding-left">
            <label class="sub-heading col-md-12 col-sm-12 col-xs-12"><b>{{header}} PROVIDED ADDRESS:</b></label>
            <div class="col-md-12 col-sm-12 col-xs-12">
                <input name="address" type="radio" class="pointer" [(ngModel)]="addressValue" name="address" value="1">
                <div class="display-inline-table">
                    <span class="main-ques2 sub-heading color-333">{{providedAddress && providedAddress['mailing_address1']}}</span><br *ngIf="providedAddress['mailing_address1']"/>
                    <span class="main-ques2 sub-heading color-333">{{providedAddress['mailing_address2']}}</span><br *ngIf="providedAddress['mailing_address2']"/>
                    <span class="main-ques2 sub-heading color-333">{{providedAddress['mailing_city']}}</span>
                    <span class="main-ques2 sub-heading color-333" *ngIf="providedAddress['mailing_city'] && providedAddress['mailing_state']">, </span>
                    <span class="main-ques2 sub-heading color-333">{{providedAddress['mailing_state']}} {{providedAddress['mailing_zipcode']}}</span>
                </div>
            </div>
            <div class="col-md-12 col-sm-12 col-xs-12 margin-10" [hidden]="!addressError">
                <div class="font-12 color-red" *ngFor="let error of addressError">{{error.description}}</div>
            </div>
            <label class="sub-heading col-md-12 col-sm-12 col-xs-12 margin-15"><b>{{header}} RECOMMENDED POSTAL ADDRESS:</b></label>
            <div class="col-md-12 col-sm-12 col-xs-12">
                <input name="address" type="radio" class="pointer" [(ngModel)]="addressValue" name="address" value="2">
                <div class="display-inline-table">
                    <span class="main-ques2 sub-heading color-333">{{validAddressData['mailing_address1']}}</span><br *ngIf="validAddressData['mailing_address1']"/>
                    <span class="main-ques2 sub-heading color-333">{{validAddressData['mailing_address2']}}</span><br *ngIf="validAddressData['mailing_address2']"/>
                    <span class="main-ques2 sub-heading color-333">{{validAddressData['mailing_city']}}</span>
                    <span class="main-ques2 sub-heading color-333" *ngIf="validAddressData['mailing_city'] && validAddressData['mailing_state']">, </span>
                    <span class="main-ques2 sub-heading color-333">{{validAddressData['mailing_state']}} {{validAddressData['mailing_zipcode']}}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="clear"> </div>
</div> 


<div class="modal-footer">
    <button type="button" class="btn btn-link" (click)="closeModal()">
        CANCEL
    </button>
    <button type="button" class="btn btn-primary btn-sm rounded-pill" (click)="saveAddress()">
        OK
    </button>
</div>