import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AppRestEndPoint } from 'src/app/app-restEndPoint';
import { ApiService } from 'src/app/services/api.service';
import { PusherService } from 'src/app/services/pusher.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-link-account-modal',
	templateUrl: './link-account-modal.component.html',
	styleUrls: ['./link-account-modal.component.scss']
})

export class LinkAccountModalComponent implements OnInit {
	currentUser: any;
	editLinkBank: any;
	editForm: any;
	fastLinkToken: any;
	rsessionToken: any;
	fastlinkUrl: any;
	callBackUrl: any;
	public bank_fastLinkToken: any;
	public bank_rsessionToken: any;
	public bank_users: any;
	public width;
	public height;
	access_token: any;
	showYodleeLoader:any = true;
	@Input() bank;
	@Input() type;
	@Input() selectedHousehold;


	@ViewChild('yodleeForm') yodleeFormElement;
	constructor(
		private apiService: ApiService,
		private pusherService: PusherService,
		private sanitizer: DomSanitizer,
		public modalReference: NgbActiveModal

	) {

		window.addEventListener('message', () => {
            console.log('window message');
            this.closeModal('callPusher');
        }, false);

	}

	ngOnInit(): void {
		this.access_token = sessionStorage.getItem('token');
		if (this.bank) {
			if(this.type && this.type == 'refresh'){
				this.linkAccountLoad('refresh', this.bank);
			}else{
				this.linkAccountLoad('edit', this.bank);
			}
			this.selectedHousehold = this.selectedHousehold.id;
			this.bindPusher();
		} else {
			this.linkAccountLoad('add');
		}
	}

	bindPusher() {
		let pusher_channel = this.selectedHousehold;
		this.pusherService.subscribeBankRefresh(pusher_channel);
		this.pusherService.bind('refresh_worker_finished', function (data) {
			console.log('refresh_worker_finished');
		});

		this.pusherService.bind('update_bank_initiated', function (data) {
			console.log('update_bank_initiated');
		});
	}

	linkAccountLoad(type, bank: any = {}, form = '') {
		if (type === 'add') {
			this.getToken(false, type);
		} else if (type === 'edit' || type == 'refresh') {
			this.editLinkBank = bank.bank;
			this.getToken(true, type);
		}
	}

	getToken(flag, type) {
		//this.presentLoading();
		this.showYodleeLoader = true;
		if (!(this.fastLinkToken) || type === 'timeout') {
			this.apiService.getCall(AppRestEndPoint.ASSET.GET_TOKEN, '').subscribe(data => {
				this.showYodleeLoader = false;
				this.fastLinkToken = data.access_token;
				this.rsessionToken = data.user_session_token;
				this.fastlinkUrl = this.sanitizer.bypassSecurityTrustResourceUrl(data.fastlink_url);

				// for fix bank per user
				this.bank_fastLinkToken = this.fastLinkToken;
				this.bank_rsessionToken = this.rsessionToken;
				this.bank_users = data.users;

				if (flag === true) {
					if (type && (type === 'edit' || type == 'refresh')) {
						this.setEditUrl(this.editLinkBank, type);
					}
				} else if (flag === false) {
					this.setLinkUrl(true);
				}
			}, (err) => {
				this.showYodleeLoader = false;
			});
		} else {
			if (flag === true) {
				if (type && (type === 'edit' || type == 'refresh')) {
					this.setEditUrl(this.editLinkBank, type);
				}
			} else if (flag === false) {
				this.setLinkUrl(true);
			}
		}
	}

	setLinkUrl(flag) {
		this.setFastlinkForm('');
		this.callBackUrl = 'callback=' + AppRestEndPoint.CURRENT_ENV + '/yodlee/yodlee_callback?access_token=' + this.access_token + '&type=callback';
		if (flag === true) {
			setTimeout(_ => this.yodleeFormElement.nativeElement.submit());
		}
	}

	// sets fastlink tokens based on a bank, or sets them to default
	setFastlinkForm(bank) {
		let bank_user;

		if (bank && this.bank_users) {
			bank_user = this.bank_users.find(function (user) {
				if (user.user_id === bank.user_id) {
					return user.fastlink_data;
				}
			});
		}

		if (bank_user) {
			this.fastLinkToken = bank_user.fastlink_data.access_token;
			this.rsessionToken = bank_user.fastlink_data.user_session_token;
		} else {
			this.fastLinkToken = this.bank_fastLinkToken;
			this.rsessionToken = this.bank_rsessionToken;
		}
	}

	setEditUrl(bank, type = '') {
		if (!bank) {
			return;
		}
		this.setFastlinkForm(bank);
		// if(type && type == 'refresh'){
		// 	this.callBackUrl = 'callback=' + AppRestEndPoint.CURRENT_ENV +
		// 	'/yodlee/yodlee_callback?access_token=' + this.access_token + '&type=callback&flow=refresh&siteAccountId=' + bank.item_id;
		// }else{
			this.callBackUrl = 'callback=' + AppRestEndPoint.CURRENT_ENV +
			'/yodlee/yodlee_callback?access_token=' + this.access_token + '&type=callback&flow=edit&siteAccountId=' + bank.item_id;
		//}
		setTimeout(_ => this.yodleeFormElement.nativeElement.submit());
	}

	closeModal(data) {
        if (data === 'callPusher') {
            this.modalReference.close({ 'success': 'closeModal', 'reload': true });
        } else {
            this.modalReference.close({ 'success': 'closeModal', 'edit': false });
        }
    }
}
