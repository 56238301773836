import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { UtilityService } from 'src/app/services/utility.service';
import { AppRestEndPoint } from 'src/app/app-restEndPoint';
import { LoginService } from 'src/app/services/login.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from 'src/app/components/toaster/toast-service';
import { CommonService } from 'src/app/services/common.service';



@Component({
	selector: 'app-enter-token',
	templateUrl: './enter-token.component.html',
	styleUrls: ['./enter-token.component.scss']
})
export class EnterTokenComponent implements OnInit {
	phoneToken: string;
	tokenForm: any;
	tokenFormSubmitted: boolean = false;
	userId: any;
	rememberDevice: boolean = false;
	redirect_to_state: any;
	isSmsToken: boolean = false;


	constructor(
		private fb: FormBuilder,
		private utilityService: UtilityService,
		private loginService: LoginService,
		public utilService: UtilityService,
		private router: Router,
		private toastService: ToastService,
		private activatedRoute: ActivatedRoute,
		private commonService: CommonService

	) {
		this.tokenForm = fb.group({
			'security': [null, Validators.required],
			'rememberDevice': [null]
		});

	}

	ngOnInit(): void {
		this.userId = localStorage.getItem('user_id');
		if (this.activatedRoute.snapshot.params.info) {
			this.rememberDevice = this.activatedRoute.snapshot.params.info === "true" ? true : false;
		}
		if (this.activatedRoute.snapshot.params.emailParams) {
			this.redirect_to_state = this.activatedRoute.snapshot.params.emailParams;  
		}
		let authytype = localStorage.getItem('authy_type');
		if(authytype && authytype == 'sms'){
			this.isSmsToken = true;
		}
	}

	@ViewChild("myinput") myInputField: ElementRef;
	ngAfterViewInit() {
		this.myInputField.nativeElement.focus();
	}

	get formControl() {
		return this.tokenForm.controls;
	}


	resendToken(requestPhoneCall) {
		let params = requestPhoneCall ? `?id=${this.userId}&request_call=${requestPhoneCall}` : `?id=${this.userId}`;
		this.loginService.getCall(AppRestEndPoint.LOGIN.SEND_TOKEN, params).subscribe(data => {
			if (data.success) {
				this.toastService.show(data.msg, {
					classname: 'bg-success text-light', delay: 3000
				});
			} else {
				this.toastService.show(data.msg.replace(/<\/?[^>]+(>|$)/g, "\n"), {
					classname: 'bg-danger text-light', delay: 7000
				});
			}
		})

	}

	openAuthyApp() {
		window.open('https://authy.com/download/', '_blank');
	}

	verifyToken() {
		this.tokenFormSubmitted = true;
		var createAuthyRecord = localStorage.getItem('create_authy_record');
		localStorage.removeItem("create_authy_record");
		if (this.tokenForm.valid) {
			let client_id = environment.client_id;
			let client_secret = environment.client_secret;
			// let firmId = environment.firm_id;
			let guid = this.utilityService.getDeviceCookie();
			let userAgent = this.utilityService.getUserAgent();
			let params = `?client_id=${client_id}&client_secret=${client_secret}&id=${this.userId}&token=${this.phoneToken}&remember_me=${this.rememberDevice}&device_id=${guid}&user_agent=${userAgent}&firm_id=${this.commonService.getDomainDetails().firm_id}`;
			if(createAuthyRecord){
				var twilio_entity_sid = localStorage.getItem('twilio_entity_sid');
        		var twilio_factor_sid = localStorage.getItem('twilio_factor_sid');
				localStorage.removeItem('twilio_entity_sid');
        		localStorage.removeItem('twilio_factor_sid');
				params = params + `&create_record=true&twilio_entity_sid=${twilio_entity_sid}&twilio_factor_sid=${twilio_factor_sid}`;
			}
			this.loginService.getCall(AppRestEndPoint.LOGIN.VERIFY_TOKEN, params).subscribe(data => {
				if (data.success) {
					localStorage.removeItem('authy_type');
					sessionStorage.setItem('token', data.access_token.token);
					sessionStorage.setItem('current_user', JSON.stringify(data.user));
					this.utilService.gtmIdentify(data.user.id,{});
					this.utilService.gtmProfile({
                        'userEmail' : data.user.email,
                        'userFirmId' : data.user.firm.id,
                        'userName' : data.user.name,
                        'userFirm' : data.user.firm.description,
                        'userId': data.user.id
					});    
					
					if (data.access_token.session_sleep_time) {
						let idleTimeoutTimer;
						idleTimeoutTimer = parseInt(data.access_token.session_sleep_time) * 60000;
						sessionStorage.setItem('idleTimeoutTimer', idleTimeoutTimer);
						this.commonService.setIdleTimeout();
					}
					if(this.redirect_to_state){                            
                        this.commonService.returnLastSavedState(this.redirect_to_state);
                    }
                    else
                    {
						this.router.navigate(['dashboard']);
                    } 
					
				} else {
					this.toastService.show(data.msg.replace(/<\/?[^>]+(>|$)/g, "\n"), {
						classname: 'bg-danger text-light', delay: 7000
					});
				}

			});
		}
	}

}
