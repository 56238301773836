<div class="modal-header">
    <div class="row w-100">
        <div class="col-md-11">
            <h5 class="modal-title">Group and Accounts</h5>
        </div>
        <div class="col-md-1 pr-0">
            <button type="button" class="close float-right text-white pr-0" aria-label="Close"
                (click)="activeModal.close()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
</div>
<div class="modal-body">
    <div class="overflow-scroll-y-auto">
        <div *ngIf="selected_managed_assets_account_groups.length > 0">
            <label class="col-md-12 col-sm-12 mb2 font-weight-bold mb-3">
                Portfolio Assets:
            </label>
            <div class="row">
                <div class="col-md-12 col-sm-12" *ngFor="let group of selected_managed_assets_account_groups">
                    <div class="col-md-12 col-sm-12">
                        <label class="cursor-pointer font-14">
                            <input type="checkbox" name="columnSelected" [(ngModel)]="group.selected" />
                            <span class="ml-2">{{group.account_group_name}}</span> 
                        </label>
                        <span class="float-right">{{group.balance_sub_total | currency:'USD':'symbol':'1.0-0'}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-link" (click)="activeModal.close()">
        Close
    </button>
    <button type="button" class="btn btn-primary btn-sm rounded-pill" (click)="saveGroups()">
        Save
    </button>
</div>