import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationService } from 'src/app/services/navigation.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
	selector: 'app-assets-and-liabilities',
	templateUrl: './assets-and-liabilities.component.html',
	styleUrls: ['./assets-and-liabilities.component.scss']
})
export class AssetsAndLiabilitiesComponent implements OnInit {

	selectedType: any;
	constructor(
		private activatedRoute: ActivatedRoute,
		private navigationService: NavigationService,
		public router: Router,
		public utilityService: UtilityService
		) { }

	ngOnInit(): void {
		this.selectedType = this.activatedRoute.snapshot.paramMap.get('type');
	}
	
	gotoPreviousState() {
        //$scope.saveState(user_id, access_token);
        var reqObj = { "info_type": this.selectedType, "state": "assets-and-liabilities" };
        this.navigationService.getPreviousPage(reqObj);
    }

	setPageNavigation() {
		this.sendMixPanelEvent({'event': 'Client Onboarding - Assets and Liabilities - Stop'});
		if (sessionStorage.getItem('is_questionnaire_required') && sessionStorage.getItem('is_questionnaire_required') != null && sessionStorage.getItem('is_questionnaire_required') != '') {
			this.router.navigate([this.selectedType+"/signup-complete"]);
			return;
		} else {
			this.router.navigate([this.selectedType+"/advisor-contact"]);
		}
	}

	gotoAccountsPage(){
		this.sendMixPanelEvent({'event': 'Client Onboarding - Assets and Liabilities - Continue'});
		this.router.navigate([this.selectedType+"/accounts"]);
	}

	sendMixPanelEvent(eventObj){
        this.utilityService.gtmPush(eventObj);
    }

}
