<div class="row float-left bg-white position-fixed h-100 pt-3 mt-n4 d-none d-sm-block d-md-block d-lg-block">
    <div class="col-sm-2 col-md-2 col-lg-1 col-xl-1 pr-0">
        <nav class="navbar navbar-light navbar-expand-sm px-0 flex-row flex-nowrap left-nav">
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarWEX"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="navbar-collapse collapse" id="navbarWEX">
                <div class="nav flex-sm-column flex-row">
                    <a [ngClass]="{'selected':selectedPage.title == '/dashboard'}" title="Dashboard"
                        class="cursor-pointer" (click)="setCurrentNav('Dashboard')"><i class="fa fa-briefcase"></i></a>
                    <a [ngClass]="{'selected':selectedPage.title == '/tasks'}" title="TODO"
                        class="cursor-pointer" (click)="setCurrentNav('Tasks')"><i class="fa fa-check"></i>
                        <div class="notification-div" >
                            {{taskPendingCount}}
                        </div>
                    </a>
                        <!-- <a  ng-class="{'selected':selectedPage.title == 'Documents'}" title="Documents"ng-click="getPageUrl('Documents')"><i class="fa fa-files-o"></i></a>-->
                    <a [ngClass]="{'selected':selectedPage.title == '/profile'}" title="Profile" class="cursor-pointer"
                        (click)="setCurrentNav('Profile')"><i class="fa fa-user-circle-o"></i></a>
                    <a [ngClass]="{'selected':currentIndex == 3}" title="Portfolio" ng-if="tamaracObj.length>0"
                        class="cursor-pointer" (click)="openPortfolio()"><i class="fa fa-pie-chart"></i></a>
                    <!-- <div class="notification-div" ng-if="documentCount>0">
                    {{documentCount}}
                </div> -->
                    <a [ngClass]="{'selected':selectedPage.title == '/documents'}" title="Documents"
                        class="cursor-pointer" (click)="setCurrentNav('Documents')"><i class="fa fa-file-text"></i>
                        <div class="notification-div" >
                            {{documentCount}}
                        </div>
                    </a>
                    <a *ngIf="showNewsIcon" ng-class="{'selected':currentIndex == 4}" title="News & Insights"
                        class="cursor-pointer" (click)="openKnowledgeBase()">
                        <i class="fa fa-newspaper-o"></i></a>
                    <a (click)="logOut()" class="cursor-pointer" title="Sign out"><i class="fa fa-sign-out"></i></a>
                </div>
            </div>
        </nav>
    </div>
</div>