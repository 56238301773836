<div class="modal-header">
    <div class="row w-100">
        <div class="col-md-11">
            <h5 class="modal-title">Add Document</h5>
        </div>
        <div class="col-md-1 pr-0">
            <button type="button" class="close float-right text-white pr-0" aria-label="Close"
                (click)="activeModal.dismiss()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
</div>
<div class="modal-body" [ngClass]="{'opacity-5 cursor-none':flagObj.uploadDocumentLoader}">
    <form name="uploadDocForm" novalidate>
        <div class="form-group row">
            <div class="col-md-12 col-sm-12">
                <div class="upload-file-drop-zone" appDropfile (fileDropped)="onFileDropped($event)">
                    <div class="text-center mt-4 text-secondary pt-2" *ngIf="files.length==0">Drop Files</div>
                    <div *ngFor="let file of files ; let i = index" class="float-left">
                        <div class="filesList text-dark p-2">
                            <span>{{file.name}}</span>
                            <i class="fa fa-times crossIcon pl-1 cursor-pointer text-danger" aria-hidden="true"
                                (click)="removeFiles(i)"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-12 col-sm-12 no-padding-left-right">
                <div class="float-left mr-2 font-14 text-secondary"><label>Attach Files</label></div>
                <label class="float-left padding paperClip cursor-pointer">
                    <input type="file" class="d-none" ng-model="uploadFile" id="fileUpload" name="uploadFile"
                        multiple="multiple" (change)="getFileDetails($event)" />
                    <i class="fa fa-paperclip font-14 color-brown"></i>
                </label>
            </div>
            <div *ngIf="flagObj.uploadDocFormSubmitted && flagObj.showUploadError" class="text-danger ml-3">Please attach a file.</div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-link" (click)="closeModal('close')" [disabled]="flagObj.disableBtn">
        CANCEL
    </button>
    <button type="button" class="btn btn-primary btn-sm rounded-pill" (click)="saveDocument()" [disabled]="flagObj.disableBtn"> 
        Add Document &nbsp;&nbsp;<span class="loader spinner-border spinner-border-sm" *ngIf="flagObj.uploadDocumentLoader"></span>
    </button>
</div>