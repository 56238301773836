import { ChangeDetectorRef, Component, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AppRestEndPoint } from 'src/app/app-restEndPoint';
import { existingUsernameValidator } from 'src/app/directives/valid-name.directive';
import { ApiService } from 'src/app/services/api.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { ToastService } from 'src/app/components/toaster/toast-service';
import { NavigationService } from 'src/app/services/navigation.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
	selector: 'app-personal-information',
	templateUrl: './personal-information.component.html',
	styleUrls: ['./personal-information.component.scss']
})
export class PersonalInformationComponent implements OnInit {
	personalInfoForm: FormGroup;
	personalInfoFormSubmitted: Boolean = false;
	relationshipStatus: any;
	selectedType: any;
	rtqTitle: any;
	isMarried: Boolean = false;
	childrenInfo: any = [];
	showBirthCalender: Boolean = false;
	showSpouseBirthCalender: Boolean = false;
	showChildBirthCalender: Boolean = false;
	isPrimaryEdit: Boolean = false;
	isSpouseEdit: Boolean = true;
	isPrimary: Boolean = true;
	spousePrimary: Boolean = false;
	userDataIndex: any;
	household_users: any;
	isMarriedCopy: any;
	household: any;
	maritalStatus: any;
	spouselabel: any;
	spousePlaceholder: any;
	currentUser: any;
	personalInfo: any = {};
	spouseInfo: any;
	isSpouseInfo: any;
	childrenCount: any;
	minDate = new Date("01/01/1901");
	maxDate = new Date();
	bsConfig?: Partial<BsDatepickerConfig>;

	constructor(
		private formBuilder: FormBuilder,
		private activatedRoute: ActivatedRoute,
		private apiService: ApiService,
		private toastService: ToastService,
		private navigationService: NavigationService,
		public utilityService: UtilityService,
		private cdref: ChangeDetectorRef
	) {
		this.personalInfoForm = this.formBuilder.group({
			'maritalStatus': [null, Validators.required],
			'childrenCount': [null],
			'primarybirthday': [null],
			'spousebirthday': [null],
			'childbirthday': [null],
			'spousePrimaryName': [null],
			'spousePrimaryEmail': [null, Validators.pattern('^[A-Z0-9a-z\\._%+-]+@([A-Za-z0-9-]+\\.)+[A-Za-z]{2,4}$')],
			'spouseName': [null],
			'spouseBirthday': [null],
			'spouseEmail': [null, Validators.pattern('^[A-Z0-9a-z\\._%+-]+@([A-Za-z0-9-]+\\.)+[A-Za-z]{2,4}$')],
			'childInfoArr': this.formBuilder.array([])
		});
	}

	ngOnInit(): void {
		this.bsConfig = Object.assign({}, { 
			adaptivePosition: true, 
			containerClass:'theme-dark-blue', 
			showWeekNumbers:false,
			dateInputFormat: 'MM/DD/YYYY' });
		this.relationshipStatus = [
			{ id: '0', value: 'Select One' },
			{ id: '1', value: 'Single', },
			{ id: '2', value: 'Married' },
			{ id: '3', value: 'In Domestic Relationship' }
		];
		this.selectedType = this.activatedRoute.snapshot.paramMap.get('type');
		this.currentUser = JSON.parse(sessionStorage.getItem('current_user'));
		this.rtqTitle = sessionStorage.getItem('rtq_title');
		this.getFamilyInfo();
	}

	ngAfterContentChecked() {
        this.cdref.detectChanges();
    }

	newChildInfo() {
		const control = <FormArray>this.personalInfoForm.controls['childInfoArr'];
		control.push(this.addChildInfo());
	}

	addChildInfo(): FormGroup {
		return this.formBuilder.group({
			'childName': [null],
			'childBirthday': [null]
		});
	}

	get childInfoArr(): FormArray {
		return this.personalInfoForm.get('childInfoArr') as FormArray;
	}

	setMaxDate(type, index:any=''){
		switch(type){
			case 'primary': if(this.personalInfo.birthday > new Date()){
				this.personalInfo.birthday = new Date();
			} break;
			case 'spouse': if(this.spouseInfo.birthDate > new Date()){
				this.spouseInfo.birthDate = new Date();
			} break;
			case 'userDataIndex': if(this.childrenInfo[this.userDataIndex].birthday > new Date()){
				this.childrenInfo[this.userDataIndex].birthday = new Date();
			} break;
			case 'child': if(this.childrenInfo[index].birthday > new Date()){
				this.childrenInfo[index].birthday = new Date();
			} break;
		}
	}

	getFamilyInfo() {
		this.isPrimary = true;
		this.spousePrimary = false;
		this.userDataIndex = -1;
		let url = AppRestEndPoint.BASIC_INFO.FAMILY_INFO;
		let param = '';
		if (this.rtqTitle) {
			param = "&rtq_title=" + this.rtqTitle.id;
		}
		this.apiService.getCall(url, param).subscribe(response => {
			var familyData = response;
			this.household_users = familyData.household_users;
			this.isMarriedCopy = JSON.parse(JSON.stringify(familyData.household_users.married));
			this.household = this.household_users.household;
			this.isMarried = familyData.household_users.married;
			if (this.isMarried) {
				this.maritalStatus = '2';
				this.spouselabel = 'SPOUSE';
				this.spousePlaceholder = 'Spouse';
			} else {
				this.maritalStatus = '1';
			}
			if (this.currentUser.id == familyData.household_users.household.primary_user_attributes.user_attributes.id) {
				this.isPrimary = true;
			} else {
				this.isPrimary = false;
			}
			this.personalInfo = familyData.household_users.household.primary_user_attributes.user_attributes;
			this.personalInfo.birthday = familyData.household_users.household.primary_user_attributes.user_attributes.birthday ? moment(familyData.household_users.household.primary_user_attributes.user_attributes.birthday).format('MM/DD/YYYY') : "";

			if (familyData.household_users.household.spouse_user_attributes) {
				if (this.currentUser.id == familyData.household_users.household.spouse_user_attributes.user_attributes.id) {
					this.spousePrimary = true;
				} else {
					this.spousePrimary = false;
				}
				this.spouseInfo = familyData.household_users.household.spouse_user_attributes.user_attributes;
			}
			else {
				this.spouseInfo = {};
			}

			this.isSpouseInfo = Object.keys(this.spouseInfo).length;
			if (this.isSpouseInfo > 0) {
				this.isSpouseEdit = false;
			}

			this.spouseInfo.birthDate = this.spouseInfo.birthday ? moment(this.spouseInfo.birthday).format('MM/DD/YYYY') : '';
			if (familyData.household_users.household.children_attributes) {
				this.childrenInfo = [];
				var childAttribute = familyData.household_users.household.children_attributes;
				_.forEach(childAttribute, function (value) {
					value.birthday = value.birthday ? moment(value.birthday).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY');
					value.isChildEdit = false;
					this.newChildInfo();
					this.childrenInfo.push(value);
				}.bind(this));
			}
			this.childrenCount = this.childrenInfo.length;
			if (!this.spousePrimary && !this.isPrimary) {
				_.map(this.childrenInfo, function (value, index) {
					if (value.id == this.currentUser.id) {
						this.userDataIndex = index;
					}
				});
			}

			sessionStorage.setItem('login_rtq_id', response.rtq_id);
		});
	}

	getChildrenInfo(newLength) {
		let diff, existingLength;
		let previousLength = this.childrenInfo.length;
		//To get deleted items
		var destroyedObjects = _.filter(this.childrenInfo, function (obj) {
			return obj._destroy == true;
		});

		if (destroyedObjects) {
			existingLength = previousLength - destroyedObjects.length;
		} else {
			existingLength = previousLength;
		}

		if (newLength > existingLength) {
			diff = newLength - existingLength;
			for (let i = 0; i < diff; i++) {
				this.newChildInfo();
				this.childrenInfo.push({ "full_name": "", "birthday": "", "isChildEdit": true });
			}
		} else {
			diff = existingLength - newLength;
			for (let i = existingLength - 1; i >= (existingLength - diff); i--) {
				if (this.childrenInfo[i]._destroy == false) {
					this.childrenInfo[i]._destroy = true;
				} else {
					this.childrenInfo.splice(this.childrenInfo.length - 1, diff);
					const control = <FormArray>this.personalInfoForm.controls['childInfoArr'];
        			control.removeAt(i);
				}
			}
		}
	}

	validateDate(date) {
		/* var inputDate = $filter('date')(date,'MM/dd/yyyy','UTC');
		var isValidDate = moment(inputDate, 'MM/DD/YYYY', true).isValid();
		if(isValidDate){
			if(new Date(inputDate) < this.options.minDate){
				isValidDate = false;
			}
		}
		return isValidDate; */
	}

	submitBasicInfo(){
		this.personalInfoFormSubmitted = true;
		if (this.personalInfoForm.valid) {
			var children_attributes = {};
			for (var x in this.childrenInfo) {
				children_attributes["child_" + x] = {
					"fullname": this.childrenInfo[x].fullname,
					"birthday": this.childrenInfo[x].birthday ? moment(this.childrenInfo[x].birthday).format('MM/DD/YYYY') : '',
					"id": this.childrenInfo[x].id ? this.childrenInfo[x].id : "",
					"_destroy": this.childrenInfo[x]._destroy ? true : false
	
				};
				if (this.isPrimary) {
					children_attributes["child_" + x]._destroy = this.childrenInfo[x]._destroy ? true : false;
				} else {
					if (this.childrenInfo[x].id) {
						children_attributes["child_" + x]._destroy = false;
					} else {
						children_attributes["child_" + x]._destroy = this.childrenInfo[x]._destroy ? true : false;
					}
				}
				if (this.household.advisor_user_attributes) {
					children_attributes["child_" + x].advisor_admin_user_id = this.household.advisor_user_attributes.admin_user_id;
				}
			}
	
	
			var spouse_attributes;
			if (this.spouseInfo.fullname) {
				spouse_attributes = {
					"user_attributes": {
						"fullname": this.spouseInfo.fullname,						
						"birthday": this.spouseInfo.birthDate? moment(this.spouseInfo.birthDate).format('MM/DD/YYYY') : '',
						"email": this.spouseInfo.email,
						"id": this.spouseInfo.id ? this.spouseInfo.id : ''
	
					},
					"id": this.household.spouse_user_attributes ? this.household.spouse_user_attributes.id : ''
				};
				if (this.household.advisor_user_attributes) {
					spouse_attributes.user_attributes.advisor_admin_user_id = this.household.advisor_user_attributes.admin_user_id;
				}
			}
	
			var hasDependents = false;
			if (this.childrenInfo.length > 0) {
				hasDependents = true;
			}	
			
			var requestData;
			requestData = {
				"household": {
					"investment_amount": this.household.investment_amount,
					"primary_user_attributes": {
						"user_attributes": {
							"first_name": this.personalInfo.first_name,
							"middle_initial": this.personalInfo.middle_initial,
							"last_name": this.personalInfo.last_name,
							"retirement_status": this.personalInfo.retirement_status,
							"retirement_age": this.personalInfo.retirement_age,
							"birthday": this.personalInfo.birthday? moment(this.personalInfo.birthday).format('MM/DD/YYYY') : '',							
							"id": this.personalInfo.id
	
						},
						"id": this.household.primary_user_attributes.id,
					},
					"advisor_user_attributes": {
	
					}
				},
				"dependents": hasDependents,
				"id": this.household_users.id,
				//"rtq_title": rtqTitle
			};
			if (this.rtqTitle) {
				requestData.rtq_title = this.rtqTitle;
			}
			if (this.isPrimary) {
				requestData.married = this.isMarried;
			} else {
				if (this.spouseInfo.id) {
					requestData.married = this.isMarriedCopy;
				} else {
					requestData.married = this.isMarried;
				}
			}
			if (this.spouseInfo.fullname) {
				requestData.household.spouse_user_attributes = spouse_attributes;
			}
			if (this.childrenInfo.length > 0) {
				requestData.household.children_attributes = children_attributes;
			}
			if (this.household.advisor_user_attributes) {
				requestData.household.primary_user_attributes.user_attributes.advisor_admin_user_id = this.household.advisor_user_attributes.admin_user_id;
				requestData.household.advisor_user_attributes.admin_user_id = this.household.advisor_user_attributes.admin_user_id;
				requestData.household.advisor_user_attributes.id = this.household.advisor_user_attributes.id;
			}
			let url = AppRestEndPoint.BASIC_INFO.SAVE_FAMILY_INFO;
			this.apiService.postCall(url, requestData).subscribe(data => {
				if (data.success === false) {
					var errorMsg = '';
					data.message.forEach(function (item) {
						errorMsg = errorMsg + item + '. ';
					});
					errorMsg =  errorMsg 
					
					this.toastService.show(errorMsg, {
						classname: 'bg-danger text-light', delay: 3000
					});
				}
				else {
					this.saveState();
					this.gotoNextState();
				}
			});
	
		}
		else {
			this.isSpouseEdit = true;
		}
	}	

	changeSpouseLabels() {
		if (this.maritalStatus === '2') {
			this.spouselabel = 'SPOUSE';
			this.spousePlaceholder = 'Spouse';
			this.isMarried = true;
		}
		else if (this.maritalStatus === '3') {
			this.spouselabel = 'PARTNER';
			this.spousePlaceholder = 'Partner';
			this.isMarried = true;
		}
		else {
			this.personalInfoForm.controls['spouseName'].clearValidators();
			this.personalInfoForm.controls['spouseName'].setErrors(null);					
			this.personalInfoForm.controls['spouseName'].updateValueAndValidity();

			this.personalInfoForm.controls['spouseBirthday'].clearValidators();
			this.personalInfoForm.controls['spouseBirthday'].setErrors(null);					
			this.personalInfoForm.controls['spouseBirthday'].updateValueAndValidity();

			this.personalInfoForm.controls['spouseEmail'].clearValidators();
			this.personalInfoForm.controls['spouseEmail'].setErrors(null);					
			this.personalInfoForm.controls['spouseEmail'].updateValueAndValidity();
			this.isMarried = false;
		}
	}

	gotoNextState(){
		this.sendMixPanelEvent({'event': 'Client Onboarding - Basic Information - Continue'})
		let reqObj = {"info_type" : this.selectedType, "state": "basic-info/personal-info"};
		this.navigationService.getNextPage(reqObj);
	}

	gotoPreviousState(){
		this.sendMixPanelEvent({'event': 'Client Onboarding - Basic Information - Go Back'})
		let reqObj = {"info_type" : this.selectedType, "state": "basic-info/personal-info"};
		this.navigationService.getPreviousPage(reqObj);
	}

	saveState(){		
		var reqObj = {
			'user': {"gettingstarted_step":'{"info_type" :"' +this.selectedType+'","state" :"personal"}'}	
		};
		let url = AppRestEndPoint.LOGIN.SAVE_STATE+'/'+this.currentUser.id;
		this.apiService.updateCall(url, reqObj, '').subscribe(data => {
		});
	}

	get formControl() {
		return this.personalInfoForm.controls;
	}
	
	get formControlArr() {
		return this.personalInfoForm.get('childInfoArr')['controls'];
	}

	validName(type, index=''){
		let val;
		if(type == 'spouseName'){
			val = this.spouseInfo.fullname;
			this.apiService.getCall(AppRestEndPoint.LOGIN.USER_LIST + '/valid_name', "&name=" + val).subscribe(response => {
				if(!response.message){
					this.personalInfoForm.controls[type].setValidators(f => <any>{ "usernameExists": true });
				}else{
					this.personalInfoForm.controls[type].clearValidators();
					this.personalInfoForm.controls[type].setErrors(null);					
				}
				this.personalInfoForm.controls[type].updateValueAndValidity();				
			});
		}else{
			val = this.childrenInfo[index].fullname;
			this.apiService.getCall(AppRestEndPoint.LOGIN.USER_LIST + '/valid_name', "&name=" + val).subscribe(response => {
				if(!response.message){				
					this.formControlArr[index].controls[type].setValidators(f => <any>{ "usernameExists": true });
				}else{				
					this.formControlArr[index].controls[type].clearValidators();
					this.formControlArr[index].controls[type].setErrors(null);
				}
				this.formControlArr[index].controls[type].updateValueAndValidity();
			});
		}
	}

	sendMixPanelEvent(eventObj){
		this.utilityService.gtmPush(eventObj);
	}
}
