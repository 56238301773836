<!-- Modal -->
<div class="modal-header">
    <div class="row w-100">
        <div class="col-md-11">
            <h5 class="modal-title">Upload Statements</h5>
        </div>
        <div class="col-md-1 pr-0">
            <button type="button" class="close float-right text-white pr-0" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
</div>
<div class="modal-body">
    <div class="modal-body">
        <div class="form-group col-md-12 col-sm-12 col-xs-12">
            <h5 class="text-center">Choose a file to upload.</h5>
        </div>
    </div>
    <div class="clear"></div>
    <div class="modal-footer">
        <button type="button" class="btn btn-link" (click)="closeModal()">
            CANCEL
        </button>
        <button type="button" class="btn btn-primary btn-sm rounded-pill btn-bs-file" (click)="uploadFile.click()">
            <label class="mb-0">
                Select File
            </label>
        </button>
        <input type="file" name="uploadFile" #uploadFile [(ngModel)]="user_document.uploadFile" (change)="saveDocument($event.target.files)" class="d-none"
        multiple="true" />
    </div>