import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-once-schedule-meeting-modal',
	templateUrl: './schedule-once-meeting-modal.component.html',
	styleUrls: ['./schedule-once-meeting-modal.component.scss']
})
export class ScheduleOnceMeetingModalComponent implements OnInit {
	@Input() bookingUrl;
	@Input() currentUserName;
	@Input() currentUserEmail;
	scheduleonceUrl: SafeResourceUrl;
	safeScheduleonceUrl:any;


	constructor(private sanitizer: DomSanitizer, private activeModal: NgbActiveModal) { }

	ngOnInit(): void {
		let schedule = this.bookingUrl + '?name=' + this.currentUserName + '&email=' + this.currentUserEmail + '&skip=1';
		this.safeScheduleonceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(schedule);
	}

   
	closeModal() {
        this.activeModal.close();
    }
}
