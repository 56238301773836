import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppRestEndPoint } from 'src/app/app-restEndPoint';
import { ApiService } from 'src/app/services/api.service';
import * as _ from 'lodash';
import { ToastService } from 'src/app/components/toaster/toast-service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
	selector: 'app-add-account-modal',
	templateUrl: './add-account-modal.component.html',
	styleUrls: ['./add-account-modal.component.scss']
})
export class AddAccountModalComponent implements OnInit {
	addManualAccForm: FormGroup;
	accountTypesCheck: any;
	currentAccountType: any;
	displayEmployer = false;
	displayBeneficiary = false;
	public selectedRow: any = {};
	public accTypes: any;
	public accTypesGroup: any;
	public fieldType = [];
	setBalance = '';
	fieldOptions: any;
	accountTypes: any;
	@Input() accoTypes;
	@Input() netWorthData;
	addManualAccFormSubmitted: boolean = false;
	public accountHolder: any;
	currentAccountTypeName:any;


	constructor(public activeModal: NgbActiveModal,
		private fb: FormBuilder,
		private apiService: ApiService,
		private toastService: ToastService,
		private utilService: UtilityService) {
		this.addManualAccForm = fb.group({
			'accountName': [null, Validators.required],
			'accountType': [null, Validators.required],
			'accountHolderName': [null],
			'balance': [null],
			'interestRate': [null],
			'accountEmployer': [null]
		});
	}

	ngOnInit(): void {
		this.loadAccountData();
	}

	loadAccountData() {
		this.accTypes = [];
		this.accountTypes = this.accoTypes;
		this.accountTypes.account_types.account_type_values.forEach(function (value) {
			let type;
			value.forEach(function (value1) {
				if (typeof value1 === 'string') {
					type = value1;
				} else {
					value1.forEach(function (value2) {
						const accObj = {};
						accObj['id'] = value2[1];
						accObj['name'] = value2[0];
						accObj['type'] = type;
						accObj['accountTypesFlag'] = value2[2];
						this.accTypes.push(accObj);
					}, this);
				}
			}, this);
		}, this);
		this.accTypesGroup = this.accTypes.reduce((r,{type})=>{
			if(!r.some(o=>o.type==type)){
			  r.push({type,groupItem:this.accTypes.filter(v=>v.type==type)});
		}
		return r;
		},[]);
		/*   if (this.accountData.navigateType === 'edit') {
			  this.showPositions();
		  } else {
			  this.selectedRow = {};
			  if(this.accountData.navigatedFrom=='other-assets'){
				  this.selectedRow.accountName='';
				  const option = this.accTypes.find(function (item) {
					  return item.name === this.accountData.selectedType;
				  }, this);
				  this.accTypes = this.accTypes.filter(function (item) {
					  return item.type == 'Debt' || item.type=='Owned Asset';
				  }, this);
				  this.selectedRow.acctype=option.id;
				  this.setInputElement(option.id);
			  }
			  
		  }*/
	}


	closeModal() {
		this.activeModal.close();
	}

	public setInputElement(option) {
		this.accountTypesCheck = _.filter(this.accTypes, function (item) {
			return item.id === parseInt(option);
		});

		this.currentAccountType = this.accountTypesCheck[0].type;
		this.currentAccountTypeName = this.accountTypesCheck[0].name;
		this.accountTypesCheck = this.accountTypesCheck[0].accountTypesFlag;
		this.showAccountHolder(this.accountTypesCheck);
		/*  this.showPositionGrid(this.accountTypesCheck);
		 this.showPositionField = false; */
		if (this.selectedRow) {
			this.selectedRow.isManualCopy = false;
			this.selectedRow.selectedAssetTypeCopy = '';
		}
		if (this.fieldType && this.fieldType[0] && (this.fieldType[0].label === 'Balance' || this.fieldType[0].label === 'Value')
			&& this.fieldType[0].data !== '') {
			this.setBalance = this.fieldType[0].data;
		}

		this.fieldOptions = this.accountTypes.account_type_fields;
		this.fieldType = [];
		if (this.fieldOptions[option] && this.fieldOptions[option][1]) {
			this.fieldType[0] = this.fieldOptions[option][1];
		}
		if (this.fieldOptions[option] && this.fieldOptions[option][2]) {
			this.fieldType[1] = this.fieldOptions[option][2];
		}
		if (this.setBalance && this.fieldType && this.fieldType[0] &&
			(this.fieldType[0].label === 'Balance' || this.fieldType[0].label === 'Value')
			&& this.setBalance !== '') {
			this.fieldType[0].data = this.setBalance;
		}
		_.forEach(this.fieldType, function (obj) {
			if (typeof obj.data === 'object') {
				obj.isObject = true;
				obj.isString = false;
				obj.isBoolean = false;
			} else if (typeof obj.data === 'string') {
				obj.isObject = false;
				obj.isString = true;
				obj.isBoolean = false;
			} else if (typeof obj.data === 'boolean') {
				obj.isObject = false;
				obj.isString = false;
				obj.isBoolean = true;
			} else {
				obj.isObject = false;
				obj.isString = true;
				obj.isBoolean = false;
			}
		});
		const len = this.accountTypes.account_types.account_type_values.length;
		let selectedAccountType = [];
		for (let i = 0; i < len; i++) {
			if (this.accountTypes.account_types.account_type_values[i][0] === 'Investable Accounts') {
				selectedAccountType = this.accountTypes.account_types.account_type_values[i];
				break;
			}
		}
		if (selectedAccountType.length > 0) {
			for (let k = 1; k < selectedAccountType.length; k++) {
				for (let l = 0; l < selectedAccountType[k].length; l++) {
					if (selectedAccountType[k][l][1] === option) {
						this.selectedRow.isManualCopy = true;
						this.selectedRow.selectedAssetTypeCopy = 'Unmanaged Assets';
						break;
					}
				}
			}
		}
	}

	showAccountHolder(argument) {
		this.displayEmployer = argument.supports_employer ? true : false;
		this.displayBeneficiary = (argument.dependent_type != null || argument.dependent_type != '') ? true : false;

	}

	createManualAccount() {

		this.addManualAccFormSubmitted = true;
		if (this.addManualAccForm.valid) {
			let assetType;
			let assetName = this.currentAccountTypeName;
			
			this.apiService.postCall(AppRestEndPoint.ASSET.CREATE_ASSET, this.getAccountRequest()).subscribe(data => {
				if (data.account) {
					this.activeModal.close(data);
					this.toastService.show('Account Created Successfully.', {
						classname: 'bg-success text-light', delay: 3000
					});

					/* $scope.resetAddAccount();
					$scope.showEdit = true;
					var index = -1;
					_.map($scope.$parent.netWorthData, function (value, i) {
						if (value.asset_type == response.data.account_type_name) {
							index = i;
						}
					});
					if (index != -1) {
						$scope.$parent.netWorthAccArr[index] = true;
					} */
					this.selectedRow.selectedId = data.account.id;
					data.account.type = assetName;
					data.account.bank_description = "Manually-Added Accounts";
					data.account.assetType = assetType;
					data.account.acctype = data.account.account_type_id;
					data.account.account_description = data.account.description;
					data.account.is_manual_bank = true;
					data.account.account_holder_info = data.account_holder_info;
					//modalDataService.setData(response.data.account);

				}
			});
		}
	}

	getAccountRequest() {
		let reqObj = {}, current_employer = 0, balance = '', interest_rate = '', user_id = '';
		reqObj['description'] = this.selectedRow.accountName;
		reqObj['account_type_id'] = this.selectedRow.acctype;
		this.fieldType.forEach(function (obj) {
			if (typeof obj.data === 'boolean') {
				current_employer = obj.data ? 1 : 0;
			} else if (typeof obj.data === 'string' || typeof obj.data === 'number') {
				if (obj.label === 'Balance' || obj.label === 'Value') {
					if (this.selectedRow.disableField !== true) {
						balance = obj.data;
					}
				} else if (obj.label === 'Interest Rate') {
					interest_rate = obj.data;
				}
			} else if (typeof obj.data === 'object') {
				user_id = this.accountHolder;
			}
		}, this);
		reqObj['current_employer'] = current_employer;
		if (balance !== '') {
			reqObj['balance'] = this.utilService.formatCurrency(balance);
		}
		reqObj['interest_rate'] = interest_rate ? parseFloat(interest_rate).toFixed(3) : interest_rate;
		reqObj['user_id'] = user_id;
		if (this.selectedRow.is_hidden) {
			reqObj['.is_hidden'] = this.selectedRow.is_hidden;
		}
		const request = { 'account': reqObj };
		return request;
	}

	get formControl() {
		return this.addManualAccForm.controls;
	}


}
