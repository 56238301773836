import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppRestEndPoint } from 'src/app/app-restEndPoint';
import { ToastService } from 'src/app/components/toaster/toast-service';
import { ApiService } from 'src/app/services/api.service';

@Component({
	selector: 'app-add-position-modal',
	templateUrl: './add-position-modal.component.html',
	styleUrls: ['./add-position-modal.component.scss']
})
export class AddPositionModalComponent implements OnInit {

	@Input() positionModalHeading;
	@Input() selectedPosition;
	@Input() disableQuantity;
	@Input() selectedRow;
	positionFormSubmitted: boolean;
	positionForm: FormGroup;
	checkCustomTicker: boolean;

	constructor(
		private activeModal: NgbActiveModal,
		private fb: FormBuilder,
		private apiService: ApiService,
		private toastService: ToastService
	) {
		this.positionForm = fb.group({
			'symbol': [null, Validators.required],
			'description': [null],
			'valueName': [null, Validators.required],
			'quantity': [null, Validators.required]
		});
	}

	ngOnInit(): void {
		this.checkCashTicker();
	}

	checkCashTicker() {
		if (this.positionModalHeading === "Add Cash") {
			this.selectedPosition.symbol = "CASH";
			this.getTicker(this.selectedPosition);
		}
	}

	savePosition() {
		this.positionFormSubmitted = true;
		let amount = '';
		if (this.selectedPosition.description == undefined || this.selectedPosition.description == '' ) {
			this.positionForm.controls['symbol'].setErrors({ 'invalidSymbol': true });
			return;
		}
		
		if (this.positionForm.valid) {
			if (this.selectedPosition.amount) {
				 amount = this.selectedPosition.amount.toString();
				if(amount.match(/\$|,/g))
				{
					amount = amount.replace(/\$|,/g, '');
				}
			}
			let reqObj = {
				"position": {
					symbol: this.selectedPosition.symbol,
					shares: this.selectedPosition.quantity?this.selectedPosition.quantity:0,
					quantity: this.selectedPosition.quantity?this.selectedPosition.quantity:0,
					value: amount,
					description: this.selectedPosition.description
				}
			};		

			if (this.selectedPosition.id) {
				const apiurl = AppRestEndPoint.DASHBOARD.GET_POSITIONS + '/' + this.selectedPosition.id + '?account_id=' + this.selectedRow.selectedId;
				this.apiService.updateCall(apiurl, reqObj, this.selectedPosition.id).subscribe(data => {
					if (data) {
						this.selectedPosition = {};
						this.cancel();
						
					}
				})
					
			} else {
				const apiurl = AppRestEndPoint.DASHBOARD.GET_POSITIONS + '?account_id=' + this.selectedRow.selectedId;
				this.apiService.postCallQuery(apiurl, reqObj).subscribe(data => {
					if (data) {
						this.selectedPosition = {};
						this.cancel();			
	
					}
				})
				
			}		
			
		}
	}

	cancel() {
		this.activeModal.close();
	}

	getTicker(ticker) {
		let params = `&ticker=${ticker.symbol}`;
		this.apiService.getCall(AppRestEndPoint.DASHBOARD.GET_TICKER, params).subscribe(data => {
			if (data) {
				//$timeout(function () {
				this.selectedPosition.description = data.description;
				this.selectedPosition.lastPrice = data.last_price;
				if (data.description == "Unknown ticker") {
					if (!ticker.is_custom_ticker) {
						this.selectedPosition.description = '';
					}
					this.checkCustomTicker = false;
				} else {
					if (data.is_custom) {
						this.checkCustomTicker = false;
					} else {
						this.checkCustomTicker = true;
					}
				}
				//});
			} else {
				if (!ticker.is_custom_ticker) {
					this.selectedPosition.description = '';
				}
				this.checkCustomTicker = false;
			}
		});
	}


	generateQuantityorValue(flag) {
		let amount = '';
		if (this.selectedPosition.amount) {
			amount = this.selectedPosition.amount.toString();
			if (amount.match(/\$|,/g)) {
				amount = amount.replace(/\$|,/g, '');
			}
		}
		if (this.selectedPosition.lastPrice) {
			switch (flag) {
				case 'generateQuantity': this.selectedPosition.quantity = parseFloat(amount) / parseFloat(this.selectedPosition.lastPrice);
					break;
				case 'generateValue': this.selectedPosition.amount = parseFloat(this.selectedPosition.quantity) * parseFloat(this.selectedPosition.lastPrice);
					break;
				default: break;

			}
		}
	}

	get formControl() {
		return this.positionForm.controls;
	}

}
