<app-breadcrumb-options-docu-sign *ngIf="editPersonalInfo"></app-breadcrumb-options-docu-sign>
<div class="tab-pane active" role="tabpanel" id="step2">
    <div [ngClass]="{'container':editPersonalInfo}">
        <div class="panel panel-default">
            <div class="text-center pt-4" *ngIf="editPersonalInfo">
                <h2>Update Personal Information</h2>
            </div>
            <div class="panel-heading pt-2 pb-2" *ngIf="editPersonalInfo">
                <h4 class="nohr">Update your personal information</h4>
            </div>
            <div class="col-md-12 mt-5 additionalInfo" [ngClass]="{'pl-0': !editPersonalInfo}">
                <form [formGroup]="additionalInfoForm">
                    <ngb-tabset #tabset="ngbTabset" (tabChange)="onTabChange($event)" formArrayName="additionalInfoArr" *ngIf="editPersonalInfo">
                        <ngb-tab *ngFor="let tab of additionalInfo; let index = index ;" [formGroupName]="index">
                            <ng-template ngbTabTitle>
                                <div (click)="clickedInfo(index)">
                                    {{tab.user_attributes && tab.user_attributes.first_name?tab.user_attributes.first_name:'+'}}
                                </div>
                            </ng-template>
                            <ng-template ngbTabContent>
                                <div>
                                    <div class="pt-3">
                                        <div><strong>General Information</strong></div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12 form-css-label1 d-flex pl-0">
                                            <div class="col-md-4">
                                                <div>
                                                    <label class="font-weight-bold text-secondary">First
                                                        Name</label>
                                                </div>
                                                <div>
                                                    <fieldset class="form-group">
                                                        <input type="text" [(ngModel)]="tab.user_attributes.first_name"
                                                            formControlName="first_name" class="form-control">
                                                        <div class="color-red"
                                                            *ngIf="informationFormSubmitted && additionalInfoFormMessageArr.controls[index].get('first_name').errors?.required">
                                                            First name is mandatory.
                                                        </div>
                                                    </fieldset>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div>
                                                    <label class="font-weight-bold text-secondary">Last Name</label>
                                                </div>
                                                <div>
                                                    <fieldset class="form-group">
                                                        <input type="text" [(ngModel)]="tab.user_attributes.last_name"
                                                            formControlName="last_name" class="form-control">
                                                        <div class="color-red"
                                                            *ngIf="informationFormSubmitted && additionalInfoFormMessageArr.controls[index].get('last_name').errors?.required">
                                                            Last name is mandatory.
                                                        </div>
                                                    </fieldset>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 form-css-label1 d-flex pl-0">
                                            <div class="col-md-4">
                                                <div>
                                                    <label class="font-weight-bold text-secondary">Birthday
                                                    </label>
                                                </div>
                                                <div>
                                                    <fieldset class="form-group">
                                                        <input type="text" #dp="bsDatepicker" bsDatepicker
                                                            formControlName="birthday"
                                                            [(ngModel)]="tab.user_attributes.birthday"
                                                            class="form-control">
                                                        <div class="color-red"
                                                            *ngIf="informationFormSubmitted && additionalInfoFormMessageArr.controls[index].get('birthday').errors?.required">
                                                            Birthday is mandatory.
                                                        </div>
                                                    </fieldset>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div>
                                                    <label class="font-weight-bold text-secondary">Social Security
                                                        Number
                                                    </label>
                                                </div>
                                                <div>
                                                    <fieldset class="form-group">
                                                        <input type="text" formControlName="social"
                                                            [(ngModel)]="tab.user_attributes.social"
                                                            class="form-control">
                                                        <div class="color-red"
                                                            *ngIf="informationFormSubmitted && additionalInfoFormMessageArr.controls[index].get('social').errors?.pattern">
                                                            Enter in 999-99-9999 format.
                                                        </div>
                                                    </fieldset>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="pt-2">
                                        <div><strong>Contact Details</strong></div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12 form-css-label1 d-flex pl-0">
                                            <div class="col-md-4">
                                                <div>
                                                    <label class="font-weight-bold text-secondary">Email</label>
                                                </div>
                                                <div>
                                                    <fieldset class="form-group">
                                                        <input type="text" formControlName="email"
                                                            [(ngModel)]="tab.user_attributes.email"
                                                            class="form-control">
                                                    </fieldset>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div>
                                                    <label class="font-weight-bold text-secondary">Phone
                                                        Number</label>
                                                </div>
                                                <div>
                                                    <fieldset class="form-group">
                                                        <input type="text" formControlName="phone1"
                                                            [(ngModel)]="tab.user_attributes.primary_phone"
                                                            class="form-control" appPhoneMask>
                                                            <div class="color-red"
                                                            *ngIf="informationFormSubmitted && additionalInfoFormMessageArr.controls[index].get('phone1').errors?.invalidPhoneLength">
                                                                Phone number must be at least 10 characters long.
                                                            </div>
                                                    </fieldset>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 form-css-label1 d-flex pl-0">
                                            <div class="col-md-8">
                                                <div>
                                                    <label class="font-weight-bold text-secondary">Address</label>
                                                </div>
                                                <div>
                                                    <fieldset class="form-group">
                                                        <input type="text" formControlName="address1" id="typeahead-format" (keyup)="getAddressList($event)" [ngbTypeahead]="searchAddress" [resultFormatter]="formatAddress" [inputFormatter]="inputAddressFormat" autocomplete="off" [(ngModel)]="tab.user_attributes.address1"
                                                        (selectItem)="selectedAddress($event, index)" class="form-control">
                                                    </fieldset>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 form-css-label1 d-flex pl-0">
                                            <div class="col-md-3">
                                                <div>
                                                    <label class="font-weight-bold text-secondary">City</label>
                                                </div>
                                                <div>
                                                    <fieldset class="form-group">
                                                        <input type="text" formControlName="city"
                                                            [(ngModel)]="tab.user_attributes.city" class="form-control">
                                                    </fieldset>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <div class="p-1">
                                                    <label class="font-weight-bold text-secondary"></label>
                                                </div>
                                                <div class="pb-3 dropdown">
                                                    <button type="button"
                                                        class="btn btn-outline-secondary dropdown-toggle rounded-pill select-wrp-country open pl-3 pr-3 font-14"
                                                        data-toggle="dropdown" id="dropdownMenuButton"
                                                        data-toggle="dropdown" aria-haspopup="true"
                                                        aria-expanded="false">
                                                        {{tab.user_attributes.state?tab.user_attributes.state:"Select State"}}
                                                    </button>
                                                    <div class="dropdown-menu  select-list scroll-list"
                                                        aria-haspopup="true" aria-expanded="false">
                                                        <a *ngFor="let state of stateList"
                                                            class="dropdown-item cursor-pointer font-14 p-2"
                                                            (click)="tab.user_attributes.state = state.name">{{state.name}}</a>

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <div>
                                                    <label class="font-weight-bold text-secondary">Zip Code</label>
                                                </div>
                                                <div>
                                                    <fieldset class="form-group">
                                                        <input type="text" formControlName="zipcode"
                                                            [(ngModel)]="tab.user_attributes.zipcode"
                                                            class="form-control">
                                                    </fieldset>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="pt-2">
                                        <div><strong>Employment Details
                                            </strong></div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 form-css-label1 d-flex pl-0">

                                            <div class="col-md-3">
                                                <div>
                                                    <label class="font-weight-bold text-secondary">Status</label>
                                                </div>


                                                <div class="pb-3 dropdown">
                                                    <button type="button"
                                                        class="btn btn-outline-secondary dropdown-toggle rounded-pill select-wrp-country open pl-3 pr-3 font-14"
                                                        data-toggle="dropdown" id="dropdownMenuButton"
                                                        data-toggle="dropdown" aria-haspopup="true"
                                                        aria-expanded="false">
                                                        {{tab.user_attributes.employer_status?tab.user_attributes.employer_status:"Select Status"}}
                                                    </button>
                                                    <div class="dropdown-menu  select-list scroll-list employee-data-list"
                                                        aria-haspopup="true" aria-expanded="false">
                                                        <a *ngFor="let status of statusList"
                                                            class="dropdown-item cursor-pointer font-14 p-2"
                                                            (click)="tab.user_attributes.employer_status = status[0]">{{status[0]}}</a>
                                                    </div>

                                                </div>

                                            </div>
                                            <div class="col-md-5">
                                                <div>
                                                    <label class="font-weight-bold text-secondary">Employer
                                                        Name</label>
                                                </div>
                                                <div>
                                                    <fieldset class="form-group">
                                                        <input id="employerName_{{index}}" name="employerName_{{index}}"
                                                            formControlName="employer_name" type="text"
                                                            [(ngModel)]="tab.user_attributes.employer_name"
                                                            class="form-control">
                                                    </fieldset>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 form-css-label1 d-flex pl-0">

                                            <div class="col-md-3">
                                                <div>
                                                    <label class="font-weight-bold text-secondary">Occupation</label>
                                                </div>

                                                <div class="pb-3 dropdown">
                                                    <button type="button"
                                                        class="btn btn-outline-secondary dropdown-toggle rounded-pill select-wrp-country select-wrp-content open pl-3 pr-3 font-14"
                                                        data-toggle="dropdown" id="dropdownMenuButton"
                                                        data-toggle="dropdown" aria-haspopup="true"
                                                        aria-expanded="false">
                                                        {{tab.user_attributes.user_employer_occupation_name?tab.user_attributes.user_employer_occupation_name:"Select Occupation"}}
                                                    </button>
                                                    <div class="dropdown-menu  select-list scroll-list"
                                                        aria-haspopup="true" aria-expanded="false">
                                                        <a *ngFor="let occupation of empOccupationCodesArr"
                                                            class="dropdown-item cursor-pointer font-14 p-2"
                                                            (click)="tab.user_attributes.user_employer_occupation_name = occupation[1];
                                                            tab.user_attributes.user_employer_occupation_id = occupation[0]">{{occupation[1]}}</a>
                                                    </div>

                                                </div>

                                            </div>
                                            <div class="col-md-4">
                                                <div>
                                                    <label class="font-weight-bold text-secondary">Type of
                                                        Business
                                                    </label>
                                                </div>
                                                <div class="pb-3 dropdown">
                                                    <button type="button"
                                                        class="btn btn-outline-secondary dropdown-toggle rounded-pill select-wrp-country select-wrp-content open pl-3 pr-3 font-14"
                                                        data-toggle="dropdown" id="dropdownMenuButton"
                                                        data-toggle="dropdown" aria-haspopup="true"
                                                        aria-expanded="false">
                                                        {{tab.user_attributes.user_employer_industry_name?tab.user_attributes.user_employer_industry_name:"Select Industry"}}
                                                    </button>
                                                    <div class="dropdown-menu  select-list scroll-list"
                                                        aria-haspopup="true" aria-expanded="false">
                                                        <a *ngFor="let industry of empIndustryCodesArr"
                                                            class="dropdown-item cursor-pointer font-14 p-2" (click)="tab.user_attributes.user_employer_industry_name= industry[1];
                                                                tab.user_attributes.user_employer_industry_id = industry[0];
                                                                ">{{industry[1]}}</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="row pt-2">
                                        <div class="col-md-12 form-css-label1 d-flex pl-0">
                                            <div class="col-md-8">
                                                <div>
                                                    <label class="font-weight-bold text-secondary">Employer
                                                        Address</label>
                                                </div>
                                                <div>
                                                    <fieldset class="form-group">
                                                        <input type="text" formControlName="employer_address" id="typeahead-format" (keyup)="getAddressList($event)" [(ngModel)]="tab.user_attributes.employer_address"
                                                             class="form-control" [ngbTypeahead]="searchAddress" [resultFormatter]="formatAddress" [inputFormatter]="inputAddressFormat" autocomplete="off"
                                                            (selectItem)="selectedEmployerAddress($event, index)">
                                                    </fieldset>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 form-css-label1 d-flex pl-0">
                                            <div class="col-md-3">
                                                <div>
                                                    <label class="font-weight-bold text-secondary">City</label>
                                                </div>
                                                <div>
                                                    <fieldset class="form-group">
                                                        <input type="text" formControlName="employer_city"
                                                            [(ngModel)]="tab.user_attributes.employer_city"
                                                            class="form-control">
                                                    </fieldset>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <div class="p-1">
                                                    <label class="font-weight-bold text-secondary"></label>
                                                </div>

                                                <div class="pb-3 dropdown">
                                                    <button type="button"
                                                        class="btn btn-outline-secondary dropdown-toggle rounded-pill select-wrp-country open pl-3 pr-3 font-14"
                                                        data-toggle="dropdown" id="dropdownMenuButton"
                                                        data-toggle="dropdown" aria-haspopup="true"
                                                        aria-expanded="false">
                                                        {{tab.user_attributes.employer_state?tab.user_attributes.employer_state:"Select State"}}
                                                    </button>
                                                    <div class="dropdown-menu  select-list scroll-list"
                                                        aria-haspopup="true" aria-expanded="false">
                                                        <a *ngFor="let state of stateList"
                                                            class="dropdown-item cursor-pointer font-14 p-2"
                                                            (click)="tab.user_attributes.employer_state = state.name">{{state.name}}</a>

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <div>
                                                    <label class="font-weight-bold text-secondary">Zip Code</label>
                                                </div>
                                                <div>
                                                    <fieldset class="form-group">
                                                        <input type="text" formControlName="employer_zipcode"
                                                            [(ngModel)]="tab.user_attributes.employer_zipcode"
                                                            class="form-control">
                                                    </fieldset>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </ngb-tab>
                    </ngb-tabset>
                    <ngb-tabset [destroyOnHide]="false" *ngIf="!editPersonalInfo">
                        <ng-container *ngFor="let tab of additionalInfo; let index = i ;">
                            <ngb-tab  [title]="tab.user_attributes.first_name" *ngIf="tab.user_attributes.category != 'Deceased'">
                            <ng-template ngbTabContent>
                                <div>
                                    <div class="pt-3">
                                        <div><strong>General Information</strong></div>
                                    </div>
                                    <div class="pt-4">
                                        <a class="advLink sml-txt color-purple cursor-pointer"
                                            (click)="openContactUsModal('profile')">Contact My Advisor to
                                            Edit
                                        </a>

                                    </div>
                                    <div class="row pt-4">
                                        <div class="col-md-4">
                                            <div>
                                                <label class="font-weight-bold text-secondary">Full
                                                    Name</label>
                                            </div>
                                            <div>
                                                <label class="font-normal">
                                                    {{tab.user_attributes.displayFullName}}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div>
                                                <label class="font-weight-bold text-secondary">Birthday</label>
                                            </div>
                                            <div>
                                                <label class="font-normal">
                                                    {{tab.user_attributes.birthday | date :'MMMM d, yyyy' }}
                                                </label>


                                            </div>

                                        </div>
                                    </div>
                                    <div class="pt-4">
                                        <div><strong>Contact Details</strong></div>
                                    </div>

                                    <div class="row pt-4">
                                        <div class="col-md-4">
                                            <div>
                                                <label class="font-weight-bold text-secondary">Email</label>
                                            </div>
                                            <div>
                                                {{tab.user_attributes.email}}
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div>
                                                <label class="font-weight-bold text-secondary">Phone
                                                    Number</label>
                                            </div>
                                            <div>
                                                <label class="font-normal"
                                                    *ngIf="tab.user_attributes.primary_phone">{{tab.user_attributes.primary_phone_type}}:&nbsp;
                                                </label>
                                                <label class="font-normal"
                                                    ng-bind="tab.user_attributes.phone1">{{tab.user_attributes.primary_phone | phoneFormatter}}</label>
                                            </div>

                                        </div>
                                        <div class="col-md-4">
                                            <div>
                                                <label class="font-weight-bold text-secondary">Address</label>
                                            </div>
                                            <div>
                                                <label class="font-normal"
                                                    *ngIf="tab.user_attributes.address1">{{tab.user_attributes.address1}}
                                                </label><br>
                                                <label class="font-normal"
                                                    *ngIf="tab.user_attributes.address2">{{tab.user_attributes.address2}}
                                                </label><br>
                                                <label class="font-normal"
                                                    *ngIf="tab.user_attributes.city">{{tab.user_attributes.city}}&nbsp;
                                                </label>
                                                <label class="font-normal"
                                                    *ngIf="tab.user_attributes.state">{{tab.user_attributes.state}}&nbsp;</label>
                                                <label class="font-normal"
                                                    *ngIf="tab.user_attributes.zipcode">{{tab.user_attributes.zipcode}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="pt-4">
                                        <div><strong>Employment Details
                                            </strong></div>
                                    </div>
                                    <div class="row pt-4">
                                        <div class="col-md-4">
                                            <div>
                                                <label class="font-weight-bold text-secondary">Status</label>
                                            </div>
                                            <div>
                                                <label class="font-normal">
                                                    {{tab.user_attributes.employer_status}}

                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div>
                                                <label class="font-weight-bold text-secondary">Employer
                                                    Name</label>
                                            </div>
                                            <div>
                                                <label class="font-normal">
                                                    {{ tab.user_attributes.employer_name}}
                                                </label>
                                            </div>

                                        </div>
                                        <div class="col-md-4">
                                            <div>
                                                <label class="font-weight-bold text-secondary">Occupation</label>
                                            </div>
                                            <div>
                                                {{tab.user_attributes.user_employer_occupation_name}}
                                            </div>

                                        </div>
                                    </div>
                                    <div class="row pt-4">
                                        <div class="col-md-4">
                                            <div>
                                                <label class="font-weight-bold text-secondary">Type of
                                                    Business
                                                </label>
                                            </div>
                                            <div>
                                                <label class="font-normal">
                                                    {{tab.user_attributes.user_employer_industry_name}}

                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div>
                                                <label class="font-weight-bold text-secondary">Employer
                                                    Address</label>
                                            </div>
                                            <div>
                                                <label class="font-normal"
                                                    *ngIf="tab.user_attributes.employer_address">{{tab.user_attributes.employer_address}}</label><br
                                                    *ngIf="tab.user_attributes.employer_address">
                                                <label class="font-normal"
                                                    *ngIf="tab.user_attributes.employer_address2">{{tab.user_attributes.employer_address2}}</label><br
                                                    *ngIf="tab.user_attributes.employer_address2">
                                                <label class="font-normal"
                                                    *ngIf="tab.user_attributes.employer_city">{{tab.user_attributes.employer_city}} &nbsp;</label>
                                                <label class="font-normal"
                                                    *ngIf="tab.user_attributes.employer_state">{{tab.user_attributes.employer_state}}&nbsp;
                                                </label>
                                                <label class="font-normal"
                                                    *ngIf="tab.user_attributes.employer_zipcode">{{tab.user_attributes.employer_zipcode}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </ngb-tab>
                        </ng-container>
                    </ngb-tabset>
                </form>
                <div *ngIf="editPersonalInfo">
                    <app-buttonbar (clickedprev)="gotoPreviousState()" (clickednext)="validateAddress('true')">
                    </app-buttonbar>
                </div>
            </div>

        </div>
    </div>
</div>