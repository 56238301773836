import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppRestEndPoint } from 'src/app/app-restEndPoint';
import { ApiService } from 'src/app/services/api.service';

@Component({
	selector: 'app-link-external-account-modal',
	templateUrl: './link-external-account-modal.component.html',
	styleUrls: ['./link-external-account-modal.component.scss']
})
export class LinkExternalAccountModalComponent implements OnInit {
	suggestedProviders: any = {};
	constructor(
		public activeModalService: NgbActiveModal,
		private apiService: ApiService
	) { }

	ngOnInit(): void {
		this.loadSuggestedProviders();
	}

	loadSuggestedProviders(){
		this.apiService.getCall(AppRestEndPoint.DASHBOARD.SUGGESTED_PROVIDERS, '').subscribe(data => {
			this.suggestedProviders = data.suggested_providers;
		});
	}
	closeModal() {
		this.activeModalService.close({ 'success': 'closeModal', 'reload': true });
	}

	openExternal(type) {
		this.activeModalService.close({ 'type': type });
	}

}
