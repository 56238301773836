import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppRestEndPoint } from 'src/app/app-restEndPoint';
import { ToastService } from 'src/app/components/toaster/toast-service';
import { ApiService } from 'src/app/services/api.service';

@Component({
	selector: 'app-remove-account-modal',
	templateUrl: './remove-account-modal.component.html',
	styleUrls: ['./remove-account-modal.component.scss']
})
export class RemoveAccountModalComponent implements OnInit {

	@Input() removalAccount;
	@Input() selectedRow;
	@Input() removeInstitution;
	showRemoveAccLoader: any;

	constructor(private apiService: ApiService,
		public activeModal: NgbActiveModal,
		private toastService: ToastService) { }

	ngOnInit(): void {
	}

	removeAccount() {
		this.showRemoveAccLoader = true;
		if (this.selectedRow.isManual) {
			const removeAccUrl = AppRestEndPoint.DASHBOARD.REMOVE_ACCOUNT + '/' + this.selectedRow.selectedId;
			this.apiService.deleteCall(removeAccUrl).subscribe(data => {
				if (data) {
					this.showRemoveAccLoader = false;					
					this.toastService.show(data.message, {
						classname: 'bg-success text-light', delay: 3000
					});
					this.closeModal();
				}
			},
				(err) => {
					throw err;
				});
		} else {
			const removeAccUrl = AppRestEndPoint.DASHBOARD.REMOVE_ALL_ACCOUNT + '.json?bank_id=' + this.selectedRow.bankId;
			this.apiService.deleteCall(removeAccUrl, this.selectedRow.bankId).subscribe(data => {
				if (data) {
					this.showRemoveAccLoader = false;					
					this.toastService.show(data.message, {
						classname: 'bg-success text-light', delay: 3000
					});
					this.closeModal();
				}
			},
				(err) => {
					throw err;
				});
		}
	}

	closeModal() {
		this.activeModal.close();
	}

}
