import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Component({
	selector: 'app-task-notes-modal',
	templateUrl: './task-notes-modal.component.html',
	styleUrls: ['./task-notes-modal.component.scss']
})
export class TaskNotesModalComponent implements OnInit {

	taskNoteData: any = {};
	taskNoteForm: FormGroup;
	taskNoteFormSubmitted: boolean = false;
	currentUser:any;
	currentAdvisor:any;
	updatedBy:any = {};
	constructor(
		private activeModal: NgbActiveModal, public formBuilder: FormBuilder
	) {
		this.taskNoteForm = this.formBuilder.group({
			subject: ['', Validators.required],
			notes: [''],
		});
		this.currentUser = JSON.parse(sessionStorage.getItem('current_user'));
		this.currentAdvisor = JSON.parse(sessionStorage.getItem('adminUsers'));

	}

	ngOnInit(): void {
		if(this.taskNoteData.user && this.taskNoteData.user.id == null){
			this.taskNoteForm.controls['subject'].disable();
			this.taskNoteForm.controls['notes'].disable();
			

		}
	}

	closeModal(data: any = '') {
		this.activeModal.close(data);
	}

	get formControl() {
		return this.taskNoteForm.controls;
	}

	submitNote(data:any = {}) {
		data.created_by_admin_user = {};
		data.updated_by_admin_user = {};
		data.user = {};
		data.created_by_admin_user = this.currentAdvisor.advisor;
		data.updated_by_admin_user = this.currentAdvisor.advisor;
		data.user = this.currentUser;

		data.created_at = moment();
		data.updated_at = moment();
		this.taskNoteFormSubmitted = true;
		if (this.taskNoteForm.valid) {
			this.activeModal.close(data);
		}
	}

}

