<div class="modal-header">
    <div class="row w-100">
        <div class="col-md-11">
            <h5 class="modal-title">{{positionModalHeading}}</h5>
        </div>
        <div class="col-md-1 pr-0">
            <button type="button" class="close float-right text-white pr-0" aria-label="Close" (click)="cancel()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
</div>

<div class="modal-body">
    <form  [formGroup]="positionForm" novalidate>
        <div class="col-md-12 col-sm-12 col-xs-12 pl-0 pr-0">
            <div class="col-md-5 col-sm-3 col-xs-3 inline-grid pr-0">
                <label class="col-md-12 col-sm-12 pl-0 pb-1">
                    Symbol
                </label>
                <div class="col-md-12 col-sm-12  pl-0 pr-0">
                    <input type="text" class="form-control" name="symbol" formControlName="symbol" placeholder="Symbol"
                        [(ngModel)]="selectedPosition.symbol" (blur)="getTicker(selectedPosition)" />
                </div>
                <div *ngIf="positionFormSubmitted" class="text-danger" role="alert">
                    <div *ngIf="formControl.symbol.errors?.required">Please enter the Symbol.
                    </div>
                    <div *ngIf="formControl.symbol.errors?.invalidSymbol">
                        Please enter the Valid Symbol.
                    </div> 
                </div>
            </div>

            <div class="col-md-7 col-sm-3 col-xs-3 inline-grid ">
                <label class="col-md-3 col-sm-3 pl-0">
                    Description
                </label>
                <div class="col-md-12 col-sm-3 pl-0 pr-0">
                    <input type="text" class="form-control" name="name" formControlName="description"
                        placeholder="Description" [(ngModel)]="selectedPosition.description"
                        ng-disabled="checkCustomTicker" />
                </div>
            </div>
        </div>
        <div class="clear"></div>
        <div class="col-md-12 col-sm-12 col-xs-12 pl-0 pr-0 mt-3">
            <div class="col-md-5 col-sm-12 col-xs-12 inline-grid pr-0">
                <label class="col-md-3 col-sm-3 pl-0">
                    Value
                </label>
                <div class="col-md-12 col-sm-3 pl-0 pr-0">
                    <input type="number" class="form-control" name="valueName" formControlName="valueName"
                        (blur)="generateQuantityorValue('generateQuantity')" placeholder="Value"
                        [(ngModel)]="selectedPosition.amount" />
                </div>
                <div *ngIf="positionFormSubmitted" class="text-danger" role="alert">
                    <div *ngIf="formControl.valueName.errors?.required">Please enter the Value.
                    </div>
                </div>
            </div>
            <div class="col-md-7 col-sm-12 col-xs-12 inline-grid pr-0">
                <label class="col-md-3 col-sm-3 pl-0">
                    Quantity
                </label>
                <div class="col-md-12 col-sm-3 pl-0">
                    <input (disabled)="disableQuantity" type="number" class="form-control" name="quantity"
                        formControlName="quantity" (blur)="generateQuantityorValue('generateValue')"
                        placeholder="Quantity" [(ngModel)]="selectedPosition.quantity" />
                </div>
                <div *ngIf="positionFormSubmitted" class="text-danger" role="alert">
                    <div *ngIf="formControl.quantity.errors?.required">Please enter the Quantity.
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-link" (click)="cancel()">
        CANCEL
    </button>
    <button type="button" class="btn btn-primary btn-sm rounded-pill" (click)="savePosition()">
        Save
    </button>
</div>
