<app-bread-crumb-options></app-bread-crumb-options>
<div class="container">
    <div class="row margin-top-10-pct">
        <div class="m-auto col-md-6 col-sm-6 col-xs-12 text-center">
            <button class="btn btn-primary rounded-pill btn-lg-custom w-75 font-15" (click)="saveInfoType('1')">
                I am an individual
            </button>
            <button class="btn btn-grn rounded-pill btn-lg-custom w-75 mt-4 font-15" (click)="saveInfoType('2')">
                I am signing up for an institution
            </button>
        </div>
    </div>
</div>