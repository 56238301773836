import { CommonModule, CurrencyPipe, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { BreadCrumbOptionsComponent } from '../components/bread-crumb-options/bread-crumb-options.component';
import { HeaderComponent } from '../components/header/header.component';
import { ToastsContainer } from '../components/toaster/toasts-container.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalComponent } from '../components/modal/modal.component';
import { NavbarComponent } from '../components/navbar/navbar.component';
import { ContactUsModalComponent } from '../components/header/contact-us-modal/contact-us-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmationDialogComponent } from '../components/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogService } from '../services/confirmation-dialog.service';
import { HelpIconComponent } from '../components/help-icon/help-icon.component';
import { ButtonbarComponent } from '../components/buttonbar/buttonbar.component';
import { PhoneMaskDirective } from '../directives/phone-mask/phone-mask.directive';
import { PhoneFormatterPipe } from '../directives/phone-formatter/phone-formatter.pipe';
import { BreadcrumbOptionsDocuSignComponent } from '../components/breadcrumb-options-docu-sign/breadcrumb-options-docu-sign.component';
import { FormatterDirective } from '../directives/formatter/formatter.directive';
import { AdditionalInformationComponent } from '../docu-sign/additional-information/additional-information.component';
import { ButtonDisableDirective } from '../directives/button-disable/button-disable.directive';
import { AppTogglePasswordDirective } from '../directives/toggle-password/app-toggle-password.directive';

@NgModule({
    declarations: [
        HeaderComponent,
        ToastsContainer,
        BreadCrumbOptionsComponent,
        BreadcrumbOptionsDocuSignComponent,
        AdditionalInformationComponent,
        ModalComponent,
        NavbarComponent,
        ContactUsModalComponent,
        ConfirmationDialogComponent,
        HelpIconComponent,
        ButtonbarComponent,
        PhoneFormatterPipe,
        PhoneMaskDirective,
        FormatterDirective,
        ButtonDisableDirective,
        AppTogglePasswordDirective
        
    ],
    exports: [
        HeaderComponent,
        ToastsContainer,
        BreadCrumbOptionsComponent,
        BreadcrumbOptionsDocuSignComponent,
        AdditionalInformationComponent,
        ModalComponent,
        NavbarComponent,
        HelpIconComponent,
        ButtonbarComponent,
        PhoneFormatterPipe,
        PhoneMaskDirective,
        FormatterDirective,
        ButtonDisableDirective,
        AppTogglePasswordDirective
    ],
    imports: [
        CommonModule,
        NgbModule,
        FormsModule,
        BsDatepickerModule.forRoot(),
        ReactiveFormsModule,
        ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'}),

    ],
    providers:[ConfirmationDialogService,CurrencyPipe, DecimalPipe, PhoneFormatterPipe]
})
export class SharedModule { }