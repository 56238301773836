import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppRestEndPoint } from 'src/app/app-restEndPoint';
import { ApiService } from 'src/app/services/api.service';
import * as _ from 'lodash';
import { NavigationService } from 'src/app/services/navigation.service';
import { CommonService } from 'src/app/services/common.service';
import { UtilityService } from 'src/app/services/utility.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
	selector: 'app-information-submitted',
	templateUrl: './information-submitted.component.html',
	styleUrls: ['./information-submitted.component.scss']
})
export class InformationSubmittedComponent implements OnInit {

	current_user: any;
	selectedType: any;
	rtq_id: any;
	loadRtqId: any;
	household_id: any;
	dashboardSelectedHousehold: any;
	current_household_id: any;
	redirectDashboard: any;
	colorRange:any = ["#44D1FF", "#00BFFF", "#00A1DB", "#0085B5", "#00729B", "#5fc4e9", "#3ba0c5", "#49cdc7", "#67e6da"];
	colorGreen:any = "#62E779";
	totalWeight = 0;
	totalInvest = 0;
	allocationList: any = [];
	allocationListGraphData: any = {};
	rtqEmail: any = null;

	constructor(
		private activatedRoute: ActivatedRoute,
		private apiService: ApiService,
		public router: Router,
		private navigationService: NavigationService,
		private commonService: CommonService,
		public utilityService: UtilityService,
		private cookieService: CookieService
		) {
	}

	ngOnInit(): void {
		this.current_user = JSON.parse(sessionStorage.getItem('current_user'));
		this.selectedType = this.activatedRoute.snapshot.paramMap.get('type');
		this.household_id = this.current_user.selected_household_id;
		this.rtq_id = JSON.parse(sessionStorage.getItem('rtqId'));
		this.dashboardSelectedHousehold = sessionStorage.getItem('current_selected_household');
		this.loadRtqId = sessionStorage.getItem('login_rtq_id');
		if (this.dashboardSelectedHousehold && this.dashboardSelectedHousehold != null) {
			this.current_household_id = this.dashboardSelectedHousehold;
		} else {
			this.current_household_id = this.household_id;
		}
		this.redirectDashboard = sessionStorage.getItem('signUpComplete');
		this.rtqEmail = sessionStorage.getItem("rtq_redirect");
		this.loadQuestions();
	}

	loadQuestions() {
		let url = AppRestEndPoint.RISK_TOLERANCE.QUESTION_LIST, param = "&household_id="+this.current_household_id+"&rtq_id="+this.loadRtqId;
		this.apiService.getCall(url, param).subscribe(response => {
            if (response) {
                if (response.rtq && response.rtq.id) {
                    this.rtq_id = response.rtq.id;
                }
                if (response.total_questions_answered == 0) {
                    this.gotoPreviousState();
                } else {
                    this.loadAllocationData();
                }
            }
        });
    }

	loadAllocationData() {
		let url = AppRestEndPoint.RISK_TOLERANCE.GET_ALLOCATION_DATA, param = "&household_id="+this.current_household_id+"&rtq_id="+this.rtq_id;
		this.apiService.getCall(url, param).subscribe(response => {
            this.allocationList = [];
            if (response && response.allocation_data) {
                var dataObj = {};
                dataObj = response.allocation_data;
                this.allocationList = _.values(dataObj);
                var index = _.findIndex(this.allocationList, { label: "Bonds" });
                this.allocationList.push(this.allocationList.splice(index, 1)[0]);
                this.colorRange[this.allocationList.length - 1] = this.colorGreen;
				this.buildFusionChartDonutData();
                this.calculateTotal();
            }
        });
    }

	buildFusionChartDonutData() {
		let allocationData = [];
		this.allocationList.forEach(element => {
			allocationData.push({
				label: element.label,
				value: element.graph_value,
				displayValue: Math.round(element.invest)
			})
		});

		allocationData

		let bonds = _.find(this.allocationList, function (val) {
			return val.label == "Bonds/Cash";
		});
		let stocks = _.filter(this.allocationList, function (val) {
			return val.label != "Bonds/Cash";
		});
		let stockSum: any = 0;
		for (let i = 0; i < stocks.length; i++) {
			stockSum = stockSum + stocks[i].weight;
		}

		stockSum = Math.round(stockSum) + '%';
		let colorRange = ["#44D1FF", "#00BFFF", "#00A1DB", "#0085B5", "#00729B", "#5fc4e9", "#3ba0c5", "#49cdc7", "#67e6da"];
		let colorGreen = "#62E779";
		colorRange[allocationData.length - 1] = colorGreen;
		const data = {
			chart: {
				caption: "",
				captionPadding: 0,
				subcaption: "",
				chartLeftMargin: 0,
				bgColor: "#F6F7FB",
				showpercentvalues: "1",
				defaultcenterlabel: stockSum + '<br/> STOCKS' + '<br/><br/>' + Math.round(bonds.weight) + '%' + '<br/>BONDS',
				aligncaptionwithcanvas: "0",
				captionpadding: "0",
				canvasPadding: "0",
				showLabels: 0,
				showValues: 0,
				width: "100%",
				height: "100%",
				pieRadius: 100,
				enableSmartLabels: 0,
				showLegend: 0,
				doughnutRadius: 75,
				palettecolors: colorRange,
				decimals: "2",
				plottooltext:
					"<b>$label</b><br/><b>$percentValue</b><br/><b>$displayValue</b>",
				centerlabel: stockSum + '<br/> STOCKS' + '<br/><br/>' + Math.round(bonds.weight) + '%' + '<br/>BONDS',
				theme: "fusion"
			},
			data: allocationData
		};


		this.allocationListGraphData = data;
	}

	calculateTotal() {
        var len = this.allocationList.length;
        for (var j = 0; j < len; j++) {
            this.totalWeight = this.totalWeight + this.allocationList[j].weight;
            this.totalInvest = this.totalInvest + parseFloat(this.allocationList[j].invest);
        }
    }

	roundTo(value) {
        return parseFloat(value).toFixed(1);
    }

	gotoNextState() {
		this.sendMixPanelEvent({'event': 'Client Onboarding - Risk Tolerance - Keep Going'});
        // if (this.redirectDashboard == "true") {
        //     this.router.navigate(['/dashboard']);  
        // } else {
            this.saveState();
            var reqObj = { "info_type": this.selectedType, "state": "information-submitted" };
            this.navigationService.getNextPage(reqObj);
        //}
    }

	gotoPreviousState() {
        //$scope.saveState(user_id, access_token);
        let reqObj = { "info_type": this.selectedType, "state": "information-submitted" };
        this.navigationService.getPreviousPage(reqObj);
    }

	saveState() {
        let reqObj = {
			'user': { "gettingstarted_step": '{"info_type" :"' + this.selectedType + '","state" :"' + "information" + '"}' }
		};
        let url = AppRestEndPoint.LOGIN.SAVE_STATE+'/'+this.current_user.id;
		this.apiService.updateCall(url, reqObj, '').subscribe(data => {
		});
    }

	logOut() {
		this.sendMixPanelEvent({'event': 'Client Onboarding - Risk Tolerance - All done for now'});
		this.commonService.logOut();
		this.cookieService.deleteAll();
	}

	sendMixPanelEvent(eventObj){
        this.utilityService.gtmPush(eventObj);
    }

}
