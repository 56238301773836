import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Domains } from 'src/constants/Domains';
import { environment } from 'src/environments/environment';
import { CommonService } from './services/common.service';
import { EventEmitterService } from './services/event-emitter.service';
import { LoginService } from './services/login.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { UtilityService } from './services/utility.service';



@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	title = 'owlinvest-client-ux-new';
	config: any;
	env: any;
	domains: any;
	hideDashguideBanner: any;
	isMobile: any;
	isDesktopDevice: any;
	currentSiteUrl: any;

	constructor(
		private loginService: LoginService,
		private commonService: CommonService,
		private eventEmitter: EventEmitterService,
		public route: ActivatedRoute,
		public router: Router,
		public utilService: UtilityService,
		private deviceService: DeviceDetectorService) {

		this.env = environment;
		this.getDomainDetails();

		let url = window.location.href;
		if (url.indexOf("logon") > -1) {
			let data = url.split('data=')[1];
			this.router.navigateByUrl("logon?data=" + data);
		}else if (url.indexOf("#!") > -1) {
			let newUrl = url.replace("#!/", "");
			window.location.href = newUrl;
		}
		this.currentSiteUrl = url.split('/')[3];
		this.isMobile = this.deviceService.isMobile();
		this.isDesktopDevice = this.deviceService.isDesktop();
		setTimeout(() => {
			this.hideDashguideBanner = true;
		}, 20000);

	}


	getDomainDetails() {
		let domainObj = {};
		let apiUrl = this.commonService.getDomainDetails().api_url;
		// load api and css from config file or domain server
		if (!apiUrl) {
			// disable getting-started if config setting
			domainObj['disable_getting_started'] = this.env.disable_getting_started;

			// this is for instances that want to lookup configs based on domain name from the api-server
			if (this.env.domain_server) {
				let docref = encodeURI(document.location.href);
				this.loginService.getCall(this.env.domain_server + '?location=' + docref, '').subscribe(response => {
					if (response.data) {
						domainObj['domain_css'] = response.data.domain_css;
						domainObj['firm_id'] = response.data.firm_id;
						domainObj['api_url'] = response.data.api_url;
						domainObj['domainLogo'] = response.data.logo;
						this.getFirmDetails(domainObj);
					}
				},
					(err) => {
						throw err;
					});
			}
			// this is for non-static configs we are going to load from the api-server durring login
			// sometimes these are hinted through using /login/firm/<firm-code> type urls
			else if (this.env.api_server) {
				let api_url = this.env.api_server;
				domainObj['api_url'] = api_url;
				this.loginService.getCall(api_url + '/domains?target=client', '').subscribe(response => {
					if (response.data) {
						domainObj['firm_name'] = response.data.name;
						domainObj['domain_css'] = response.data.domain_css;
						domainObj['firm_id'] = response.data.firm_id;
						domainObj['domainLogo'] = response.data.logo;
						this.getFirmDetails(domainObj);

					} else {
						alert(response.error);
					}


				},
					(err) => {
						throw err;
					});

			}
			// this is for static configs based on domain name
			else {
				let domain_val = document.location.host;
				if (Domains[domain_val]) {
					let api_url = Domains[domain_val].api_url;
					domainObj['api_url'] = api_url;
					this.loginService.getCall(api_url + '/domains?target=client&firm_id=' + Domains[domain_val].firm_id, '').subscribe(response => {
						if (response.data) {
							domainObj['firm_name'] = response.data.name;
							domainObj['domain_css'] = response.data.domain_css;
							domainObj['firm_id'] = response.data.firm_id;
							domainObj['domainLogo'] = response.data.logo;
							domainObj['formal_name'] = response.data.formal_name;
							domainObj['firm_website'] = response.data.firm_website;
							domainObj['domain_icon'] = response.data.icon;
							this.getFirmDetails(domainObj);

						}
					},
						(err) => {
							throw err;
						});
				}
			}

		}

	}

	getFirmDetails(domainObj) {
		this.loginService.getCall(domainObj.api_url + '/apiclient/v1/firms/' + domainObj.firm_id, '').subscribe(response => {
			if (response.firm) {
				domainObj['firm'] = response.firm;
				this.commonService.setDomainDetails(domainObj);
				this.eventEmitter.broadcast("Domain", domainObj);
				if (domainObj.domain_icon) {
					// add icon to title
					const iconlink = document.createElement('link');
					iconlink.rel = "icon";
					iconlink.type = "image/x-icon";
					iconlink.href = domainObj.domain_icon;
					document.head.appendChild(iconlink);
					// add css file dynamically
					const csslink = document.createElement('link');
					csslink.rel = "stylesheet";
					csslink.href = domainObj.domain_css;
					document.head.appendChild(csslink);
				}
			}
		},
			(err) => {
				throw err;
			});
	}


	closeBanner() {
		(document.querySelector('.built-app') as HTMLElement).style.display = 'none';
	}

	changeOfRoutes() {
		if (this.route.snapshot.paramMap.get('id')) {
			this.utilService.gtmPush({
				'event': 'route: ' + this.router.url,
				'eventId': this.route.snapshot.paramMap.get('id')
			});
		} else {
			this.utilService.gtmPush({
				'event': 'route: ' + this.router.url
			});
		}
	}
}
