<div class="container px-0 wizard d-none d-sm-block" *ngIf="rtqEmail == null">
    <ul class="nav nav-tabs">
        <li class="nav-item">
            <a class="nav-link cursor-pointer">
                <span class="round-tab" [ngClass]="{'tabActive':currentIndex == 0, 'tabDone':currentIndex > 0}" (click)="redirectToPage(0)">
                    <i class="fa fa-user-o my-auto"></i>
                </span>
                <span class="arrow"></span>
                <span class="wiz-txt">Account Details</span>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link cursor-pointer">
                <span class="round-tab" [ngClass]="{'tabActive':currentIndex == 1, 'tabDone':currentIndex > 1}" (click)="redirectToPage(1)">
                    <i class="fa fa-file-text-o"></i>
                </span>
                <span class="arrow"></span>
                <span class="wiz-txt">Basic Information</span>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link cursor-pointer">
                <span class="round-tab" [ngClass]="{'tabActive':currentIndex == 2, 'tabDone':currentIndex > 2}" (click)="redirectToPage(2)">
                    <i class="fa fa-clock-o"></i>
                </span>
                <span class="arrow"></span>
                <span class="wiz-txt">Risk Tolerance</span>
            </a>
        </li>
        <li class="nav-item" *ngIf="infoType == 'financial-info'">
            <a class="nav-link cursor-pointer">
                <span class="round-tab" [ngClass]="{'tabActive':currentIndex == 3, 'tabDone':currentIndex > 3}" (click)="redirectToPage(3)">
                    <i class="fa fa-briefcase"></i>
                </span>
                <span class="arrow"></span>
                <span class="wiz-txt">Assets & Liabilities</span>
            </a>
        </li>
        <li class="nav-item" *ngIf="infoType == 'financial-info'">
            <a class="nav-link cursor-pointer">
                <span class="round-tab" [ngClass]="{'tabActive':currentIndex == 4, 'tabDone':currentIndex > 4}" (click)="redirectToPage(4)">
                    <i class="fa fa-money"></i>
                </span>
                <span class="arrow"></span>
                <span class="wiz-txt">Cash Flow</span>
            </a>
        </li>
        <li class="nav-item" *ngIf="infoType == 'basic-flow'">
            <a class="nav-link cursor-pointer">
                <span class="round-tab" [ngClass]="{'tabActive':currentIndex == 3}" (click)="redirectToPage(5)">
                    <i class="fa fa-money"></i>
                </span>
                <span class="arrow"></span>
                <span class="wiz-txt">Preliminary Analysis</span>
            </a>
        </li>
    </ul>
</div>