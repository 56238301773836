<app-breadcrumb-options-docu-sign></app-breadcrumb-options-docu-sign>
<div class="tab-pane active" role="tabpanel" id="step2">
    <div class="container">
        <div class="panel panel-default">
            <div class="text-center pt-4">
                <h2>Upload Documents</h2>
            </div>
            <div class="panel-heading pt-2 pb-2">
                <h4 *ngIf="documentData[0]" class="nohr">Upload most recent brokerage statements and 401K investment
                    options</h4>
            </div>
            <div *ngIf="documentData.length==0" class="ml-3 mr-3 text-center">
                <div><img src="./assets/images/categories_1x.png" /></div>
                <div class="sub-txt-big mb-4 w-75">
                    To get started, we need to transfer your accounts. By uploading your latest monthly statements, we
                    can complete the transfer documents and send them to you for signature.
                </div>
                <div>
                    <app-buttonbar (clickedupload)="openUploadModal()"  hidebackbtn = "true"
                        (clickednext)="gotoNextState()" showuploadbtn="true"></app-buttonbar>
                </div>
            </div>
            <div *ngIf="documentData[0]" class="pt-2">
                <div class="docusign-text-div border-bottom" *ngFor="let document of documentData|keyvalue">
                    <div class="d-flex pt-2">
                        <div class="col-md-1"><i class="fa fa-file-text-o" aria-hidden="true"></i></div>
                        <div class="col-md-9 text-left">{{document.value.description}}</div>

                        <div class="col-md-2 text-right"><i class="fa fa-trash-o cursor-pointer"
                                (click)="openDeleteModal(document.value);"></i></div>
                    </div>
                </div>
                <div>
                    <app-buttonbar (clickedupload)="openUploadModal()" (clickedprev)="gotoPreviousState()"
                        (clickednext)="gotoNextState()" showuploadbtn="true"></app-buttonbar>
                </div>

            </div>
        </div>
    </div>
</div>