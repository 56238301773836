import { Component, OnInit, TemplateRef, ContentChild, ViewChild, ElementRef } from '@angular/core';
import * as _ from 'lodash';
import { ApiService } from 'src/app/services/api.service';
import { AppRestEndPoint } from 'src/app/app-restEndPoint';
import { isUndefined } from 'util';
import { PusherService } from 'src/app/services/pusher.service';
import { LinkAccountModalComponent } from '../link-account-modal/link-account-modal.component';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EventEmitterService } from 'src/app/services/event-emitter.service';
import { ContactUsModalComponent } from 'src/app/components/header/contact-us-modal/contact-us-modal.component';
import { AddAccountModalComponent } from '../add-account-modal/add-account-modal.component';
import { AddPositionModalComponent } from '../add-position-modal/add-position-modal.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastService } from 'src/app/components/toaster/toast-service';
import { ConfirmationDialogService } from 'src/app/services/confirmation-dialog.service';
import { RemoveAccountModalComponent } from '../remove-account-modal/remove-account-modal.component'
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';
import { PubSubscribeService } from '../../services/pub-subscribe.service';
import { RelinkAccountModalComponent } from '../relink-account-modal/relink-account-modal/relink-account-modal.component';
import { QuovoAccountModalComponent } from '../quovo-account-modal/quovo-account-modal.component';
import { ScenarioAccountAndGroupModalComponent } from '../scenario-account-and-group-modal/scenario-account-and-group-modal.component';
import { isDefined } from '@angular/compiler/src/util';
import { LinkExternalAccountModalComponent } from '../link-external-account-modal/link-external-account-modal.component';
import { UtilityService } from 'src/app/services/utility.service';
import { IncomeExpenseModalComponent } from '../income-expense-modal/income-expense-modal.component';
declare var Plaid;

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
	graphScenarioData: any;
	retireGraphValues: any = [];
	rangeData: any = {};
	value_at_retirement: any;
	sustainable_spending: any;
	expectedEndingValue: any;
	graphDataSource: any = {};
	selectedHousehold: any = {};
	graphValues: any;
	graphData: any = {};
	currentUser: any;
	netWorth: any;
	totalSavings: any;
	loadDashboardFlag: any = true;
	defaulRtqs: any;
	tempScenario: any;
	incToDate: any;
	yearToDate: any;
	cashFlowDetails: any = {};
	inWithdrawal: any;
	selectedPortfolio: any = {};
	itdValue: any;
	ytdValue: any;
	inceptionDate: any;
	allocationList: any = [];
	taxGradeAdvice: any;
	advisorFullName: any;
	advisorFirstName: any;
	savingWaterfallDetails: any;
	accountGroupsDropdown: any;
	accountGroupsDropdownLength: any;
	redirectViewPerformance: any;
	openAccordion: any;
	totalInvest: any;
	colorRange: any = ["#44D1FF", "#00BFFF", "#00A1DB", "#0085B5", "#00729B", "#5fc4e9", "#3ba0c5", "#49cdc7", "#67e6da"];
	diversificationGrade: any;
	expenseGrade: any;
	assetAllocationGrade: any;
	expenseGradeDetails: any;
	diversificationWidth: any;
	expenseWidth: any;
	assetAllocationWidth: any;
	totalWeight: any = 0;
	targetWeight: any = 0;
	targetInvest: any = 0;
	allocationTargetList: any = [];
	totalTargetWeight: any;
	targetWeight1: any;
	targetInvest1: any;
	colorGreen: any = "#62E779";
	sustainableIncrease: any;
	averageAnnualReturn: any;
	managementFees: any;
	standaradDeviation: any;
	selected_managed_assets_account_groups_copy: any = [];
	selected_managed_assets_account_groups: any = [];
	isRetired: any = false;
	canChange: any;
	maxRetirementAge: any;
	graphValueEdit: any = {};
	age: any = {};
	disable: any;
	rtqId: any;
	scenarioId: any;
	retirementAge: any;
	monthlySavings: any;
	currentAssestsInv: any;
	householdList: any = [];
	netWorthData: any = [];
	errorBankCount: any;
	dashboard_bank_status_color: any;
	banks: any;
	linkedBanks: any = [];
	clickedIndex: any;
	clickedIndexCopy: any;
	yodleeBanks: any;
	topCardData: any = {};
	collapsed: any = true;
	allocationListGraphData: any = {};
	collapsedAllocation: any = true;
	clientId: any;
	current_user_firm_setting: any = {};
	tamaracObj: any = [];
	bank_linker: any;
	displayTodayTotal: any = false;
	disableAddAccount: boolean = false;
	loadRefreshAll: boolean = false;
	showEdit: boolean = false;
	showYodleeLoader: boolean;
	adminUsers: any;
	accountTypes: any;
	saveAccountInfo: boolean = false;
	accountEdit: FormGroup;
	public selectedRow: any = {};
	public fieldType = [];
	displayEmployer: boolean = false;
	accTypes: any;
	showEditPosition: boolean = false;
	selectedPosition: any = {};
	displayBeneficiary = false;
	accountTypesCheck: any;
	currentAccountType: any;
	setBalance = '';
	fieldOptions: any;
	public accountHolder: any;
	displayPositionGrid: boolean = false;
	positions: any = [];
	loadRefresh: boolean = false;
	filteredData = [];
	removeInstitution: boolean;
	disableQuantity: boolean;
	checkCustomTicker: boolean;
	positionModalHeading: any;
	handler: any;
	editLinkBank: any;
	accTypesGroup: any = [];
	allocationGraphData = [];
	linkExternalAccountModalInstance: any;
	environment: any;
	advisoryTeam:any;
	incomeExpenseDataOld: any;
	showTargets:any;
	showGrades:any;
	firmGraphVisibleClient:any;
	dropDownValues:any = {};
	estatePlanData:any = { 
		estateInformation:[],
		estateBusiness:[],
		estateCategories:[],
		estateClients:[]

	};
	@ViewChild("networth") networth: ElementRef;
	@ViewChild("allocationAnalysisId") allocationAnalysisId: ElementRef;


	constructor(private apiService: ApiService,
		private pusherService: PusherService,
		private modalService: NgbModal,
		private activeModalService: NgbActiveModal,
		private eventEmitter: EventEmitterService,
		private toastService: ToastService,
		private confirmationDialogService: ConfirmationDialogService,
		private fb: FormBuilder,
		private cs: CommonService,
		public utilService: UtilityService,
		private pubSubcribe: PubSubscribeService) {
		this.accountEdit = fb.group({
			'accountName': [null, Validators.required],
			'accountType': [null, Validators.required],
			'accountHolderName': [null],
			'balance': [null],
			'interestRate': [null],
			'accountEmployer': [null]
		});

		this.pubSubcribe.getObservable().subscribe((closeplaid) => {
			this.bindPusherNetworth();
			if (closeplaid && this.handler) {
				this.handler.destroy();
			}
		});
	}

	ngOnInit(): void {
		this.environment = environment.environment;
		this.currentUser = JSON.parse(sessionStorage.getItem('current_user'));
		this.current_user_firm_setting = this.currentUser.firm;
		this.tamaracObj = _.filter(this.currentUser.firm.firm_sso_configs, function (obj) {
			if (obj.firm_sso_config.sso_config.name == 'Tamarac') {
				return obj;
			}
		});
		this.clientId = this.currentUser.id;
		this.bank_linker = this.currentUser.bank_api_level;
		this.getHouseholds();
	}

	buildFusionChartData(scenario) {
		this.graphScenarioData = scenario;
		let xaxisLabelLen;
		let fusionGraphData = scenario.graph_data.split(',');
		fusionGraphData[0] = fusionGraphData[0].replace('[', '');
		fusionGraphData[fusionGraphData.length - 1] = fusionGraphData[fusionGraphData.length - 1].replace(']', '');
		let xaxisLabel = [], dataset1 = [], dataset2 = [];
		if (scenario.is_retired) {
			if (scenario.start_age > scenario.retirement_age) {
				xaxisLabelLen = scenario.investment_horizon - scenario.start_age + 1;
			} else {
				xaxisLabelLen = scenario.investment_horizon - scenario.start_age + 2;
			}
		} else {
			xaxisLabelLen = scenario.investment_horizon - scenario.start_age + 2;
		}
		let index1 = scenario.retirement_age - scenario.start_age + 1;
		let index2 = scenario.investment_horizon - scenario.retirement_age + 1;
		this.retireGraphValues.push({
			age: scenario.start_age,
			money: parseInt(scenario.managed_assets),
			target_savings: parseInt(scenario.target_savings_amount),
			sustainable_spending: scenario.sustainable_spending,
			desired_spending: this.rangeData.desiredMonthlyIncome * 12,
			graphLabel: "Start Age"
		});



		this.retireGraphValues.push({ age: scenario.retirement_age, money: scenario.value_at_retirement, graphLabel: (scenario.is_retired ? "Sustainable Withdrawal" : "Expected Portfolio Value at Retirement") });
		this.retireGraphValues.push({ age: scenario.investment_horizon, money: scenario.ending_sustainable_spending, graphLabel: (scenario.is_retired ? "Ending Portfolio Value" : "Annual Expected Sustainable Spending") });
		this.updateScenarioToggle();
		this.expectedEndingValue = fusionGraphData[fusionGraphData.length - 1];
		for (let i = 0; i < xaxisLabelLen; i++) {
			if (i == 0) {
				xaxisLabel[i] = { "label": "Age " + scenario.start_age, "showLabel": "1" };
			} else if (i == xaxisLabelLen - 1) {
				xaxisLabel[i] = { "label": "Age " + scenario.investment_horizon, "showLabel": "1" };
			} else if (i == index1) {
				xaxisLabel[i - 1] = { "vLine": "true", "dashed": true, "linePosition": 0.99, "labelPosition": 1, "showLabelBorder": 0, thickness: 1, "label": '', "showLabel": "1" };
				xaxisLabel[i] = { "label": "Age " + scenario.retirement_age, "showLabel": "1" };
			} else {
				if(i<index1){
					xaxisLabel[i] = { "label": (Number(scenario.start_age) + Number(i)).toString() };
				}else{
					xaxisLabel[i] = { "label": (Number(scenario.start_age-1) + Number(i)).toString() };
				}
			}
		}

		for (let j = 0; j < index1; j++) {
			if (Number(fusionGraphData[j]) > 0) {
				dataset1[j] = { "value": fusionGraphData[j] };
			} else {
				dataset1[j] = { "value": "" };
			}
			dataset2[j] = { "value": "" };
		}

		let len;
		if (dataset2.length > 0) {
			len = dataset2.length - 1;
		} else {
			len = dataset2.length;
		}

		let totalLength = len + index2;
		for (let k = len; k < totalLength; k++) {
			if (Number(fusionGraphData[k]) > 0) {
				dataset2[k] = { "value": fusionGraphData[k] };
			} else {
				dataset2[k] = { "value": "" };
			}
		}

		this.graphDataSource = {
			"chart": {
				'baseFontSize': 11,
				"chartRightMargin": "0",
				"drawcrossline": "0",
				"showLabels": "0",
				"yAxisMinValue": "0",
				"drawCrossLineOnTop": 1,
				"drawAnchors": "0",
				"showPlotBorder": "1",
				"drawFullAreaBorder": "0",
				"plotBorderThickness": "3",
				"minimizeTendency": "1",
				"labelDisplay": "none",
				"canvasPadding": "0",
				"theme": "fusion",
				"labelPadding": "2"

			}, "categories": [
				{
					"category": xaxisLabel
				}
			], "dataset": [{
				"color": "#62E779",
				"plotBorderColor": "#008000",
				"renderAs": "area",
				"plottooltext": "Expected Portfolio value at age <b>$label<b>: <b>$dataValue</b>",
				"data": dataset1

			}, {
				"color": "#D9F4F7",
				"plotBorderColor": "#0000FF",
				"renderAs": "area",

				"plottooltext": "Expected Portfolio value at age <b>$label<b>: <b>$dataValue</b>",
				"data": dataset2

			}
			]
		};
		this.disable = false;
		this.loadDashboardFlag = false;
	}

	bindGraphPusher() {
		let householdIdGraph;
		if (this.selectedHousehold.id) {
			householdIdGraph = this.selectedHousehold.id;
		} else {
			householdIdGraph = this.currentUser.selected_household_id;
		}


		this.pusherService.subscribeDocusign(householdIdGraph);
		const self = this;
		this.pusherService.bind('client_update_temp_scenario', function (data) {
			let access_token = sessionStorage.getItem('token');
			if (access_token) {
				self.getScenarioAfterCalc(data.rtq_id, data.scenario_id);
			}
		});

		this.pusherService.bind('update_temp_scenario', function (data) {
			let access_token = sessionStorage.getItem('token');
			if (access_token) {
				self.getScenarioAfterCalc(data.rtq_id, data.scenario_id);
			}
		});
	}

	loadDashboard() {
		//this.bindGraphPusher();
		let countReCalProj: any = 0;
		countReCalProj = sessionStorage.getItem('countReCalProjVal');
		countReCalProj++;
		sessionStorage.setItem('countReCalProjVal', countReCalProj);
		this.graphValues = false;
		this.graphData = null;
		let url = AppRestEndPoint.DASHBOARD.DASHBOARD_LOAD;
		let param = '';
		if (this.selectedHousehold.id) {
			param = "&household_id=" + this.selectedHousehold.id + "&countReCalProj=" + countReCalProj;
		} else {
			param = "&countReCalProj=" + countReCalProj;
		}
		this.apiService.getCall(url, param).subscribe(data => {
			if (data) {
				let houseHoldData = data.household;
				let householdId = houseHoldData.id;
				sessionStorage.setItem('current_selected_household', householdId);
				this.netWorth = houseHoldData.net_worth;

				if (houseHoldData.account_groups && houseHoldData.account_groups.length != 0) {

					this.incToDate = houseHoldData.account_groups.itd;
					this.yearToDate = houseHoldData.account_groups.ytd;
				} else {
					this.incToDate = 0;
					this.yearToDate = 0;
				}
				this.incomeExpenseDataOld = JSON.parse(JSON.stringify(houseHoldData.cash_flow));
				this.cashFlowDetails = JSON.parse(JSON.stringify(houseHoldData.cash_flow));
				this.inWithdrawal = houseHoldData.in_withdrawal;
				this.showGrades = houseHoldData.show_grades;
				this.showTargets = houseHoldData.show_targets;;
				houseHoldData.waterfall_recommendations = _.filter(houseHoldData.waterfall_recommendations, function (item) {
					if (houseHoldData.in_withdrawal) {
						return item.is_savings == false;
					} else {
						return item.is_savings == true;
					}
				});
				this.savingWaterfallDetails = this.formatSavingsWaterfall(houseHoldData.waterfall_recommendations);
				setTimeout(() => {
					this.openAccordion = false;
				}, 3000);

				let selectedhouseholdDetails = _.find(this.householdList, function (param) {
					return param[0] === houseHoldData.id;
				});

				this.selectedHousehold.id = houseHoldData.id;
				if(selectedhouseholdDetails && selectedhouseholdDetails[1]){
					this.selectedHousehold.name = selectedhouseholdDetails[1];
				}
				this.taxGradeAdvice = houseHoldData.tax_grade_advice;

				if (houseHoldData.household_admin_users) {
					this.advisoryTeam = [];
					this.advisorFullName = houseHoldData.household_admin_users.advisor.name;
					this.advisorFirstName = houseHoldData.household_admin_users.advisor.first_name;
					this.advisoryTeam.push(houseHoldData.household_admin_users.advisor);
					if(houseHoldData.household_admin_users.csa_advisors.length > 0){
						this.advisoryTeam.push(houseHoldData.household_admin_users.csa_advisors[0]);
					}
					if(houseHoldData.household_admin_users.secondary_advisors.length > 0){
						this.advisoryTeam.push(houseHoldData.household_admin_users.secondary_advisors[0]);
					}
				} else {
					this.advisorFullName = "";
					this.advisorFirstName = "";
				}
				this.adminUsers = houseHoldData.household_admin_users;
				this.firmGraphVisibleClient = houseHoldData.firm_graph_visible_to_client;
				sessionStorage.setItem("adminUsers", JSON.stringify(this.adminUsers));
				this.eventEmitter.broadcast("adminUsers", this.adminUsers);
				this.eventEmitter.broadcast("updateDocumentCount", houseHoldData.user_document_notification_count);
				sessionStorage.setItem('user_document_notification_count', JSON.stringify(houseHoldData.user_document_notification_count));
				this.eventEmitter.broadcast("unreadTaskCount", JSON.stringify(houseHoldData.unread_task_count));
				sessionStorage.setItem('unread_task_count', JSON.stringify(houseHoldData.unread_task_count));
				this.bindPusher(householdId);
				if (houseHoldData.temp_scenario && houseHoldData.temp_scenario.id) {
					this.tempScenario = houseHoldData.temp_scenario;
					this.rtqId = houseHoldData.temp_scenario.rtq_id;
					this.scenarioId = houseHoldData.temp_scenario.id;
					sessionStorage.setItem('login_rtq_id', houseHoldData.temp_scenario.rtq_id);
					this.getScenarioAfterCalc(houseHoldData.temp_scenario.rtq_id, houseHoldData.temp_scenario.id);
				}
				setTimeout(() => {
					this.loadDashboardFlag = false;
				}, 5000);
			}
		});

	};

	loadRebalancingGroups() {
		let url = AppRestEndPoint.DASHBOARD.DASHBOARD_REBALANCING_DROPDOWN;
		let param = '';
		if (this.selectedHousehold.id) {
			param = "&household_id=" + this.selectedHousehold.id;
		}
		this.apiService.getCall(url, param).subscribe(data => {
			if (data.collection) {
				this.accountGroupsDropdown = data.collection;
				let accountGroupsDropdown = _.filter(this.accountGroupsDropdown, function (item) {
					if (item[0] !== 'All Assets' && item[0] !== 'Unmanaged') {
						return item;
					}
				});
				this.accountGroupsDropdownLength = accountGroupsDropdown.length;
			}
			if ((data.collection) && (data.collection.length > 0)) {
				this.loadRebalancingGroupAccountDetail(data.collection[0]);
				
			} else {
				this.allocationList = [];
			}
		});
	}

	loadRebalancingGroupAccountDetail(selectedPortfolio) {
		let param = '&group=' + selectedPortfolio.group;
		if (selectedPortfolio.id) {
			param = param + "&id=" + selectedPortfolio.id;
		}
		this.selectedPortfolio = selectedPortfolio;
		this.sendMixPanelEvent({'event': 'Dashboard - Allocation & Analysis - Select Portfolio'});

		this.apiService.getCall(AppRestEndPoint.DASHBOARD.DASHBOARD_REBALANCING_ACCOUNT_DETAIL, param).subscribe(accountData => {
			this.itdValue = accountData.account_group.itd;
			this.ytdValue = accountData.account_group.ytd;
			this.inceptionDate = accountData.account_group.inception_date;
			this.redirectViewPerformance = accountData.account_group.allocation_data.redirect;
			this.allocationGraphData = accountData.account_group.allocation_data.allocation_graph;
			this.setPortfolioGraph(accountData.account_group.allocation_data);
		});
	}

	formatSavingsWaterfall(data) {
		this.totalSavings = 0;
		for (let i = 0; i < data.length; i++) {
			this.totalSavings = this.totalSavings + parseFloat(data[i].target_savings);
			switch (data[i].status) {
				case 'not_started':
					data[i].width = { 'width': "25%" };
					break;
				case 'talk_to_your_advisor':
					data[i].width = { 'width': "50%" };
					break;
				case 'in_progress':
					data[i].width = { 'width': "75%" };
					break;
				case 'completed':
					data[i].width = { 'width': "100%" };
					break;
			}
		}
		return data;
	}

	setPortfolioGraph(data) {
		let dataObj = {};
		dataObj = data.allocation_graph;
		this.allocationList = _.values(dataObj);
		this.allocationList = _.filter(this.allocationList, function (item) {
			if (item.weight != 0 || item.target_weight != 0) {
				return item;
			}
		});
		let index = _.findIndex(this.allocationList, { label: "Bonds" });
		this.allocationList.push(this.allocationList.splice(index, 1)[0]);
		this.colorRange[this.allocationList.length - 1] = this.colorGreen;
		this.calculateTotal();
		this.totalInvest = data.total_assets;
		this.diversificationGrade = data.diversification_grade;
		this.expenseGrade = data.expense_grade;
		this.assetAllocationGrade = data.asset_location_grade;
		this.expenseGradeDetails = data.expense_grade_details;
		this.diversificationWidth = { 'width': parseInt(data.diversification_grade) + "%" };
		this.expenseWidth = { 'width': parseInt(data.expense_grade) + "%" };
		this.assetAllocationWidth = { 'width': parseInt(data.asset_location_grade) + "%" };
		this.buildFusionChartDonutData();
	}


	buildFusionChartDonutData() {
		let allocationData = [];
		this.allocationList.forEach(element => {
			allocationData.push({
				label: element.label,
				value: element.graph_value,
				displayValue: Math.round(element.invest)
			})
		});


		let bonds = _.find(this.allocationList, function (val) {
			return val.label == "Bonds/Cash";
		});
		let stocks = _.filter(this.allocationList, function (val) {
			return val.label != "Bonds/Cash";
		});
		let stockSum: any = 0;
		for (let i = 0; i < stocks.length; i++) {
			stockSum = stockSum + stocks[i].weight;
		}

		stockSum = Math.round(stockSum) + '%';
		let colorRange = ["#44D1FF", "#00BFFF", "#00A1DB", "#0085B5", "#00729B", "#5fc4e9", "#3ba0c5", "#49cdc7", "#67e6da"];
		let colorGreen = "#62E779";
		colorRange[allocationData.length - 1] = colorGreen;
		const data = {
			chart: {
				animation: 0,
				caption: "",
				captionPadding: 0,
				subcaption: "",
				chartLeftMargin: 0,
				showpercentvalues: "1",
				defaultcenterlabel: stockSum + '<br/> STOCKS' + '<br/><br/>' + Math.round(bonds.weight) + '%' + '<br/>BONDS',
				aligncaptionwithcanvas: "0",
				captionpadding: "0",
				canvasPadding: "0",
				showLabels: 0,
				showValues: 0,
				width: "100%",
				height: "100%",
				pieRadius: 100,
				enableSmartLabels: 0,
				enableSlicing: 0,
				enableRotation:0,
				showLegend: 0,
				doughnutRadius: 75,
				palettecolors: colorRange,
				decimals: "2",
				plottooltext:
					"<b>$label</b><br/><b>$percentValue</b><br/><b>$displayValue</b>",
				centerlabel: stockSum + '<br/> STOCKS' + '<br/><br/>' + Math.round(bonds.weight) + '%' + '<br/>BONDS',
				theme: "fusion"
			},
			data: allocationData
		};


		this.allocationListGraphData = data;
	}

	calculateTotal() {
		this.totalWeight = 0;
		this.targetWeight = 0;
		this.targetInvest = 0;
		let len = this.allocationList.length;
		for (let j = 0; j < len; j++) {
			this.totalWeight = parseFloat(this.totalWeight + this.allocationList[j].weight);
			if (this.allocationList[j].target_weight || this.allocationList[j].target_invest) {
				this.targetWeight = parseFloat(this.targetWeight) + parseFloat(this.allocationList[j].target_weight);
				this.targetInvest = parseFloat(this.targetInvest) + parseFloat(this.allocationList[j].target_invest);
			}
		}
		if (this.allocationTargetList && this.allocationTargetList.length > 0) {
			this.totalTargetWeight = 0;
			this.targetWeight1 = 0;
			this.targetInvest1 = 0;
			let len = this.allocationTargetList.length;
			for (let j = 0; j < len; j++) {
				this.totalTargetWeight = parseFloat(this.totalTargetWeight + this.allocationTargetList[j].weight);
				if (this.allocationTargetList[j].target_weight || this.allocationTargetList[j].target_invest) {
					this.targetWeight1 = this.targetWeight1 + this.allocationTargetList[j].target_weight;
					this.targetInvest1 = this.targetInvest1 + parseFloat(this.allocationTargetList[j].target_invest);
				}
			}
		}
	}

	bindPusher(household_id) {
		const self = this;
		let pusher_channel = this.selectedHousehold.id;
		pusher_channel = pusher_channel.toString();

		this.pusherService.subscribeBankRefresh(pusher_channel);
		this.pusherService.bind('refresh_worker_finished', function (data) {
			console.log("hard refresh pusher " + data);
			self.getNetworthData(true);
		});

	}

	getScenarioAfterCalc(rtqId, scenarioId) {
		if (rtqId !== '') {
			//this.retireGraphValues = [];
			let url = AppRestEndPoint.DASHBOARD.SCENARIOS + '/' + scenarioId;
			this.apiService.getCall(url, '&rtq_id=' + rtqId).subscribe(data => {
				if (data && data.scenario) {
					if (data.scenario.graph_visible_to_client == true) {
						this.buildDataForRangeBar(data.scenario);
						this.buildFusionChartData(data.scenario);
					}

					this.sustainableIncrease = data.scenario.sustainable_growth_rate_percentage;
					this.averageAnnualReturn = data.scenario.average_annual_return;
					this.standaradDeviation = data.scenario.standard_deviation;
					this.managementFees = data.scenario.management_fee;

					this.graphData = data.scenario;
					this.getScenarioAccountGroups();

				}
			});
		}
	};

	getScenarioAccountGroups() {
		if (this.selected_managed_assets_account_groups_copy && this.selected_managed_assets_account_groups_copy.length == 0 && this.graphData) {
			if (this.netWorthData && this.netWorthData.length > 0) {
				this.selected_managed_assets_account_groups = [];
				this.selected_managed_assets_account_groups = JSON.parse(JSON.stringify(this.netWorthData[0].accounts));
				this.selected_managed_assets_account_groups = this.netWorthData[0].accounts;
				let len = this.graphData.selected_managed_assets_account_groups ? this.graphData.selected_managed_assets_account_groups.length : 0;
				let len1 = this.selected_managed_assets_account_groups.length;
				for (let i = 0; i < len; i++) {
					for (let j = 0; j < len1; j++) {
						if (this.graphData.selected_managed_assets_account_groups[i] == this.selected_managed_assets_account_groups[j].account_group_id) {
							this.selected_managed_assets_account_groups[j].selected = true;
						}
					}
				}
			}
		}
	}

	buildDataForRangeBar(scenario) {
		this.isRetired = scenario.is_retired;
		this.canChange = false;

		if (scenario.scenario_results[0]) {
			this.rangeData.sustainableSpending = scenario.sustainable_spending;
		}
		if (scenario.is_retired) {
			this.rangeData.investmentHorizon = this.checkValidNumber(parseInt(scenario.investment_horizon));
			this.rangeData.investmentHorizonVal = parseInt(scenario.investment_horizon);
			this.rangeData.investmentStep = '';
			this.rangeData.investmentMaxValue = parseInt(scenario.investment_horizon) <= 104 ? 104 : parseInt(scenario.investment_horizon);
			if (!this.rangeData.desiredMonthlyIncome) {
				this.rangeData.desiredMonthlyIncome = this.checkValidNumber(scenario.sustainable_spending) / 12;
				this.rangeData.desiredMonthlyIncomeVal = this.checkValidNumber(scenario.sustainable_spending) / 12;
				this.rangeData.desiredStep = '250';
				this.rangeData.desiredMaxValue = this.rangeData.desiredMonthlyIncome * 2;
			}
		}
		this.maxRetirementAge = parseInt(scenario.investment_horizon) || 104;
		// model gets reset when we set max, we have to use a timeout to reset the value after resetting the max
		this.rangeData.startAge = parseInt(scenario.start_age);
		this.rangeData.retirementAge = parseInt(scenario.retirement_age);
		this.rangeData.inputVal = parseInt(scenario.retirement_age);
		this.rangeData.step = '';
		this.rangeData.maxValue = parseInt(scenario.retirement_age) <= 140 ? 140 : parseInt(scenario.retirement_age);
		this.rangeData.monthlySavingsMax = Math.max(this.checkValidNumber(scenario.target_savings_amount) * 2, 25000);
		this.rangeData.currentAssestsInvMax = Math.max(this.checkValidNumber(scenario.managed_assets) * 2, 100000);

		setTimeout(() => {
			this.rangeData.monthlySavings = this.checkValidNumber(scenario.target_savings_amount) / 12;
			this.rangeData.monthlySavingsVal = this.checkValidNumber(scenario.target_savings_amount) / 12;
			this.rangeData.monthlySavingsStep = '';
			this.rangeData.currentAssestsInv = this.checkValidNumber(scenario.managed_assets);
			this.rangeData.currentAssestsInvVal = this.checkValidNumber(scenario.managed_assets);
			this.rangeData.currSavingsStep = '';
			setTimeout(() => {
				this.canChange = true;
			}, 0);
		}, 0);


	}

	checkValidNumber(numberVal) {
		if (numberVal === null || isUndefined(numberVal)) {
			numberVal = undefined;
		} else {
			numberVal = parseFloat(numberVal);
		}
		return numberVal;
	}

	changeGraphValueMode(param) {
		if (param == 'age') {
			this.graphValueEdit.age = !this.graphValueEdit.age;
			this.rangeData.step = '';
			setTimeout(() => {
				document.getElementById('retirementAge').focus();
			}, 10);
		} else if (param == 'ih') {
			this.graphValueEdit.ih = !this.graphValueEdit.ih;
			this.rangeData.step = ''
			setTimeout(() => {

				document.getElementById('ih').focus();
			}, 10);

		} else if (param == 'ms') {

			this.graphValueEdit.ms = !this.graphValueEdit.ms;
			this.rangeData.monthlySavingsStep = '';
			setTimeout(() => {
				document.getElementById('ms').focus();
			}, 10);

		} else if (param == 'cas') {
			this.graphValueEdit.cas = !this.graphValueEdit.cas;
			this.rangeData.currSavingsStep = '';
			setTimeout(() => {
				document.getElementById('cas').focus();
			}, 10);
		} else if (param == 'dmi') {
			this.graphValueEdit.dmi = !this.graphValueEdit.dmi;
			this.rangeData.desiredStep = '';
			setTimeout(() => {
				document.getElementById('dmi').focus();
			}, 10);
		}
	};

	updateRangeBar(param) {
		if (param == 'age') {
			this.rangeData.inputVal <= 104 ? this.rangeData.retirementAge = this.rangeData.inputVal : this.rangeData.retirementAge = 104;
			this.graphValueEdit.age = false;
		} else if (param == 'ih') {
			this.rangeData.investmentHorizonVal <= 104 ? this.rangeData.investmentHorizon = this.rangeData.investmentHorizonVal : this.rangeData.investmentHorizon = 104;
			this.graphValueEdit.ih = false;
		} else if (param == 'ms') {
			this.graphValueEdit.ms = false;
		} else if (param == 'cas') {
			this.graphValueEdit.cas = false;
		} else if (param == 'dmi') {
			this.graphValueEdit.dmi = false;
		}
	};

	recalcBlur(param) {
		//event.target.blur();
		this.updateRangeBar(param);
		setTimeout(() => {
			this.calculateRetirementPlan();
		}, 10);
	};

	calculateRetirementPlan(managed_ids: any = '') {
		this.bindGraphPusher();
		this.disable = true;
		this.loadDashboardFlag = true;
		//this.retireGraphValues = [];
		let reqObj: any = {};
		if (!this.isRetired) {
			reqObj = {
				"scenario": {
					"retirement_age": this.rangeData.retirementAge,
					"target_savings_amount": this.rangeData.monthlySavings * 12,
					"managed_assets": this.rangeData.currentAssestsInv
				}
			};
		} else {
			reqObj = {
				"scenario": {
					"investment_horizon": this.rangeData.investmentHorizon,
					"desired_monthly_income": this.rangeData.desiredMonthlyIncome,
					"managed_assets": this.rangeData.currentAssestsInv
				}
			};
		}
		if (isDefined(managed_ids)) {
			reqObj.scenario.managed_assets_account_groups_array = managed_ids.length > 0 ? JSON.stringify(managed_ids) : ''
		}
		if (this.rtqId && this.scenarioId) {
			let url = AppRestEndPoint.DASHBOARD.SCENARIOS + '/' + this.scenarioId + '?rtq_id=' + this.rtqId;
			this.apiService.updateCall(url, reqObj, this.scenarioId).subscribe(data => {
				this.sendMixPanelEvent({'event': 'Dashboard - Portfolio Projections Run Calc'});
				if (data.scenario) {
					if (!data.scenario.is_retired) {
						this.rangeData.retirementAge = data.retirement_age;
						this.rangeData.monthlySavings = data.target_savings_amount;
						this.rangeData.managed_assets = data.managed_assets;
					} else {
						this.rangeData.investmentHorizon = data.investment_horizon;
						this.rangeData.currentAssestsInv = data.managed_assets;
					}
				}

			});
		}
	};

	resetRetirementPlan() {
		this.loadDashboardFlag = true;
		this.selected_managed_assets_account_groups_copy = undefined;
		this.bindGraphPusher();
		this.disable = true;
		if (this.rtqId && this.scenarioId) {
			let url = AppRestEndPoint.DASHBOARD.RESET_SCENARIO;
			let param = '&rtq_id=' + this.rtqId + '&scenario_id=' + this.scenarioId;
			this.apiService.getCall(url, param).subscribe(data => {
				this.disable = false;
				this.sendMixPanelEvent({'event': 'Dashboard - Portfolio Projections Reset Calc'});
				if (data) {
					this.scenarioId = data.scenario.id;
					if (!data.scenario.is_retired) {
						this.retirementAge = parseInt(data.scenario.retirement_age);
						this.monthlySavings = parseFloat(data.scenario.target_savings_amount);
						this.currentAssestsInv = parseFloat(data.scenario.managed_assets);
					} else {
						this.rangeData.investmentHorizon = data.scenario.investment_horizon;
						this.rangeData.desiredMonthlyIncome = null;
						this.rangeData.desiredMonthlyIncomeVal = null;
						this.rangeData.currentAssestsInv = data.scenario.managed_assets;
					}

				}
			});
		}
	};

	selectHousehold(householdId) {
		this.selectedHousehold.id = householdId[0];
		this.selectedHousehold.name = householdId[1];
		this.collapsed = true;
		this.sendMixPanelEvent({'event': 'Dashboard - Select Household'});
		sessionStorage.setItem('current_selected_household', householdId[0]);
		this.loadDashboardFlag = true;
		this.loadDashboard();
		this.getNetworthForm();
		this.getNetworthData(false);
		this.getEstateCategoryData();
		this.loadRebalancingGroups();

	};

	loadHouseholdList() {
		let url = AppRestEndPoint.DASHBOARD.HOUSEHOLD_LIST.replace(':id', this.clientId), param = '';
		this.apiService.getCall(url, param).subscribe(data => {
			if (data) {
				this.householdList = data;
				this.loadDashboard();
				this.getNetworthForm();
				this.getNetworthData(false);
				this.getEstateCategoryData();
				this.loadRebalancingGroups();
			}
		});
	}

	getEstatePlanData(){
		let param = '';
		let url = AppRestEndPoint.DASHBOARD.ESTATE_PLAN;
		this.apiService.getCall(url, param).subscribe(response => {
			this.estatePlanData.estateInformation =response.estate_information;
			this.estatePlanData.estateBusiness =response.estate_business;
			this.estatePlanData.estateCategories =response.estate_categories;
			this.estatePlanData.estateClients =response.estate_clients;
			this.setNonTrustAccountTotal();
			for (var index = 0; index < this.estatePlanData.estateCategories.Wills.length; index++) {
				var j = 0, k = 0;
				for (var index1 = 0; index1 < this.estatePlanData.estateCategories.Wills[index].estate_contacts.length; index1++) {
					if (this.estatePlanData.estateCategories.Wills[index].estate_contacts[index1].contact_type == 'Executors') {
						j = j + 1;
						this.estatePlanData.estateCategories.Wills[index].estate_contacts[index1].position = j;
					} else {
						k = k + 1;
						this.estatePlanData.estateCategories.Wills[index].estate_contacts[index1].position = k;
					}
				}
			}
			for (var index = 0; index < this.estatePlanData.estateCategories.Trusts.length; index++) {
				var j = 0;
				for (var index1 = 0; index1 < this.estatePlanData.estateCategories.Trusts[index].estate_trustees.length; index1++) {
					if (this.estatePlanData.estateCategories.Trusts[index].estate_trustees[index1].trustee_type == 'Successor') {
						j = j + 1;
						this.estatePlanData.estateCategories.Trusts[index].estate_trustees[index1].position = j;
					} 
				}
			}
		});
	}

	getEstateCategoryData() {
		let param = '';
		let url = AppRestEndPoint.DASHBOARD.DROPDOWN_VALUES;
		this.apiService.getCall(url, param).subscribe(response => {
			this.dropDownValues.estate_trustee_types = response.estate_trustee_types;
			this.getEstatePlanData();
		});
	}

	setNonTrustAccountTotal(){
		for (var index = 0; index < this.estatePlanData.estateCategories.Other_Assets.length; index++) {
			var totalBalance=0, totalInsuranceBalance = 0;
			for (var index1 = 0; index1 < this.estatePlanData.estateCategories.Other_Assets[index].estate_accounts.length; index1++) {
				if(!this.estatePlanData.estateCategories.Other_Assets[index].estate_accounts[index1].is_liability){
					if(this.estatePlanData.estateCategories.Other_Assets[index].estate_accounts[index1].account_balance){
						totalBalance = parseFloat(this.estatePlanData.estateCategories.Other_Assets[index].estate_accounts[index1].account_balance) + totalBalance;
					}
				}else{
					if(this.estatePlanData.estateCategories.Other_Assets[index].estate_accounts[index1].account_balance){
						totalBalance = totalBalance - parseFloat(this.estatePlanData.estateCategories.Other_Assets[index].estate_accounts[index1].account_balance);
					}
				}
			}
			for (var index1 = 0; index1 <this.estatePlanData.estateCategories.Other_Assets[index].estate_household_insurances.length; index1++) {
				if(this.estatePlanData.estateCategories.Other_Assets[index].estate_household_insurances[index1].household_insurance && this.estatePlanData.estateCategories.Other_Assets[index].estate_household_insurances[index1].household_insurance.account_value){ 
					totalInsuranceBalance = parseFloat(this.estatePlanData.estateCategories.Other_Assets[index].estate_household_insurances[index1].household_insurance.account_value) + totalInsuranceBalance; 
				} 
			}
			
			this.estatePlanData.estateCategories.Other_Assets[index].total_balance = totalBalance + totalInsuranceBalance;
		}
		for (var index = 0; index < this.estatePlanData.estateCategories.Trusts.length; index++) {
			var totalBalance=0;
			for (var index1 = 0; index1 < this.estatePlanData.estateCategories.Trusts[index].estate_accounts.length; index1++) {
				if(!this.estatePlanData.estateCategories.Trusts[index].estate_accounts[index1].is_liability){
					if(this.estatePlanData.estateCategories.Trusts[index].estate_accounts[index1].account_balance){
						totalBalance = parseFloat(this.estatePlanData.estateCategories.Trusts[index].estate_accounts[index1].account_balance) + totalBalance;
					}
				}else{
					if(this.estatePlanData.estateCategories.Trusts[index].estate_accounts[index1].account_balance){
						totalBalance = totalBalance - parseFloat(this.estatePlanData.estateCategories.Trusts[index].estate_accounts[index1].account_balance);
					}
				}
			}
			this.estatePlanData.estateCategories.Trusts[index].total_balance = totalBalance;
		}
	}

	getNetworthData(flag) {
		let errorBankCount = 0; this.errorBankCount = 0;
		let url = AppRestEndPoint.DASHBOARD.NET_WORTH;
		let param = '';
		if (this.selectedHousehold.id) {
			param = '&household_id=' + this.selectedHousehold.id;
		}
		this.apiService.getCall(url, param).subscribe(response => {
			if (response && response.net_worth) {
				this.dashboard_bank_status_color = response.net_worth.dashboard_bank_status_color;
				let data = {};
				this.netWorthData = []; this.banks = [];
				data = response.net_worth.assets;
				this.banks = response.net_worth.banks;
				this.formatNetworthData(data);
				_.filter(this.banks, function (item) {
					if (item.status && item.status != 0 && item.status != 1 && item.manual == false) {
						errorBankCount++;
					}
				});
				this.linkedBanks = [];
				let self = this;
				_.filter(this.banks, function (item) {
					if (item.manual == false) {
						self.linkedBanks.push(item);
					}
				});

				this.errorBankCount = errorBankCount;
				this.clickedIndex = -1;
				this.clickedIndexCopy = -1;
				this.yodleeBanks = [];
				if (this.errorBankCount > 0) {
					this.yodleeBanks = _.filter(this.linkedBanks, function (val) {
						return val.api_level != 'plaid';
					});
				}
				if (this.selected_managed_assets_account_groups.length == 0) {
					this.getScenarioAccountGroups();
				}
			}
		});
	}

	formatNetworthData(data) {
		this.netWorthData.push(data.managed_assets);
		this.netWorthData.push(data.savings);
		this.netWorthData.push(data.unmanaged_assets);
		this.netWorthData.push(data.other_assets);
		this.netWorthData.push(data.liabilities);
		let len = this.netWorthData.length;
		let unvestedTotal = 0, unvestedFlag = false;
		let totalAssets = 0;
		for (let i = 1; i < len; i++) {
			let total = 0;
			for (let j = 0; j < this.netWorthData[i].accounts.length; j++) {
				if (this.netWorthData[i].accounts[j].is_hidden == false) {
					total = total + this.netWorthData[i].accounts[j].balance;
				}

				if (this.netWorthData[i].accounts[j].positions.length > 0) {

					for (let t = 0; t < this.netWorthData[i].accounts[j].positions.length; t++) {
						if (this.netWorthData[i].accounts[j].positions[t].is_unvested) {

							unvestedTotal = unvestedTotal + parseInt(this.netWorthData[i].accounts[j].positions[t].unvested_value);
							unvestedFlag = true;
						} else {
							unvestedFlag = false;
						}
					}
					this.netWorthData[i].accounts[j].unvested_total = unvestedTotal;
					this.netWorthData[i].accounts[j].unvested_flag = unvestedFlag;
				}
			}

			this.netWorthData[i].total = total;
			if (i != 4) {
				totalAssets = totalAssets + total;
			}
		}
		let len1 = this.netWorthData[0].accounts.length;
		let total1 = 0;
		for (let k = 0; k < len1; k++) {
			total1 = total1 + this.netWorthData[0].accounts[k].balance_sub_total;
			let unvestedManagedTotal, unvestedManagedFlag;
			if (this.netWorthData[0].accounts[k].sub_accounts.length > 0) {
				for (let r = 0; r < this.netWorthData[0].accounts[k].sub_accounts.length; r++) {
					if (this.netWorthData[0].accounts[k].sub_accounts[r].positions.length > 0) {
						for (let q = 0; q < this.netWorthData[0].accounts[k].sub_accounts[r].positions.length; q++) {
							if (this.netWorthData[0].accounts[k].sub_accounts[r].positions[q].is_unvested) {
								unvestedManagedTotal = unvestedTotal + parseInt(this.netWorthData[0].accounts[k].sub_accounts[r].positions[q].unvested_value);
								unvestedManagedFlag = true;
							} else {
								unvestedManagedFlag = false;
							}

						}

					}
					this.netWorthData[0].accounts[k].sub_accounts[r].unvested_total = unvestedManagedTotal;
					this.netWorthData[0].accounts[k].sub_accounts[r].unvested_flag = unvestedManagedFlag;
				}

			}
		}

		data.liabilities.accounts = _.groupBy(_.sortBy(data.liabilities.accounts, "type"), 'type');
		for (var key in data.liabilities.accounts) {
			let liabilityGrouptotal = 0;
			for(var j = 0; j < data.liabilities.accounts[key].length; j++){
				if(data.liabilities.accounts[key][j].is_hidden == false){
					liabilityGrouptotal = liabilityGrouptotal + parseFloat(data.liabilities.accounts[key][j].balance);
				} 
			}
			data.liabilities.accounts[key].total = liabilityGrouptotal;
		}
		this.netWorthData[0].total = total1;
		totalAssets = totalAssets + total1;
		this.topCardData.total_assets = totalAssets;
		this.topCardData.total_debts = this.netWorthData[4].total;
		this.topCardData.total_net_worth = this.topCardData.total_assets - this.topCardData.total_debts;
		this.topCardData.totalMngedValue = this.netWorthData[0].total;
	}

	showScenarioAccountAndGroup() {
		const scenarioAccountModalRef = this.modalService.open(ScenarioAccountAndGroupModalComponent,
			{
				windowClass: 'modal-width-550',
				backdrop: 'static'
			}
		);
		scenarioAccountModalRef.componentInstance.selected_managed_assets_account_groups = this.selected_managed_assets_account_groups;
		scenarioAccountModalRef.result.then((data) => {
			if (data && data.account_groups) {
				let len = this.selected_managed_assets_account_groups.length;
				var total = 0;
				for (var i = 0; i < len; i++) {
					if (this.selected_managed_assets_account_groups[i].selected == true) {
						total = total + parseFloat(this.selected_managed_assets_account_groups[i].balance_sub_total);
					}
				}
				total = total + this.graphData.outside_assets;
				this.selected_managed_assets_account_groups_copy = JSON.parse(JSON.stringify(this.selected_managed_assets_account_groups));
				this.rangeData.currentAssestsInvMax = Math.max(this.checkValidNumber(total) * 2, 100000);
				var managed_ids = [];
				var len1 = this.selected_managed_assets_account_groups.length;
				if (len1 > 0) {
					for (var i = 0; i < len1; i++) {
						if (this.selected_managed_assets_account_groups[i].selected) {
							managed_ids.push(this.selected_managed_assets_account_groups[i].account_group_id);
						}
					}
				}
				setTimeout(() => {
					this.rangeData.currentAssestsInv = total;
					this.rangeData.currentAssestsInvVal = total;
					this.calculateRetirementPlan(managed_ids);
				}, 0);

			}
		});
	}


	async getHouseholds() {
		await this.loadHouseholdList();
	}

	roundTo(value: any) {
		let num = Number(parseFloat(value).toFixed(1));
		return num;
	};

	

	updatePortfolioGraph() {
		this.collapsedAllocation = false;
		let selectedData: any = {};
		let self = this;
		let selectedPortfolio;


		selectedData = _.find(this.allocationGraphData, function (item) {
			return item.selection === self.selectedPortfolio[1];
		});
		selectedPortfolio = this.selectedPortfolio;
		this.selectedPortfolio = selectedData;
		if (selectedPortfolio[0] == 'Unmanaged' || selectedPortfolio[0] == 'All Assets') {
			this.selectedPortfolio.name = selectedPortfolio[0];
		}
		this.itdValue = selectedData.itd;
		this.ytdValue = selectedData.ytd;
		this.inceptionDate = selectedData.inception_date;
		this.redirectViewPerformance = selectedData.allocation_data.redirect;
		this.setPortfolioGraph(selectedData.allocation_data);
	};


	getNumberColour(value) {
		let valueWeight: any = this.roundTo(value.weight);
		let valueTarget: any = this.roundTo(value.target_weight)
		if (valueWeight - valueTarget < 0) {
			return 'text-danger';
		}
		else if (valueWeight - valueTarget > 0) {
			return 'text-success';
		}
		else if (valueWeight - valueTarget === 0) {
			return 'text-dark';
		}
	};

	getTotalNumberColour(totalWeight, targetWeight) {
		totalWeight = this.roundTo(totalWeight);
		targetWeight = this.roundTo(targetWeight);
		if (totalWeight - targetWeight < 0) {
			return 'text-danger';
		}
		else if (totalWeight - targetWeight > 0) {
			return 'text-success';
		}
		else if (totalWeight - targetWeight === 0) {
			return 'text-dark';
		}
	};

	openContactModal(value) {
		const contactUsModalRef = this.modalService.open(ContactUsModalComponent,
			{
				windowClass: 'modal-width-850',
				backdrop: 'static'
			}
		);
		contactUsModalRef.componentInstance.selectedType = value;
		contactUsModalRef.componentInstance.inWithdrawal = this.inWithdrawal;
		contactUsModalRef.componentInstance.advisorData = this.adminUsers;
		contactUsModalRef.componentInstance.advisoryTeam = this.advisoryTeam;
	}

	updateScenarioToggle() {
		if (this.displayTodayTotal) {
			this.value_at_retirement = this.graphScenarioData.allocation_value_at_retirement_todays_dollar;
			this.sustainable_spending = this.graphScenarioData.sustainable_spending_todays_dollar;
		}
		else {
			this.value_at_retirement = this.graphScenarioData.value_at_retirement;
			this.sustainable_spending = this.graphScenarioData.sustainable_spending;
		}

	}


	async openLinkAccount(bank = '', type = '') {
		const openLinkAccountModalRef = this.modalService.open(LinkAccountModalComponent, { windowClass: 'modal-width-850', backdrop: 'static' });
		openLinkAccountModalRef.componentInstance.bank = bank;
		openLinkAccountModalRef.componentInstance.type = type;
		openLinkAccountModalRef.componentInstance.selectedHousehold = this.selectedHousehold;
		openLinkAccountModalRef.result.then(() => {
			//if (data.reload && data.reload === true) {
			this.getNetworthData(false);
			this.disableAddAccount = false;
			this.bindPusherNetworth();
			//}
		});
	}

	bindPusherNetworth() {
		const self = this;
		this.pusherService.bind('bank_setting_refresh', function (data) {
			self.setStatus(data, true, 'Establishing connection...');
		});

		this.pusherService.bind('refresh_worker_starting', function (data) {
			self.setStatus(data, true, 'Securing connection...');
		});

		this.pusherService.bind('yodlee_initializing_client', function (data) {
			self.setStatus(data, true, 'Retrieving bank information...');
		});

		this.pusherService.bind('yodlee_starting_refresh_loop', function (data) {
			self.setStatus(data, true, 'Reading account records...');
		});

		this.pusherService.bind('yodlee_finished_refresh_loop', function (data) {
			self.setStatus(data, true, 'Reading position records...');
		});

		this.pusherService.bind('yodlee_retrieving_summary_info', function (data) {
			self.setStatus(data, true, 'Reading transactions records...');
		});

		this.pusherService.bind('yodlee_processing_summary', function (data) {
			self.setStatus(data, true, 'Processing accounts...');
		});

		this.pusherService.bind('refresh_worker_finished', function (data) {
			self.setStatus(data, false, '');
			self.getNetworthData(false);
			self.disableAddAccount = false;
		});

		this.pusherService.bind('refresh_worker_error', function (data) {
			this.toastService.show('Backend encountered a error. Please try again later.'.replace(/<\/?[^>]+(>|$)/g, "\n"), {
				classname: 'bg-danger text-light', delay: 7000
			});
			self.setStatus(data, false, '');
			self.getNetworthData(false);
			self.disableAddAccount = false;
		});
	}

	getNetworthForm() {
		let params = '';
		if (this.selectedHousehold.id) {
			params = `&household_id=${this.selectedHousehold.id}`;
		}
		this.apiService.getCall(AppRestEndPoint.ASSET.NETWORTH_FORM, params).subscribe(data => {
			this.accountTypes = data.account;
			this.accTypes = [];
			this.accountTypes.account_types.account_type_values.forEach(function (value) {
				let type;
				value.forEach(function (value1) {
					if (typeof value1 === 'string') {
						type = value1;
					} else {
						value1.forEach(function (value2) {
							const accObj = {};
							accObj['id'] = value2[1];
							accObj['name'] = value2[0];
							accObj['type'] = type;
							accObj['accountTypesFlag'] = value2[2];
							this.accTypes.push(accObj);
						}, this);
					}
				}, this);
			}, this);

			this.accTypesGroup = this.accTypes.reduce((r, { type }) => {
				if (!r.some(o => o.type == type)) {
					r.push({ type, groupItem: this.accTypes.filter(v => v.type == type) });
				}
				return r;
			}, []);

		});
	}

	setStatus(id, show, status) {
		_.forEach(this.banks, function (val) {
			if (val.id == id) {
				val.status = (show) ? 1 : 0;
				val.statusMessage = status;
			}
		});
	}


	addManualAccountModal() {
		this.sendMixPanelEvent({'event': 'Dashboard - Add Manual Account'});
		const openAddAccountModalRef = this.modalService.open(AddAccountModalComponent, { windowClass: 'modal-width-850' , backdrop: 'static'});
		openAddAccountModalRef.componentInstance.accoTypes = this.accountTypes;
		openAddAccountModalRef.componentInstance.netWorthData = this.netWorthData;
		openAddAccountModalRef.result.then((data) => {
			if (data) {
				this.showEdit = true;
				this.resetAddAccount();
				this.selectedRow.selectedId = data.account.id;
				this.showPositions(true, data.account, data.account_type_name);
				this.getNetworthData(true);
			} else {
				this.showEdit = false;
			}
		});
	}

	editAccount() {
		this.saveAccountInfo = true;
		if (this.accountEdit.valid) {
			this.apiService.updateCall(AppRestEndPoint.ASSET.ACCOUNTS + '/' + this.selectedRow.selectedId, this.getAccountRequest()).subscribe(data => {
				if (data) {
					this.toastService.show('Account Updated Successfully.', {
						classname: 'bg-success text-light', delay: 3000
					});
					var index = -1;
					this.getNetworthData(true);
					this.resetAddAccount();
					this.showEdit = false;
				}
			});
		}
	};

	getAccountRequest() {
		let reqObj = {}, current_employer = 0, balance = '', interest_rate = '', user_id = '';
		reqObj['description'] = this.selectedRow.accountName;
		reqObj['account_type_id'] = this.selectedRow.acctype;
		this.fieldType.forEach(function (obj) {
			if (typeof obj.data === 'boolean') {
				current_employer = obj.data ? 1 : 0;
			} else if (typeof obj.data === 'string' || typeof obj.data === 'number') {
				if (obj.label === 'Balance' || obj.label === 'Value') {
					if (this.selectedRow.disableField !== true) {
						balance = obj.data;
					}
				} else if (obj.label === 'Interest Rate') {
					interest_rate = obj.data;
				}
			} else if (typeof obj.data === 'object') {
				user_id = this.accountHolder;
			}
		}, this);
		reqObj['current_employer'] = current_employer;
		if (balance !== '') {
			reqObj['balance'] = balance;
		}
		reqObj['interest_rate'] = interest_rate;
		reqObj['user_id'] = user_id;
		if (this.selectedRow.is_hidden) {
			reqObj['.is_hidden'] = this.selectedRow.is_hidden;
		}
		const request = { 'account': reqObj };
		return request;
	}

	resetAddAccount() {
		_.forEach(this.fieldType, function (obj) {
			if (typeof obj.data === 'boolean') {
				obj.data = false;
			} else if (typeof obj.data === "number" || typeof obj.data === 'string') {
				obj.data = '';
			}
		});

		this.setBalance = '';
		this.selectedRow = {};
		this.setInputElement(this.accTypes[0].id);
		this.saveAccountInfo = false;
	}

	changeEditPosition(flag) {
		this.showEditPosition = flag;
		this.resetAddPosition();
	};

	public setInputElement(option) {
		this.accountTypesCheck = _.filter(this.accTypes, function (item) {
			return item.id === parseInt(option);
		});

		this.currentAccountType = this.accountTypesCheck[0].type;
		this.accountTypesCheck = this.accountTypesCheck[0].accountTypesFlag;
		this.showAccountHolder(this.accountTypesCheck);
		this.showPositionGrid(this.accountTypesCheck);
		if (this.selectedRow) {
			this.selectedRow.isManualCopy = false;
			this.selectedRow.selectedAssetTypeCopy = '';
		}
		if (this.fieldType && this.fieldType[0] && (this.fieldType[0].label === 'Balance' || this.fieldType[0].label === 'Value')
			&& this.fieldType[0].data !== '') {
			this.setBalance = this.fieldType[0].data;
		}

		this.fieldOptions = this.accountTypes.account_type_fields;
		this.fieldType = [];
		if (this.fieldOptions[option] && this.fieldOptions[option][1]) {
			this.fieldType[0] = this.fieldOptions[option][1];
		}
		if (this.fieldOptions[option] && this.fieldOptions[option][2]) {
			this.fieldType[1] = this.fieldOptions[option][2];
		}
		if (this.setBalance && this.fieldType && this.fieldType[0] &&
			(this.fieldType[0].label === 'Balance' || this.fieldType[0].label === 'Value')
			&& this.setBalance !== '') {
			this.fieldType[0].data = this.setBalance;
		}
		_.forEach(this.fieldType, function (obj) {
			if (typeof obj.data === 'object') {
				obj.isObject = true;
				obj.isString = false;
				obj.isBoolean = false;
			} else if (typeof obj.data === 'string') {
				obj.isObject = false;
				obj.isString = true;
				obj.isBoolean = false;
			} else if (typeof obj.data === 'boolean') {
				obj.isObject = false;
				obj.isString = false;
				obj.isBoolean = true;
			} else {
				obj.isObject = false;
				obj.isString = true;
				obj.isBoolean = false;
			}
		});
		const len = this.accountTypes.account_types.account_type_values.length;
		let selectedAccountType = [];
		for (let i = 0; i < len; i++) {
			if (this.accountTypes.account_types.account_type_values[i][0] === 'Investable Accounts') {
				selectedAccountType = this.accountTypes.account_types.account_type_values[i];
				break;
			}
		}
		if (selectedAccountType.length > 0) {
			for (let k = 1; k < selectedAccountType.length; k++) {
				for (let l = 0; l < selectedAccountType[k].length; l++) {
					if (selectedAccountType[k][l][1] === option) {
						this.selectedRow.isManualCopy = true;
						this.selectedRow.selectedAssetTypeCopy = 'Unmanaged Assets';
						break;
					}
				}
			}
		}
	}

	showAccountHolder(argument) {
		this.displayEmployer = argument.supports_employer ? true : false;
		this.displayBeneficiary = (argument.dependent_type != null || argument.dependent_type != '') ? true : false;

	}

	showPositionGrid(argument) {
		if (this.positions && this.positions.length > 0) {
			this.displayPositionGrid = true;
		} else {
			this.displayPositionGrid = argument.is_investable ? true : false;
		}
	}

	showPositions = function (flag, rowData, assetType) {
		if (this.relinkFlag == true) {
			this.relinkFlag = false;
			return;
		}
		this.enableBeneficiary = true;
		this.showEdit = flag;
		this.positions = [];
		this.positions = rowData.positions;
		let option = _.find(this.accTypes, function (item) {
			return item.name === rowData.type;
		});
		if (rowData.account_type) {
			this.showAccountHolder(rowData.account_type);
			this.showPositionGrid(rowData.account_type);
		}
		//Showing the position grid only if it's a investable account
		this.selectedRow = {
			accountName: rowData.account_description,
			selectedAssetType: assetType,
			selectedAssetTypeCopy: assetType,
			amount: rowData.balance,
			isManual: rowData.is_manual_bank,
			isManualCopy: rowData.is_manual_bank,
			acctype: option ? option.id : '',
			selectedId: rowData.id,
			bankId: rowData.bank_id,
			type: rowData.type,
			isManaged: rowData.is_managed,
			bankCreated: rowData.bank_created,
			bankLastRefreshed: rowData.bank_last_refreshed_date,
			yodleeLastRefreshed: rowData.last_refresh_by_yodlee,
			manualAccUpdatedDate: rowData.account_updated_at_date
		};
		if (assetType == "Managed Assets") {
			this.selectedRow.bankDescription = rowData.account_group_member_name;
		} else {
			this.selectedRow.bankDescription = rowData.bank_description;
		}
		if (option) {
			this.setInputElement(option.id);
		} else {
			if (rowData.positions && rowData.positions.length > 0) {
				this.displayPositionGrid = true;
			}
		}
		if (this.selectedRow.isManual === true) {
			this.selectedRow.disableField = false;
			this.selectedRow.disableNameField = false;
		} else if ((this.selectedRow.bankId && !this.selectedRow.isManual)) {
			this.selectedRow.disableField = true;
			this.selectedRow.disableNameField = false;
		} else {
			this.selectedRow.disableField = true;
			this.selectedRow.disableNameField = true;
		}
		if (this.selectedRow.bankId === null) {
			this.selectedRow.disableSelect = true;
		}
		let self = this;
		_.forEach(this.fieldType, function (obj) {
			if (typeof obj.data === "number" || typeof obj.data === "string") {
				if (obj.label === 'Interest Rate') {
					obj.data = rowData.interest_rate;
				} else if (obj.label === 'Balance' || obj.label === 'Value') {
					//obj.data = $filter('number')(rowData.balance, 2);
					obj.data = Math.round(rowData.balance * 100) / 100;
				}
			} else if (typeof obj.data === "boolean") {
				obj.data = rowData.current_employer;
			} else if (typeof obj.data === "object" && rowData.account_holder_info) {
				self.accountHolder = { "obj": [] };
				self.accountHolder.obj[0] = rowData.account_holder_info.fullname;
				self.accountHolder.obj[1] = rowData.account_holder_info.id;
			}
		});

	};

	linkAccountLoad(bank) {
		if ((bank.bank_api_level && bank.bank_api_level == 'plaid') || (bank.api_level && bank.api_level == 'plaid')) {
			var id = bank.bank_id ? bank.bank_id : bank.id;
			this.openPlaid(id);
		} else {
			this.openLinkAccount(bank);
		}

	}

	openPositionCashModal(crudType, type, positionRow) {
		this.disableQuantity = false;
		this.checkCustomTicker = false;
		if (crudType == 'add') {
			this.positionModalHeading = "Add " + type;
			this.disableQuantity = type === 'Cash' ? true : false;
			this.selectedPosition = {};
		} else {
			if (positionRow.ticker == "CASH") {
				this.disableQuantity = true;
				this.positionModalHeading = "Edit Cash";
			} else {
				this.positionModalHeading = "Edit " + type;
			}

			this.selectedPosition = {};
			this.selectedPosition = {
				symbol: positionRow.ticker,
				description: positionRow.description,
				is_custom_ticker: positionRow.is_custom_ticker,
				quantity: positionRow.shares,
				lastPrice: positionRow.price,
				amount: parseFloat(positionRow.amount).toFixed(2),
				id: positionRow.id
			};
			if (positionRow.is_custom_ticker) {
				this.checkCustomTicker = false;
			} else {
				this.checkCustomTicker = true;
			}
		}

		const openAddpositionModalRef = this.modalService.open(AddPositionModalComponent, { windowClass: 'modal-width-600', backdrop: 'static' });
		openAddpositionModalRef.componentInstance.positionModalHeading = this.positionModalHeading;
		openAddpositionModalRef.componentInstance.selectedPosition = this.selectedPosition;
		openAddpositionModalRef.componentInstance.disableQuantity = this.disableQuantity;
		openAddpositionModalRef.componentInstance.selectedRow = this.selectedRow;
		openAddpositionModalRef.result.then((data) => {
			this.getPositionsData(this.selectedRow.selectedId);
			this.getNetworthData(true);

		});
	}

	getPositionsData(id) {
		let params = `&account_id=${id}`;
		this.apiService.getCall(AppRestEndPoint.DASHBOARD.GET_POSITIONS, params).subscribe(data => {
			if (data.positions) {
				this.positions = data.positions;
			}
		})
	}

	removeAccountModal(bankId) {
		if (this.selectedRow.isManual === false && bankId != null) {
			this.removeInstitution = true;
			this.netWorthData.forEach(function (item) {
				if(item.asset_type == "Liabilities"){
					for (let key in item.accounts){
						item.accounts[key].forEach(function (account) {
							if (account.bank_id === bankId) {
								this.filteredData.push(account);
							}
						}, this);
					}
				}else{
					item.accounts.forEach(function (account) {
						if (account.bank_id === bankId) {
							this.filteredData.push(account);
						}
					}, this);
				}
			}, this);
		} else if (this.selectedRow.isManual === true && bankId != null) {
			this.removeInstitution = false;
			this.selectedRow.account_description = this.selectedRow.accountName;
			this.selectedRow.type = this.selectedRow.type;
			this.selectedRow.isManual = this.selectedRow.isManual;
			this.filteredData.push(this.selectedRow);
		}

		this.presentDeleteConfirm()
	}

	presentDeleteConfirm() {
		const removeAccountModalRef = this.modalService.open(RemoveAccountModalComponent, { windowClass: 'modal-width-650' });
		removeAccountModalRef.componentInstance.removalAccount = this.filteredData;
		removeAccountModalRef.componentInstance.selectedRow = this.selectedRow;
		removeAccountModalRef.componentInstance.removeInstitution = this.removeInstitution;
		removeAccountModalRef.result.then((data) => {
			this.showEdit = false;
			this.getNetworthData(true);
		});
	}

	getSelectedPosition(positionRow, type) {
		if (!type) {
			this.showEditPosition = true;
		}
		this.selectedPosition = {};
		this.selectedPosition = {
			symbol: positionRow.ticker,
			description: positionRow.description,
			amount: parseFloat(positionRow.amount).toFixed(2),
			id: positionRow.id
		};
		if (type === 'delete') {
			this.openDeleteModalPosition(this.selectedPosition.id);
		}
	}

	openDeleteModalPosition(id) {
		this.confirmationDialogService.confirm('CONFIRM', 'Are you sure you want to delete this record?')
			.then((confirmed) => {
				if (confirmed) {
					const apiurl = AppRestEndPoint.DASHBOARD.GET_POSITIONS + '/' + this.selectedPosition.id + '?account_id=' + this.selectedRow.selectedId;
					this.apiService.deleteCall(apiurl, this.selectedPosition.id).subscribe(data => {
						if (data) {

							this.toastService.show('Position deleted successfully', {
								classname: 'bg-success text-light', delay: 3000
							});
							this.getPositionsData(this.selectedRow.selectedId);
							this.getNetworthData(true);
							this.resetAddPosition();
						}
					})

				}
			})
			.catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
	}




	openPlaid(bankId: any = '') {
		this.cs.setBankApiLevelType('plaid');
		this.disableAddAccount = true;
		var url = this.cs.getDomainDetails().api_url + '/plaidOAuth';
		let redirectUrl = encodeURIComponent(url);
		let params = "&redirect_url=" + redirectUrl;
		if (bankId) {
			params = "&bank_id=" + bankId;
			sessionStorage.setItem('plaid_bank_id', bankId);
		}
		let self = this;
		this.apiService.getCall(AppRestEndPoint.PLAID.GET_LINK_TOKEN, params).subscribe(response => {
			if (response && response.token && response.token.error_code && response.token.error_message) {
				this.disableAddAccount = false;

				this.toastService.show(response.token.error_message, {
					classname: 'bg-danger text-light', delay: 3000
				})
			} else if (response && response.token && response.token.link_token) {
				sessionStorage.setItem('plaid_link_token', response.token.link_token);
				this.handler = Plaid.create({
					token: response.token.link_token,
					onSuccess: function (public_token, metadata) {
						let reqObj = {
							"public_token": public_token,
							"meta_data": metadata
						};

						self.apiService.postCall(AppRestEndPoint.PLAID.UPSERT_BANK, reqObj).subscribe(response => {
							if (response) {
								self.bindPusherNetworth();
							}
						});
					},

					onExit: function (err, metadata) {
						let reqObj = {
							"error": err,
							"meta_data": metadata
						};
						if (bankId) {
							self.apiService.postCallQuery(AppRestEndPoint.PLAID.PLAID_EXIT + "?bank_id=" + bankId, reqObj).subscribe(response => {

							});
						} else {
							self.apiService.postCall(AppRestEndPoint.PLAID.PLAID_EXIT, reqObj).subscribe(response => {

							});
						}
						if (err != null && err.error_code === 'INVALID_LINK_TOKEN') {
							self.handler.destroy();
							self.openPlaid();
						}
						console.log('user closed');
						self.bindPusherNetworth();
						setTimeout(() => {
							self.disableAddAccount = false;
						}, 100);
					}
				});
				this.handler.open();
			}
		});
	}

	resetAddPosition() {
		this.selectedPosition.symbol = '';
		this.selectedPosition.description = '';
		this.selectedPosition.amount = 0;
		this.selectedPosition.quantity = 0;
	};

	refreshAccount(bank_id) {
		this.loadRefresh = true;
		//dashboardService.refreshAccount(access_token, bank_id);
	};

	relinkBankPopup = function () {
		this.editLinkBank = "";
		let origlength = this.linkedBanks.length;
		this.sendMixPanelEvent({'event': 'Dashboard - Fix Bank'});
		const openrelinkAccountModalRef = this.modalService.open(RelinkAccountModalComponent, { windowClass: 'modal-width-500' });
		openrelinkAccountModalRef.componentInstance.linkedBanks = this.linkedBanks

		openrelinkAccountModalRef.result.then((data) => {
			if(data){
				this.selectedBankId = data.bank.id;
				if (data.bank.api_level == 'plaid') {
					this.openPlaid(this.selectedBankId);
				} else {
					if(data.type){
						this.openLinkAccount(data,data.type);
					}else{
						this.openLinkAccount(data);
					}
				}
			}

		});

	};

	hardRefreshLinkedBank() {
	}

	openComingSoonModal() {
		const quovoAccountModalRef = this.modalService.open(QuovoAccountModalComponent, { windowClass: 'modal-width-650' });
		quovoAccountModalRef.result.then((data) => {
			this.showEdit = false;
			this.getNetworthData(true);
		});
	}


	getDifference(param1: number, param2: any) {
		return this.roundTo(Number(param1) - Number(param2));
	}

	scroll(el: any) {
		el=='networth'?this.networth.nativeElement.scrollIntoView({ block: "start", inline: "nearest" }) : this.allocationAnalysisId.nativeElement.scrollIntoView({ block: "start", inline: "nearest" });
	}

	closeModal(data) {
		if (data === 'callPusher') {
			this.activeModalService.dismiss({ 'success': 'closeModal', 'reload': true });
		} else {
			this.activeModalService.dismiss({ 'success': 'closeModal', 'edit': false });
		}
	}

	openLinkExternalAccountModal($event) {

		if ($event) {
			$event.stopPropagation();
			$event.preventDefault();
		}
		this.sendMixPanelEvent({'event': 'Dashboard - Link External Account'});
		this.linkExternalAccountModalInstance = this.modalService.open(LinkExternalAccountModalComponent, { windowClass: 'modal-width-800' });
		this.linkExternalAccountModalInstance.result.then((data) => {
			if (data && data.type == 'plaid') {
				this.openPlaid();
			} else if (data && data.type == 'yodlee') {
				this.openLinkAccount();
			}
		});

	}

	sendMixPanelEvent(obj){
		this.utilService.gtmPush(obj);
	}

	openIncomeExpenseModal(){
		this.incomeExpenseDataOld = JSON.parse(JSON.stringify(this.cashFlowDetails.income_and_expense));
		const incomeExpenseModalRef = this.modalService.open(
			IncomeExpenseModalComponent,
			{
				windowClass: 'modal-width-850',
				backdrop: 'static'
			}
		);
		incomeExpenseModalRef.componentInstance.incomeExpenseLoadData = this.cashFlowDetails.income_and_expense;
		incomeExpenseModalRef.result.then((data) => {
			if (data && data.income_expense_data) {
				this.cashFlowDetails = data.income_expense_data;
				this.incomeExpenseDataOld = JSON.parse(JSON.stringify(this.cashFlowDetails));
			}else{
				this.cashFlowDetails.income_and_expense = JSON.parse(JSON.stringify(this.incomeExpenseDataOld));
			}
		});
	}

}
