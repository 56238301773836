import { Directive } from '@angular/core';
import { AbstractControl, AsyncValidator, AsyncValidatorFn, NG_ASYNC_VALIDATORS, ValidationErrors } from '@angular/forms';
import { Observable, timer } from 'rxjs';
import { map, switchMap } from "rxjs/operators";
import { AppRestEndPoint } from '../app-restEndPoint';
import { ApiService } from '../services/api.service';

export function existingUsernameValidator(apiService: ApiService, token: String): AsyncValidatorFn {
	return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
		let debounceTime = 1000; //milliseconds
		if (token) {
			return timer(debounceTime).pipe(
				switchMap(() => apiService.getCall(AppRestEndPoint.LOGIN.USER_LIST + '/valid_name', "&name=" + control.value)),
				map(data => {
					return (!data.message) ? { "usernameExists": true } : null;
				})
			);
		} else {
			return timer(debounceTime).pipe(
				switchMap(() => apiService.getCallWithoutToken(AppRestEndPoint.LOGIN.USER_LIST + '/valid_name', "?name=" + control.value)),
				map(data => {
					return (!data.message) ? { "usernameExists": true } : null;
				})
			);
		}
	};
}

@Directive({
	selector: '[usernameExists][formControlName],[usernameExists][formControl],[usernameExists][ngModel]',
	providers: [{ provide: NG_ASYNC_VALIDATORS, useExisting: ValidNameDirective, multi: true }]
})
export class ValidNameDirective implements AsyncValidator {

	accessToken: any;
	constructor(private apiService: ApiService) { }

	validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
		return existingUsernameValidator(this.apiService, this.accessToken)(control);
	}

}
