import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppRestEndPoint } from 'src/app/app-restEndPoint';
import { LoginService } from 'src/app/services/login.service';
import { Validator } from 'src/app/services/validator';
import { countries } from './../../constants/countries';
import { ToastService } from 'src/app/components/toaster/toast-service';

@Component({
	selector: 'app-enable-authy',
	templateUrl: './enable-authy.component.html',
	styleUrls: ['./enable-authy.component.scss']
})
export class EnableAuthyComponent implements OnInit {

	enableAuthyForm: FormGroup;
	cellPhone: any;
	country: any;
	enableAuthyFormSubmitted: boolean;
	params: string;
	userId: any;
	countries: Array<any>;

	constructor(private formBuilder: FormBuilder,
		private loginService: LoginService,
		private router: Router,
		private toastService: ToastService) {
		localStorage.getItem('user_id');
	}

	ngOnInit(): void {
		this.countries = countries;
		this.enableAuthyForm = this.formBuilder.group({
			'country': [null, Validators.required],
			'cellPhone': ['', Validators.required],
		});
		this.userId = localStorage.getItem('user_id');

	}

	enableAuthy(requestPhoneCall) {
		this.enableAuthyFormSubmitted = true;
		if (this.cellPhone) {
			let cellPhone = this.cellPhone.replace(/[^\d]/g, "");
			if (cellPhone.length < 10) {
				this.enableAuthyForm.controls['cellPhone'].setErrors({ 'incorrect': true });
			}else{
				this.enableAuthyForm.controls['cellPhone'].setErrors(null);
			}
		}
		if (this.enableAuthyForm.valid) {
			let cellPhone = this.cellPhone.replace(/[^\d]/g, "");
			if (requestPhoneCall) {
				this.params = `?id=${this.userId}&cellphone=${cellPhone}&country_code=${this.country.code}&request_call=${requestPhoneCall}&authy_type='sms'`;
			}
			else {
				this.params = `?id=${this.userId}&cellphone=${cellPhone}&country_code=${this.country.code}&authy_type=sms`;
			}
			localStorage.setItem('authy_type','sms');
			const url = AppRestEndPoint.LOGIN.ENABLE_AUTHY + this.params;
			this.loginService.postCall(url, '').subscribe(data => {
				if (data.success) {
					this.router.navigate(['enterToken']);
				} else {
					this.toastService.show(data.msg.replace(/<\/?[^>]+(>|$)/g, "\n"), {
						classname: 'bg-danger text-light', delay: 7000
					});
				}
			});
		}
	}


	get formControl() {
		return this.enableAuthyForm.controls;
	}

	gotoMfaPage() {
		this.router.navigate(['enableAuthyMFA']);
	}

}
