<div class="container">
    <div class="row">
        <div class="col-md-6 col-xs-10 col-sm-12 m-auto">
            <div class="card  border-radius-10 border-0 shadow-sm p-2">
                <div class="card-body pb-1">
                    <div class="pb-3">
                        <h2 class="text-left text-uppercase mb-0 mt-3">AUTHY SETUP</h2>
                        <hr class="header-hr my-1">
                        <div class="text-center">
                            <qrcode [qrdata]="qrcode" [width]="256"></qrcode>
                        </div>
                        <hr class="header-hr my-1">
                        <div class="mt-3">
                            <b>Key</b> <br />
                            <span (click)="copyLinkToaster()" title="Click to copy text" ngxClipboard
                                [cbContent]="secretKey">
                                {{secretKey}}<i class="fa fa-clone cursor-pointer ml-3" aria-hidden="true"></i>
                            </span>
                        </div>
                        <hr class="header-hr my-3">
                        <div class="pb-5">
                            <div class="float-left mt-3">
                                <a class="cursor-pointer" (click)="openAuthyLink()">
                                    DOWNLOAD AUTHY APP
                                </a>
                            </div>
                            <div class="float-right">
                                <button type="button"
                                    class="btn btn-outline-secondary font-14 float-left text-uppercase row btn-sm rounded-pill mr-1"
                                    (click)="gotoEnableMFA()">
                                    Cancel
                                </button>
                                <button class="btn btn-primary btn-sm rounded-pill" (click)="enableAuthy()">
                                    Enable
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>