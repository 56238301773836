import { Injectable } from '@angular/core';
import { interval } from 'rxjs';
import { CommonService } from './common.service';
import { EventEmitterService } from './event-emitter.service';
import { LoginService } from './login.service';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { v4 as uuidv4 } from 'uuid';
import { MixpanelEventService } from './mixpanel-event.service';
import { UAParser } from 'ua-parser-js';
import { CookieService } from 'ngx-cookie-service';


@Injectable({
	providedIn: 'root'
})
export class UtilityService {
	firmCodeCounter = 0;
	gtm_guid:any;
	subscription:any;
	constructor(
		private commonService: CommonService,
		private eventEmitter: EventEmitterService,
		private loginService: LoginService,
		private currencyPipe: CurrencyPipe,
		private decimalPipe: DecimalPipe,
		public mixpanelEventService: MixpanelEventService,
		private cookieService: CookieService

	) { 
		let access_token = sessionStorage.getItem('token');
		if (access_token) {
			this.subscription = interval(60000).subscribe(x => {
				this.gtmPush({ 'event': 'Heartbeat' });
			});
		}
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	  }

	getDeviceCookie = function () {
		/*Storing the device ID in a service instead of a mobile storage because the promise returned from storage is time consuming*/
		this.deviceId = this.cookieService.get('guid');
		if (this.deviceId === '' || this.deviceId === null || this.deviceId === undefined) {
			this.deviceId = this.generateGUID();
			this.cookieService.set('guid',this.deviceId);

		}
		console.log('DEVICE ID' + this.deviceId);
		return this.deviceId;
	};


	generateGUID = function () {
		function s4() {
			return Math.floor((1 + Math.random()) * 0x10000)
				.toString(16)
				.substring(1);
		}

		return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
			s4() + '-' + s4() + s4() + s4();
	};

	getUserAgent = function () {
		/* Since user agent requires ionic native plugins to be upgarded. Just sending a string 'ionic4Clientapp'*/
		return 'clientWebApp';
	}

	setRootScopeFirm(firmCode) {
		if (this.commonService.getDomainDetails().api_url) {
			let url = this.commonService.getDomainDetails().api_url;
			this.getDomainFirmDetails(url, firmCode);
		} else {
			this.eventEmitter.subscribe("Domain", (domainObj) => {
				if (domainObj && this.firmCodeCounter == 0) {
					let url = domainObj.api_url;
					this.getDomainFirmDetails(url, firmCode);
				}
			});
		}
	}

	getDomainFirmDetails(url, firmCode) {
		this.firmCodeCounter = 1;
		let domainObj = {};
		this.loginService.getCall(url + '/domains?target=client&firm_code=' + firmCode, '').subscribe(response => {
			if (response.data) {
				domainObj['firm_code'] = firmCode;
				domainObj['firm_name'] = response.data.name;
				domainObj['domain_css'] = response.data.domain_css;
				domainObj['firm_id'] = response.data.firm_id;
				domainObj['domainLogo'] = response.data.logo;
				this.commonService.setDomainFirmDetails(domainObj);
				this.eventEmitter.broadcast("Domain", domainObj);
			} else {
				alert(response.error);
			}
		},
			(err) => {
				throw err;
			});
	}

	public unique(origArr) {
		let newArr = [],
			origLen = origArr.length,
			found, x, y;

		for (x = 0; x < origLen; x++) {
			found = undefined;
			for (y = 0; y < newArr.length; y++) {
				if (origArr[x].name === newArr[y].name) {
					found = true;
					break;
				}
			}
			if (!found) {
				newArr.push(origArr[x]);
			}
		}
		return newArr;
	}

	formatData(data, key) {
		const formattedData = [];
		if (data) {
			for (let i = 0; i < data.length; i++) {
				formattedData[i] = data[i][key];
			}
		}

		return formattedData;
	}

	formatValue(element, type) {
		if (type == 'currency') {
			if (element.target.value.includes('$')) {
				return;
			}
			let formattedAmount = this.currencyPipe.transform(element.target.value, 'USD', 'symbol', '1.0-0');
			element.target.value = element.target.value ? formattedAmount : 0;
		} else if (type == 'percentagetransfer') {
			if (element.target.value == 0) {
				element.target.value = "0";
			}
			element.target.value = element.target.value ? element.target.value.toString().replace(/%/g, '') : '';
			element.target.value = element.target.value ? this.decimalPipe.transform(element.target.value, "1.0-0") + '%' : '';
		} else if (type == 'threedecimal') {
			if (element.target.value) {
				element.target.value = element.target.value ? parseFloat(element.target.value).toFixed(3) : 0;
			}
		}
	}

	getFormattedValue(value, type) {
		if (type == 'currency') {
			let formattedAmount = this.formatCurrency(value);
			formattedAmount = this.currencyPipe.transform(formattedAmount, 'USD', 'symbol', '1.0-0');
			return formattedAmount;
		}
	}

	formatCurrency = function (value) {
		let plainAmount;
		if (typeof (value) !== 'number') {
			plainAmount = value.replace(/[^\d|\-+|\.+]/g, '');
		} else {
			plainAmount = value;
		}
		return plainAmount;
	}

	clearStorage = function () {
		sessionStorage.removeItem('token');
		sessionStorage.removeItem('current_user');
		sessionStorage.removeItem('current_index');
		sessionStorage.removeItem('household_username');
		sessionStorage.removeItem('onboarding_invitation');
		sessionStorage.removeItem('environment');
		sessionStorage.removeItem('firm_Name');
		sessionStorage.removeItem('login_rtq_id');
		sessionStorage.removeItem('rtq_redirect');
		sessionStorage.removeItem('current_selected_household');
		localStorage.removeItem('user_id');
		localStorage.removeItem('authy_flag');
		localStorage.removeItem('knowledge_base_url');
		sessionStorage.removeItem('is_questionnaire_required');
		sessionStorage.removeItem('advisor_admin_user_id');
		sessionStorage.removeItem('signUpComplete');
		sessionStorage.removeItem('idleTimeoutTimer');
		sessionStorage.removeItem('posted_document');
		sessionStorage.removeItem('plaid_open_page');
		sessionStorage.removeItem('plaid_link_token');
		sessionStorage.removeItem('page_info_type');
		sessionStorage.removeItem('plaid_bank_id');
		sessionStorage.removeItem('task_id');
		localStorage.removeItem('twilio_entity_sid');
		localStorage.removeItem('twilio_factor_sid');
		localStorage.removeItem('authy_type');
	}

	gtmIdentify(user_id, eventObj) {
		this.gtmPush({
			'event': 'Identify',
			'userId': user_id,
			'userName': null,
			'userEmail': null,
			'userFirmId': null,
			'userFirm': null
		});
	};

	gtmPush(eventObj) {
		let access_token = sessionStorage.getItem('token');
		let current_user = sessionStorage.getItem('current_user')?JSON.parse(sessionStorage.getItem('current_user')):'';
		this.gtm_guid = sessionStorage.getItem('gtm_guid');
		let userAgent = window.navigator.userAgent;
		let parser = new UAParser();
		let user_id;
		parser.setUA(userAgent);
		let obj = {
			'event_name': '',
			"properties": {
				'Event ID': '',
				'User ID': '',
				'User Fullname': '',
				'User Firstname': '',
				'User Lastname': '',
				'User Email': '',
				'User Firm ID': '',
				'User Firm': '',
				'Method': '',
				'Browser': '',
				'Browser Version': '',
				'Screen Height': '',
				'Screen Width': '',
				'Device ID': '',
				'userFullname': '',
				'OS': '',
				'Unique ID': '',
				'Answer':''
			}
		};
		if (eventObj.userId) {
			eventObj.userId = eventObj.userId;
			user_id = eventObj.userId;
		} else if (current_user) {
			eventObj.userId = current_user.id;
			eventObj.userFirmId = current_user.firm.id;
			eventObj.userFirm = current_user.firm.name;
		}
		eventObj = {
			'event_name': eventObj.event,
			"properties": {
				'Event ID': eventObj.eventId ? eventObj.eventId : '',
				'User ID': eventObj.userId ? eventObj.userId : '',
				'User Fullname': current_user ? current_user.name : '',
				'User Firstname': eventObj.userFirstName ? eventObj.userFirstName : '',
				'User Lastname': eventObj.userLastName ? eventObj.userLastName : '',
				'User Email': eventObj.userEmail ? eventObj.userEmail : '',
				'User Firm ID': eventObj.userFirmId ? eventObj.userFirmId : '',
				'User Firm': eventObj.userFirm ? eventObj.userFirm : '',
				'Method': eventObj.method ? eventObj.method : '',
				'Browser': parser.getResult().browser.name,
				'Browser Version': parser.getResult().browser.version,
				'Screen Height': window.innerHeight,
				'Screen Width': window.innerWidth,
				'OS': parser.getResult().os.name,
				'Device ID': uuidv4(),
				'Unique ID': eventObj.unique_id ? eventObj.unique_id : '',
				'Answer':eventObj.answer?eventObj.answer:''
			}
		};
		let newEventObj = Object.assign(obj, eventObj);
		console.log('Mixpanel event object', newEventObj);

		if (newEventObj.event_name == 'Identify') {
			this.mixpanelEventService.gtmIdentify(this.gtm_guid, user_id).subscribe(response => {
				if (response) {

				}
			});
		} else {
			this.mixpanelEventService.gtmPushEvent(newEventObj, this.gtm_guid).subscribe(response => {
				if (response && this.gtm_guid == null) {
					sessionStorage.setItem('gtm_guid', response.properties.guid);
				}
			});
		}
	};


	gtmProfile(eventObj) {
		eventObj['event'] = 'Profile';
		this.gtmPush(eventObj);
	};

	formatSsn(value) {
        var trimmedValue;
        if (value) {
            trimmedValue = value.replace(/\-/g, '');
        }
        if (trimmedValue) {
            if (trimmedValue.length >= 9) {
                return trimmedValue.substr(0, 3) + "-" + trimmedValue.substr(3, 2) + "-" + trimmedValue.substr(5);
            } else if (trimmedValue.length >= 5) {
                return trimmedValue.substr(0, 3) + "-" + trimmedValue.substr(3, 2) + "-" + trimmedValue.substr(5);
            } else if (trimmedValue.length >= 3) {
                return trimmedValue.substr(0, 3) + "-" + trimmedValue.substr(3);
            }
        }
        return trimmedValue;
    };

	removeSsnFormat(value){
        if (value) {
            return value.replace(/-/g, "");
        }
        return value;
    };
}
