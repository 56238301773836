import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-show-usps-address-modal',
	templateUrl: './show-usps-address-modal.component.html',
	styleUrls: ['./show-usps-address-modal.component.scss']
})
export class ShowUspsAddressModalComponent implements OnInit {
    @Input() public addressError = [];
    @Input() public providedAddress = [];
    @Input() public validAddressData = [];
	@Input() public addressIndex: any;
	@Input() public employerAddress: boolean;
    defaultChecked = true;
    addressValue: any = '2';
	header: any = '';
	mailing_header: any = '';

	constructor(public activeModal: NgbActiveModal) {
	}

	ngOnInit(): void {
		this.mailing_header = this.providedAddress['address_header'];
		if (!this.employerAddress) {
			this.employerAddress = true;
		} else {
			this.header = 'EMPLOYER';
			this.employerAddress = false;
		}
	}

    closeModal() {
		this.activeModal.close();
	}

    saveAddress() {
		this.activeModal.close({'val':'confirm', 'addressValue': this.addressValue, 'employerAddress': this.employerAddress, 'addressIndex': this.addressIndex});
	}

}
