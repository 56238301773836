import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppRestEndPoint } from 'src/app/app-restEndPoint';
import { ToastService } from 'src/app/components/toaster/toast-service';
import { ApiService } from 'src/app/services/api.service';
import * as _ from 'lodash';
import { UtilityService } from 'src/app/services/utility.service';
import { TaskNotesModalComponent } from '../task-notes-modal/task-notes-modal.component';
import { TreeViewComponent } from 'src/app/components/tree-view/tree-view.component';
import { EventEmitter } from 'stream';
import { EventEmitterService } from 'src/app/services/event-emitter.service';
import { ConfirmationDialogService } from 'src/app/services/confirmation-dialog.service';


@Component({
	selector: 'app-task-modal',
	templateUrl: './task-modal.component.html',
	styleUrls: ['./task-modal.component.scss']
})
export class TaskModalComponent implements OnInit {
	@Input() taskData;
	taskTabs: any = {};
	uploadDocObj: any = {};
	workflowChecklistObj: any = {};
	workflowTemplateFullList: any;
	currentUser: any;
	oldTaskData: any;
	constructor(
		private activeModal: NgbActiveModal,
		private apiService: ApiService,
		private toastService: ToastService,
		private sanitizer: DomSanitizer,
		private utilityService: UtilityService,
		private modalService: NgbModal,
		private confirmationDialogService: ConfirmationDialogService,
		private eventEmitter: EventEmitterService

	) {
		this.setActiveTab(0);
		this.uploadDocObj.files = [];
		this.eventEmitter.subscribe("updatedStepCount", (data) => {
			if (data) {
				this.workflowChecklistObj.workflowStepCount = data.stepCount;
			}
		});
		this.oldTaskData = this.taskData;
	}

	ngOnInit(): void {
		this.uploadDocObj.files = this.taskData.user_documents;
		if (this.taskData.company_workflow && this.taskData.company_workflow != null && this.taskData.company_workflow != '') {
			this.workflowChecklistObj.key = 'company_workflow_steps';
			this.workflowChecklistObj.dontResetTemplate = true;
			this.workflowChecklistObj.selectedTemplate = this.taskData.company_workflow;
			this.workflowChecklistObj.savedWorkflowStepList = this.taskData.company_workflow_steps_tasks;
			this.workflowChecklistObj.workflowStepCount = this.taskData.workflow_incomplete_count;
			this.getTaskWorkflow();
		}

	}

	closeModal(data: any = '') {
		this.activeModal.close(data);
	}

	formatTaskData(data) {
		var formData = new FormData();
		let documentTypeId: any = 101; let documentTemplateId: any = 1;
		if (data.user_documents.length > 0) {
			for (var i in data.user_documents) {
				if (data.user_documents[i].id) {
					formData.append("task[user_documents_attributes][" + i + "][id]", data.user_documents[i].id);
				} else {
					formData.append("task[user_documents_attributes][" + i + "][user_document_type_id]", documentTypeId);
					formData.append("task[user_documents_attributes][" + i + "][document_template_id]", documentTemplateId);
					formData.append("task[user_documents_attributes][" + i + "][url]", data.user_documents[i]);
					formData.append("task[user_documents_attributes][" + i + "][description]", data.user_documents[i].name);
				}
				if (data.user_documents[i].destroy) {
					formData.append("task[user_documents_attributes][" + i + "][_destroy]", data.user_documents[i].destroy);
				}
			}
		}

		if (this.taskData.notes.length > 0) {
			for (let i = 0; i < this.taskData.notes.length; i++) {
				formData.append('task[notes_attributes][' + i + '][note]', this.taskData.notes[i].note);
				formData.append('task[notes_attributes][' + i + '][subject]', this.taskData.notes[i].subject);
				if (this.taskData.notes[i].id) {
					formData.append('task[notes_attributes][' + i + '][id]', this.taskData.notes[i].id);
				} else {
					formData.append('task[notes_attributes][' + i + '][created_by_admin_user_id]', this.taskData.notes[i].created_by_admin_user.id)
				}
				formData.append('task[notes_attributes][' + i + '][user_id]', this.taskData.notes[i].user.id);
				if (this.taskData.notes[i].status == 'Deleted') {
					formData.append('task[notes_attributes][' + i + '][status]', 'Deleted');
				} else {
					formData.append('task[notes_attributes][' + i + '][status]', 'Created');
				}
				formData.append('task[notes_attributes][' + i + '][updated_by_admin_user_id]', this.taskData.notes[i].updated_by_admin_user.id);
			}

		}

		if (this.workflowChecklistObj.selectedTemplateId) {
			formData.append("task[company_workflow_id]", this.workflowChecklistObj.selectedTemplateId);
			formData.append("task[workflow_incomplete_count]", this.workflowChecklistObj.workflowStepCount);
			const workFlowObj = this.workflowChecklistObj.workFlowReqObj;
			for (let i = 0; i < workFlowObj.length; i++) {
				Object.keys(workFlowObj[i]).map(function (key) {
					formData.append('task[company_workflow_steps_tasks_attributes][' + i + '][' + key + ']', workFlowObj[i][key]);
				});
			}
		}

		if(data.user_documents.length == 0 && this.taskData.notes.length == 0 && this.workflowChecklistObj.selectedTemplateId === undefined){
			formData.append("task[name]", data.name);
		}

		return formData;
	}

	completeTask(flag) {
		this.workflowChecklistObj.errorMessage = false;
		if (flag == true) {
			if(this.taskData.company_workflow && this.taskData.company_workflow.id){
				this.workflowChecklistObj.workflowStepCount == 0 ?this.completeTaskApiCall('?checked=true'):this.workflowChecklistObj.errorMessage = true;
			}else {
				this.completeTaskApiCall('?checked=true');
			}
		} else if (flag == false) {
			this.completeTaskApiCall('');
		} 
	}

	completeTaskApiCall(completeTaskFlag) {
		this.apiService.updateMultipartFormCall(AppRestEndPoint.DASHBOARD.TASK_COMPLETED.replace(':id', this.taskData.id) + completeTaskFlag, this.formatTaskData(this.taskData), completeTaskFlag).subscribe(data => {
			if (data && data.success) {
				if (!this.taskData.noteModal) {
					this.closeModal(data);
				}
				if ((this.taskData.notesIndex>=0) && this.taskData.notes[this.taskData.notesIndex].status == 'Deleted') {
					this.taskData.notes.splice(this.taskData.notesIndex, 1);
					this.taskData.notesIndex = '';
				}
			} else {
				this.toastService.show('Save Unsuccessful', {
					classname: 'bg-danger text-light', delay: 3000
				});
				if (!this.taskData.noteModal) {
					this.closeModal(data);
				}
				this.taskData = this.oldTaskData;
			}
		});
	}

	setActiveTab(index) {
		switch (index) {
			case 0: this.taskTabs.showGeneralTab = true; this.taskTabs.showWorkflowTab = false; this.taskTabs.showDocumentsTab = false; break;
			case 1: this.taskTabs.showWorkflowTab = true; this.taskTabs.showGeneralTab = false; this.taskTabs.showDocumentsTab = false; break;
			//  case 2: this.taskTabs.showAssociatedTab = true; this.taskTabs.showGeneralTab = false; this.taskTabs.showWorkflowTab = false; this.taskTabs.showDocumentsTab = false; break;
			case 3: this.taskTabs.showDocumentsTab = true; this.taskTabs.showGeneralTab = false; this.taskTabs.showWorkflowTab = false; break;
		}
	};

	removeFiles(index) {
		if (this.uploadDocObj.files[index].id) {
			this.uploadDocObj.files[index].destroy = true;
		} else {
			this.uploadDocObj.files.splice(index, 1);
		}

	}

	downloadDoc(document) {
		let url = AppRestEndPoint.DASHBOARD.DOCUMENT_LIST + '/' + document.id + '/url'
		if (document.id) {
			this.apiService.getCall(url, '').subscribe(response => {
				if (response && response.url && response.url != null) {
					var url = this.sanitizer.bypassSecurityTrustResourceUrl(response.url);
					window.open(response.url, '_blank');
				} else if (response.message != null) {
					this.toastService.show(response.message, {
						classname: 'bg-danger text-light', delay: 3000
					});
				} else {
					this.toastService.show('Document not found', {
						classname: 'bg-danger text-light', delay: 3000
					});
				}
			});
		} else {
			var file = new Blob([document], { type: document.type });
			var fileURL = window.URL.createObjectURL(file);
			window.open(fileURL, '_blank');
		}
	};


	getFileDetails = function (e) {
		for (var i = 0; i < e.files.length; i++) {
			e.files[i].description = e.files[i].name;
			this.uploadDocObj.files.push(e.files[i]);
		}
	};

	setTemplate() {
		let self = this;
		let selectedTemplate = _.find(this.workflowChecklistObj.workflowTemplate, function (item) {
			return item.id == self.workflowChecklistObj.selectedTemplate.id;
		});
		if (selectedTemplate) {
			if (!this.workflowChecklistObj.savedWorkflowStepList || this.workflowChecklistObj.savedWorkflowStepList === '') {
				this.workflowChecklistObj.workflowStepCount = selectedTemplate.company_workflow_steps.length;
			}
			this.workflowChecklistObj.workflowStepList = JSON.parse(JSON.stringify(selectedTemplate.company_workflow_steps));
			this.formatWorkflowObj();
		}
	}
	getTaskWorkflow() {
		this.apiService.getCall(AppRestEndPoint.DASHBOARD.TASK_WORKFLOW, '').subscribe(data => {
			this.workflowTemplateFullList = data.company_workflows;
			_.remove(this.workflowTemplateFullList, function (item: any) {
				return item.category == "Default";
			});
			this.workflowChecklistObj.templateCategory = _.uniq(_.map(this.workflowTemplateFullList, 'category'));
			if (this.workflowChecklistObj.selectedTemplate && this.workflowChecklistObj.selectedTemplate != '') {
				const tempId = this.workflowChecklistObj.selectedTemplate.id;
				const templateData = _.find(this.workflowTemplateFullList, function (item) {
					return item.id == tempId;
				});
				this.workflowChecklistObj.category = JSON.parse(JSON.stringify(templateData.category));
				this.setTemplateValues();
				this.workflowChecklistObj.selectedTemplateCopy = JSON.parse(JSON.stringify(this.workflowChecklistObj.selectedTemplate));
				this.workflowChecklistObj.selectedTemplate = {};
				this.workflowChecklistObj.selectedTemplate = {
					'id': this.workflowChecklistObj.selectedTemplateCopy.id,
					'name': this.workflowChecklistObj.selectedTemplateCopy.name,
					'url': this.workflowChecklistObj.selectedTemplateCopy.url,
					'document': this.workflowChecklistObj.selectedTemplateCopy.document,
					'notes': this.workflowChecklistObj.selectedTemplateCopy.notes,
					'note_subject': this.workflowChecklistObj.selectedTemplateCopy.note_subject,
					'company_workflow_steps': templateData.company_workflow_steps
				};
				this.workflowChecklistObj.selectedTemplateId = this.workflowChecklistObj.selectedTemplate.id;
				this.setTemplate();
				this.workflowChecklistObj.templateExists = true;
			} else {
				if (this.workflowChecklistObj.templateCategory && this.workflowChecklistObj.templateCategory.length == 1) {
					this.workflowChecklistObj.category = this.workflowChecklistObj.templateCategory[0];
					this.setTemplateValues();
				}
			}
		});
	}

	setTemplateValues() {
		this.workflowChecklistObj.selectedTemplateId = '';
		let self: any = this;
		this.workflowChecklistObj.workflowTemplate = _.filter(this.workflowTemplateFullList, function (item) {
			return item.category == self.workflowChecklistObj.category;
		}.bind(this));
	};

	formatWorkflowObj() {
		if (this.workflowChecklistObj.workflowStepList.length > 0) {
			for (let i = 0; i < this.workflowChecklistObj.workflowStepList.length; i++) {
				// set req object
				this.workflowChecklistObj.workFlowReqObj = [];
				const tempId = this.workflowChecklistObj.workflowStepList[i].id;
				const parentDataExists = _.find(this.workflowChecklistObj.savedWorkflowStepList, function (item) {
					return item.company_workflow_step.id == tempId;
				});
				if (parentDataExists && (parentDataExists.is_completed == true || parentDataExists.is_completed == false)) {
					let status = '';
					if (parentDataExists.is_completed) {
						status = 'Completed';
					} else {
						if (parentDataExists.is_not_applicable) {
							status = 'Not_Applicable';
						} else {
							status = 'Started';
						}
					}
					this.workflowChecklistObj.workflowStepList[i].is_completed = parentDataExists.is_completed;
					this.workflowChecklistObj.workflowStepList[i].savedId = parentDataExists.id;
					this.workflowChecklistObj.workFlowReqObj.push({ 'company_workflow_step_id': this.workflowChecklistObj.workflowStepList[i].id, 'status': status, 'id': this.workflowChecklistObj.workflowStepList[i].savedId });
				}
				// hide the first child if parent is unchecked
				let completeChild1 = false;
				const childLen1 = this.workflowChecklistObj.workflowStepList[i].company_workflow_steps.length;
				for (let c = 0; c < childLen1; c++) {
					if (!this.workflowChecklistObj.workflowStepList[i].is_completed) {
						this.workflowChecklistObj.workflowStepList[i].company_workflow_steps[c].hideFlag = true;
					} else {
						// assign saved data
						const tempId = this.workflowChecklistObj.workflowStepList[i].company_workflow_steps[c].id;
						const childDataExists = _.find(this.workflowChecklistObj.savedWorkflowStepList, function (item) {
							return item.company_workflow_step.id == tempId;
						});
						if (childDataExists && (childDataExists.is_completed == true || childDataExists.is_completed == false)) {
							this.workflowChecklistObj.workflowStepList[i].company_workflow_steps[c].is_completed = childDataExists.is_completed;
							this.workflowChecklistObj.workflowStepList[i].company_workflow_steps[c].savedId = childDataExists.id;
						}
						// Flag to check if any of the children is checked
						if (this.workflowChecklistObj.workflowStepList[i].company_workflow_steps[c].is_completed) {
							completeChild1 = true;
						}
					}
				}
				if (completeChild1) {
					this.workflowChecklistObj.workflowStepList[i].disableFlag = true;
				}

				this.workflowChecklistObj.workflowStepList[i].company_workflow_steps = this.buildInitialData(this.workflowChecklistObj.workflowStepList[i].company_workflow_steps);
			}
		}
	}

	buildInitialData(data) {
		for (let i = 0; i < data.length; i++) {
			if (data[i].parent_id == null) {
			} else {
				// make children visible
				const childLen = data[i].company_workflow_steps.length;
				let completeChild = false;

				if (this.workflowChecklistObj.savedWorkflowStepList && this.workflowChecklistObj.savedWorkflowStepList.length > 0) {
					// set is_completed, id, saved id and reqobj
					let dataExists = null;
					dataExists = _.find(this.workflowChecklistObj.savedWorkflowStepList, function (item) {
						return item.company_workflow_step.id == data[i].id;
					});
					if (dataExists && dataExists.is_not_applicable) {
						data[i].is_not_applicable = true;
					} else {
						data[i].is_not_applicable = false;
					}
					if (dataExists && dataExists.completed_date) {
						data[i].completed_date = dataExists.completed_date;
						data[i].is_completed = true;
						data[i].savedId = dataExists.id;
						this.workflowChecklistObj.workFlowReqObj.push({ 'company_workflow_step_id': data[i].id, 'status': 'Completed', 'id': data[i].savedId });
					} else {
						if (dataExists && dataExists.is_completed == false) {
							data[i].savedId = dataExists.id;
							let status = '';
							if (dataExists.is_not_applicable) {
								status = 'Not_Applicable';
							} else {
								status = 'Started';
							}
							this.workflowChecklistObj.workFlowReqObj.push({ 'company_workflow_step_id': data[i].id, 'status': status, 'id': data[i].savedId });
						}
					}
				}
				for (let j = 0; j < childLen; j++) {
					if (data[i].is_completed) {
						data[i].company_workflow_steps[j].hideFlag = false;
					} else {
						data[i].company_workflow_steps[j].hideFlag = true;
					}
					// Flag to check if any of the children is checked
					if (data[i].company_workflow_steps[j].is_completed) {
						completeChild = true;
					}
				}
				// disable parent if any of the children are checked
				if (completeChild) {
					data[i].disableFlag = true;
				}
				if (childLen > 0) {
					this.buildInitialData(data[i].company_workflow_steps);
				}
			}
		}
		return data;
	}

	openTaskNoteModal(data) {
		const openTaskModalRef = this.modalService.open(TaskNotesModalComponent, { windowClass: 'modal-width-650', backdrop: 'static' });
		if (data) {
			openTaskModalRef.componentInstance.taskNoteData = data;
		}
		openTaskModalRef.result.then((noteData) => {
			if (noteData) {
				this.taskData.noteModal = true;
				data ? '' : this.taskData.notes.push(noteData);
			}
		});
	}

	deleteNote(note, index) {
		this.confirmationDialogService.confirm('CONFIRM', 'Are you sure you want to delete note ?')
			.then((confirmed) => {
				if (confirmed) {
					this.taskData.noteModal = true;
					this.taskData.notes[index].status = 'Deleted';
					this.taskData.notesIndex = index;
				}
			})
			.catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));

	}



}
