<div class="container">
    <div class="row">
        <div class="col-md-6 col-xs-10 col-sm-12 m-auto">
            <div class="card border-radius-10 border-0 shadow-sm p-2">
                <div class="card-body pb-1" [hidden]="hideLogin">
                    <form [formGroup]="loginForm" novalidate>
                        <h2 class="text-left mb-0 mt-2">Client Login</h2>
                        <hr class="header-hr my-1">
                        <div class="pt-3 pb-3">
                            <label class="form-label text-uppercase">Email</label>
                            <input name="email" type="email" autocomplete="off" class="form-control form-input p-2"
                                autofocus="true" (trim)="true" [(ngModel)]="login.email" formControlName="userEmail"
                                (input)="($event.charCode==13)? submitLogin() : ''">
                            <div *ngIf="loginFormSubmitted" class="text-danger" role="alert">
                                <div *ngIf="formControl.userEmail.errors?.required">Email is required</div>
                                <div *ngIf="formControl.userEmail.errors?.email">Email must be a valid email address
                                </div>
                            </div>
                        </div>
                        <div class="pt-3 pb-5">
                            <label class="form-label text-uppercase">Password</label>
                            <input name="password" type="password" autocomplete="off"
                                class="form-control form-input p-2" autofocus="true" (trim)="true"
                                [(ngModel)]="login.password" formControlName="userPassword" appTogglePassword
                                (input)="($event.charCode==13)? submitLogin() : ''">
                            <div *ngIf="loginFormSubmitted" class="text-danger" role="alert">
                                <div *ngIf="formControl.userPassword.errors?.required">Password is required</div>
                            </div>
                        </div>
                        <div class="pb-5">
                            <div class="float-left">
                                <button class="btn btn-primary btn-sm rounded-pill" (click)="submitLogin()" [formvalid]="loginForm.valid" appButtonDisable>
                                    Log In
                                </button>
                            </div>
                            <div class="float-right">
                                <button type="button"
                                    class="btn font-15 float-left row color-purple"
                                    (click)="gotoForgotPwd()">
                                    <u>Forgot Password?</u>
                                </button>
                            </div>
                        </div>
                        <div class="pb-5 text-center">
                            <a class="link-text" href="{{firmTermsUrl}}" target="_blank">Wealth Portal Terms of
                                Service</a>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>