import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GetStartedRoutingModule } from './get-started-routing.module';
import { SharedModule } from '../shared/shared.module';
import { FusionChartsModule } from "angular-fusioncharts";

// Import FusionCharts library and chart modules
import * as FusionCharts from "fusioncharts";
import * as charts from "fusioncharts/fusioncharts.charts";
import * as FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import { GetStartedComponent } from './get-started/get-started.component';
import { SignupComponent } from './signup/signup.component';
import { BasicInfoComponent } from './basic-info/basic-info.component';
import { RiskToleranceComponent } from './risk-tolerance/risk-tolerance.component';
import { AssetsAndLiabilitiesComponent } from './assets-and-liabilities/assets-and-liabilities.component';
import { CashFlowComponent } from './cash-flow/cash-flow.component';
import { PersonalInformationComponent } from './basic-info/personal-information/personal-information.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { InvestmentInfoComponent } from './investment-info/investment-info.component';
import { AdvisorContactComponent } from './advisor-contact/advisor-contact.component';
import { InformationSubmittedComponent } from './information-submitted/information-submitted.component';
import { SignupCompleteComponent } from './signup-complete/signup-complete.component';
import { AccountsComponent } from './assets-and-liabilities/accounts/accounts.component';
import { OtherAssetsComponent } from './assets-and-liabilities/other-assets/other-assets.component';
import { BrowserModule } from '@angular/platform-browser';
import { ExternalAdvisorContactComponent } from './external/external-advisor-contact/external-advisor-contact.component';
import { ExternalRiskToleranceComponent } from './external/external-risk-tolerance/external-risk-tolerance.component';

FusionChartsModule.fcRoot(FusionCharts, charts, FusionTheme);

@NgModule({
	declarations: [
		GetStartedComponent,
		SignupComponent,
		BasicInfoComponent,
		PersonalInformationComponent,
		RiskToleranceComponent,
		AssetsAndLiabilitiesComponent,
		CashFlowComponent,
		InvestmentInfoComponent,
		AdvisorContactComponent,
		InformationSubmittedComponent,
		SignupCompleteComponent,
		AccountsComponent,
		OtherAssetsComponent,
		ExternalAdvisorContactComponent,
		ExternalRiskToleranceComponent
	],
	imports: [
		BrowserModule,
		FormsModule,
		CommonModule,
		ReactiveFormsModule,
		NgbModule,
		BsDatepickerModule.forRoot(),
		GetStartedRoutingModule,
		SharedModule,
		FusionChartsModule
	]
})
export class GetStartedModule { }
