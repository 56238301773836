<app-bread-crumb-options *ngIf="!isDocusignBreadCrumb"></app-bread-crumb-options>
<app-breadcrumb-options-docu-sign *ngIf="isDocusignBreadCrumb"></app-breadcrumb-options-docu-sign>
<div class="container">
    <div class="panel panel-default mt-5">
        <div class="panel-body">
            <div class="ques-block-row">
                <div class="ques-block-inr w-100 d-lg-flex d-md-flex  text-muted">
                    <div class="des">
                        ASSETS
                        <span class="cur pt-2">{{netWorth.totalAssets | currency:'USD':'symbol':'1.0-0'}}</span>
                    </div>
                    <div class="des">
                        LIABILITIES
                        <span class="cur pt-2">{{netWorth.totalLiabilities | currency:'USD':'symbol':'1.0-0'}}</span>
                    </div>
                    <div class="des">
                        NET WORTH
                        <span class="cur pt-2">{{netWorth.netWorth | currency:'USD':'symbol':'1.0-0'}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="panel-heading pt-4">
            <h2 class="nohr">Net Worth Builder</h2>
        </div>
        <div>
            <div>Use the Link Account button to link your bank, company retirement, and brokerage account. Use the Add
                Account Manually button to tell us about other assets and liabilities.</div>
            <hr class="grey-hr">
        </div>

    </div>
    <!-- <form> -->
    <div class="row mt-4 ques-box" *ngIf="!showAddAcc">
        <div class="col-md-6 col-sm-6 col-xs-6">
            <button type="submit"  class="submit btn btn-default btn-lg font-14"
            (click)="openLinkExternalAccountModal()"><i class="fa fa-link color-purple"
            *ngIf="!disableLinkAccount"></i>
            &nbsp;Link External Account</button>
            <!-- <form action="{{fastlinkUrl}}" method="POST" id="target" target="abc" ng-form-commit name="yodleeForm"
                *ngIf="bankLinker=='yodlee'">
                <input type="hidden" name="app" value="10003600" />
                <input type="hidden" name="rsession" value="{{rsessionToken}}" class="rsession" />
                <input type="hidden" name="token" value="{{fastLinkToken}}" class="token" />
                <input type="hidden" name="redirectReq" value="true" />
                <input type="hidden" name="extraParams" value="{{callBackUrl}}" />
                <button type="submit" class="submit btn btn-default btn-lg font-14" data-toggle="modal"
                    (click)="openLinkAccount();sendMixPanelEvent({'event': 'Client Onboarding - Assets and Liabilities - Link Account'})" [disabled]="disableLinkAccount"><i class="fa fa-link color-purple"
                        *ngIf="!disableLinkAccount"></i>
                    &nbsp;Link Account
                    <span *ngIf="disableLinkAccount">&nbsp;</span>
                    <span *ngIf="disableLinkAccount" class="loader-status">
                        <div class="loader loader-spinner top-15"></div>&nbsp;
                    </span>
                </button>

            </form>
            <div *ngIf="bankLinker=='plaid'">
                <button type="submit" class="submit btn btn-default btn-lg font-14" data-toggle="modal"
                    (click)="openPlaid();sendMixPanelEvent({'event': 'Client Onboarding - Assets and Liabilities - Link Account'})" [disabled]="disableLinkAccount"><i class="fa fa-link color-purple"
                        *ngIf="!disableLinkAccount"></i>
                    &nbsp;Link Account
                    <span *ngIf="disableLinkAccount">&nbsp;</span>
                    <span *ngIf="disableLinkAccount" class="loader-status">
                        <div class="loader loader-spinner top-15"></div>&nbsp;
                    </span>
                </button> -->
                <!-- <button type="submit" class="submit btn btn-default btn-sm sml-txt visible-xs" data-toggle="modal" ng-click="openPlaid()" ng-disabled="disableLinkAccount"><i class="fa fa-link color-purple" *ngIf="!disableLinkAccount"></i>
                                &nbsp;Link Account
                                    <span *ngIf="disableLinkAccount">&nbsp;</span>
                                    <span *ngIf="disableLinkAccount" class="loader-status">
                                        <div class="loader loader-spinner top-15"></div>&nbsp;
                                    </span>
                                </button> -->
            <!-- </div>
            <form *ngIf="bankLinker=='Quovo'">
                <button type="submit" class="submit btn btn-default btn-lg font-14" data-toggle="modal"
                    [disabled]="disableLinkAccount" ng-click="openComingSoonModal();">Link
                    Account
                </button>

            </form> -->
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6" *ngIf="!showAddAcc">
            <button class="submit btn btn-default btn-lg font-14 float-right" (click)="showAddAcc=true;sendMixPanelEvent({'event': 'Client Onboarding - Assets and Liabilities - Add Account Manually'})"><i
                    class="fa fa-plus color-purple"></i>&nbsp; Add Account
                Manually</button>
        </div>
        <div class="clear"></div>
        <div class="link-block w-100 pt-3">
            <div *ngFor="let bank of bankList | keyvalue">
                <div *ngIf="!bank.value.accounts && !bank.value.assets_bank">
                    <div class="col-md-12 link-row">
                        <div class="col-md-3 col-sm-3 col-xs-3" *ngIf="!accNameEdit" (click)="accNameEdit = true">
                            {{bank.value.description}}
                        </div>
                        <div class="col-md-3 col-sm-3 col-xs-3" *ngIf="accNameEdit">
                            <input type="text" name="accName" class="form-control" [(ngModel)]="bank.value.description"
                                placeholder="Account name" (blur)="updateAccount(bank);accNameEdit = false">
                        </div>
                        <div class="val col-md-4 col-sm-4 col-xs-3 loader-wrp"
                            [ngClass]="{'color-red' : bank.value.isDebt}" *ngIf="!accBalEdit"
                            (click)="accBalEdit = true">
                            <span
                                *ngIf="!bank.status || bank.status == 0">{{bank.value.position_balance_net | currency:'USD':'symbol':'1.0-0'}}</span>
                            <span *ngIf="bank.status > 0" class="loader-status float-right">
                                <span
                                    class="loader"></span><span>{{ bank.value.statusMessage || 'Refreshing...' }}</span>
                            </span>
                        </div>
                        <div class="val col-md-4 col-sm-4 col-xs-4" [ngClass]="{'color-red' : bank.value.isDebt}"
                            *ngIf="accBalEdit">
                            <input type="text" name="accBalance" class="form-control"
                                [(ngModel)]="bank.value.position_balance_net" placeholder="Balance"
                                (blur)="updateAccount(bank);accBalEdit = false"  appFormatter="currency">
                        </div>
                        <div class="col-md-4 append_width">
                        </div>
                        <div class="col-md-1 col-sm-1 col-xs-1">
                            <i class="fa fa-trash-o cursor-pointer " (click)="getSelectedAcc('',bank,'accounts')"></i>
                        </div>
                    </div>
                </div>

                <div *ngIf="bank.value.accounts">
                    <div class="link-row" *ngFor="let account of bank.value.accounts">
                        <div class="col-md-3 col-sm-3 col-xs-3 font-15" *ngIf="!accNameEdit"
                            (click)="accNameEdit = true">
                            {{account.description}}
                        </div>
                        <div class="col-md-3 col-sm-3 col-xs-3" *ngIf="accNameEdit">
                            <input type="text" name="accName" class="form-control" [(ngModel)]="account.description"
                                placeholder="Account name" (blur)="updateAccount(account);accNameEdit = false">
                        </div>
                        <div class="val col-md-4 col-sm-4 col-xs-3 loader-wrp"
                            [ngClass]="{'color-red' : account.isDebt}" *ngIf="!accBalEdit" (click)="accBalEdit = true">
                            <span
                                *ngIf="!bank.status || bank.status == 0">{{account.position_balance_net | currency:'USD':'symbol':'1.0-0'}}</span>
                            <span *ngIf="bank.status > 0" class="loader-status float-right">
                                <span class="loader"></span>{{ bank.statusMessage || 'Refreshing...' }}</span>
                        </div>
                        <div class="val col-md-4 col-sm-4 col-xs-4" [ngClass]="{'color-red' : account.isDebt}"
                            *ngIf="accBalEdit">
                            <input type="text" name="accBalance" class="form-control"
                                [(ngModel)]="account.position_balance_net" placeholder="Balance"
                                (blur)="updateAccount(account);accBalEdit = false">
                        </div>
                        <div class="option-select col-md-4">
                            <div class="dropdown select-wrp">
                                <span *ngIf="account.account_type">
                                    <input type="hidden" [(ngModel)]="account.account_type.id">
                                </span>

                                <button type="button"
                                    class="btn btn-outline-secondary dropdown-toggle rounded-pill border-0  open pl-3 pr-3 font-14 w-100 text-align-left"
                                    data-toggle="dropdown" id="dropdownMenuButton" 
                                    aria-haspopup="true" aria-expanded="false">
                                    <span *ngIf="account.account_type">{{account.account_type.description}}</span>
                                    <span *ngIf="account.account_type == 'Please choose an account type!'">Select
                                        Account Type</span>
                                </button>
                                <div class="dropdown-menu select-list scroll-list">
                                    <div *ngFor="let accType of  accTypes | keyvalue;let i=index"
                                        [ngClass]="{'border-bottom-green': i < accountTypes.account_types.account_type_values.length-1}">
                                        <a class="dropdown-header font-16 font-weight-700 color-555">{{accType.key}}</a>
                                        <a class="dropdown-item" *ngFor="let option of accType.value"
                                            (click)="account.account_type.id?account.account_type.id = option.id:account.account_type={id:option.id}; account.account_type.description = option.name;updateAccount(account)">
                                            {{option.name}}
                                        </a><br />
                                    </div>
                                </div>

                            </div>



                        </div>
                        <div class="col-md-1 col-sm-1 col-xs-1">
                            <i class="fa fa-trash-o cursor-pointer"
                                (click)="getSelectedAcc(account,bank,'accounts')"></i>
                            <i class="fa fa-pencil cursor-pointer pl-2" (click)="editManualAccount(account)"
                                *ngIf="bank.value.manual"></i>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="add-acc-box" *ngIf="showAddAcc">
        <div class="tab-form-inr clearfix">
            <form name="manualAccountForm" [formGroup]="manualAccountForm" novalidate>
                <div class="form-label form-css-label1">
                    <fieldset class="form-group">
                        <label for="accName" class="mb-0">Name</label>
                        <input id="accName" name="accName" type="text" [(ngModel)]="description" class="form-control"
                            formControlName="accName" autocomplete="off" />
                        <div *ngIf="f.accName.errors && manualAccountFormSubmitted" class="text-danger" role="alert">
                            <div *ngIf="f.accName.errors.required">Account Name is required</div>
                        </div>
                    </fieldset>
                    <fieldset class="form-group">
                        <label class="select-label">Type</label>
                        <div class="option-select">
                            <div class="dropdown select-wrp">
                                <input type="hidden" [(ngModel)]="newAcc.id" formControlName="acctype">
                                <button type="button"
                                    class="btn btn-outline-secondary dropdown-toggle rounded-pill border-0  open pl-3 pr-3 font-14 w-100 text-align-left"
                                    data-toggle="dropdown" id="dropdownMenuButton" data-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false">
                                    <span>{{newAcc.name}}</span>
                                </button>
                                <div class="dropdown-menu select-list scroll-list">
                                    <div *ngFor="let accType of  accTypes | keyvalue;let i=index"
                                        [ngClass]="{'border-bottom-green': i < accountTypes.account_types.account_type_values.length-1}">
                                        <a class="dropdown-header font-16 font-weight-700 color-555">{{accType.key}}</a>
                                        <a *ngFor="let option of accType.value" (click)="setInputElement(option)">
                                            {{option.name}}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                    <div *ngFor="let field of fieldType | keyvalue">
                        <fieldset class="form-group" *ngIf="field.value.isObject">
                            <label class="select-label">{{field.value.label}}</label>
                            <div class="dropdown select-wrp open">
                                <span *ngIf="accountHolder.obj"><input type="hidden" 
                                        [(ngModel)]="accountHolder.obj[1]"></span>
                                <button type="button"
                                    class="btn btn-outline-secondary dropdown-toggle rounded-pill border-0  open pl-3 pr-3 font-14 w-100 text-align-left"
                                    data-toggle="dropdown" id="dropdownMenuButton1" data-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false">
                                    <span *ngIf="accountHolder.obj"> {{accountHolder.obj[0]}}</span>
                                </button>
                                <div class="dropdown-menu select-list account-holder-list">
                                    <a *ngFor="let acc of field.value.data"
                                        (click)="setAccountHolderData(acc)">{{acc[0]}}</a>
                                </div>
                            </div>
                        </fieldset>
                        <fieldset class="form-group"
                            *ngIf="field.value.isString && field.value.label !== 'Interest Rate'">
                            <label for="accInterest">{{field.value.label}}</label>
                            <input id="accBalance" name="accBalance" formControlName="accBalance"
                                [(ngModel)]="field.value.data" type="text" autocomplete="off" class="form-control"  
                                appFormatter="currency" placeholder="$0" >
                        </fieldset>
                        <fieldset class="form-group"
                            *ngIf="field.value.isString && field.value.label === 'Interest Rate'">
                            <label for="accInterest">{{field.value.label}}</label>
                            <input id="accInterest" name="accInterest" formControlName="accInterest"
                                [(ngModel)]="field.value.data" type="text" maxlength="3" autocomplete="off"
                                class="form-control"  decimalval="3" placeholder="0.000%"  appFormatter="percentage" >
                            <div *ngIf="f.accInterest.errors" class="error-text col-md-8 error-msg" role="alert">
                                <div *ngIf="f.accInterest.errors.maxlength" class="text-danger">Interest rate cannot be
                                    more than 3
                                    digits.</div>
                            </div>
                        </fieldset>
                        <fieldset class="form-group" *ngIf="field.value.isBoolean">
                            <input name="currentEmployer" class="mr-2" [(ngModel)]="field.value.data" type="checkbox">
                            <label for="styled-checkbox-1" (click)="field.value.data = !field.value.data">
                                <span class="cursor-pointer">{{field.value.label}}</span>
                            </label>
                        </fieldset>
                    </div>

                </div>
            </form>
        </div>

    </div>
    <div *ngIf="isInvestable" class="tab-form-inr clearfix  w-70 mt-5">
        <div ng-class="{'header-div padding-top-1':positions.length > 0}">
            <div class="d-inline-flex mr-2" *ngIf="positions.length > 0">{{newAcc.name}}</div>
            <div class="d-inline-flex"><a class="color-purple cursor-pointer" (click)="changeEditPosition(true,'')">Add
                    Position</a></div>
            <div class="d-inline-flex"><a class="color-purple cursor-pointer ml-3"
                    (click)="changeEditPosition(true, 'Cash')">Add Cash</a></div>
            <div class="d-inline-flex float-right" *ngIf="positions.length > 0">
                {{selectedPosition.amount| currency}} </div>
        </div>

        <div class="mt-5" *ngIf="showEditPosition">
            <div class="col-md-3 col-sm-3 col-xs-3 inline-grid no-padding-left">
                <label>
                    Symbol
                </label>
                <div>
                    <input type="text" class="form-control input-edit corner-rounded" name="name" placeholder="Symbol" 
                        [(ngModel)]="selectedPosition.symbol" (blur)="getTicker(selectedPosition.symbol)" />
                </div>
            </div>
            <div class="col-md-3 col-sm-3 col-xs-3 inline-grid no-padding-left">
                <label>
                    Description
                </label>
                <div>
                    <input type="text" class="form-control input-edit corner-rounded" name="name" 
                        placeholder="Description" [(ngModel)]="selectedPosition.description" readonly tabindex="-1" />
                </div>
            </div>
            <div class="col-md-3 col-sm-3 col-xs-3 inline-grid no-padding-left">
                <label>
                    Amount
                </label>
                <div>
                    <input type="number" class="form-control input-edit corner-rounded" name="name" placeholder="Amount"
                        [(ngModel)]="selectedPosition.amount" />
                </div>
            </div>
            <div class="clear"></div>
            <div class="float-right">
                <button class="btn btn-primary btn-sm rounded-pill pt-1 pl-3 pr-3 pb-1"
                    (click)="savePosition(selectedPosition.id)"
                    [disabled]="!selectedPosition.description || selectedPosition.description == 'Unknown ticker'">Save</button>
                <button class="btn btn-primary btn-sm rounded-pill pt-1 pl-3 pr-3 pb-1 ml-2"
                    (click)="changeEditPosition(false, '')">Cancel</button>
            </div>
        </div>

        <table class="table cont-table-2 font-14 col-md-12 mt-3" *ngIf="positions.length > 0 && !showEditPosition">
            <thead>
                <tr>
                    <th class="col-md-1">Symbol</th>
                    <th class="col-md-6 padding-left-5-imp">Description</th>
                    <th class="col-md-1 padding-left-5-imp text-right">Fee</th>
                    <th class="col-md-1 padding-left-5-imp text-right">Price</th>
                    <th class="col-md-1 padding-left-5-imp text-right position-quantity">Quantity</th>
                    <th class="col-md-1 padding-left-5-imp text-right">Value</th>
                    <th class="col-md-1">
                    </th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let position of positions; let i= index">
                    <tr class="content-table" *ngIf="!position._destroy">

                        <td class="col-md-1">{{position.ticker}}</td>
                        <td class="col-md-6 padding-left-5-imp">{{position.description}}</td>
                        <td class="col-md-1 padding-left-5-imp text-right">{{position.fee }}%</td>
                        <td class="col-md-1 padding-left-5-imp text-right">
                            {{position.price|  currency:'USD':'symbol':'1.0-0'}}</td>
                        <td class="col-md-1 padding-left-5-imp  text-right position-quantity">{{position.shares |  number:'1.0-0'}}</td>
                        <td class="col-md-1 padding-left-5-imp text-right">
                            {{position.amount|  currency:'USD':'symbol':'1.0-0'}}</td>
                        <td class="col-md-1 pl-0 pr-0">
                            <div class="float-right add-edit-div text-decoration-none" *ngIf="isInvestable">
                                <span class="fa fa-pencil color-blue mr-2" data-toggle="tooltip" title="Edit"
                                    (click)="getSelectedPosition(position, 'edit', i)"></span>
                                <span class="fa fa-remove color-red mr-1" data-toggle="tooltip" title="Delete"
                                    (click)="getSelectedPosition(position, 'delete', i)"></span>
                            </div>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
    <div class="text-center mb-4 mt-3" *ngIf="showAddAcc">
        <button class="btn btn-primary btn-md rounded-pill font-14 pl-4 pr-4 " (click)="createAccount()"
            [disabled]="showEditPosition">
            Submit
        </button>
        <button class="btn btn-primary btn-md rounded-pill ml-3 font-14 pl-4 pr-4 "
            (click)="resetAddAccount();showAddAcc=false;">
            Cancel
        </button>
    </div>
    <div class="pt-5">
        <app-buttonbar (clickedprev)="gotoPreviousState()" (clickednext)="gotoNextState()"></app-buttonbar>
    </div>

</div>