import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppRestEndPoint } from 'src/app/app-restEndPoint';
import { ApiService } from 'src/app/services/api.service';
import * as _ from 'lodash';
import { UtilityService } from 'src/app/services/utility.service';
import { ToastService } from 'src/app/components/toaster/toast-service';
import { PusherService } from 'src/app/services/pusher.service';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationDialogService } from 'src/app/services/confirmation-dialog.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { environment } from 'src/environments/environment';
import { LinkAccountModalComponent } from 'src/app/dashboard-main/link-account-modal/link-account-modal.component';
import { CommonService } from 'src/app/services/common.service';
import { LinkExternalAccountModalComponent } from 'src/app/dashboard-main/link-external-account-modal/link-external-account-modal.component';

declare var Plaid;

@Component({
	selector: 'app-accounts',
	templateUrl: './accounts.component.html',
	styleUrls: ['./accounts.component.scss']
})
export class AccountsComponent implements OnInit {

	netWorth: any = {};
	bankList: any = [];
	assetsList: any = [];
	isShowOptions: any;
	dropdownValues: any;
	assetTypes: any;
	showAddAcc = false;
	bankLinker: any;
	fastlinkUrl: any;
	accNameEdit: any;
	accBalEdit: any;
	disableLinkAccount: any;
	rsessionToken: any;
	fastLinkToken: any;
	callBackUrl: any;
	selectedHousehold: any;
	accountTypes: any;
	accTypes: any;
	accName: any;
	fieldOptions: any;
	fieldType: any;
	isInvestable: any;
	positions: any = [];
	isdropdownMenuOpen = false;
	disableAddAccount: any;
	description: any;
	newAcc: any = {};
	accountHolder: any = { "obj": [] };
	manualAccountFormSubmitted: any = false;
	showEditPosition: any;
	selectedManualAccId: any;
	isManualEdit: any;
	ungroupedAccTypes: any;
	current_user: any;
	selectedType: any;
	manualAccountForm: FormGroup;
	selectedPosition: any = {};
	editType: any;
	isDocusignBreadCrumb: any;
	pageUrl:any;
	linkExternalAccountModalInstance: any;

	constructor(
		private apiService: ApiService,
		private utilService: UtilityService,
		private toastService: ToastService,
		private pusherService: PusherService,
		private activatedRoute: ActivatedRoute,
		private confirmationDialogService: ConfirmationDialogService,
		private navigationService: NavigationService,
		private modalService: NgbModal,
		private formBuilder: FormBuilder,
		private cs: CommonService,
		public utilityService: UtilityService,
		private cdr: ChangeDetectorRef

	) {
		this.manualAccountForm = this.formBuilder.group({
			accName: ['', Validators.required],
			accInterest: [''],
			accBalance: [''],
			acctype: ['']


		});
	}

	ngOnInit(): void {

		let currentUser = JSON.parse(sessionStorage.getItem('current_user'));
		this.bankLinker = currentUser.bank_api_level;
		if (this.bankLinker == '') {
			this.disableLinkAccount = true;
		}
		this.selectedHousehold = currentUser.selected_household_id;
		this.getAssetsList();
		this.bindPusher();
		this.bindPusherYodlee();
		this.getNetworth();
		this.getNetworthForm();
		this.getAccList();
		this.getDropdown();
		this.current_user = JSON.parse(sessionStorage.getItem('current_user'));
		this.selectedType = this.activatedRoute.snapshot.paramMap.get('type');
		 this.pageUrl = document.URL.split('/');
		if (this.pageUrl[4] == 'docusign-accounts') {
			this.isDocusignBreadCrumb = true;
		} else {
			this.isDocusignBreadCrumb = false;
		}
	}

	ngAfterViewChecked(){
		this.cdr.detectChanges();
	}

	get f() { return this.manualAccountForm.controls; }


	async openLinkAccount(bank = '') {
		const openLinkAccountModalRef = this.modalService.open(LinkAccountModalComponent, { windowClass: 'modal-width-850', backdrop: 'static' });
		openLinkAccountModalRef.componentInstance.bank = bank
		openLinkAccountModalRef.componentInstance.selectedHousehold = this.selectedHousehold;
		openLinkAccountModalRef.result.then(() => {
			//if (data.reload && data.reload === true) {
			this.getNetworth();
			this.disableAddAccount = false;
			this.bindPusherNetworth();
			//}
		});
	}

	bindPusherNetworth() {
		const self = this;
		this.pusherService.bind('bank_setting_refresh', function (data) {
			self.setStatus(data, true, 'Establishing connection...');
		});

		this.pusherService.bind('refresh_worker_starting', function (data) {
			self.setStatus(data, true, 'Securing connection...');
		});

		this.pusherService.bind('yodlee_initializing_client', function (data) {
			self.setStatus(data, true, 'Retrieving bank information...');
		});

		this.pusherService.bind('yodlee_starting_refresh_loop', function (data) {
			self.setStatus(data, true, 'Reading account records...');
		});

		this.pusherService.bind('yodlee_finished_refresh_loop', function (data) {
			self.setStatus(data, true, 'Reading position records...');
		});

		this.pusherService.bind('yodlee_retrieving_summary_info', function (data) {
			self.setStatus(data, true, 'Reading transactions records...');
		});

		this.pusherService.bind('yodlee_processing_summary', function (data) {
			self.setStatus(data, true, 'Processing accounts...');
		});

		this.pusherService.bind('refresh_worker_finished', function (data) {
			self.setStatus(data, false, '');
			self.getNetworth();
			self.disableAddAccount = false;
		});

		this.pusherService.bind('refresh_worker_error', function (data) {
			this.toastService.show('Backend encountered a error. Please try again later.'.replace(/<\/?[^>]+(>|$)/g, "\n"), {
				classname: 'bg-danger text-light', delay: 7000
			});
			self.setStatus(data, false, '');
			self.getNetworth();
			self.disableAddAccount = false;
		});
	}
	bindPusher() {
		let pusher_channel = this.selectedHousehold;
		pusher_channel = pusher_channel.toString();
		const self = this;

		this.pusherService.subscribeBankRefresh(pusher_channel);
		this.pusherService.bind('bank_setting_refresh', function (data) {
			self.setStatus(data, true, 'Establishing connection...');
		});

		this.pusherService.bind('refresh_worker_starting', function (data) {
			self.setStatus(data, true, 'Securing connection...');
		});

		this.pusherService.bind('yodlee_initializing_client', function (data) {
			self.setStatus(data, true, 'Retrieving bank information...');
		});

		this.pusherService.bind('yodlee_starting_refresh_loop', function (data) {
			self.setStatus(data, true, 'Reading account records...');
		});

		this.pusherService.bind('yodlee_finished_refresh_loop', function (data) {
			self.setStatus(data, true, 'Reading position records...');
		});

		this.pusherService.bind('yodlee_retrieving_summary_info', function (data) {
			self.setStatus(data, true, 'Reading transactions records...');
		});

		this.pusherService.bind('yodlee_processing_summary', function (data) {
			self.setStatus(data, true, 'Processing accounts...');
		});

		this.pusherService.bind('refresh_worker_finished', function (data) {
			self.setStatus(data, false, '');
			self.getAccList();
		});
	}

	bindPusherYodlee() {
		let pusher_channel = this.selectedHousehold;
		pusher_channel = pusher_channel.toString();
		this.pusherService.subscribeBankRefresh(pusher_channel);
		this.pusherService.bind('yodlee_callback_initiated', function (data) {
			this.showYodleeLoader = true;
		});
	}

	getNetworth() {
		//	let errorBankCount = 0; this.errorBankCount = 0;
		let url = AppRestEndPoint.ASSET.NET_WORTH;
		let param = '';
		this.apiService.getCall(url, param).subscribe(response => {
			if (response) {
				this.netWorth = response.netWorth;
			}
		});
	}

	getAccList() {
		let url = AppRestEndPoint.ASSET.GET_ACC_DETAILS;
		let param = '';
		this.apiService.getCall(url, param).subscribe(response => {
			if (response) {
				this.bankList = response.banks;
			}
		});
	}

	getAssetsList() {
		let url = AppRestEndPoint.ASSET.ACCOUNTS;
		let param = "&scope=liabilities";
		this.apiService.getCall(url, param).subscribe(response => {
			if (response) {
				this.assetsList = response.account_lists;
				if (!this.assetsList) {
					this.isShowOptions = true;
				}
				_.forEach(this.assetsList, function (value) {
					if (value.position_balance_net.indexOf('(') !== -1) {
						value.position_balance_net = value.position_balance_net.substring(1, value.position_balance_net.length - 1);
					}
				});
			}
		});
	}

	getDropdown() {
		let url = AppRestEndPoint.ASSET.DROPDOWN_VALUES;
		let param = "";
		this.apiService.getCall(url, param).subscribe(response => {
			if (response) {
				this.dropdownValues = response.account_types.account_type_values;
				this.assetTypes = [];
				let self = this;
				_.forEach(this.dropdownValues, function (value) {
					let type = value[0];
					_.forEach(value[1], function (key) {
						let assetObj: any = {};
						assetObj.id = key[1];
						assetObj.name = key[0];
						assetObj.type = type;
						self.assetTypes.push(assetObj);
					});
				});
			}

		});
	}

	getNetworthForm() {
		let url = AppRestEndPoint.ASSET.NETWORTH_FORM;
		let param = "&household_id=" + this.selectedHousehold;
		this.apiService.getCall(url, param).subscribe(response => {
			if (response) {
				this.accountTypes = response.account;
				let accTypes = [];
				_.forEach(this.accountTypes.account_types.account_type_values, function (value) {
					let type;

					_.forEach(value, function (value1) {
						if (typeof value1 === 'string') {
							type = value1;
						}
						else {
							let self = this;
							_.forEach(value1, function (value2) {
								let accObj: any = {};
								accObj.id = value2[1];
								accObj.name = value2[0];
								accObj.type = type;
								accTypes.push(accObj);
							});
						}
					});
				});
				this.accName = accTypes[0].id;
				this.setInputElement(accTypes[0]);
				this.ungroupedAccTypes = accTypes;
				const result = this.groupBy(accTypes, (c) => c.type);
				this.accTypes = result;
			}
		});

	}
	groupBy(xs, f) {
		return xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
	}

	setInputElement(option) {
		this.positions = [];
		this.isInvestable = (option.type === 'Investable Accounts') ? true : false;
		this.newAcc.name = option.name;
		this.newAcc.id = option.id;
		this.fieldOptions = this.accountTypes.account_type_fields;
		this.fieldType = this.fieldOptions[option.id];
		let self = this;
		_.forEach(this.fieldType, function (obj) {
			if (typeof obj.data === "object") {
				obj.isObject = true;
				obj.isString = false;
				obj.isBoolean = false;
				self.accountHolder = { "obj": [] };
				self.accountHolder.obj[1] = obj.data[0][1];
				self.accountHolder.obj[0] = obj.data[0][0];

			}
			else if (typeof obj.data === "string") {
				obj.isObject = false;
				obj.isString = true;
				obj.isBoolean = false;
			}
			else if (typeof obj.data === "boolean") {
				obj.isObject = false;
				obj.isString = false;
				obj.isBoolean = true;
			}
			else {
				obj.isObject = false;
				obj.isString = true;
				obj.isBoolean = false;
			}
		});
	};


	openPlaid() {
		let handler, url, param; let self = this;
		sessionStorage.setItem('plaid_open_page', "accounts");
		sessionStorage.setItem('page_info_type', this.activatedRoute.snapshot.paramMap.get('type'));
		this.disableLinkAccount = true;
		let redirectUrl = this.cs.getDomainDetails().api_url + '/plaidOAuth';
		url = AppRestEndPoint.PLAID.GET_LINK_TOKEN;
		param = "&redirect_url=" + redirectUrl;
		this.apiService.getCall(url, param).subscribe(response => {
			if (response && response.token && response.token.error_code && response.token.error_message) {
				this.toastService.show(response.token.error_message, {
					classname: 'bg-danger text-light', delay: 7000
				});
				this.disableAddAccount = false;
			} else if (response && response.token && response.token.link_token) {
				this.disableLinkAccount = false;
				sessionStorage.setItem('plaid_link_token', response.token.link_token);
				handler = Plaid.create({
					token: response.token.link_token,
					onSuccess: function (public_token, metadata) {
						let reqObj = {
							"public_token": public_token,
							"meta_data": metadata
						};
						self.apiService.postCall(AppRestEndPoint.PLAID.UPSERT_BANK, reqObj).subscribe(response => {
							if (response && response.status) {
								self.getNetworth();
							}
						});
					},
					onExit: function (err, metadata) {
						let reqObj = {
							"error": err,
							"meta_data": metadata
						};
						self.apiService.postCall(AppRestEndPoint.PLAID.PLAID_EXIT, reqObj).subscribe(response => {
							if (err != null && err.error_code === 'INVALID_LINK_TOKEN') {
								handler.destroy();
								this.openPlaid();
							}
						});
						console.log('user closed');
					}
				});
				handler.open();
			}
		});
	};


	updateAccount(obj) {
		let request: any = {
			"account": {
				"description": obj.description,
				"balance": this.utilService.formatCurrency(obj.position_balance_net),
				"account_type_id": obj.account_type.id
			}
		};
		this.apiService.updateCall(AppRestEndPoint.ASSET.ACCOUNTS + '/' + obj.id, request).subscribe(response => {
			if (response) {
				this.toastService.show('Successfully Updated', {
					classname: 'bg-success text-light', delay: 7000
				});
				this.getAccList();
				this.getNetworth();
			}
		});
	};

	setStatus(id, show, status) {
		_.forEach(this.bankList, function (val) {
			if (val.id == id) {
				val.status = (show) ? 1 : 0;
				val.statusMessage = status;
			}
		});
	}


	getSelectedAcc(obj, bank, key) {
		this.confirmationDialogService.confirm('CONFIRM', 'Are you sure you would like to remove ' + obj.description + '?')
			.then((confirmed) => {
				if (confirmed) {
					let apiurl = '', param = '';
					if (bank.value.manual) {
						apiurl = AppRestEndPoint.ASSET.REMOVE_ACCOUNT.replace(':account_id', obj.id);
					} else {
						apiurl = AppRestEndPoint.ASSET.REMOVE_ALL_ACCOUNT + ".json?bank_id=" + bank.value.id;
						param = obj.id;
					}
					this.apiService.deleteCall(apiurl, param).subscribe(data => {
						if (data) {
							this.toastService.show(data.message, {
								classname: 'bg-success text-light', delay: 3000
							});
							if (key === 'assets') {
								this.getAssetsList();
							}
							else {
								this.getAccList();
							}
							this.getNetworth();
						}
					})

				}
			})
			.catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
	}

	editManualAccount(rowData) {
		this.showAddAcc = true;
		this.isManualEdit = true;
		this.selectedManualAccId = rowData.id;
		this.description = rowData.description;
		let option = _.find(this.ungroupedAccTypes, function (item) {
			return item.name === rowData.account_type.description;
		});
		this.setInputElement(option);
		let self = this;
		_.forEach(this.fieldType, function (obj) {
			if (typeof obj.data === "number" || typeof obj.data === "string") {
				if (obj.label === 'Interest Rate') {
					obj.data = rowData.interest_rate;
				} else if (obj.label === 'Balance' || obj.label === 'Value') {
					obj.data = self.utilService.formatCurrency(rowData.balance);
				}
			} else if (typeof obj.data === "boolean") {
				obj.data = rowData.account_type.supports_employer;
			} else if (typeof obj.data === "object" && rowData.account_holder_info) {
				self.accountHolder.obj[0] = rowData.account_holder_info.fullname;
				self.accountHolder.obj[1] = rowData.account_holder_info.id;
			}
		});
		this.getPositionsData(this.selectedManualAccId);
	}

	getPositionsData(id) {
		let url = AppRestEndPoint.DASHBOARD.GET_POSITIONS
		this.apiService.getCall(url, "&account_id=" + id).subscribe(response => {
			if (response) {
				this.positions = response.positions;
			}
		});
	};

	gotoPreviousState() {
		let reqObj = { "info_type": this.selectedType, "state": this.pageUrl[4] };
		this.navigationService.getPreviousPage(reqObj);
	}

	gotoNextState() {
		this.sendMixPanelEvent({'event': 'Client Onboarding - Assets and Liabilities - Continue'});
		this.saveState();
		let reqObj = { "info_type": this.selectedType, "state": this.pageUrl[4] };
		this.navigationService.getNextPage(reqObj);
	}


	saveState() {
		let reqObj = {
			'user': { "gettingstarted_step": '{"info_type" :"' + this.selectedType + '","state" :"' + this.pageUrl[4] + '"}' }
		};
		let url = AppRestEndPoint.LOGIN.SAVE_STATE + '/' + this.current_user.id;
		this.apiService.updateCall(url, reqObj, '').subscribe(data => {
		});
	}

	createAccount() {
		this.manualAccountFormSubmitted = true;
		let request = this.getAccountRequest();
		let requestPos = this.buildPositions();
		request.account.positions_attributes = requestPos;
		if (this.manualAccountForm.valid) {
			// if (angular.isDefined(this.ManualAccountForm.accInterest)) {
			// 	$scope.ManualAccountForm.accInterest.$setValidity('numberlength', true);
			// 	if (request.account.interest_rate && !utilService.validateThreeDigitNumber(request.account.interest_rate)) {
			// 		$scope.ManualAccountForm.accInterest.$setValidity('numberlength', false);
			// 	}
			// }

			///if ($scope.ManualAccountForm.$valid) {
			if (!this.isManualEdit) {
				this.apiService.postCall(AppRestEndPoint.ASSET.CREATE_ASSET, request).subscribe(response => {
					if (response) {
						this.showAddAcc = false;
						this.getAccList();
						this.resetAddAccount();
						this.getNetworth();
						this.manualAccountFormSubmitted = false;

					}
				});
			}
			else {
				this.apiService.updateCall(AppRestEndPoint.ASSET.ACCOUNTS + '/' + this.selectedManualAccId, request).subscribe(response => {
					if (response) {
						this.toastService.show('Successfully Updated', {
							classname: 'bg-success text-light', delay: 3000
						});
						this.selectedManualAccId = "";
						this.isManualEdit = false;
						this.showAddAcc = false;
						this.getAccList();
						this.getNetworth();
						this.resetAddAccount();
						this.manualAccountFormSubmitted = false;

					}
				});
			}
		}
		//}
	};

	buildPositions() {
		this.showEditPosition = false;
		let formattedPositionsObj = [];

		for (let i = 0; i < this.positions.length; i++) {
			formattedPositionsObj[i] = {};
			//Update new position
			if (this.positions[i].id) {
				formattedPositionsObj[i].id = this.positions[i].id;
				formattedPositionsObj[i].symbol = this.positions[i].ticker;
				formattedPositionsObj[i].shares = this.positions[i].amount;
				formattedPositionsObj[i]._destroy = this.positions[i]._destroy;
			}

			//Create new position
			else {
				formattedPositionsObj[i].symbol = this.positions[i].ticker;
				formattedPositionsObj[i].shares = this.positions[i].amount;
				formattedPositionsObj[i]._destroy = this.positions[i]._destroy;
			}

		}

		return formattedPositionsObj;


	};

	resetAddAccount() {
		_.forEach(this.fieldType, function (obj) {
			if (typeof obj.data === 'boolean') {
				obj.data = false;
			}
			else if (typeof obj.data === 'string') {
				obj.data = '';
			}
			else if (typeof obj.data === 'number') {
				obj.data = '';
			}
		});
		this.manualAccountFormSubmitted = false;
		this.accName = this.ungroupedAccTypes[0].id;
		this.setInputElement(this.ungroupedAccTypes[0]);
		this.description = '';
		this.accountHolder.obj = '';
		this.selectedManualAccId = "";
		this.isManualEdit = false;
	};


	getAccountRequest() {
		let self = this;
		let reqObj: any = {}, current_employer = 0, balance = '', interest_rate = '', user_id = '';
		reqObj.description = this.description;
		reqObj.account_type_id = this.newAcc.id;
		_.forEach(this.fieldType, function (obj) {
			if (typeof obj.data === "boolean") {
				current_employer = obj.data ? 1 : 0;
			}
			else if (typeof obj.data === "string" || typeof obj.data === "number") {
				if (obj.label === 'Balance' || obj.label === 'Value') {
					balance = obj.data;
				}
				else if (obj.label === 'Interest Rate') {
					interest_rate = obj.data;
				}
			}
			else if (typeof obj.data === "object") {
				user_id = self.accountHolder.obj[1];
			}
		});
		reqObj.current_employer = current_employer;
		reqObj.balance = this.utilService.formatCurrency(balance);
		reqObj.interest_rate = interest_rate ? parseFloat(interest_rate).toFixed(3) : interest_rate;
		reqObj.user_id = user_id;
		let request = { "account": reqObj };
		return request;
	};

	setAccountHolderData(acc) {
		this.accountHolder.obj[1] = acc[1];
		this.accountHolder.obj[0] = acc[0];
	}

	changeEditPosition(flag, type) {
		this.showEditPosition = flag;
		this.resetAddPosition();
		if (type === "Cash") {
			this.selectedPosition.symbol = "CASH";
			this.getTicker("CASH");
		}
	};

	resetAddPosition() {
		this.selectedPosition = {};
		this.selectedPosition.symbol = '';
		this.selectedPosition.description = '';
		this.selectedPosition.amount = 0;
	};

	getTicker(ticker) {
		this.apiService.getCall(AppRestEndPoint.DASHBOARD.GET_TICKER, '&ticker=' + ticker).subscribe(response => {
			if (response) {
				this.selectedPosition.description = response.description;
				this.selectedPosition.fee = response.expense_ratio;
				this.selectedPosition.lastPrice = response.last_price;
			} else {
				this.selectedPosition.description = '';
			}
		});

	};


	getSelectedPosition(positionRow, type, selectedIndex) {
		if (type === 'edit') {
			this.showEditPosition = true;
		}
		this.editType = type;
		this.selectedPosition = {
			symbol: positionRow.ticker,
			description: positionRow.description,
			amount: positionRow.amount,
			id: positionRow.id,
			selectedIndex: selectedIndex
		};
		if (type === 'delete') {
			this.openDeleteModal(this.selectedPosition.selectedIndex);
		}
	};

	openDeleteModal(positionId) {

		this.confirmationDialogService.confirm('CONFIRM', 'Are you sure you want to delete this record?')
			.then((confirmed) => {
				if (confirmed) {
					let self = this;
					this.positions.forEach(function (elem, index) {
						if (index === self.selectedPosition.selectedIndex) {
							elem._destroy = true;
						}
					});

				}
			})
			.catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
	};

	evaluatePositionDisplay(position) {
		return (position._destroy === true ? false : true);
	}


	savePosition(positionRowId) {
		this.showEditPosition = false;

		let reqObj = {}, self = this;
		if (this.editType === 'edit') {
			this.positions.find(function (eachElem, index) {
				//if(eachElem.id === positionRowId)
				if (index === self.selectedPosition.selectedIndex) {


					eachElem.symbol = self.selectedPosition.ticker;
					eachElem.amount = self.selectedPosition.amount;
					eachElem.shares = parseFloat(self.selectedPosition.amount) / parseFloat(eachElem.price);
					eachElem._destroy = false;
				}

			});
		}
		else {
			//Create new position object
			reqObj = {
				amount: this.selectedPosition.amount,
				description: this.selectedPosition.description,
				fee: this.selectedPosition.fee,
				id: this.selectedPosition.id,
				price: this.selectedPosition.lastPrice,
				shares: parseFloat(this.selectedPosition.amount) / this.selectedPosition.lastPrice,
				ticker: this.selectedPosition.symbol,
				_destroy: false

			};
			this.positions.push(reqObj);
		}
		this.editType = '';

	};

	sendMixPanelEvent(eventObj){
        this.utilityService.gtmPush(eventObj);
    }
	openLinkExternalAccountModal() {
		this.sendMixPanelEvent({'event': 'Client Onboarding - Assets and Liabilities - Link External Account'});
		this.linkExternalAccountModalInstance = this.modalService.open(LinkExternalAccountModalComponent, { windowClass: 'modal-width-800', backdrop: 'static' });
		this.linkExternalAccountModalInstance.result.then((data) => {
			if (data && data.type == 'plaid') {
				this.openPlaid();
			} else if (data && data.type == 'yodlee') {
				this.openLinkAccount();
			}
		});
	}

}
