import { Component, OnInit } from '@angular/core';
import { AppRestEndPoint } from 'src/app/app-restEndPoint';
import { ApiService } from 'src/app/services/api.service';
import * as _ from 'lodash';
import { ConfirmationDialogService } from 'src/app/services/confirmation-dialog.service';
import { ToastService } from 'src/app/components/toaster/toast-service';
import { UtilityService } from 'src/app/services/utility.service';
import { ActivatedRoute } from '@angular/router';
import { NavigationService } from 'src/app/services/navigation.service';


@Component({
	selector: 'app-other-assets',
	templateUrl: './other-assets.component.html',
	styleUrls: ['./other-assets.component.scss']
})
export class OtherAssetsComponent implements OnInit {

	netWorth: any = {};
	showAddAsset: any;
	assetsList: any = [];
	isShowOptions: any = false;
	otherAssetName: any;
	assetTypes: any;
	dropdownValues: any = [];
	assetNameEdit: any;
	assetBalEdit: any;
	showEditPosition: any;
	otherAsset: any = {};
	selectedObj: any;
	assetValue: any;
	selectedType: any;
	current_user: any;
	isDocusignBreadCrumb:any;
	pageUrl:any;
	constructor(private apiService: ApiService,
		private confirmationDialogService: ConfirmationDialogService,
		private navigationService: NavigationService,
		private activatedRoute: ActivatedRoute,
		private toastService: ToastService,
		private utilService: UtilityService) { }

	ngOnInit(): void {
		this.current_user = JSON.parse(sessionStorage.getItem('current_user'));
		this.selectedType = this.activatedRoute.snapshot.paramMap.get('type');
		this.getNetworth();
		this.getAssetsList();
		this.getDropdown();
		this.pageUrl = document.URL.split('/');
		console.log(this.pageUrl);
		if (this.pageUrl[4] == 'docusign-other-assets') {
			this.isDocusignBreadCrumb = true;
		} else {
			this.isDocusignBreadCrumb = false;
		}
	}

	getNetworth() {
		//	let errorBankCount = 0; this.errorBankCount = 0;
		let url = AppRestEndPoint.ASSET.NET_WORTH;
		let param = '';
		this.apiService.getCall(url, param).subscribe(response => {
			if (response) {
				this.netWorth = response.netWorth;
			}
		});
	}

	getAssetsList() {
		let url = AppRestEndPoint.ASSET.ACCOUNTS;
		let param = "&scope=liabilities";
		this.apiService.getCall(url, param).subscribe(response => {
			if (response) {
				if (_.isEmpty(response)) {
					this.isShowOptions = true;
				} else {
					this.assetsList = response.account_lists;
					_.forEach(this.assetsList, function (value) {
						if (value.position_balance_net.indexOf('(') !== -1) {
							value.position_balance_net = value.position_balance_net.substring(1, value.position_balance_net.length - 1);
						}
					});
				}
			}
		});
	}

	gotoPreviousState() {
		let reqObj = { "info_type": this.selectedType, "state": this.pageUrl[4] };
		this.navigationService.getPreviousPage(reqObj);
	}

	gotoNextState() {
		this.sendMixPanelEvent({'event': 'Client Onboarding - Other Assets and Liabilities - Continue'});
		this.saveState();
		let reqObj = { "info_type": this.selectedType, "state": this.pageUrl[4] };
		this.navigationService.getNextPage(reqObj);
	}

	createOtherAssets() {
		let request: any = {
			"account": {
				"description": this.otherAsset.name,
				"balance": this.assetValue,
				"account_type_id": this.otherAsset.id
			}
		};

		this.apiService.postCall(AppRestEndPoint.ASSET.CREATE_ASSET, request).subscribe(response => {
			if (response) {
				this.showAddAsset = false;
				this.getAssetsList();
				this.otherAsset.name = "";
				this.assetValue = "";
			}
		});
	};

	saveState() {
		let reqObj = {
			'user': { "gettingstarted_step": '{"info_type" :"' + this.selectedType + '","state" :"' + "otherAssets" + '"}' }
		};
		let url = AppRestEndPoint.LOGIN.SAVE_STATE + '/' + this.current_user.id;
		this.apiService.updateCall(url, reqObj, '').subscribe(data => {
		});
	}

	updateAccount(obj) {
		let request: any = {
			"account": {
				"description": obj.description,
				"balance": this.utilService.formatCurrency(obj.position_balance_net),
				"account_type_id": obj.account_type.id
			}
		};
		this.apiService.updateCall(AppRestEndPoint.ASSET.ACCOUNTS + '/' + obj.id, request).subscribe(response => {
			if (response) {
				this.toastService.show('Successfully Updated', {
					classname: 'bg-success text-light', delay: 7000
				});
				this.getNetworth();
			}
		});
	}; s

	getDropdown() {
		let url = AppRestEndPoint.ASSET.DROPDOWN_VALUES;
		let param = "";
		this.apiService.getCall(url, param).subscribe(response => {
			if (response) {
				this.dropdownValues = response.account_types.account_type_values;
				this.assetTypes = [];
				let self = this;
				_.forEach(this.dropdownValues, function (value) {
					let type = value[0];
					_.forEach(value[1], function (key) {
						let assetObj: any = {};
						assetObj.id = key[1];
						assetObj.name = key[0];
						assetObj.type = type;
						self.assetTypes.push(assetObj);
					});
				});
				const result = this.groupBy(this.assetTypes, (c) => c.type);
				this.assetTypes = result;
			}

		});
	}

	groupBy(xs, f) {
		return xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
	}


	getSelectedAsset(option) {
		this.otherAsset.name = option.name;
		this.otherAsset.id = option.id;
	};

	goToAddAssets(type) {
		this.showAddAsset = true;
		this.isShowOptions = false;
		let getAssetType;
		_.filter(this.assetTypes, function (obj) {
			_.filter(obj, function (obj1) {
				if(obj1.name == type){
					getAssetType = obj1;
				}
			});
			
		});
		this.otherAsset.name = getAssetType.name;
		this.otherAsset.id = getAssetType.id;
	};


	getSelectedAccLiability(obj) {
		this.confirmationDialogService.confirm('CONFIRM', 'Are you sure you would like to remove ' + obj.value.description + '?')
			.then((confirmed) => {
				if (confirmed) {
					let apiurl = '', param = '';
					apiurl = AppRestEndPoint.ASSET.REMOVE_ACCOUNT.replace(':account_id', obj.value.id);
					this.apiService.deleteCall(apiurl, param).subscribe(data => {
						if (data) {
							this.toastService.show(data.message, {
								classname: 'bg-success text-light', delay: 3000
							});
							this.selectedObj = {};
							this.getAssetsList();
							this.getNetworth();
						}
					})

				}
			})
			.catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));

	};

	changeView() {
		this.showAddAsset = false;
		this.otherAssetName = "";
		this.assetValue = "";
		this.assetsList.length > 0 ? this.isShowOptions = false : this.isShowOptions = true;
	};


	sendMixPanelEvent(eventObj){
        this.utilService.gtmPush(eventObj);
    }

}
