<div class="modal-header">
    <div class="row w-100">
        <div class="col-md-11">
            <h5 class="modal-title"><span>{{taskNoteData.subject?'Edit':'Add'}} </span> Note</h5>
        </div>
        <div class="col-md-1 pr-0">
            <button type="button" class="close float-right text-white pr-0" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
</div>
<div class="modal-body">
    <form [formGroup]="taskNoteForm" novalidate>
        <div class="form-group col-md-12 col-sm-12 col-xs-12">
            <div class="col-md-12 col-sm-12 pl-0 pr-0">
                <input class="form-control contact-us-com" [(ngModel)]="taskNoteData.subject" type="text"
                    formControlName="subject" placeholder="Subject" />
                <div *ngIf="taskNoteFormSubmitted" class="text-danger pt-1" role="alert">
                    <div *ngIf="formControl.subject.errors?.required">Subject is mandatory.</div>
                </div>
            </div>
        </div>
        <div class="form-group col-md-12 col-sm-12 col-xs-12">
            <div class="col-md-12 col-sm-12 pl-0 pr-0">
                <editor [init]="{
                height: 300,
                menubar: false,
                selector: 'textarea',
                placeholder: 'Notes',
                readonly: true,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount'
                ],
                toolbar:false,
                statusbar:false
                  
              }" formControlName="notes" ngDefaultControl [(ngModel)]="taskNoteData.note" placeholder="Notes" [disabled]="taskNoteData.user && taskNoteData.user.id == null "></editor>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-link" (click)="closeModal()">
        CANCEL
    </button>
    <button type="button" class="btn btn-primary btn-sm rounded-pill" (click)="submitNote(this.taskNoteData)"
        [disabled]="taskNoteData.user && !taskNoteData.user.id" [formvalid]="taskNoteForm.valid" appButtonDisable>
        Submit
    </button>
</div>