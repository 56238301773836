import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/app/components/toaster/toast-service';
import { environment } from 'src/environments/environment';
import { AppRestEndPoint } from 'src/app/app-restEndPoint';
import { ApiService } from 'src/app/services/api.service';

@Component({
	selector: 'app-upload-statement-modal',
	templateUrl: './upload-statement-modal.component.html',
	styleUrls: ['./upload-statement-modal.component.scss']
})
export class UploadStatementModalComponent implements OnInit {

	user_document: any = {};
	user: any;

	constructor(public activeModal: NgbActiveModal,
		public apiService: ApiService,
		public toastService: ToastService) {
	}

	ngOnInit(): void {
		this.user = JSON.parse(sessionStorage.getItem('current_user'));
	}

	cancel() {

	}


	closeModal() {
		this.activeModal.close();
	}

	saveDocument(element: any) {
		if (element[0]) {
			let template_id: any = environment.statement_document_id;
			let type_id: any = environment.statement_user_document_type_id;
			const formData = new FormData();
			formData.append('user_document[household_id]', this.user.selected_household_id);
			formData.append('user_document[description]', element[0].name);
			formData.append('user_document[document_template_id]', template_id);
			formData.append('user_document[user_document_type_id]', type_id);
			formData.append("user_document[url]", element[0]);

			this.apiService.postMultipartFormCall(AppRestEndPoint.UPLOAD.DOCS_LOAD, formData).subscribe(data => {
				if (data) {
					this.toastService.show('Saved Successfully.', {
						classname: 'bg-success text-light', delay: 3000
					});
					this.activeModal.close(data);
				}
			}, function (err) {
				this.toastService.show('Error in Uploading Statement.', {
					classname: 'bg-danger text-light', delay: 3000
				});
			});

		} else {
			this.toastService.show('Please select a file.', {
				classname: 'bg-danger text-light', delay: 3000
			});
		}
	};

}
