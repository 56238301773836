import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppRestEndPoint } from 'src/app/app-restEndPoint';
import { ToastService } from 'src/app/components/toaster/toast-service';
import { ApiService } from 'src/app/services/api.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { isDefined } from '@angular/compiler/src/util';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
	selector: 'app-risk-tolerance',
	templateUrl: './risk-tolerance.component.html',
	styleUrls: ['./risk-tolerance.component.scss']
})
export class RiskToleranceComponent implements OnInit {

	current_user: any;
	selectedType: any;
	household_id: any;
	dashboardSelectedHousehold: any;
	loadRtqId: any;
	setQuestionNumber: any;
	current_household_id: any;
	selectedAnswer: Boolean = false;
	loadQuestionData: any = {};
	selectedAnsArr: any = [];
	questionArrLength: Number = 0;
	questionNumber: any;
	questionList: any = [];
	rtqEmail: any = null;
	isExternalPage:boolean = false;

	constructor(
		private activatedRoute: ActivatedRoute,
		private apiService: ApiService,
		private toastService: ToastService,
		private navigationService: NavigationService,
		public utilityService: UtilityService,
		public router: Router
	) { }

	ngOnInit(): void {
		this.current_user = JSON.parse(sessionStorage.getItem('current_user'));
		this.selectedType = this.activatedRoute.snapshot.paramMap.get('type');
    	this.household_id = this.current_user.selected_household_id;
		this.dashboardSelectedHousehold = sessionStorage.getItem('current_selected_household');
		sessionStorage.removeItem('investment_state');
		this.loadRtqId = sessionStorage.getItem('login_rtq_id');
		this.rtqEmail = sessionStorage.getItem("rtq_redirect");
		if (this.dashboardSelectedHousehold && this.dashboardSelectedHousehold != null && this.dashboardSelectedHousehold != "") {
			this.current_household_id = this.dashboardSelectedHousehold;
		} else {
			this.current_household_id = this.household_id;
		}
		switch (this.setQuestionNumber) {
			case "question1": this.questionNumber = 2; break;
			case "question2": this.questionNumber = 3; break;
			case "question3": this.questionNumber = 4; break;
			case "question4": this.questionNumber = 5; break;
			case "question5": this.questionNumber = 6; break;
			case "question6": this.questionNumber = 7; break;
			default: this.questionNumber = 1; break;
		}
		this.loadQuestions();
	}

	loadQuestions() {
		this.questionList[0] = {};
		let url = AppRestEndPoint.RISK_TOLERANCE.QUESTION_LIST, param = "&household_id="+this.current_household_id+"&rtq_id="+this.loadRtqId;
		this.apiService.getCall(url, param).subscribe(response => {
            if (response) {
                let loadQuestionData = response;
                this.questionList = loadQuestionData.investor_profile_questions;
                this.questionArrLength = loadQuestionData.investor_profile_questions.length;
                if (loadQuestionData.rtq && loadQuestionData.rtq.id) {
                    sessionStorage.setItem("rtqId", loadQuestionData.rtq.id);
                }
				this.questionList[0].investor_profile_answers.disabled = false;
            }
        });
    }

	saveAnswer() {       
        if (this.questionList[this.questionNumber - 1].userOption) {
            var reqObj = {
                "investor_profile": {
                    "onboarding": "true",
                    "rtq_id": this.loadRtqId,
                    "investor_profile_question_id": this.questionList[this.questionNumber - 1].userOption.investor_profile_question_id,
                    "investor_profile_answer_id": this.questionList[this.questionNumber - 1].userOption.id
                }
            };

			let url = AppRestEndPoint.RISK_TOLERANCE.SAVE_INVESTOR_PROFILE +"?household_id=" + this.current_household_id;
			this.apiService.postCallQuery(url, reqObj).subscribe(response => {
                if(isDefined(response.success) && response.success == false){
					this.toastService.show(response.message, {
                        classname: 'bg-danger text-light', delay: 3000
                    });
                }else{
					this.utilityService.gtmPush({'event': 'Client Onboarding - Risk Tolerance - Question '+this.questionNumber, 'answer': this.questionList[this.questionNumber - 1].userOption.description});
                    this.gotoNextState();
                }
            });
        }
    }

	clearSelection(){
		this.questionList[this.questionNumber-1].investor_profile_answers.forEach(function (option) {
			option.selected = false; 
			option.disabled = true;                       
		});
	}

	getSelectedAnswer(option){
		this.clearSelection();
		option.selected = true;
		this.questionList[this.questionNumber - 1].userOption = option;
		this.saveAnswer();
	}

	gotoNextState() {
        if (this.questionList[this.questionNumber - 1].userOption) {
            this.saveState();
            if (this.questionNumber < this.questionArrLength) {
                this.questionNumber = this.questionNumber + 1;
            } else if (this.questionNumber == this.questionArrLength) {
                var reqObj = { "info_type": this.selectedType, "state": "risk-tolerance" };
				if(this.rtqEmail == 'true'){
					this.router.navigate([this.selectedType+"/advisor-contact"]);
				}else{
                	this.navigationService.getNextPage(reqObj);
				}
            }
        }
    }

	gotoPreviousState() {
        if (this.questionNumber != 1) {
            this.questionNumber = this.questionNumber - 1;
			this.questionList[this.questionNumber - 1].investor_profile_answers.forEach(function (option) {
				option.disabled = false;
			});
        } else {
            var reqObj = { "info_type": this.selectedType, "state": "risk-tolerance" };
            this.navigationService.getPreviousPage(reqObj);
        }
        //this.saveState(user_id, access_token);
    }

	saveState(){
		var reqObj;
        if (this.questionNumber < this.questionArrLength) {
            reqObj = {
                'user': { "gettingstarted_step": '{"info_type" :"' + this.selectedType + '","state" :"question' + this.questionNumber + '"}' }
            };
        } else {
            reqObj = {
                'user': { "gettingstarted_step": '{"info_type" :"' + this.selectedType + '","state" :"' + "riskTolerance" + '"}' }
            };
        }
		
		let url = AppRestEndPoint.LOGIN.SAVE_STATE+'/'+this.current_user.id;
		this.apiService.updateCall(url, reqObj, '').subscribe(data => {
		});
	}



}
