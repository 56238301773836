<app-navbar></app-navbar>
<!-- this is tooltip template-->
<ng-template #ToolTipTemplate1>
    <span>Fund Expense Grade –
        <br>
        We use the Average Weighted Expense Ratio calculated for the selected grouping and then apply that to our
        proprietary scale. Anything at or under .35% would reflect a 100%. For every basis point above that the score is
        reduced by 1%. A Weighted Average Expense Ratio greater or equal to 1.35% would generate a score of
        0%.<br /><br />


        Diversification Grade –
        <br>
        Represents the deviation of the current percentage for each asset class from the target percentage for the
        selected grouping. The grade is on a sliding scale so that a 1% deviation in a single asset class should have a
        similar reduction in score.<br /><br />

        Asset Location Grade –
        <br>
        Calculates the percentage of bonds in tax free and brokerage accounts and the percentage of stocks in tax
        deferred accounts. Using this figure the grade is then reduced. A client with 100% stocks allocated to the Roth
        and brokerage accounts and 100% of bonds allocated to the tax deferred would reflect 100%.
    </span>
</ng-template>
<ng-template #ToolTipTemplate2>
    <span>Important Disclosures on Simulations | Portfolio Projections

        Simulations are used to show how a reasonable range of variation in rates of return each year can affect your
        outcomes. The simulation calculates the results of your plan by running hypothetical cash flows and investment
        returns, each time using a different sequence of annual returns. Some sequences of returns will give you better
        results, and some will give you worse results. These multiple trials provide a range of possible outcomes, some
        successful (you would have money remaining at your target age) and some unsuccessful (you would not have money
        remaining at your target age). The percentage of trials that were successful is the probability that your plan,
        with all its underlying assumptions, could be successful. The simulations indicate the likelihood that an event
        may occur as well as the likelihood that it may not occur. In analyzing this information, please note that the
        analysis does not take into account actual market conditions, which may severely affect the outcome of your
        goals over the long-term.

        {{current_user_firm_setting.description}} makes no guarantee of the Sustainable Spending rate. The probability
        of success indicated herein represents a range of possible outcomes, some of which would require the Client to
        adjust their spending rate down in order to avoid running out of money before their death. The sustainable
        spending rate is expected to
        increase by {{sustainableIncrease}} per year. The Sustainable Spending rate will not be achievable if the
        Client's actual spending exceeds this amount or if equity and/or bond returns over the Client's lifetime are
        below historical averages.

        For the purposes of running this simulation, we must make certain assumptions. The projected return and
        volatility assumptions for the Portfolio shown in this analysis are an average annual return of
        {{averageAnnualReturn}} and annual standard deviation of {{standaradDeviation}} as estimated by the Investment
        Committee of {{current_user_firm_setting.description}}, which has been adjusted for the advisory fees and mutual
        fund expenses. This return has been reduced to reflect a {{managementFees}} annual management fee.

        The Client understands the above graphs are provided for illustrative and educational purposes only, and that no
        guarantees can be given about future performance. These illustrations should not be construed as offering such a
        guarantee.<br />

    </span>
</ng-template>
<div resize-window>
    <div class="container-fluid container-spacing" [ngClass]="{'opacity-5':loadDashboardFlag}">
        <div class="row margin-top-90" [ngClass]="{'opacity-5':loadDashboardFlag}">
            <div class="col-md-12 col-xs-12 col-sm-12 border-bottom mx-3 pb-2 pt-3">
                <div class="float-left font-30">Dashboard</div>
                <div class="float-right">
                    <input type="hidden" id="household-select" [(ngModel)]="selectedHousehold">
                    <label *ngIf="householdList.length > 1"
                        class="float-left pr-3 font-weight-bold pt-2 d-none d-sm-block d-md-block d-lg-block">{{householdList.length}}
                        Households</label>
                    <label *ngIf="householdList.length == 1"
                        class="float-left pr-3 font-weight-bold pt-2 pt-2 d-none d-sm-block d-md-block d-lg-block">{{householdList.length}}
                        Household</label>
                    <div class="dropdown float-left pr-3" *ngIf="householdList.length > 1">
                        <button type="button"
                            class="btn btn-outline-secondary dropdown-toggle rounded-lg bg-white pl-2 pr-2 font-14"
                            data-toggle="dropdown" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">
                            {{selectedHousehold.name}}
                        </button>
                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item cursor-pointer font-14" *ngFor="let option of householdList"
                                (click)="selectHousehold(option)">{{option[1]}}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="clear"></div>

            <div class="w-100 ques-block-row">
                <div class="ques-block-inr list big" (click)="scroll(networth)">
                    <img src="./assets/images/net-worth.png" class="list-img">
                    <div class="list-txt">
                        <div class="pull-left">
                            <div>NET WORTH </div>
                            <div>
                                <span class="font-24 color-333 font-weight-100">{{topCardData.total_net_worth |
                                    currency:'USD':'symbol':'1.0-0'}}</span>
                            </div>
                        </div>
                        <div class="pull-right">
                            <span class="pull-right margin-top-min-3">
                                <button type="submit"
                                    class="btn btn-primary link-acc-auto text-decoration-none linkAccBtn display-block"
                                    (click)="openLinkExternalAccountModal($event)">Link External Account
                                </button>
                            </span>
                        </div>
                    </div>
                    <div class="clear display-block" *ngIf="dashboard_bank_status_color == 'red'">
                        <span class="cursor-pointer color-red text-decoration-underline font-13"
                            *ngIf="errorBankCount == 1" (click)="relinkBankPopup()"
                            [ngClass]="{'pointer-events-none':disableAddAccount || loadRefreshAll}"
                            data-toggle="tooltip" data-placement="bottom"
                            data-title="Unable to access {{errorBankCount}} Bank"
                            data-content="Relink accounts to grant access to receive latest account information."
                            tooltip [ngClass]="{'pointer-events-none':disableAddAccount || loadRefreshAll}">Fix Bank
                            Error </span>
                        <span class="cursor-pointer color-red text-decoration-underline font-13"
                            *ngIf="errorBankCount > 1" (click)="relinkBankPopup()"
                            [ngClass]="{'pointer-events-none':disableAddAccount || loadRefreshAll}"
                            data-toggle="tooltip" data-placement="bottom"
                            data-title="Unable to access {{errorBankCount}} Banks"
                            data-content="Relink accounts to grant access to receive latest account information."
                            tooltip [ngClass]="{'pointer-events-none':disableAddAccount || loadRefreshAll}">Fix Bank
                            Errors</span>
                    </div>
                    <span class="clear display-block mb-1" *ngIf="dashboard_bank_status_color == 'orange'">
                        <div class="cursor-pointer color-orange1 text-decoration-underline font-13"
                            (click)="relinkBankPopup()"
                            [ngClass]="{'pointer-events-none':disableAddAccount || loadRefreshAll}">Refresh Bank</div>
                    </span>
                    <div class="clear"></div>
                    <div class="border-bottom"></div>
                    <div class="pt-2 pb-4">
                        <div class="float-left">
                            <span class="topCardLabel">Total Assets</span>
                            <div><a>{{topCardData.total_assets| currency:'USD':'symbol':'1.0-0'}}</a></div>
                        </div>
                        <div class="float-right"><span class="topCardLabel">Total Debt</span>
                            <div><a>{{topCardData.total_debts| currency:'USD':'symbol':'1.0-0'}}</a></div>
                        </div>
                    </div>
                </div>
                <div class="ques-block-inr list big" (click)="scroll(allocationAnalysisId)">
                    <img src="./assets/images/total-val.png" class="float-left mw-25 pr-3">
                    <div class="list-txt">
                        <div class="pull-left">
                            <span class="text-black-50 ">TOTAL MANAGED VALUE</span>
                            <div>
                                <span class="font-weight-100 font-24 color-333">
                                    {{topCardData.totalMngedValue| currency:'USD':'symbol':'1.0-0'}}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                    <div class="border-bottom"></div>
                    <div class="mt-2 pb-4">
                    </div>
                </div>
                <div class="ques-block-inr list big" (click)="scroll(networth)">
                    <img src="./assets/images/cash-flow.png" class="float-left mw-25 pr-3">
                    <div class="list-txt">
                        <div class="pull-left">
                            <span class="text-black-50">ANNUAL CASH FLOW (AFTER-TAX)</span>
                            <div>
                                <h3 class="font-weight-100 font-24 color-333">
                                    {{cashFlowDetails.yearly_cash_flow| currency:'USD':'symbol':'1.0-0'}}</h3>
                            </div>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                    <div class="border-bottom"></div>
                    <div class="pt-2 pb-4">
                        <div class="float-left"> <span class="topCardLabel">Income</span>
                            <div><a>{{cashFlowDetails.yearly_income| currency:'USD':'symbol':'1.0-0'}}</a></div>
                        </div>
                        <div class="float-right"><span class="topCardLabel">Expenses</span>
                            <div><a>{{cashFlowDetails.yearly_expense| currency:'USD':'symbol':'1.0-0'}}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 col-xs-12 col-sm-12 pt-4 pl-3">
                <div class="d-flex justify-content-center" *ngIf="loadDashboardFlag">
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <div class="card w-100 m-auto border-radius-10 border-0 shadow-sm m-2"                
                    *ngIf="tempScenario  && graphData  && tempScenario.graph_visible_to_client == true && firmGraphVisibleClient == true && (graphDataSource | json) != '{}'">
                    <div class="card-body pb-5">
                        <div class="clearfix mb-1">
                            <h5 class="float-left">Portfolio Projection: </h5>
                            <div class="clearfix"></div>
                            <div class="row">
                                <div class="col-md-8 pl-0 pt-5">
                                    <fusioncharts width="100%" height="350" type="mscombi2d"
                                        [dataSource]="graphDataSource">
                                    </fusioncharts>
                                    <div class="col-md-12 font-13 text-black-50 text-uppercase mt-n3"
                                        *ngIf="!isRetired">
                                        <div class="col-md-2"></div>
                                        <div class="float-right col-md-5"
                                            *ngIf="retireGraphValues.length>0 && retireGraphValues[1].graphLabel && graphData">
                                            <div><span>Expected Value at Retirement: </span><i
                                                    class="fa fa-question-circle cursor-pointer pl-1"
                                                    tooltipClass="tooltipData"
                                                    ngbTooltip="This is the median value generated using the values and expected return of the portfolio from portfolio inception date until the retirement date. This includes Unmanaged Assets."
                                                    placement="bottom"></i></div>
                                            <h5 class="text-dark">
                                                {{value_at_retirement | currency :'USD':'symbol':'1.0-0'}}</h5>
                                        </div>
                                        <div class="float-right col-md-5 col-sm-6"
                                            *ngIf="retireGraphValues.length>0 && retireGraphValues[2].graphLabel">
                                            <div><span>Expected Sustainable Spending: </span><i
                                                    class="fa fa-question-circle cursor-pointer pl-1"
                                                    tooltipClass="tooltipData"
                                                    ngbTooltip="This number is calculated such that 75% of outcomes have money remaining when the Client reaches planned life expectancy."
                                                    placement="bottom"></i></div>
                                            <h5 class="text-dark font-20">
                                                {{sustainable_spending | currency :'USD':'symbol':'1.0-0'}}</h5>
                                        </div>
                                    </div>
                                    <div class="col-md-12 pull-left font-13 text-black-50 text-uppercase mt-n3"
                                        *ngIf="isRetired">
                                        <div class="col-md-2"></div>
                                        <div class="col-md-5 float-left"
                                            *ngIf="retireGraphValues.length>0 && retireGraphValues[1].graphLabel">
                                            <div>Expected Sustainable Spending:<span><i
                                                        class="fa fa-question-circle cursor-pointer ml-1"
                                                        tooltipClass="tooltipData"
                                                        ngbTooltip="This number is calculated such that 75% of outcomes have money remaining when the Client reaches planned life expectancy."
                                                        placement="bottom"></i></span> </div>
                                            <h5 class="text-dark font-20">
                                                {{sustainable_spending | currency :'USD':'symbol':'1.0-0'}}</h5>
                                        </div>
                                        <div *ngIf="retireGraphValues.length>0 && retireGraphValues[2].graphLabel"
                                            class="col-md-5 float-right">
                                            <div>Expected Ending Value: <span><i
                                                        class="fa fa-question-circle cursor-pointer ml-1"
                                                        tooltipClass="tooltipData"
                                                        ngbTooltip="Value of portfolio at ending age based on median expected return of portfolio, cash flows, and yearly desired spending being spent from the portfolio"
                                                        placement="bottom"></i></span></div>
                                            <h5 class="text-dark">
                                                {{expectedEndingValue | currency :'USD':'symbol':'1.0-0'}}</h5>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-4 col-sm-4 mt-5">
                                    <div class="slider-row wrap" *ngIf="!isRetired">
                                        <div class="slider-head">Retirement Age</div>
                                        <div class="border-bottom w-50">
                                            <span *ngIf="!graphValueEdit.age" class="font-16"
                                                (click)="changeGraphValueMode('age')">{{rangeData.retirementAge}}
                                            </span>
                                            <input type="number" name="rangeData.inputVal"
                                                class="form-control input-round" id="retirementAge"
                                                *ngIf="graphValueEdit.age" [(ngModel)]="rangeData.inputVal"
                                                (blur)="updateRangeBar('age')" (focus)="$event.target.select();"
                                                (keyup.enter)="recalcBlur('age')">
                                        </div>

                                        <div class="slider-mast w-50 pl-4">
                                            <input type="range" min="{{rangeData.startAge}}" max="{{maxRetirementAge}}"
                                                class="w-100" [(ngModel)]="rangeData.retirementAge"
                                                value="{{rangeData.retirementAge<=rangeData.inputVal?rangeData.retirementAge:rangeData.inputVal}}"
                                                (change)="rangeData.inputVal = rangeData.retirementAge; rangeData.step = 1;"
                                                step="{{rangeData.step}}" (click)="graphValueEdit.age = false;">
                                        </div>
                                    </div>
                                    <div class="slider-row wrap" *ngIf="isRetired">
                                        <div class="slider-head">Investment Horizon</div>
                                        <div class="slider-val w-50">
                                            <span *ngIf="!graphValueEdit.ih" class="font-16"
                                                (click)="changeGraphValueMode('ih')">{{rangeData.investmentHorizon}}
                                            </span>
                                            <input type="number" name="rangeData.investmentHorizonVal" id='ih'
                                                class="font-16" class="form-control input-round"
                                                *ngIf="graphValueEdit.ih" [(ngModel)]="rangeData.investmentHorizonVal"
                                                (blur)="updateRangeBar('ih')" (focus)="$event.target.select();"
                                                (keyup.enter)="recalcBlur('ih')">
                                        </div>
                                        <div class="slider-mast pl-4">
                                            <input type="range" min="{{rangeData.startAge}}" max="104" class="w-100"
                                                [(ngModel)]="rangeData.investmentHorizon"
                                                value="{{rangeData.investmentHorizon<=rangeData.investmentHorizonVal?rangeData.investmentHorizon:rangeData.investmentHorizonVal}}"
                                                (change)="rangeData.investmentHorizonVal = rangeData.investmentHorizon; rangeData.step = 1;"
                                                step="{{rangeData.step}}" (click)="age.showEdit = false;">
                                        </div>
                                    </div>

                                    <div class="slider-row wrap" *ngIf="!isRetired">
                                        <div class="slider-head">Monthly Savings</div>
                                        <div class="slider-val w-50">
                                            <span *ngIf="!graphValueEdit.ms" (click)="changeGraphValueMode('ms')"
                                                class="font-16">
                                                {{rangeData.monthlySavings| currency:'USD':'symbol':'1.0-0'}}
                                            </span>
                                            <input type="number" name="rangeData.monthlySavings" id="ms"
                                                class="form-control input-round" *ngIf="graphValueEdit.ms"
                                                [(ngModel)]="rangeData.monthlySavings" (blur)="updateRangeBar('ms')"
                                                (focus)="$event.target.select();" (keyup.enter)="recalcBlur('ms')">
                                        </div>
                                        <div class="slider-mast w-50 pl-4">
                                            <input type="range" min="0" max="25000" class="w-100"
                                                step="{{rangeData.monthlySavingsStep}}"
                                                [(ngModel)]="rangeData.monthlySavingsVal"
                                                (change)="rangeData.monthlySavings = canChange ? rangeData.monthlySavingsVal : rangeData.monthlySavings;"
                                                (click)="age.showEdit = false;">
                                        </div>
                                    </div>
                                    <div class="slider-row wrap" *ngIf="isRetired">
                                        <div class="slider-head">Desired Monthly Income</div>
                                        <div class="slider-val w-50">
                                            <span *ngIf="!graphValueEdit.dmi" (click)="changeGraphValueMode('dmi')"
                                                class="font-16">
                                                {{rangeData.desiredMonthlyIncome| currency:'USD':'symbol':'1.0-0'}}
                                            </span>
                                            <input type="number" name="rangeData.desiredMonthlyIncome"
                                                class="form-control input-round font-16" id='dmi'
                                                *ngIf="graphValueEdit.dmi" [(ngModel)]="rangeData.desiredMonthlyIncome"
                                                (blur)="updateRangeBar('dmi')" (focus)="$event.target.select();"
                                                (keyup.enter)="recalcBlur('dmi')">
                                        </div>
                                        <div class="slider-mast w-50 pl-4">
                                            <input type="range" min="0" max="{{rangeData.desiredMaxValue}}"
                                                step="{{rangeData.desiredStep}}" class="w-100"
                                                [(ngModel)]="rangeData.desiredMonthlyIncomeVal"
                                                (change)="rangeData.desiredMonthlyIncome = canChange ? rangeData.desiredMonthlyIncomeVal : rangeData.desiredMonthlyIncome;"
                                                (click)="graphValueEdit.dmi = false;">
                                        </div>
                                    </div>
                                    <div class="slider-row wrap">
                                        <div class="slider-head"><span
                                                ngbTooltip="This is the value of your managed portfolio and is used in combination with any outside assets to generate the graph."
                                                tooltipClass="tooltipData" placement="bottom">Current Assets
                                                Invested</span><i
                                                class="fa fa-pencil text-dark ml-2 cursor-pointer font-16"
                                                aria-hidden="true" (click)="showScenarioAccountAndGroup()"
                                                *ngIf="selected_managed_assets_account_groups.length>0"></i></div>
                                        <div class="slider-val w-50">
                                            <span *ngIf="!graphValueEdit.cas" (click)="changeGraphValueMode('cas')"
                                                class="font-16">
                                                {{rangeData.currentAssestsInv| currency :'USD':'symbol':'1.0-0'}}
                                            </span>
                                            <input type="number" name="rangeData.currentAssestsInv" id="cas"
                                                class="form-control input-round" *ngIf="graphValueEdit.cas"
                                                [(ngModel)]="rangeData.currentAssestsInv" (blur)="updateRangeBar('cas')"
                                                ng-focus="$event.target.select();" (keyup.enter)="recalcBlur('cas')">
                                        </div>
                                        <div class="slider-mast w-50 pl-4">
                                            <input type="range" min="0" max="{{rangeData.currentAssestsInvMax}}"
                                                step="{{rangeData.currSavingsStep}}" class="w-100"
                                                [(ngModel)]="rangeData.currentAssestsInvVal"
                                                (change)="rangeData.currentAssestsInv = canChange ? rangeData.currentAssestsInvVal : rangeData.currentAssestsInvVal;"
                                                (click)="age.showEdit = false;">
                                        </div>
                                    </div>
                                    <div class="slider-row wrap pt-5">
                                        <div class="col-md-8 col-xs-12 col-sm-12">
                                            <button
                                                class="btn btn-primary btn-sm cursor-pointer rounded-pill font-12 rebtn"
                                                (click)="calculateRetirementPlan()" (disabled)="disable">Recalculate
                                                Portfolio
                                                Projection</button>
                                        </div>
                                        <div class="col-md-4 col-xs-12 col-sm-12">
                                            <button
                                                class="btn btn-primary btn-sm rounded-pill cursor-pointer font-12 float-right rebtn"
                                                (click)="resetRetirementPlan()" (disabled)="disable">Reset
                                                Values</button>
                                        </div>
                                    </div>
                                    <div *ngIf="!graphData.is_retired" class="col-md-12 col-sm-12 col-xs-12">
                                        <div class="row">
                                            <div class="col-md-12 pl-0 pt-5 pr-0">
                                                <label class="switch">
                                                    <input type="checkbox" [(ngModel)]="displayTodayTotal"
                                                        (ngModelChange)="updateScenarioToggle()" on="Yes" off="No">
                                                    <div class="slider round">
                                                        <span class="on">Yes</span>
                                                        <span class="off">No</span>
                                                    </div>
                                                </label>
                                                <label class="pl-2 pt-1">
                                                    <app-help-icon textLabel="Today's Dollars" code="TODAY_DOLLAR">
                                                    </app-help-icon>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="clearfix"></div>
                                <div id="accordion" class="mt-3 w-100 pl-2">
                                    <div>
                                        <div id="headingOne">
                                            <div class="mb-4 float-left w-50 border-bottom">
                                                <button class="btn  color-purple font-15 pl-0" data-toggle="collapse"
                                                    data-target="#collapseOne" aria-expanded="true"
                                                    aria-controls="collapseOne">
                                                    Cash Flows
                                                </button>
                                            </div>
                                            <div class="float-right">
                                                <div class="pull-right dash-disclosure disclosure-tooltip pr-3">
                                                    <span class="mt-2">Important
                                                        Disclosures on Simulations | Portfolio Projections </span>
                                                    <i class="fa fa-question-circle color-black ml-2 cursor-pointer"
                                                        tooltipClass="tooltipBigData" placement="left" triggers="click:blur"
                                                        [ngbTooltip]="ToolTipTemplate2">
                                                    </i>
                                                </div>
                                            </div>
                                        </div>

                                        <div id="collapseOne" class="collapse show mt-2" aria-labelledby="headingOne"
                                            data-parent="#accordion">
                                            <div id="cashFlowChart1" [appCashflowChart]="graphData"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row pt-1"  #allocationAnalysisId>
            <div class="col-md-12 col-sm-12 col-xs-12 pt-4 pl-3" [hidden]="allocationList.length == 0">
                <div class="card  m-auto border-radius-10 border-0 shadow-sm p-2">
                    <div class="card-body pb-1">
                        <div class="mb-2 float-left border-bottom w-100">
                            <div class="float-left">
                                <h5>Allocation & Analysis: </h5>
                            </div>
                            <div class="float-right mb-1">
                                <input type="hidden" id="rebalancing-group" [(ngModel)]="selectedPortfolio">
                                <label *ngIf="accountGroupsDropdownLength > 1"
                                    class="pr-3 font-weight-bold float-left pt-2">{{accountGroupsDropdownLength}}
                                    Groups</label>
                                <label *ngIf="accountGroupsDropdownLength == 1"
                                    class="pr-3 font-weight-bold float-left pt-2">{{accountGroupsDropdownLength}}
                                    Group</label>
                                <div class="dropdown float-left" *ngIf="selectedPortfolio">
                                    <button
                                        class="btn btn-outline-secondary dropdown-toggle rounded-lg bg-white pl-2 pr-2 font-14"
                                        type="button" id="dropdownMenuButton" data-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false">
                                        {{selectedPortfolio.name}}<span
                                            *ngIf="selectedPortfolio.account_group_key">&nbsp;({{selectedPortfolio.account_group_key}})
                                        </span>
                                    </button>
                                    <div class="allocationList-menu dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <a class="dropdown-item cursor-pointer" (click)="loadRebalancingGroupAccountDetail(option)"
                                            *ngFor="let option of accountGroupsDropdown">{{option.name}}
                                            <span
                                                *ngIf="option.account_group_key">&nbsp;({{option.account_group_key}})</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                        <div class="row  pb-1">
                            <div class="col-md-5 col-xs-12 col-sm-12">
                                <div class="row float-left w-100 justify-content-center" >
                                    <div class="col-md-6 col-sm-6 col-xs-12 pl-4" *ngIf="(allocationListGraphData|json)!='{}'">
                                        <fusioncharts width="100%" class="float-left" type="doughnut2d"
                                            dataFormat="json" [dataSource]="allocationListGraphData">
                                        </fusioncharts>
                                    </div>
                                    <div class="col-md-6 col-sm-6 col-xs-12 mt-5 pl-5"
                                        *ngIf="selectedPortfolio.name !='All Assets' && selectedPortfolio.name !='Unmanaged'">
                                        <div *ngIf="current_user_firm_setting.show_rebalancing_group_inception_date">
                                            Inception to Date
                                            ({{inceptionDate| date:'MM/dd/yyyy' }})
                                        </div>
                                        <div *ngIf="itdValue">
                                            <span class="fs-3"
                                                [ngClass]="{'text-danger': (itdValue < 0),'text-success':(itdValue > 0),'text-dark': (itdValue === 0)}">
                                                {{itdValue | number :'1.2-2'}}%</span> (annualized)
                                        </div>
                                        <div class="mt-3"
                                            *ngIf="current_user_firm_setting.show_rebalancing_group_itd_ytd">
                                            <div>
                                                Year to Date
                                            </div>
                                            <div>
                                                <span class="font-16"
                                                    [ngClass]="{'text-danger': (ytdValue < 0),'text-success': (ytdValue > 0),'text-dark': (ytdValue === 0)}">
                                                    {{ytdValue| number :'1.2-2'}}%
                                                </span>
                                            </div>
                                        </div>
                                        <div *ngIf="tamaracObj.length>0" class="col-md-12 col-sm-12 col-xs-12 mt-3 pl-0">
                                            <a href="{{redirectViewPerformance}}" target="_blank">
                                                <button
                                                        class="btn btn-primary cursor-pointer pl-3 pr-3 rounded-pill font-12">View
                                                        Performance</button></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-7 col-sm-12 col-xs-12 pl-0 pr-0">
                                <table class="table cont-table allocationAnalysis">
                                    <thead class="allocationHeader">
                                        <tr>
                                            <th class="border-top-0">
                                                <div class="bullet">HOLDINGS</div>
                                            </th>
                                            <th class="text-right border-top-0">VALUE</th>
                                            <th class="text-right border-top-0">CURRENT %</th>
                                            <th class="text-right border-top-0" *ngIf="showTargets">TARGET %</th>
                                            <th class="text-right border-top-0"  *ngIf="showTargets">DIFFERENCE</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let value of allocationList; let i = index">
                                            <td>
                                                <div class="bullet">
                                                    <span class="mr-2 mt-1" [ngStyle]="{'background':colorRange[i]}">
                                                    </span>{{value.label}}
                                                </div>
                                            </td>
                                            <td class="text-right"><span class="margin-right-10">{{value.invest|
                                                    currency: 'USD' : 'symbol' : '1.0-0'}}</span></td>
                                            <td class="text-right">{{roundTo(value.weight)}}%</td>
                                            <td class="text-right"  *ngIf="showTargets">{{roundTo(value.target_weight)}}%</td>
                                            <td class="text-right" [ngClass]="getNumberColour(value)"  *ngIf="showTargets">
                                                {{getDifference(roundTo(value.weight)
                                                ,roundTo(value.target_weight))}}%</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td class="border-primary">
                                                <div class="bullet">Total</div>
                                            </td>
                                            <td class="text-right border-primary"><span class="mr-2">{{totalInvest|
                                                    currency: 'USD' : 'symbol' : '1.0-0'}}</span></td>
                                            <td class="text-right border-primary">{{roundTo(totalWeight)}}%</td>
                                            <td class="text-right border-primary"  *ngIf="showTargets">{{roundTo(targetWeight)}}%</td>
                                            <td class="text-right border-primary" *ngIf="totalWeight && targetWeight && showTargets"
                                                [ngClass]="getNumberColour(roundTo(totalWeight)
                                                - roundTo(targetWeight))">{{getDifference(totalWeight,targetWeight)}}%
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                        <div class="clearfix border-bottom"></div>
                        <div class="row p-3" *ngIf="showGrades == true">
                            <div class="text-left col-md-4 col-sm-12 col-xs-12">
                                <div class="text-muted font-weight-light">
                                    <span class="float-right font-30"
                                        [ngClass]="{'color-orng':diversificationGrade >= 70 && diversificationGrade < 80, 'color-red1':diversificationGrade < 70, 'color-grn':diversificationGrade >= 80}">{{diversificationGrade|
                                        number:'1.0-0'}}%</span>
                                    Diversification Grade
                                    <div class="bfr-bar lg mt-2 mb-2"
                                        [ngClass]="{'orng':diversificationGrade >= 70 && diversificationGrade < 80, 'red':diversificationGrade < 70}">
                                        <span [ngStyle]="diversificationWidth"></span>
                                    </div>
                                </div>
                                <div class="text-dark">
                                    <span *ngIf="diversificationGrade >= 80">
                                        Your portfolio is well-diversified, matches your risk tolerance, and is
                                        allocated
                                        properly.
                                    </span>
                                    <span *ngIf="diversificationGrade < 80">
                                        Your portfolio is not well diversified and does not match your risk tolerance.
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-4 col-xs-12">
                                <div class="text-muted font-weight-light">
                                    <span class="float-right font-30"
                                        [ngClass]="{'color-orng':expenseGrade >= 70 && expenseGrade < 80, 'color-red1':expenseGrade < 70, 'color-grn':expenseGrade >= 80}">{{expenseGrade|
                                        number:'1.0-0'}}%</span>
                                    Fund Expense Grade
                                    <div class="bfr-bar lg"
                                        [ngClass]="{'orng':expenseGrade >= 70 && expenseGrade < 80, 'red':expenseGrade < 70}">
                                        <span [ngStyle]="expenseWidth"></span>
                                    </div>
                                </div>
                                <div class="text-dark">
                                    Your current investment expense is <b
                                        *ngIf="expenseGradeDetails">{{expenseGradeDetails.current}}%</b> per year.
                                    <span *ngIf="expenseGrade == 100">Congratulations! Your portfolio expenses are low.
                                        Low
                                        expenses are a significant factor in generating higher long-term
                                        returns.</span><span *ngIf="expenseGrade < 100">Changing your investments could
                                        save
                                        you up to <b>
                                            {{expenseGradeDetails.costs_saving| currency: 'USD' : 'symbol' :
                                            '1.0-0'}}</b>
                                        per year in
                                        portfolio expenses.</span>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-4 col-xs-12">
                                <div class="text-muted font-weight-light">
                                    <span class="float-right font-30"
                                        [ngClass]="{'color-orng':assetAllocationGrade >= 70 && assetAllocationGrade < 80, 'color-red1':assetAllocationGrade < 70, 'color-grn':assetAllocationGrade >= 80}">{{assetAllocationGrade|
                                        number:'1.0-0'}}%</span>
                                    Asset Location Grade
                                    <div class="bfr-bar lg"
                                        [ngClass]="{'orng':assetAllocationGrade >= 70 && assetAllocationGrade < 80, 'red':assetAllocationGrade < 70}">
                                        <span [ngStyle]="assetAllocationWidth"></span>
                                    </div>
                                </div>
                                <div class="text-dark" id="assetAllocationGrade">
                                    <span *ngIf="assetAllocationGrade > 80">
                                        Your accounts are properly invested to take advantage of the benefits of asset
                                        location. <a href="{{taxGradeAdvice}}" target="_blank">Asset location</a> can
                                        have
                                        a significant impact on your long-term wealth.
                                    </span>
                                    <span *ngIf="assetAllocationGrade <= 80">
                                        Your accounts are not properly invested to take advantage of the benefits of
                                        asset
                                        location. <a href="{{taxGradeAdvice}}" target="_blank">Asset location</a> can
                                        have
                                        a significant impact on your long-term wealth.
                                    </span>
                                </div>
                                <div class="float-right text-muted mt-3"><span>Important Disclosures on Grades</span>
                                    <i class="fa fa-question-circle text-dark ml-1" tooltipClass="tooltipBigData"
                                        placement="left" [ngbTooltip]="ToolTipTemplate1"></i>
                                </div>
                            </div>
                            <div #networth></div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="row pt-1">
            <div class="col-md-8 col-sm-12 col-xs-12 pt-4 pl-3" *ngIf="!showEdit">
                <span class="inline-flex pull-left mt-3 font-18">My Net Worth</span>
                <div *ngIf="bank_linker=='yodlee'" class="pull-right">
                    <button class="btn btn-primary btn-sm m-2 cursor-pointer rounded-pill rebtn font-12"
                        *ngIf="dashboard_bank_status_color == 'red'" (click)="relinkBankPopup()"
                        [disabled]="disableAddAccount || loadRefreshAll">Fix {{errorBankCount}}
                        <span *ngIf="errorBankCount > 1">Banks</span>
                        <span *ngIf="errorBankCount == 1">Error</span>
                        <span *ngIf="disableAddAccount || loadRefreshAll">&nbsp;</span>
                        <span *ngIf="disableAddAccount || loadRefreshAll" class="loader-status">
                            <div class="loader loader-spinner top-30"></div>&nbsp;
                        </span>
                    </button>
                    <button class="btn btn-primary btn-sm m-2 cursor-pointer rounded-pill rebtn orange-btn font-12"
                        *ngIf="dashboard_bank_status_color == 'orange'" (click)="relinkBankPopup()"
                        [disabled]="disableAddAccount || loadRefreshAll">
                        Refresh Bank
                        <span *ngIf="disableAddAccount || loadRefreshAll">&nbsp;</span>
                        <span ng-show="disableAddAccount || loadRefreshAll" class="loader-status">
                            <div class="loader loader-spinner top-30"></div>&nbsp;
                        </span>
                    </button>
                    <button type="submit"
                        class="btn btn-primary m-2 btn-sm cursor-pointer rounded-pill pull-right rebtn font-12"
                        (click)="openLinkExternalAccountModal($event)">Link External Account
                    </button>
                    <button class="btn btn-primary btn-sm m-2 cursor-pointer rounded-pill pull-right rebtn font-12"
                        (click)="addManualAccountModal()">Add
                        Manual Account</button>

                    <button class="btn btn-primary btn-sm m-2 cursor-pointer rounded-pill rebtn font-12"
                        *ngIf="linkedBanks.length > 0" (click)="hardRefreshLinkedBank()"
                        [disabled]="loadRefreshAll">Refresh
                        All <span *ngIf="loadRefreshAll">&nbsp;</span>
                        <span *ngIf="loadRefreshAll" class="loader-status">
                            <div class="loader loader-spinner top-30"></div>&nbsp;
                        </span>
                    </button>
                </div>

                <div *ngIf="bank_linker=='plaid'" class="pull-right">
                    <button class="btn btn-primary btn-sm m-2 cursor-pointer rounded-pill rebtn red-btn font-12"
                        *ngIf="dashboard_bank_status_color == 'red'" (click)="relinkBankPopup()"
                        [disabled]="disableAddAccount || loadRefreshAll">Fix {{errorBankCount}}
                        <span *ngIf="errorBankCount > 1">Banks</span>
                        <span *ngIf="errorBankCount == 1">Error</span>
                        <span *ngIf="disableAddAccount || loadRefreshAll">&nbsp;</span><span
                            *ngIf="disableAddAccount || loadRefreshAll" class="loader-status">
                            <div class="loader loader-spinner mt-5 p-1"></div>&nbsp;
                        </span>
                    </button>
                    <button class="btn btn-primary btn-sm m-2 cursor-pointer rounded-pill rebtn orange-btn font-12"
                        *ngIf="dashboard_bank_status_color == 'orange'" (click)="relinkBankPopup()"
                        [disabled]="disableAddAccount  || loadRefreshAll">
                        Refresh Bank
                        <span *ngIf="disableAddAccount || loadRefreshAll">&nbsp;</span>
                        <span *ngIf="disableAddAccount || loadRefreshAll" class="loader-status">
                            <div class="loader loader-spinner mt-5 p-1"></div>&nbsp;
                        </span>
                    </button>
                    <button type="submit"
                        class="btn btn-primary m-2 btn-sm cursor-pointer rounded-pill pull-right rebtn font-12"
                        (click)="openLinkExternalAccountModal($event)">Link External Account
                    </button>
                    <button class="btn btn-primary btn-sm m-2 cursor-pointer rounded-pill rebtn font-12"
                        (click)="addManualAccountModal()">Add
                        Manual Account</button>
                    <button class="btn btn-primary btn-sm m-2 cursor-pointer rounded-pill rebtn font-12"
                        *ngIf="linkedBanks.length > 0" (click)="hardRefreshLinkedBank()"
                        [disabled]="loadRefreshAll">Refresh All <span *ngIf="loadRefreshAll">&nbsp;</span>
                        <span *ngIf="loadRefreshAll" class="loader-status">
                            <div class="loader loader-spinner top-30"></div>&nbsp;
                        </span>
                    </button>
                </div>
                <div class="card w-100 m-auto border-radius-10 border-0 shadow-sm p-2">
                    <div class="card-body pb-1 pt-2">
                        <div *ngFor="let asset of netWorthData; let i= index">
                            <div id="accordion" class="w-100 assetType">
                                <div id="headingOne">
                                    <h5 class="mb-0">
                                        <div class="row font-16 font-weight-400 collapsed" data-toggle="collapse"
                                            [attr.data-target]="'#collapseDoc'+i" aria-expanded="true"
                                            [attr.aria-controls]="'collapseDoc'+i">
                                            <div class="col-md-6 labelhead"><i class="fa pr-2"></i>{{asset.asset_type}}</div>
                                            <div class="col-md-4"></div>
                                            <div class="col-md-2 text-right">{{asset.total | currency: 'USD' : 'symbol'
                                                : '1.0-0'}}</div>
                                        </div>
                                    </h5>
                                </div>
                                <div [id]="'collapseDoc'+i" class="collapse" aria-labelledby="headingOne"
                                    data-parent="#accordion">
                                    <div class="card-body pr-0 pl-0 pb-0">
                                        <table class="table mb-0">
                                            <tbody>
                                                <ng-container *ngIf="asset.asset_type != 'Liabilities'">
                                                    <tr *ngFor="let values of asset.accounts; let i = index" class="cursor-pointer font-14"
                                                        [ngClass]="{'content-table':asset.asset_type !== 'Managed Assets'}">
                                                        <td *ngIf="asset.asset_type !== 'Managed Assets' && values.is_hidden == false"
                                                            (click)="showPositions(true, values, asset.asset_type)">
                                                            <div class="font-13 color-999">
                                                                <span *ngIf="values.account_custodian_login_url">
                                                                    <a href="{{values.account_custodian_login_url}}"
                                                                    target="_blank">{{values.account_custodian_name}}</a> - 
                                                                </span>
                                                                <span *ngIf="values.bank_id">
                                                                    <span *ngIf="!values.bank_website">{{values.bank_description}}</span>
                                                                    <span *ngIf="values.bank_website" class="color-purple"><a href="{{values.bank_website}}"
                                                                            target="_blank">{{values.bank_description}}</a></span>
                                                                    - </span> {{values.type}}
                                                                <span *ngIf="values.account_no">&nbsp;({{values.account_no}})</span>
                                                            </div>
                                                            <span class="word-break-all">{{values.account_description}}</span>
                                                            <span class="pl-1" *ngIf="values.error"><i class="fa fa-exclamation-triangle color-red font-12"
                                                                    aria-hidden="true"></i><span class="font-10 color-red vertical-align-middle">&nbsp;Access
                                                                    Failed!</span>
                                                                <span class="link-failed-account" (click)="linkAccountLoad(values)" data-toggle="modal">Relink
                                                                    Account</span>
                                            
                                                            </span>
                                                        </td>
                                                        <td id="accordion1" *ngIf="asset.asset_type == 'Managed Assets'" class="managed-assets pb-0" colspan="4">
                                                            <div id="managedHeading" class="pb-2">
                                                                <div class="row font-16 collapsed" data-toggle="collapse" [attr.data-target]="'#collapseManaged'+i"
                                                                    aria-expanded="true" [attr.aria-controls]="'collapseManaged'+i">
                                                                    <div class="col-md-6"><i class="fa pr-2"></i>{{values.account_group_name}}
                                                                    </div>
                                                                    <div class="col-md-4"></div>
                                                                    <div class="col-md-4" *ngIf="values.account_no">
                                                                        &nbsp;({{values.account_no}})</div>
                                                                    <div class="col-md-2 text-right">
                                                                        {{values.balance_sub_total|
                                                                        currency: 'USD' : 'symbol' : '1.0-0'}}</div>
                                                                </div>
                                                            </div>
                                            
                                                            <div [id]="'collapseManaged'+i" class="collapse" aria-labelledby="headingOne" data-parent="#accordion1">
                                                                <div class="card-body p-0">
                                                                    <div class="cursor-pointer networth-managed content-table"
                                                                        *ngFor="let sub_account_values of values.sub_accounts; let k = index"
                                                                        (click)="showPositions(true, sub_account_values, asset.asset_type)">
                                                                        <div class="font-13 col-md-12 pl-0 pr-0 sml-txt">
                                                                            <span *ngIf="sub_account_values.account_custodian_login_url">
                                                                                <a href="{{sub_account_values.account_custodian_login_url}}"
                                                                                target="_blank">{{sub_account_values.account_custodian_name}}</a> - 
                                                                            </span>
                                                                            <span *ngIf="sub_account_values.bank_id">
                                                                                <span
                                                                                    *ngIf="!sub_account_values.bank_website">{{sub_account_values.bank_description}}</span>
                                            
                                                                                <span *ngIf="sub_account_values.bank_website" class="color-purple"><a
                                                                                        href="{{sub_account_values.bank_website}}"
                                                                                        target="_blank">{{values.bank_description}}</a></span>
                                                                                - </span> {{sub_account_values.type}}
                                                                            <span
                                                                                *ngIf="sub_account_values.account_no">&nbsp;({{sub_account_values.account_no}})</span>
                                                                        </div>
                                                                        <div class="pr-0 row">
                                                                            <div class="font-14 col-md-6 pt-2 pr-0">
                                                                                {{sub_account_values.account_group_member_name}}
                                                                            </div>
                                                                            <div class="col-md-4"> </div>
                                                                            <div class="col-md-2 pull-right text-right font-16">
                                                                                {{sub_account_values.current_value|
                                                                                currency: 'USD' : 'symbol' : '1.0-0'}}</div>
                                                                        </div>
                                                                        <span class="pl-2" *ngIf="sub_account_values.error">
                                                                            <i class="fa fa-exclamation-triangle color-red font-12" aria-hidden="true"></i>
                                                                            <span class="font-10 color-red vertical-align-middle">&nbsp;Access
                                                                                Failed!</span>
                                                                            <span class="link-failed-account" (click)="openLinkAccount()" data-toggle="modal">Relink
                                                                                Account</span>
                                                                        </span>
                                                                        <span class="pull-right text-right unvestedTotal" *ngIf="sub_account_values.unvested_flag">
                                                                            <span>
                                                                                Unvested Total:
                                                                            </span>{{sub_account_values.unvested_total|
                                                                            currency: 'USD' : 'symbol' : '1.0-0'}}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-right pr-0" *ngIf="asset.asset_type !== 'Managed Assets' && values.is_hidden == false">
                                                            <span>{{values.balance
                                                                | currency: 'USD' : 'symbol' : '1.0-0'}}
                                                            </span>
                                                            <div class="unvestedTotal" *ngIf="values.unvested_flag">
                                                                <span>Unvested Total:
                                                                </span>{{values.unvested_total
                                                                | currency: 'USD' : 'symbol' : '1.0-0'}}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </ng-container>
                                                <ng-container *ngIf="asset.asset_type == 'Liabilities'">
                                                    <tr class="cursor-pointer font-14" *ngFor="let item of asset.accounts | keyvalue;let i = index">
                                                        <td id="accordion1" class="pb-0" colspan="4">
                                                            <div id="managedHeading" class="pb-2">
                                                                <div class="row font-16 collapsed" data-toggle="collapse" [attr.data-target]="'#collapseLiabilities'+i"
                                                                    aria-expanded="true" [attr.aria-controls]="'collapseLiabilities'+i">
                                                                    <div class="col-md-6"><i class="fa pr-2"></i>{{item.key}}
                                                                    </div>
                                                                    <div class="col-md-4"></div>
                                                        
                                                                    <div class="col-md-2 text-right pr-0">
                                                                        {{item.value.total|
                                                                        currency: 'USD' : 'symbol' : '1.0-0'}}</div>
                                                                </div>
                                                                <div [id]="'collapseLiabilities'+i" class="collapse" aria-labelledby="headingOne" data-parent="#accordion1">
                                                                    <div class="card-body p-0">
                                                                        <div class="cursor-pointer networth-managed content-table" *ngFor="let values of item.value; let k = index"
                                                                            (click)="showPositions(true, values, item.key)">
                                                                
                                                                            <div class="font-13 col-md-6 pl-0  sml-txt">
                                                                                <span *ngIf="values.account_custodian_login_url">
                                                                                    <a href="{{values.account_custodian_login_url}}"
                                                                                    target="_blank">{{values.account_custodian_name}}</a> - 
                                                                                </span>
                                                                                <span *ngIf="values.bank_id">
                                                                                    <span *ngIf="!values.bank_website">{{values.bank_description}}</span>
                                                                
                                                                                    <span *ngIf="values.bank_website" class="color-purple"><a href="{{values.bank_website}}"
                                                                                            target="_blank">{{values.bank_description}}</a></span>
                                                                                    - </span> {{values.type}}
                                                                
                                                                            </div>
                                                                            <div class="pr-0 row">
                                                                                <div class="col-md-6">{{values.account_description}} <span class="pl-2" *ngIf="values.error">
                                                                                    <i class="fa fa-exclamation-triangle color-red font-12" aria-hidden="true"></i>
                                                                                    <span class="pl-1" *ngIf="values.error"><i class="fa fa-exclamation-triangle color-red font-12"
                                                                                            aria-hidden="true"></i><span class="font-10 color-red vertical-align-middle">&nbsp;Access
                                                                                            Failed!</span>
                                                                                        <span class="link-failed-account" (click)="linkAccountLoad(values)" data-toggle="modal">Relink
                                                                                            Account</span>
                                                                                    </span>
                                                                                </span></div>
                                                                                <div class="col-md-4"> </div>
                                                                                <div class="col-md-2 pull-right text-right font-16">
                                                                                    {{values.balance|
                                                                                    currency: 'USD' : 'symbol' : '1.0-0'}}</div>
                                                                                   
                                                                            </div>
                                                                            
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </ng-container>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="pb-3 pt-2">
                        <strong class="font-16 pl-3">Total Net Worth </strong>
                        <strong class="font-16 pr-3 pull-right">{{topCardData.total_net_worth|
                            currency: 'USD' : 'symbol' : '1.0-0'}}</strong>
                    </div>
                </div>
              <div>

                <span class="inline-flex pull-left mt-3 mb-1 font-18">Estate Plan</span>
                <div class="card w-100 m-auto border-radius-10 border-0 shadow-sm p-2" *ngIf="(estatePlanData  | json) != '{}'">
                    <div class="card-body pb-1 pt-3">
                
                        <div class="col-md-12 inline-flex pl-0 font-14">
                            <div class="col-md-6 pl-0">
                                <div class="color-gray font-13">Drafting Attorney</div>
                                <div class="color-black font-16">{{estatePlanData.estateInformation?.drafting_attorney_name}}</div>
                                <div class="color-blue font-16">{{estatePlanData.estateInformation?.drafting_attorney_email}}</div>
                                <div class="color-blue font-16">{{estatePlanData.estateInformation?.drafting_attorney_phone}}</div>
                            </div>
                            <div class="col-md-3">
                                <div class="color-gray font-13">State of Residence</div>
                                <div class="color-black font-16">{{estatePlanData.estateInformation?.estate_state_tax?.name}}</div>
                
                                <div class="pt-3 color-gray font-13">State Estate Taxes?</div>
                                <div class="color-black font-16">{{estatePlanData.estateInformation?.is_state_estate_tax?'Yes':'No'}}</div>
                            </div>
                            <div class="col-md-3">
                                <div class="color-gray font-13">Last Updated</div>
                                <div class="color-black font-16">{{estatePlanData.estateInformation?.last_updated_at}}</div>
                                <div class="pt-3 color-gray font-13">Last Advisor Review</div>
                                <div class="color-black font-16">{{estatePlanData.estateInformation?.last_forum_review}}</div>
                            </div>
                        </div>
                        <!-- <div class="col-md-12 pt-2 inline-flex pl-0">
                            <div class="col-md-4 pl-0 pr-0">
                                <span class="font-16 close-grey">Currently Using their Annual Exclusion for Gifts?</span>
                            </div>
                            <div class="col-md-4 pl-0">
                                <label class="switch">
                                    <input type="checkbox" [(ngModel)]="estatePlanData.estateInformation.is_annual_exclusion_gifting"
                                        class="d-none" on="Yes" off="No">
                                    <div class="slider round">
                                        <span class="on">Yes</span>
                                        <span class="off">No</span>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div class="col-md-12 pt-2 inline-flex pl-0">
                            <div class="col-md-4 pl-0 pr-0">
                                <span class="font-16 close-grey">Are they a Business Owner?</span> 
                            </div>
                            <div class="col-md-4 pl-0">
                                <label class="switch">
                                    <input type="checkbox" [(ngModel)]="estatePlanData.estateInformation.is_business_owner"
                                        class="d-none" on="Yes" off="No">
                                    <div class="slider round">
                                        <span class="on">Yes</span>
                                        <span class="off">No</span>
                                    </div>
                                </label>
                            </div>
                        </div> -->
                
                        <div>
                
                            <div id="accordion" class="w-100 assetType">
                                <div id="headingOne">
                                    <h5 class="mb-0">
                                        <div class="row font-16 font-weight-400 collapsed" data-toggle="collapse"
                                            data-target="#collapseTrust" aria-expanded="true" aria-controlss="collapseTrust">
                                            <div class="col-md-6 labelhead"><i class="fa pr-2"></i>Trusts<div class="inline-flex estate-plan-number-div" *ngIf="estatePlanData.estateCategories.Trusts && estatePlanData.estateCategories.Trusts.length > 0">
                                                {{ estatePlanData.estateCategories.Trusts.length}}
                                            </div></div>
                                            <div class="col-md-4"></div>
                                            <div class="col-md-2 text-right">
                                                
                                            </div>
                                        </div>
                                    </h5>
                
                                </div>
                
                                <div id="collapseTrust" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                    <div class="card-body pr-0 pl-0 pb-0">
                                        <table class="table mb-0">
                                            <tbody>
                                                <tr *ngFor="let trust of estatePlanData.estateCategories.Trusts; let i = index"
                                                    class="cursor-pointer font-14">
                                                    <td id="accordion1" class="managed-assets pb-0" colspan="4">
                                                        <div id="trust" class="pb-2">
                                                            <div class="row font-16 collapsed" data-toggle="collapse"
                                                                [attr.data-target]="'#collapseTrustData'+i" aria-expanded="true"
                                                                [attr.aria-controls]="'collapseTrustData'+i">
                                                                <div class="col-md-6"><i class="fa pr-2"></i>{{trust.name}}: {{trust.total_balance |  currency: 'USD' : 'symbol' : '0.0-0'}}
                                                                </div>
                                                                <div class="col-md-4"></div>
                                                                <!-- <div class="col-md-4" *ngIf="values.account_no">
                                                                                                                                                                        &nbsp;({{values.account_no}})</div>
                                                                                                                                                                    <div class="col-md-2 text-right">
                                                                                                                                                                        {{values.balance_sub_total|
                                                                                                                                                                        currency: 'USD' : 'symbol' : '1.0-0'}}</div> -->
                                                            </div>
                                                        </div>
                
                                                        <div [id]="'collapseTrustData'+i" class="collapse" aria-labelledby="headingOne"
                                                            data-parent="#accordion1">
                                                            <div class="card-body p-0">
                                                                <div>
                                                                    <div class="font-weight-bold font-16 color-222 pt-2">Trustees
                                                                    </div>
                                                                    <div *ngIf="trust.estate_trustees">
                                                                        <div *ngFor="let trustee of trust.estate_trustees">
                                                                            <div class="pb-2 color-555" *ngIf="trustee.trustee_type==dropDownValues.estate_trustee_types[0][1]">
                                                                               
                                                                                {{trustee.name}}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div class="font-weight-bold font-16 color-222 pt-2">Co-Trustee
                                                                    </div>
                                                                    <div *ngIf="trust.estate_trustees">
                                                                        <div *ngFor="let trustee of trust.estate_trustees">
                                                                            <div class="font-14 pb-2 color-555" *ngIf="trustee.trustee_type==dropDownValues.estate_trustee_types[2][1]">
                                                                                {{trustee.name}}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                    <div class="pt-3">
                                                                        <div class="font-weight-bold font-16 color-222">Successor Trustees
                                                                        </div>
                                                                        <div *ngIf="trust.estate_trustees">
                                                                            <div *ngFor="let trustee of trust.estate_trustees">
                                                                                <div class="font-14 pb-2 color-555 pt-1" *ngIf="trustee.trustee_type== dropDownValues.estate_trustee_types[1][1]">
                                                                                    {{trustee.position}}. {{trustee.name}}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                
                                                                <div class="font-weight-bold font-16 color-222 pt-3"
                                                                    *ngIf="trust.estate_beneficiaries.length> 0">Beneficiaries
                                                                </div>
                                                                <div *ngIf="trust.estate_beneficiaries.length>0"
                                                                    class="col-md-12 pt-2 pl-0 inline-flex">
                                                                    <div class="col-md-6 pl-0 pr-0  color-gray">
                                                                        <label>
                                                                            Beneficiary
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-md-3  pl-0 pr-0 color-gray">
                                                                        <label>
                                                                            Type
                                                                        </label>
                                                                    </div>
                
                                                                    <div class="col-md-3 pl-0 pr-0 color-gray">
                                                                        <label>
                                                                            Share %
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-12  pl-0  pb-2 inline-flex"
                                                                    *ngFor="let user of trust.estate_beneficiaries;let i= index">
                                                                    <div class="col-md-6 pl-0 pr-0 color-555">
                                                                        {{user.name}}
                                                                    </div>
                                                                    <div class="col-md-3 pl-0 pr-0 color-555">
                                                                        {{user.user_type}}
                                                                    </div>
                
                                                                    <div class="col-md-3 pl-0 pr-0 color-555">
                                                                        {{user.percentage}}
                                                                    </div>
                                                                </div>
                                                                <div class="clear"></div>
                                                                <div class="pt-2">
                                                                    <div class="font-weight-bold font-16 color-222 pt-2 pb-1">Accounts
                                                                    </div>
                                                                    <table class="table account">
                                                                        <tbody>
                                                                            <ng-container *ngFor="let epaccount of trust.estate_accounts;let epIndex= index">
                                                                            <tr class="content-frame-new cursor-pointer font-weight-normal"
                                                                              *ngIf="epaccount" >
                                                                                <td class="pl-0  col-md-8">
                                                                                    <div class="color-555">
                                                                                        <span>{{epaccount.position?epaccount.position:epIndex+1}}. </span>
                                                                                        {{epaccount.account_name?.substring(0, 2) == " -" ?epaccount.account_name.replace('-',''):epaccount.account_name}}
                                                                                    </div>
                                                                                </td>
                                                                                <td
                                                                                    class="pl-0 col-md-4">
                                                                                    <div class="font-weight-bold color-555" *ngIf="!epaccount.account">
                                                                                        {{epaccount.account_balance | currency: 'USD' :
                                                                                        'symbol' :
                                                                                        '1.0-0'}}</div>
                                                                                    <div class="font-weight-bold color-555" *ngIf="epaccount.account">
                                                                                        <span *ngIf="epaccount.account.account_group_member_name"> {{epaccount.account.current_value | currency: 'USD' :
                                                                                            'symbol' :
                                                                                            '1.0-0'}} </span>
                                                                                        <span *ngIf="!epaccount.account.account_group_member_name"> {{epaccount.account.balance | currency: 'USD' :
                                                                                            'symbol' :
                                                                                            '1.0-0'}} </span>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-container>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                               
                                                                    <div class="font-weight-bold font-14 color-222"
                                                                        [ngClass]="{'pb-1': trust.notes == ''}">Notes
                                                                    </div>
                                                                    <div class="color-555 font-style-italic noteWrapWord pb-2">
                                                                        {{trust.notes}}</div>
                
                
                                                            </div>
                                                        </div>
                                                    </td>
                
                                                    <td class="text-right pr-0">
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div id="accordion" class="w-100 assetType">
                                <div id="headingOne">
                                    <h5 class="mb-0">
                                        <div class="row font-16 font-weight-400 collapsed" data-toggle="collapse"
                                            data-target="#collapseOtherAsset" aria-expanded="true" aria-controlss="collapseTrust">
                                            <div class="col-md-6 labelhead"><i class="fa pr-2"></i>Non-trust Assets<div class="inline-flex estate-plan-number-div" *ngIf="estatePlanData.estateCategories.Other_Assets && estatePlanData.estateCategories.Other_Assets.length > 0">
                                                {{ estatePlanData.estateCategories.Other_Assets.length}}
                                            </div></div>
                                            <div class="col-md-4"></div>
                                            <div class="col-md-2 text-right">
                                                
                                            </div>
                                        </div>
                                    </h5>
                
                                </div>
                
                                <div id="collapseOtherAsset" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                    <div class="card-body pr-0 pl-0 pb-0">
                                        <table class="table mb-0">
                                            <tbody>
                                                <tr *ngFor="let otherAsset of estatePlanData.estateCategories.Other_Assets; let i = index"
                                                    class="cursor-pointer font-14">
                                                    <td id="accordion1" class="managed-assets pb-0" colspan="4">
                                                        <div id="trust" class="pb-2">
                                                            <div class="row font-16 collapsed" data-toggle="collapse"
                                                                [attr.data-target]="'#collapseOtherAsset'+i" aria-expanded="true"
                                                                [attr.aria-controls]="'collapseOtherAsset'+i">
                                                                <div class="col-md-6"><i class="fa pr-2"></i>{{otherAsset.name}}<span
                                                                        *ngIf="otherAsset.asset_type"> -
                                                                        {{otherAsset.asset_type}}</span>
                                                                    <div class="inline-flex estate-plan-number-div"
                                                                        *ngIf="(otherAsset.estate_accounts.length + otherAsset.estate_household_insurances.length ) > 0">
                                                                        {{otherAsset.estate_accounts.length +
                                                                        otherAsset.estate_household_insurances.length}}
                                                                    </div>
                                                                    
                                                                </div>
                                                                <div class="col-md-4"></div>
                                                                <!-- <div class="col-md-4" *ngIf="values.account_no">
                                                                                                                                                                        &nbsp;({{values.account_no}})</div>
                                                                                                                                                                    <div class="col-md-2 text-right">
                                                                                                                                                                        {{values.balance_sub_total|
                                                                                                                                                                        currency: 'USD' : 'symbol' : '1.0-0'}}</div> -->
                                                            </div>
                                                        </div>
                
                                                        <div [id]="'collapseOtherAsset'+i" class="collapse" aria-labelledby="headingOne"
                                                            data-parent="#accordion1">
                                                            <div class="card-body p-0">
                                                                <table class="table account">
                                                                    <thead>
                                                                        <tr>
                                                                            <th class="pl-0">Account</th>
                                                                            <th class="text-right" nowrap>Current Value</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody class="padding-bottom-15">
                                                                        <ng-container *ngFor="let epaccount of otherAsset.estate_accounts;"s>
                                                                        <tr class="content-frame-new cursor-pointer font-weight-normal"
                                                                            *ngIf="epaccount">
                                                                            <td class="pl-0 col-md-7">
                                                                                <div class="color-555">
                                                                                     {{(epaccount.account_name?.substring(0, 2)) == " -"?epaccount.account_name.replace('-',''):epaccount.account_name}} 
                                                                                </div>
                                                                            </td>
                                                                            <td
                                                                                class="pl-0   col-md-5 text-right">
                                                                                <div class="font-weight-bold color-555" *ngIf="!epaccount.account">
                                                                                    {{epaccount.account_balance | currency: 'USD' :
                                                                                    'symbol' :
                                                                                    '1.0-0'}}</div>
                                                                                <div class="font-weight-bold color-555" *ngIf="epaccount.account">
                                                                                    <span *ngIf="epaccount.account.account_group_member_name"> {{epaccount.account.current_value | currency: 'USD' :
                                                                                        'symbol' :
                                                                                        '1.0-0'}} </span>
                                                                                    <span *ngIf="!epaccount.account.account_group_member_name"> {{epaccount.account.balance | currency: 'USD' :
                                                                                        'symbol' :
                                                                                        '1.0-0'}} </span>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        <tr class="content-frame-new cursor-pointer font-weight-normal"
                                                                            *ngFor="let epaccount of otherAsset.estate_household_insurances">
                                                                            <td class="pl-0 pt-0  col-md-7">
                                                                                <div class="color-555">
                                                                                    {{epaccount.household_insurance.name}}
                                                                                </div>
                                                                            </td>
                                                                            <td
                                                                                class="pl-0 pt-0   col-md-5 text-right">
                                                                                <div class="color-555">
                                                                                    {{epaccount.household_insurance.account_value
                                                                                    | currency: 'USD' : 'symbol' : '0.0-0'}}
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-container>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </td>
                
                                                    <td class="text-right pr-0">
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div id="accordion" class="w-100 assetType">
                                <div id="headingOne">
                                    <h5 class="mb-0">
                                        <div class="row font-16 font-weight-400 collapsed" data-toggle="collapse"
                                            data-target="#collapseWills" aria-expanded="true" aria-controls="collapseWills">
                                            <div class="col-md-6 labelhead"><i class="fa pr-2"></i>Wills<div class="inline-flex estate-plan-number-div" *ngIf="estatePlanData.estateCategories.Wills && estatePlanData.estateCategories.Wills.length > 0">
                                                {{ estatePlanData.estateCategories.Wills.length}}
                                            </div></div>
                                            <div class="col-md-4"></div>
                                            <div class="col-md-2 text-right">
                                                
                                            </div>
                                        </div>
                                    </h5>
                
                                </div>
                                <div id="collapseWills" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                    <div class="card-body pr-0 pl-0 pb-0">
                                        <table class="table mb-0">
                                            <tbody>
                                                <tr *ngFor="let will of estatePlanData.estateCategories.Wills; let i = index"
                                                    class="cursor-pointer font-14">
                                                    <td id="accordion1" class="managed-assets pb-0" colspan="4">
                                                        <div id="trust" class="pb-2">
                                                            <div class="row font-16 collapsed" data-toggle="collapse"
                                                                [attr.data-target]="'#collapseWills'+i" aria-expanded="true"
                                                                [attr.aria-controls]="'collapseWills'+i">
                                                                <div class="col-md-6"><i class="fa pr-2"></i>{{will.name}}
                                                                </div>
                
                                                            </div>
                                                        </div>
                
                                                        <div [id]="'collapseWills'+i" class="collapse" aria-labelledby="headingOne"
                                                            data-parent="#accordion1">
                                                            <div class="card-body p-0">
                                                                <div>
                                                                    <div class="font-weight-bold font-16 pt-3"
                                                                        *ngIf="will.estate_contacts.length> 0">
                                                                        Executors
                                                                    </div>
                                                                    <ng-container
                                                                        *ngFor="let contact of will.estate_contacts;let i= index" >
                                                                        <div *ngIf="contact.contact_type=='Executors'" class="pb-2">
                                                                            <div
                                                                                class="font-16 pt-2">
                                                                                {{contact.position}}. {{contact.name}}</div>
                                                                            <div class="color-darkgray font-16 pt-2 pl-3"
                                                                                *ngIf="contact.phone">{{contact.phone }}</div>
                                                                            <div class="color-darkgray font-16 pt-2 pl-3"
                                                                                *ngIf="contact.email">{{contact.email}}</div>
                                                                            <div class="color-darkgray font-16 pt-2 pl-3"
                                                                                *ngIf="contact.address">{{contact.address}}</div>
                                                                            <div class="color-darkgray font-16 pt-2 pl-3"
                                                                                *ngIf="contact.address1">{{contact.address1}}</div>
                                                                        </div>
                                                                    </ng-container>
                                                                </div>
                                                                <div class="pt-3">
                                                                    <div class="font-weight-bold font-16"
                                                                        *ngIf="will.estate_contacts.length> 0">
                                                                        Guardians
                                                                    </div>
                                                                    <ng-container
                                                                        *ngFor="let contact of will.estate_contacts;let i= index">
                                                                        <div *ngIf="contact.contact_type=='Guardians'" class="pb-2">
                                                                            <div
                                                                                class="font-16  pt-2">
                                                                                {{contact.position}}. {{contact.name}}</div>
                                                                            <div class="color-darkgray font-16  pt-2 pl-3"
                                                                                *ngIf="contact.phone">{{contact.phone
                                                                                }}</div>
                                                                            <div class="color-darkgray font-16  pt-2 pl-3"
                                                                                *ngIf="contact.email">{{contact.email}}</div>
                                                                            <div class="color-darkgray font-16  pt-2 pl-3"
                                                                                *ngIf="contact.address">{{contact.address}}</div>
                                                                            <div class="color-darkgray font-16  pt-2 pl-3"
                                                                                *ngIf="contact.address1">{{contact.address1}}</div>
                                                                        </div>
                                                                    </ng-container>
                                                                </div>
                                                                <div class="font-weight-bold font-14 color-222 pt-3" *ngIf="will.estate_beneficiaries.length> 0">Beneficiaries
                                                                </div>
                                                                <div *ngIf="will.estate_beneficiaries.length>0" class="col-md-12 pt-1 pl-0 ">
                                                                    <div class="col-md-6 pl-0 pr-0 color-gray inline-flex">
                                                                        <label>
                                                                            Beneficiary
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-md-3 pl-0 pr-0 color-gray">
                                                                        <label>
                                                                            Type
                                                                        </label>
                                                                    </div>
                                                                
                                                                    <div class="col-md-3 pl-0 pr-0 color-gray">
                                                                        <label>
                                                                            Share %
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <ng-container
                                                                    *ngFor="let user of will.estate_beneficiaries;let i= index">
                
                                                                    <div class="col-md-12 pl-0 pb-2 inline-flex"
                                                                        *ngIf="!user._destroy">
                                                                        <div class="col-md-6  pl-0 pr-0 color-555">
                                                                            {{user.name}}
                                                                        </div>
                                                                        <div class="col-md-3 pl-0 pr-0 color-555">
                                                                            {{user.user_type}}
                                                                        </div>
                
                                                                        <div class="col-md-3 pl-0 pr-0 color-555">
                                                                            {{user.percentage}}%
                                                                        </div>
                                                                    </div>
                                                                </ng-container>
                
                                                            </div>
                                                        </div>
                                                    </td>
                
                                                    <td class="text-right pr-0">
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div id="accordion" class="w-100 assetType">
                                <div id="headingOne">
                                    <h5 class="mb-0">
                                        <div class="row font-16 font-weight-400 collapsed" data-toggle="collapse"
                                            data-target="#collapsePOA" aria-expanded="true" aria-controls="collapsePOA">
                                            <div class="col-md-6 labelhead"><i class="fa pr-2"></i>Power(s) Of Attorney<div class="inline-flex estate-plan-number-div" *ngIf="estatePlanData.estateCategories.Power_of_Attorney && estatePlanData.estateCategories.Power_of_Attorney.length > 0">
                                                {{ estatePlanData.estateCategories.Power_of_Attorney.length}}
                                            </div></div>
                                            <div class="col-md-4"></div>
                                            <div class="col-md-2 text-right">
                                                
                                            </div>
                                        </div>
                                    </h5>
                                </div>
                
                                <div id="collapsePOA" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                    <div class="card-body pr-0 pl-0 pb-0">
                                        <table class="table mb-0">
                                            <tbody>
                                                <tr *ngFor="let poa of estatePlanData.estateCategories.Power_of_Attorney; let i = index"
                                                    class="cursor-pointer font-14">
                                                    <td id="accordion1" class="managed-assets pb-0" colspan="4">
                                                        <div id="trust" class="pb-2">
                                                            <div class="row font-16 collapsed" data-toggle="collapse"
                                                                [attr.data-target]="'#collapsePOA'+i" aria-expanded="true"
                                                                [attr.aria-controls]="'collapsePOA'+i">
                                                                <div class="col-md-6"><i class="fa pr-2"></i>{{poa.name}}
                                                                </div>
                
                                                            </div>
                                                        </div>
                                                        <div [id]="'collapsePOA'+i" class="collapse" aria-labelledby="headingOne" data-parent="#accordion1">
                                                            <div class="card-body p-0">
                                                                <div class="font-weight-bold font-16 pt-3">
                                                                    Individuals
                                                                </div>
                                                                <div *ngFor="let contact of poa.estate_contacts;let poaIndex= index">
                                                                    <div class="font-14 pb-2 color-555 pt-2">
                                                                        {{poaIndex+1}}. {{contact.name}}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                
                                                    <td class="text-right pr-0">
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div id="accordion" class="w-100 assetType">
                                <div id="headingOne">
                                    <h5 class="mb-0">
                                        <div class="row font-16 font-weight-400 collapsed" data-toggle="collapse"
                                            data-target="#collapseBusiness" aria-expanded="true" aria-controls="collapseBusiness">
                                            <div class="col-md-6 labelhead"><i class="fa pr-2"></i>Business Owner</div>
                                            <div class="col-md-4"></div>
                                            <div class="col-md-2 text-right">
                
                                            </div>
                                        </div>
                                    </h5>
                                </div>
                
                                <div id="collapseBusiness" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                    <div class="card-body pr-0 pl-0 pb-0">
                                        <div>
                                            <div class="col-md-12 pt-3">
                                                <div class="col-md-12 pr-0 ">
                                                    <div  class="color-gray font-13">Name</div>
                                                    <div class="color-222 pt-1 font-16">{{estatePlanData.estateBusiness.name}}</div>
                                                </div>
                
                                            </div>
                                            <div class="col-md-12 pt-3">
                                                <div class="col-md-6 pr-0 ">
                                                    <div  class="color-gray font-13">Do they have a buy/sell agreement?</div>
                                                    <div class="color-222 pt-1 font-16">
                                                        {{estatePlanData.estateBusiness.has_agreement == true?'Yes':'No'}}
                                                    </div>
                                                </div>
                                                <div class="col-md-6 pr-0 pt-3">
                                                    <div  class="color-gray font-13">How is it Financed?</div>
                                                    <div class="color-222 font-16">{{estatePlanData.estateBusiness.finance_notes}} </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12 pr-0  pt-3">
                                                <div class="col-md-6 pr-0">
                                                    <div  class="color-gray font-13">Who is Involved?</div>
                                                    <div class="color-222 font-16"
                                                        *ngFor="let value of estatePlanData.estateBusiness.estate_contacts">
                                                        {{value.name}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12 inline-flex pr-0  pt-3 pb-3">
                                                <div class="col-md-3 pr-0 ">
                                                    <div class="color-gray font-13">Date Agreed to</div>
                                                    <div class="color-222 font-16">{{estatePlanData.estateBusiness.date_agreed |
                                                        date:'MM/dd/yy'}} </div>
                                                </div>
                                                <div class="col-md-3 pr-0 ">
                                                    <div  class="color-gray font-13">Date Reviewed</div>
                                                    <div class="color-222 font-16">{{estatePlanData.estateBusiness.date_reviewed |
                                                        date:'MM/dd/yy'}}</div>
                                                </div>
                                                <div class="col-md-3 pr-0 ">
                                                    <div  class="color-gray font-13">Valuation Date</div>
                                                    <div class="color-222 font-16">{{estatePlanData.estateBusiness.valuation_date |
                                                        date:'MM/dd/yy'}}</div>
                                                </div>
                                                <div class="col-md-3 pr-0 ">
                                                    <div  class="color-gray font-13">Valuation Amount</div>
                                                    <div class="color-222 font-16">{{estatePlanData.estateBusiness.valuation_amount |
                                                        currency }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12 pt-2 pb-2">
                                                <!-- <div class="col-md-12 pr-0  cursor-pointer"
                                                                                                        ng-click='openEstatePlanningModal(estatePlanData.estateBusiness,"Edit Business Owner")'>
                                                                                                        <div class="color-blue">Edit Business Information</div>
                                                                                                    </div> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="accordion" class="w-100 assetType">
                                <div id="headingOne">
                                    <h5 class="mb-0">
                                        <div class="row font-16 font-weight-400 collapsed" data-toggle="collapse"
                                            data-target="#collapseContact" aria-expanded="true" aria-controls="collapseContact">
                                            <div class="col-md-6 labelhead"><i class="fa pr-2"></i>Contacts</div>
                                            <div class="col-md-4"></div>
                                            <div class="col-md-2 text-right">
                
                                            </div>
                                        </div>
                                    </h5>
                
                                </div>
                
                                <div id="collapseContact" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                    <div class="card-body pr-0 pl-0 pb-0">
                                        <table class="table mb-0">
                                            <tbody>
                                                <ng-container *ngFor="let clientDetails of estatePlanData.estateClients; let i = index">
                                                <tr *ngIf="clientDetails.user"
                                                    class="cursor-pointer font-14">
                                                    <td id="accordion1" class="managed-assets pb-0" colspan="4">
                                                        <div id="trust" class="pb-2">
                                                            <div class="row font-16 collapsed" data-toggle="collapse"
                                                                [attr.data-target]="'#collapseContact'+i" aria-expanded="true"
                                                                [attr.aria-controls]="'collapseContact'+i">
                                                                <div class="col-md-6"><i class="fa pr-2"></i>
                                                                    {{clientDetails.user.name}}
                                                                </div>
                
                                                            </div>
                                                        </div>
                                                        <div [id]="'collapseContact'+i" class="collapse" aria-labelledby="headingOne"
                                                            data-parent="#accordion1">
                                                            <div class="card-body p-0">
                                                                <table class="table account">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Name</th>
                                                                            <th>Type</th>
                                                                            <th class=""> Address</th>
                                                                            <th class="text-right" nowrap>Phone</th>
                                                                            <th class="text-right">Email</th>
                                                                            <th class="col-md-1"></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr class="content-frame-new cursor-pointer font-weight-normal"
                                                                            *ngFor="let clientContact of clientDetails.estate_contacts; let contactIndex = index">
                                                                            <td>
                                                                                {{clientContact.name}}
                                                                            </td>
                                                                            <td> {{clientContact.contact_type}}</td>
                                                                            <td> {{clientContact.address}}</td>
                                                                            <td class="text-right" nowrap> {{clientContact.phone }}
                                                                            </td>
                                                                            <td class="text-right word-break"> {{clientContact.email}}
                                                                            </td>
                                                                            <td class="col-md-1">
                                                                                <div class="add-edit-div ">
                                                                                    <span
                                                                                        class="glyphicon glyphicon-pencil blue-color padding-right-5"
                                                                                        button-disable title="Edit"
                                                                                        ></span>
                                                                                    <span
                                                                                        class="glyphicon glyphicon-remove close-red-assets"
                                                                                        title="Delete"
                                                                                        ></span>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="text-right pr-0">
                                                    </td>
                                                </tr>
                                            </ng-container>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                </div>

            <div class="col-md-8 pt-4 pl-3" *ngIf="showEdit">
                <div class="card w-100 m-auto p-2">
                    <div class="card-body pb-1">
                        <form [formGroup]="accountEdit" novalidate>
                            <div class="header-div">
                                <div class="back-btn">
                                    <a class="color-purple cursor-pointer"
                                        (click)="showEdit = false; resetAddAccount(); changeEditPosition(false);"><img
                                            src="../../assets/images/left-arrow-grey.png" class="mr-2" />Back</a>
                                </div>
                                <div class="inline-flex pl-2 heading-txt">
                                    {{selectedRow.bankDescription}} - {{selectedRow.selectedAssetType}}
                                </div>

                                <div class="pull-right font-12 mt-3 color-gray"
                                    *ngIf="selectedRow.bankCreated && !selectedRow.isManual">
                                    <span *ngIf="selectedRow.yodleeLastRefreshed">Updated on
                                        {{selectedRow.yodleeLastRefreshed |
                                        date:
                                        "MMMM dd, yyyy"}}</span><span *ngIf="!selectedRow.yodleeLastRefreshed">Updated
                                        on
                                        {{selectedRow.bankLastRefreshed | date: "MMMM dd, yyyy"}}</span>
                                </div>
                                <!-- Last updated for Manual banks -->
                                <div class="pull-right font-12 mt-3 color-gray" *ngIf="selectedRow.isManual">
                                    <span *ngIf="selectedRow.manualAccUpdatedDate">Updated on
                                        {{selectedRow.manualAccUpdatedDate |
                                        date:
                                        "MMMM dd, yyyy"}}</span>
                                </div>
                            </div>
                            <div class="pb-2 row">
                                <div class="col-md-3 col-sm-3 col-xs-3 inline-grid">
                                    <div class="font-weight-700">Name</div>
                                    <div class="col-md-12 col-sm-3 pl-0 pr-0 width-110">
                                        <input type="text" class="form-control corner-rounded"
                                            formControlName="accountName" placeholder="Account Name or Description"
                                            [readonly]="selectedRow.disableNameField"
                                            [(ngModel)]="selectedRow.accountName" />
                                    </div>
                                    <!--  <div *ngIf="addManualAccFormSubmitted" class="text-danger" role="alert">
                                                    <div *ngIf="formControl.accountName.errors?.required"> Account Name is mandatory.</div>
                                                </div> -->
                                </div>
                                <div class="col-md-3 col-sm-3 col-xs-3 inline-grid">
                                    <div class="font-weight-700">Type</div>
                                    <div class="col-md-12 col-sm-3 pl-0 pr-0">
                                        <select class="form-control p-2" [(ngModel)]="selectedRow.acctype"
                                            formControlName="accountType"
                                            (change)="setInputElement(selectedRow.acctype)"
                                            [attr.disabled]="selectedRow.disableSelect">
                                            <optgroup *ngFor="let group of accTypesGroup" label="{{group.type}}">
                                                <option value="{{option.id}}" *ngFor="let option of group.groupItem">
                                                    {{option.name}}</option>
                                            </optgroup>
                                        </select>
                                    </div>
                                    <!-- <div *ngIf="saveAccountInfo" ng-messages="account.edit.accountType.$error"
                                                            role="alert">
                                                            <div ng-message="required" class="error-text">Please select the Account
                                                                type
                                                            </div>
                                                        </div>
                             -->
                                </div>
                                <div class="col-md-3 col-sm-3 col-xs-3 inline-grid" *ngFor="let field of fieldType">
                                    <div *ngIf="!selectedRow.isManaged">
                                        <div class="pl-0">
                                            <div *ngIf="displayBeneficiary && field.isObject">
                                                <div class="font-weight-700 col-md-12 col-sm-12 col-xs-12 pl-0">
                                                    {{field.label}}
                                                </div>
                                                <div>
                                                    <select [(ngModel)]="accountHolder"
                                                        formControlName="accountHolderName" name="accHolder"
                                                        class="form-control p-2">
                                                        <option *ngFor="let acc of field.data" value="acc[1]">{{acc[0]}}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="width-80" *ngIf="displayBeneficiary && field.isString">
                                                <div class="font-weight-700 col-md-12 col-sm-12 col-xs-12 pl-0">
                                                    {{field.label}}</div>
                                                <div>
                                                    <input *ngIf="field.label!='Interest Rate'" type="text"
                                                        class="form-control" [(ngModel)]="field.data"
                                                        formControlName="balance"
                                                        [readonly]="selectedRow.disableField && field.label!='Interest Rate'">
                                                    <input *ngIf="field.label=='Interest Rate'" type="text"
                                                        class="form-control" [(ngModel)]="field.data"
                                                        formControlName="interestRate">
                                                </div>
                                            </div>
                                            <div class="width-80" *ngIf="displayEmployer && field.isBoolean">
                                                <div class="font-weight-700 pl-0 ">{{field.label}}</div>
                                                <div>
                                                    <input type="checkbox" [(ngModel)]="field.data"
                                                        formControlName="accountEmployer" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="pl-3  mt-2" *ngIf="displayPositionGrid">
                        <table class="table cont-table-2 font-14">
                            <thead>
                                <tr>
                                    <th class="col-md-1">Symbol</th>
                                    <th class="col-md-5 pl-2">Description</th>
                                    <th class="col-md-1 pl-2"></th>
                                    <th class="col-md-1 pl-2 text-right">Fee</th>
                                    <th class="col-md-1 pl-2 text-right">Price</th>
                                    <th class="col-md-1 pl-2 text-right position-quantity">Quantity
                                    </th>
                                    <th class="col-md-1 pl-2 text-right">Value</th>
                                    <th class="col-md-1" *ngIf="selectedRow.isManual">
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="content-table" *ngFor="let position of positions">
                                    <td class="col-md-1">{{position.ticker}}</td>
                                    <td class="col-md-5 padding-left-5-imp">{{position.description}}
                                    </td>
                                    <td class="col-md-1 padding-left-5-imp" *ngIf="!position.is_unvested"></td>
                                    <td class="col-md-1 padding-left-5-imp" *ngIf="position.is_unvested">Vested</td>
                                    <td class="col-md-1 padding-left-5-imp text-right">{{position.fee}}%
                                    </td>
                                    <td class="col-md-1 padding-left-5-imp text-right">{{position.price|
                                                        currency: 'USD' : 'symbol' : '1.0-0'}}</td>
                                    <td class="col-md-1 padding-left-5-imp  text-right position-quantity">{{position.shares
                                                        | number:'1.0-0'}}</td>
                                    <td class="col-md-1 padding-left-5-imp text-right">{{position.amount|
                                                        currency: 'USD' : 'symbol' : '1.0-0'}}</td>
                                    <td class="col-md-1">
                                        <div class="pull-right add-edit-div text-decoration-none" *ngIf="selectedRow.isManual">
                                            <span class="color-blue pr-2" data-toggle="tooltip" title="Edit"
                                                (click)="openPositionCashModal('edit','Position',position)"><i class="fa fa-pencil"
                                                    aria-hidden="true"></i>
                                            </span>
                                            <span class="color-red1" data-toggle="tooltip" title="Delete"
                                                (click)="getSelectedPosition(position, 'delete')"><i class="fa fa-times"
                                                aria-hidden="true"></i></span>
                                        </div>
                                    </td>
                                </tr>
                                <tr class="content-table" *ngFor="let position of positions">
                                    <td class="col-md-1" *ngIf="position.is_unvested">{{position.ticker}}</td>
                                    <td class="col-md-5 padding-left-5-imp" *ngIf="position.is_unvested">
                                        {{position.description}}</td>
                                    <td class="col-md-1 padding-left-5-imp" *ngIf="position.is_unvested">Unvested</td>
                                    <td class="col-md-1 padding-left-5-imp text-right" *ngIf="position.is_unvested">
                                        {{position.fee}}%
                                    </td>
                                    <td class="col-md-1 padding-left-5-imp text-right" *ngIf="position.is_unvested">
                                        {{position.price|
                                                        currency: 'USD' : 'symbol' : '1.0-2'}}</td>
                                    <td class="col-md-1 padding-left-5-imp  text-right position-quantity" *ngIf="position.is_unvested">
                                        {{position.unvested_quantity | number:'1.0-0'}}</td>
                                    <td class="col-md-1 padding-left-5-imp text-right" *ngIf="position.is_unvested">
                                        {{position.unvested_value|
                                                        currency: 'USD' : 'symbol' : '1.0-0'}}</td>
                                    <td class="col-md-1" *ngIf="position.is_unvested">
                                        <div class="pull-right add-edit-div text-decoration-none" *ngIf="selectedRow.isManual">
                                            <span class="color-blue pr-2" data-toggle="tooltip" title="Edit"
                                                ng-click="openPositionCashModal('edit','Position',position)">
                                                <i class="fa fa-pencil"
                                                aria-hidden="true"></i>
                                            </span>
                                            <span class="color-red1" data-toggle="tooltip" title="Delete"
                                                ng-click="getSelectedPosition(position, 'delete')">
                                                <i class="fa fa-times"
                                                aria-hidden="true"></i>
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                                <tr *ngIf="selectedRow.isManual && selectedRow.selectedAssetTypeCopy == 'Unmanaged Assets'">
                                    <td colspan="2">
                                        <div class="inline-flex font-14"><a class="color-purple cursor-pointer"
                                                (click)="openPositionCashModal('add','Position', '')" *ngIf="!selectedRow.isManaged">Add
                                                Position</a></div>
                                        <div class="inline-flex font-14"><a class="color-purple ml-3 cursor-pointer"
                                                (click)="openPositionCashModal('add', 'Cash', '')" *ngIf="!selectedRow.isManaged">Add
                                                Cash</a></div>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        <div class="inline-flex pull-right" *ngIf="positions && positions.length > 0">
                                            {{selectedRow.amount
                                                            | currency: 'USD' : 'symbol' : '1.0-0'}} </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-md-8 margin-top-15 pl-0 ">
                        <a class="color-purple cursor-pointer" *ngIf="selectedRow.bankId != null"
                            (click)="removeAccountModal(selectedRow.bankId)">
                            <span>Remove</span>
                        </a>
                    </div>
                    <div class="pull-right pr-1">
                        <button class="btn btn-primary btn-sm rounded-pill font-12 pull-right rebtn ml-2"
                            (click)="refreshAccount(selectedRow.bankId)"
                            *ngIf="selectedRow.bankId !== null && !selectedRow.isManual"
                            [disabled]="loadRefresh">Refresh <span *ngIf="loadRefresh">&nbsp;</span><span
                                *ngIf="loadRefresh" class="loader-status">
                                <div class="loader loader-spinner"></div>&nbsp;
                            </span></button>
                        <button class="btn btn-primary btn-sm rounded-pill font-12 pull-right rebtn ml-2"
                            (click)="editAccount()" *ngIf="selectedRow.bankId !== null" [formvalid]="accountEdit.valid" appButtonDisable>Save</button>
                        <button class="btn btn-primary btn-sm rounded-pill font-12 pull-right rebtn"
                            (click)="showEdit = false; resetAddAccount(); changeEditPosition(false);"
                            *ngIf="selectedRow.bankId !== null">Cancel</button>
                    </div>
                </div>
            </div>

            <div class="col-md-4 pt-3 pl-3">
                <div class="font-18 m-3">Income and Expenses</div>
                <div class="card w-100 m-auto border-radius-10 border-0 shadow-sm p-2">
                    <div class="card-body pb-1 pt-0">
                        <table class="table cont-table-2 mt-3 incomeExpense">
                            <thead>
                                <tr>
                                    <th>Income</th>
                                    <th class="text-right"><span ngbTooltip="Annual Gross"
                                            tooltipClass="tooltipData">Annual
                                            Gross</span>
                                    </th>
                                    <th class="text-right"><span ngbTooltip="Monthly After-Tax"
                                            tooltipClass="tooltipData">Monthly After-Tax</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody *ngIf="cashFlowDetails.income_and_expense">
                                <tr *ngFor="let income of cashFlowDetails.income_and_expense.monthly_income.income">
                                    <td class="">{{income.description}}</td>
                                    <td class="text-right"><span ng-show="income.annual_amount>0">{{income.annual_amount
                                            | currency: 'USD' : 'symbol' : '1.0-0'}}</span></td>
                                    <td class="text-right">
                                        {{income.amount | currency: 'USD' : 'symbol' : '1.0-0'}}</td>
                                </tr>
                            </tbody>
                            <tfoot *ngIf="cashFlowDetails">
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td class="text-right text-success font-weight-bold"
                                        *ngIf="cashFlowDetails.income_and_expense">
                                        {{cashFlowDetails.income_and_expense.monthly_income.total_monthly_income|
                                        currency: 'USD' : 'symbol' : '1.0-0'}}</td>
                                </tr>
                            </tfoot>
                        </table>
                        <table class="table cont-table-2 mt-3">
                            <thead>
                                <tr>
                                    <th>Monthly Expenses</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="cashFlowDetails.income_and_expense">
                                <tr *ngFor="let expense of cashFlowDetails.income_and_expense.monthly_expense.expense">
                                    <td class="border-top-0">{{expense.description}}</td>
                                    <td class="text-right border-top-0">
                                        {{expense.amount| currency: 'USD' : 'symbol' : '1.0-0'}}</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td></td>
                                    <td class="text-right text-danger font-weight-bold"
                                        *ngIf="cashFlowDetails.income_and_expense">
                                        {{cashFlowDetails.income_and_expense.monthly_expense.total_monthly_expense|
                                        currency: 'USD' : 'symbol' : '1.0-0'}}</td>
                                </tr>
                            </tfoot>
                        </table>
                        <table class="table cont-table-2" *ngIf="cashFlowDetails">
                            <tfoot>
                                <tr>
                                    <td class="font-weight-bold">Monthly Cash Flow</td>
                                    <td class="text-right font-weight-bold"
                                        [ngClass]="{'text-success':cashFlowDetails.monthly_cash_flow > 0, 'text-danger':cashFlowDetails.monthly_cash_flow <= 0}">
                                        {{cashFlowDetails.monthly_cash_flow|
                                        currency: 'USD' : 'symbol' : '1.0-0'}}</td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">Annual Cash Flow</td>
                                    <td class="text-right font-weight-bold"
                                        [ngClass]="{'text-success':cashFlowDetails.yearly_cash_flow > 0, 'text-danger':cashFlowDetails.yearly_cash_flow <= 0}">
                                        {{cashFlowDetails.yearly_cash_flow|
                                        currency: 'USD' : 'symbol' : '1.0-0'}}</td>
                                </tr>
                                <tr>
                                    <td class="border-top-0">
                                        <a class="advLink" (click)="openIncomeExpenseModal()">Edit </a>
                                    </td>
                                    <td width="70%">
                                        <span class="text-muted font-13 float-right" *ngIf="cashFlowDetails.income_and_expense">
                                            Updated on {{cashFlowDetails.income_and_expense.last_updated_at | date: "MMMM dd, yyyy"}}</span>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>

                <div class="font-18 m-3">
                    <span *ngIf="!inWithdrawal">Savings Waterfall</span>
                    <span *ngIf="inWithdrawal">Withdrawal Plan</span>
                </div>
                <div class="card w-100 m-auto border-radius-10 border-0 shadow-sm p-2">
                    <div class="card-body pb-1">
                        <table class="savingsWaterfall table cont-table-2">
                            <thead>
                                <tr>
                                    <th class="border-top-0">Account</th>
                                    <th class="text-right border-top-0">Target</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let savings of savingWaterfallDetails">
                                    <td colspan="2" class="border-top-0">
                                        <div class="float-right">
                                            {{savings.target_savings| currency: 'USD' : 'symbol' : '1.0-0'}}
                                        </div>
                                        {{savings.name}}
                                        <div class="bfr-bar w-100"><span [ngStyle]="savings.width"></span></div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr class="border-top">
                                    <td class="font-weight-bold border-top-0">Total Amount</td>
                                    <td class="float-right border-top-0 font-weight-bold">
                                        {{totalSavings| currency: 'USD' : 'symbol' : '1.0-0'}}</td>
                                </tr>
                                <tr>
                                    <td class="border-top-0"><a class="advLink"
                                            (click)="openContactModal('waterfall')">Contact
                                            My Advisor to Edit </a></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="row pt-1 ">
            <div class="col-md-8 col-sm-12 col-xs-12 pt-4 pl-3">
            </div>
            <div class="col-md-4 col-sm-12 col-xs-12 pt-4 pl-3">
                <div class="font-18 m-3">
                    <span *ngIf="!inWithdrawal">Savings Waterfall</span>
                    <span *ngIf="inWithdrawal">Withdrawal Plan</span>
                </div>
                <div class="card w-100 m-auto border-radius-10 border-0 shadow-sm p-2">
                    <div class="card-body pb-1">
                        <table class="savingsWaterfall table cont-table-2">
                            <thead>
                                <tr>
                                    <th class="border-top-0">Account</th>
                                    <th class="text-right border-top-0">Target</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let savings of savingWaterfallDetails">
                                    <td colspan="2" class="border-top-0">
                                        <div class="float-right">
                                            {{savings.target_savings| currency: 'USD' : 'symbol' : '1.0-0'}}
                                        </div>
                                        {{savings.name}}
                                        <div class="bfr-bar w-100"><span [ngStyle]="savings.width"></span></div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr class="border-top">
                                    <td class="font-weight-bold border-top-0">Total Amount</td>
                                    <td class="float-right border-top-0 font-weight-bold">
                                        {{totalSavings| currency: 'USD' : 'symbol' : '1.0-0'}}</td>
                                </tr>
                                <tr>
                                    <td class="border-top-0"><a class="advLink"
                                            (click)="openContactModal('waterfall')">Contact
                                            My Advisor to Edit </a></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</div>