import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppRestEndPoint } from '../app-restEndPoint';

const httpOptions = {
	headers: new HttpHeaders({
		'Accept': 'application/json',
		'Content-Type': 'application/json; charset=UTF-8',
		'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
		'Cache-Control': 'no-cache',
		'Pragma': 'no-cache'
	})
};


@Injectable({
	providedIn: 'root'
})
export class MixpanelEventService {

	constructor(private http: HttpClient) { }

	gtmPushEvent(data, gtm_guid: any = ''): Observable<any> {
		let url = '';
		if (this.getToken()) {
			url = AppRestEndPoint.CURRENT_API_URL + '/gtm/push_event?access_token=' + this.getToken() + "&type=client";
		} else if (gtm_guid) {
			url = AppRestEndPoint.CURRENT_API_URL + '/gtm/push_event?guid=' + gtm_guid + "&type=client";
		} else {
			url = AppRestEndPoint.CURRENT_API_URL + '/gtm/push_event?type=client';
		}
		return this.http.post(url, data, httpOptions)
			.pipe(map((response: any) => {
				return response;
			}));
	}

	gtmIdentify(gtm_guid, user_id) {

		let reqObj = { "guid": gtm_guid, "user_id": user_id }
		let url = AppRestEndPoint.CURRENT_API_URL + '/gtm/identify?access_token=' + this.getToken() + "&type=client"

		return this.http.post(url, reqObj, httpOptions)
			.pipe(map((response: any) => {
				return response;
			}));

	};
	public getToken(): any {
		return sessionStorage.getItem('token');
	}
}
