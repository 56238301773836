import { Injectable } from '@angular/core';
import { isDefined } from '@angular/compiler/src/util';
import { ConfirmationDialogService } from './confirmation-dialog.service';
import { AppRestEndPoint } from '../app-restEndPoint';
import { ApiService } from './api.service';
import { UtilityService } from './utility.service';
import { ToastService } from '../components/toaster/toast-service';
import { Router } from '@angular/router';
import { pageRouteMap } from '../constants/pageRouteMap';
import * as _ from 'lodash';

@Injectable({
	providedIn: 'root'
})
export class CommonService {
	private domainDetails = {};
	private guid: any;
	timeOutThread = null;
	confirmModalInstance = null;
	bankApiLevelType: any;

	constructor(
		private confirmationDialogService: ConfirmationDialogService,
		private apiService: ApiService,
		private toastService: ToastService,
		private router: Router
		) {

	}

	public getDomainDetails(): any {
		return this.domainDetails;
	}

	public setDomainDetails(val): any {
		this.domainDetails = val;
	}

	// function to replace firm code and other firm related data without deleting other keys in object
	public setDomainFirmDetails(val): any {
		let obj = this.getDomainDetails();
		obj.firm_code = val.firm_code;
		obj.firm_name = val.firm_name;
		obj.domain_css = val.domain_css;
		obj.firm_id = val.firm_id;
		obj.domainLogo = val.domainLogo;
		this.setDomainDetails(obj);
	}

	public getBankApiLevelType(): any {
        return this.bankApiLevelType;
    }

    public setBankApiLevelType(val): any {
        this.bankApiLevelType = val;
    }


	// session timeout

	public setIdleTimeout(){
        clearTimeout(this.timeOutThread);
		let idleTimeoutTimer;
        idleTimeoutTimer = sessionStorage.getItem('idleTimeoutTimer');
        idleTimeoutTimer = parseInt(idleTimeoutTimer);
        idleTimeoutTimer = idleTimeoutTimer - 30000;
        if(isDefined(idleTimeoutTimer)  && idleTimeoutTimer != null && idleTimeoutTimer > 0){
			this.timeOutThread = setTimeout(() => {
				this.showConfirmDialog();
			}, idleTimeoutTimer);
		}
    }

	public resetTimer(val) {
        if(val == true){
            this.setIdleTimeout();
        }
    }

    public cleartimeoutthread(val) {
        if(val == true){
            clearTimeout(this.timeOutThread);
            if(this.confirmModalInstance && this.confirmModalInstance != null){
                this.confirmModalInstance.dismiss();
            }
        }
    }

	public showConfirmDialog(){
		this.confirmModalInstance = this.confirmationDialogService.confirm('Confirm', '<div class="mb-2 color-red font-18">Your session is about to end.</div>'+
        '<span class="font-16">You\'ve been inactive for a while. For your security, we\'ll automatically sign you out in approximately 30 seconds. You may choose "Stay signed in" to continue or sign out if you\'re done.</span>','Stay signed in','Sign out')
			.then((confirmed) => {
				if (confirmed) { 
					this.confirmModalInstance = null;
					this.staySignedIn(); 
				}else{
					this.confirmModalInstance = null;
					this.logOut();
				}
			});
	}

	public staySignedIn(){
		let currentUser;
		currentUser = JSON.parse(sessionStorage.getItem('current_user')); 
		if (currentUser) {
			let household_id = currentUser.selected_household_id;
			const apiurl = AppRestEndPoint.LOGIN.UPDATE_SESSION_TIME + '?household_id=' + household_id;
			this.apiService.updateCall(apiurl, '', household_id).subscribe(data => {
			});
		}
	}

	public logOut(){
		let url = AppRestEndPoint.LOGIN.SIGN_OUT;
		this.apiService.postCall(url, '').subscribe(response => {
			if (response.success) {
				this.toastService.show('Logged Out', {
					classname: 'bg-success text-light', delay: 3000
				});
				this.clearStorage();
				if (this.getDomainDetails().firm_code) {
					this.router.navigate(['/login/firm/' + this.getDomainDetails().firm_code]);
				} else {
					this.router.navigate(['/login']);
				}
			}
		});
	}

	public clearStorage() {
		sessionStorage.removeItem('token');
		sessionStorage.removeItem('current_user');
		sessionStorage.removeItem('current_index');
		sessionStorage.removeItem('household_username');
		sessionStorage.removeItem('onboarding_invitation');
		sessionStorage.removeItem('environment');
		sessionStorage.removeItem('firm_Name');
		sessionStorage.removeItem('login_rtq_id');
		sessionStorage.removeItem('rtq_redirect');
		sessionStorage.removeItem('current_selected_household');
		sessionStorage.removeItem('user_id');
		sessionStorage.removeItem('authy_flag');
		sessionStorage.removeItem('knowledge_base_url');
		sessionStorage.removeItem('is_questionnaire_required');
		sessionStorage.removeItem('advisor_admin_user_id');
		sessionStorage.removeItem('signUpComplete');
		sessionStorage.removeItem('idleTimeoutTimer');
		sessionStorage.removeItem('posted_document');
		sessionStorage.removeItem('plaid_open_page');
		sessionStorage.removeItem('plaid_link_token');
		sessionStorage.removeItem('page_info_type');
		sessionStorage.removeItem('plaid_bank_id');
		sessionStorage.removeItem('adminUsers');
		sessionStorage.removeItem('is_questionnaire_required_email');
		sessionStorage.removeItem('task_id');
	}

	public returnLastSavedState(redirect_to_state){
		let step;
		var questionArr = ["question1", "question2", "question3", "question4", "question5", "question6", "question7"];
		// sometimes state is passed in on the url from emails for instance           
		if (redirect_to_state) {
			switch (redirect_to_state) {

				case 'portfolio':
					var url = AppRestEndPoint.LOGIN.PORTFOLIO;
					window.open(url);
					this.router.navigate(['dashboard']);
					break;
				case 'basic-info':
					this.router.navigateByUrl('financial-info/basic-info/personal-info');
					break;
				case 'tasks':
					this.router.navigate(['tasks']);
					break;
				default:
					step = JSON.stringify({ "info_type": "financial-info", "state": redirect_to_state });
					break;

			}
		}
		var savedState = JSON.parse(step);
		if (redirect_to_state === undefined && (step == null)) {
			this.router.navigate(['dashboard']);
			return;
		}
		var info_type = pageRouteMap[0][savedState.info_type];
		var newState;
		if (sessionStorage.getItem('is_questionnaire_required') && sessionStorage.getItem('is_questionnaire_required') == "false") {
			var index1 = _.findIndex(info_type, { template: 'risk-tolerance' });
			if (savedState.state === 'basic-info/investment-info' && index1 > -1) {
				info_type.splice(index1, 1);
			}
			var index2 = _.findIndex(info_type, { template: 'information' });
			if (savedState.state === 'basic-info/investment-info' && index2 > -1) {
				info_type.splice(index2, 1);
			}
		}
		if (_.indexOf(questionArr, savedState.state) > -1) {
			var setIndex = _.findIndex(info_type, { template: "risk-tolerance" });
			savedState.state = info_type[setIndex].template;
		}

		var mainIndex = _.findIndex(info_type, { template: savedState.state });
		if (mainIndex > -1) {
			newState = info_type[mainIndex].template;
		} else {
			_.forEach(info_type, function (val) {
				var index = _.findIndex(val.child_pages, { template: savedState.state });
				if (index > -1) {
					if (index < val.child_pages.length - 1) {
						newState = val.child_pages[index + 1].template;
						return false;
					} else {
						var parentIndex = _.findIndex(info_type, function (obj) {
							return obj == val;
						});
						newState = info_type[parentIndex + 1].template;
						return false;
					}
				}
			});
		}
		if(newState == 'dashboard'){
            this.router.navigate(['dashboard']);
        }else{
            this.router.navigate([savedState.info_type + "/" + newState]);
        }
	}
}
