<form name="oncehubForm" method="POST" id="targetHub" target="oncehubPopup" ng-form-commit abcnovalidate>
    <div class="modal-content modal-scheduleonce">
        <div>
            <span class="heading-scheduleonce pull-left">Schedule A Meeting</span>    
            <button type="button" class="modal-closebtn" aria-labl="Close" data-dismiss="modal" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
            </button>  
        </div>  
        <div>               
            <iframe [src]="safeScheduleonceUrl" name="oncehubPopup" class="iframe-scheduleonce"></iframe><br>
        </div>               
    </div>
</form>