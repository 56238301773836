import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { AppRestEndPoint } from 'src/app/app-restEndPoint';
import { ApiService } from 'src/app/services/api.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { UtilityService } from 'src/app/services/utility.service';
import { isUndefined } from 'util';

@Component({
    selector: 'app-cash-flow',
    templateUrl: './cash-flow.component.html',
    styleUrls: ['./cash-flow.component.scss']
})
export class CashFlowComponent implements OnInit {
    incomeExpense: any = {};
    incomeInput: any = {};
    expenseInput: any = {};
    selectedType: any;
    currentUser: any;
    isDocusignBreadCrumb:any;
    pageUrl:any;

    constructor(
        public apiService: ApiService, 
        public utilityService: UtilityService,
        private navigationService: NavigationService,
        private activatedRoute: ActivatedRoute
    ) { }


    ngOnInit(): void {
        this.selectedType = this.activatedRoute.snapshot.paramMap.get('type');
		this.currentUser = JSON.parse(sessionStorage.getItem('current_user'));
        this.loadIncomeExpenseData();
        this.pageUrl = document.URL.split('/');
        if(this.pageUrl[4] == 'docusign-cash-flow'){
			this.isDocusignBreadCrumb = true;
		}else{
			this.isDocusignBreadCrumb = false;
		}
    }

    loadIncomeExpenseData() {
        let url = AppRestEndPoint.CASH_FLOW.INCOME_EXPENSE, param = '';
        this.apiService.getCall(url, param).subscribe(data => {
            if (data && data.incomeAndExpenses) {
                this.incomeExpense = this.formatIncomeExpenseData(data.incomeAndExpenses);
            }
        });
    }

    formatIncomeExpenseData(data) {
        data = data.incomeExpense;
        data.incomeDetails = _.values(data.incomeDetails);
        data.expenseDetails = _.values(data.expenseDetails);
        for (let j in data.incomeDetails) {
            data.incomeDetails[j] = data.incomeDetails[j].income;
            data.incomeDetails[j]._destroy = false;
            //data.incomeDetails[j].source = incomeTypeVal[data.incomeDetails[j].user_income_type_id];
            data.incomeDetails[j].source = data.incomeDetails[j].source;
            data.incomeDetails[j].inputValue = data.incomeDetails[j].value;
            data.incomeDetails[j].maxValue = data.incomeDetails[j].value <= 25000 ? 25000 : data.incomeDetails[j].value;
            data.incomeDetails[j].step = '';
            data.incomeDetails[j].error = false;
        }
        for (let i in data.expenseDetails) {
            data.expenseDetails[i] = data.expenseDetails[i].expense;
            data.expenseDetails[i]._destroy = false;
            //data.expenseDetails[i].source = expenseTypeVal[data.expenseDetails[i].user_expense_type_id];        
            data.expenseDetails[i].source = data.expenseDetails[i].source;
            data.expenseDetails[i].inputValue = data.expenseDetails[i].value;
            data.expenseDetails[i].maxValue = data.expenseDetails[i].value <= 25000 ? 25000 : data.expenseDetails[i].value;
            data.expenseDetails[i].step = '';
            data.expenseDetails[i].error = false;
        }
        return data;
    }

    incomeExpenseSum(type) {
        let incomeLen = this.incomeExpense.incomeDetails.length;
        let expenseLen = this.incomeExpense.expenseDetails.length;
        let totalIncome = 0;
        let totalExp = 0;
        if (type == 'income') {
            for (let i = 0; i < incomeLen; i++) {
                if (!this.incomeExpense.incomeDetails[i]._destroy && this.incomeExpense.incomeDetails[i].inputValue) {
                    totalIncome = totalIncome + parseFloat(this.utilityService.formatCurrency(this.incomeExpense.incomeDetails[i].inputValue)) || 0;
                }
            }
            this.incomeExpense.monthlyIncome = totalIncome;
        } else if (type == 'expense') {
            for (let j = 0; j < expenseLen; j++) {
                if (!this.incomeExpense.expenseDetails[j]._destroy && this.incomeExpense.expenseDetails[j].inputValue) {
                    totalExp = totalExp + parseFloat(this.utilityService.formatCurrency(this.incomeExpense.expenseDetails[j].inputValue)) || 0;
                }
            }
            this.incomeExpense.monthlyExpenses = totalExp;
        }
        this.incomeExpense.netMonthlyCashFlow = this.incomeExpense.monthlyIncome - this.incomeExpense.monthlyExpenses;
        this.incomeExpense.netYearlyCashFlow = this.incomeExpense.netMonthlyCashFlow * 12;
    };

    addSource(type) {
        if (type == 'income') {
            this.incomeExpense.incomeDetails.push({ 'source': '', 'value': 0, '_destroy': false, 'error': false });
        } else if (type == 'expense') {
            this.incomeExpense.expenseDetails.push({ 'source': '', 'value': 0, '_destroy': false, 'error': false });
        }
    };

    deleteElement(index, type) {
        if (type == 'income') {
            if (this.incomeExpense.incomeDetails[index].id) {
                this.incomeExpense.incomeDetails[index]._destroy = true;

            } else {
                this.incomeExpense.incomeDetails.splice(index, 1);

            }
            let chkIncomeDestroy = {};
            chkIncomeDestroy = _.find(this.incomeExpense.incomeDetails, function (item) {
                return item._destroy === false;
            });
            if (this.incomeExpense.incomeDetails.length === 0 || isUndefined(chkIncomeDestroy)) {
                this.addSource('income');
            }
        } else if (type == "expense") {
            if (this.incomeExpense.expenseDetails[index].id) {
                this.incomeExpense.expenseDetails[index]._destroy = true;

            } else {
                this.incomeExpense.expenseDetails.splice(index, 1);
            }
            let chkExpenseDestroy = {};
            chkExpenseDestroy = _.find(this.incomeExpense.expenseDetails, function (item) {
                return item._destroy === false;
            });
            if (this.incomeExpense.expenseDetails.length === 0 || isUndefined(chkExpenseDestroy)) {
                this.addSource('expense');
            }
        }
        this.incomeExpenseSum(type);
    };

    setIncomeInput(value) {
        this.incomeInput.inputValue = value;
        this.incomeExpenseSum('income');
        this.incomeInput.maxValue = 25000;
        this.incomeInput.step = 250;
    }

    setExpenseInput(value) {
        this.expenseInput.inputValue = value;
        this.incomeExpenseSum('expense');
        this.expenseInput.maxValue = 25000; this.expenseInput.step = 250;
    }


    saveIncomeExpense() {
        _.map(this.incomeExpense.incomeDetails, function (item) {
            return item.error = false;
        });
        _.map(this.incomeExpense.expenseDetails, function (item) {
            return item.error = false;
        });
        let formattedIncomeObj = {};
        let formattedExpenseObj = {};
        for (let i = 0; i < this.incomeExpense.incomeDetails.length; i++) {
            // this.cashFlowForm['income'+i].$setValidity('invalidIncome',true);
            if (this.incomeExpense.incomeDetails[i].inputValue == 0 && this.incomeExpense.incomeDetails[i].source == '') {
                if (isUndefined(this.incomeExpense.incomeDetails[i].id)) {
                    this.incomeExpense.incomeDetails.splice(i, 1);
                    i = i - 1;
                } else {
                    this.incomeExpense.incomeDetails[i]._destroy = true;
                }
            } else if (this.incomeExpense.incomeDetails[i].inputValue > 0 && this.incomeExpense.incomeDetails[i].source == '') {
                // this.cashFlowForm['income'+i].$setValidity('invalidIncome',false);
                this.incomeExpense.incomeDetails[i].error = true;
            }
        }
        for (let j = 0; j < this.incomeExpense.expenseDetails.length; j++) {
            // this.cashFlowForm['expense'+j].$setValidity('invalidExpense',true);
            if (this.incomeExpense.expenseDetails[j].inputValue == 0 && this.incomeExpense.expenseDetails[j].source == '') {
                if (isUndefined(this.incomeExpense.expenseDetails[j].id)) {
                    this.incomeExpense.expenseDetails.splice(j, 1);
                    j = j - 1;
                } else {
                    this.incomeExpense.expenseDetails[j]._destroy = true;
                }
            } else if (this.incomeExpense.expenseDetails[j].inputValue > 0 && this.incomeExpense.expenseDetails[j].source == '') {
                //this.cashFlowForm['expense'+j].$setValidity('invalidExpense',false);
                this.incomeExpense.expenseDetails[j].error = true;
            }
        }
        // if(this.cashFlowForm.valid){
        for (let i = 0; i < this.incomeExpense.incomeDetails.length; i++) {
            formattedIncomeObj["income_" + i] = {};
            if (this.incomeExpense.incomeDetails[i].id) {
                formattedIncomeObj["income_" + i].id = this.incomeExpense.incomeDetails[i].id;
                formattedIncomeObj["income_" + i]._destroy = this.incomeExpense.incomeDetails[i]._destroy;
            }
            formattedIncomeObj["income_" + i].description = this.incomeExpense.incomeDetails[i].source;
            formattedIncomeObj["income_" + i].amount = this.incomeExpense.incomeDetails[i].inputValue;
        }

        for (let j = 0; j < this.incomeExpense.expenseDetails.length; j++) {
            formattedExpenseObj["expense_" + j] = {};
            if (this.incomeExpense.expenseDetails[j].id) {
                formattedExpenseObj["expense_" + j].id = this.incomeExpense.expenseDetails[j].id;
                formattedExpenseObj["expense_" + j]._destroy = this.incomeExpense.expenseDetails[j]._destroy;
            }
            formattedExpenseObj["expense_" + j].description = this.incomeExpense.expenseDetails[j].source;
            formattedExpenseObj["expense_" + j].amount = this.incomeExpense.expenseDetails[j].inputValue;
            formattedExpenseObj["expense_" + j].recurrence_cd = "2";
        }

        let reqObj = { "household": { "user_incomes_attributes": formattedIncomeObj, "monthly_expenses_attributes": formattedExpenseObj } };

        let url = AppRestEndPoint.BASIC_INFO.SAVE_FAMILY_INFO;
        this.apiService.postCall(url, reqObj).subscribe(data => {
            this.sendMixPanelEvent({'event': 'Client Onboarding - Income Expense Details - Continue'});
            this.saveState();
            this.gotoNextState();
        });
    };

    gotoPreviousState() {
		var reqObj = {"info_type" : this.selectedType, "state": this.pageUrl[4]};
		this.navigationService.getPreviousPage(reqObj);
	}

	gotoNextState(){
       
		var reqObj = {"info_type" : this.selectedType, "state": this.pageUrl[4]};
		this.navigationService.getNextPage(reqObj);
	}

	saveState(){
		var reqObj = {
			'user': {"gettingstarted_step":'{"info_type" :"' +this.selectedType+'","state" :"cashFlow"}'}	
		};
		let url = AppRestEndPoint.LOGIN.SAVE_STATE+'/'+this.currentUser.id;
		this.apiService.updateCall(url, reqObj, '').subscribe(data => {
		});
    }
    
    sendMixPanelEvent(eventObj){
        this.utilityService.gtmPush(eventObj);
    }
}
