<app-bread-crumb-options></app-bread-crumb-options>
<div class="container">
    <div class="tab-form-header pt-md-5">For your privacy, you need to create a secure login</div>
    <form [formGroup]="signUpForm" novalidate>
        <div class="row">
            <div class="card col-md-6 col-sm-6 col-xs-6  m-auto border-radius-10 border-0 shadow-sm p-2">
                <div class="card-body pb-1">
                    <div class="pb-3">
                        <label class="form-label">Full Name</label>
                        <input autocomplete="off" class="form-control form-input p-2" [(ngModel)]="signup.firstName"
                            formControlName="firstName">
                        <div *ngIf="signUpFormSubmitted" class="text-danger" role="alert">
                            <div *ngIf="formControl.firstName.errors?.required">Name is mandatory</div>
                            <div *ngIf="formControl.firstName.errors?.usernameExists">Please enter your first and last
                                name.
                            </div>
                        </div>
                    </div>
                    <div class="pb-3">
                        <label class="form-label">Phone Number</label>
                        <span class="float-right">
                            <label>
                                <input name="phoneType" type="radio" class="mr-1" [(ngModel)]="signup.phoneType"
                                    formControlName="phoneType" [value]="3" />
                                <span class="cursor-pointer pr-2">Mobile</span>
                            </label>
                            <label>
                                <input name="phoneType" type="radio" class="mr-1" [(ngModel)]="signup.phoneType"
                                    formControlName="phoneType" [value]="1">
                                <span class="cursor-pointer padding-right-5">Home</span>
                            </label>
                        </span>
                        <input autocomplete="off" class="form-control form-input p-2" [(ngModel)]="signup.phoneNumber"
                            id="phone-number" formControlName="phoneNumber" appPhoneMask />
                        <div *ngIf="signUpFormSubmitted" class="text-danger" role="alert">
                            <div *ngIf="formControl.phoneNumber.errors?.required">Phone number is mandatory.</div>
                        </div>
                    </div>
                    <div class="pb-3" *ngIf="showConsentCheckbox && !textMessageConsent">
                        <input class="mr-1" formControlName="consent"
                        [(ngModel)]="signup.agreed_to_receive_text_message" type="checkbox">
                        I consent to communications by text message from <b>{{firmName}}</b>. You may opt-out by replying STOP or ask for more information by replying HELP. Message and data rates may apply. You may review our Privacy Policy at  <a href="{{firmWebsite}}">{{firmWebsite}}</a>  to learn how your data is used.
                        <div *ngIf="signUpFormSubmitted" class="text-danger" role="alert">
                            <div *ngIf="formControl.consent.errors?.required">Please confirm.</div>
                        </div>
                    </div>
                    <div class="pb-3" *ngIf="showConsentCheckbox && textMessageConsent">
                        <input class="mr-1" formControlName="consent"
                        [(ngModel)]="signup.agreed_to_receive_text_message" type="checkbox">
                        {{textMessageConsent}}
                        <div *ngIf="signUpFormSubmitted" class="text-danger" role="alert">
                            <div *ngIf="formControl.consent.errors?.required">Please confirm.</div>
                        </div>
                    </div>
                    <div class="pb-3">
                        <label class="form-label">Email</label>
                        <input autocomplete="off" class="form-control form-input p-2" [(ngModel)]="signup.email"
                            formControlName="email" autocomplete="false">
                        <div *ngIf="signUpFormSubmitted" class="text-danger" role="alert">
                            <div *ngIf="formControl.email.errors?.required">Email is mandatory.</div>
                            <div *ngIf="formControl.email.errors?.pattern">Enter a valid email.</div>
                        </div>
                    </div>
                    <div class="pb-3">
                        <label class="form-label">Password</label>
                        <input type="password" autocomplete="off" class="form-control form-input p-2" appTogglePassword
                            [(ngModel)]="signup.password" formControlName="password" autocomplete="false">
                        <div *ngIf="signUpFormSubmitted" class="text-danger" role="alert">
                            <div *ngIf="formControl.password.errors?.required">Password is mandatory.</div>
                        </div>
                        <div *ngIf="signUpFormSubmitted && invalid.length>0" class="text-danger" role="alert">
                            <div *ngFor="let error of invalid">{{error}}</div>
                        </div>
                    </div>
                    <div class="pb-3">
                        <label class="form-label">Confirm Password</label>
                        <input type="password" autocomplete="off" class="form-control form-input p-2"
                            [(ngModel)]="signup.confirmPassword" formControlName="confirmPassword" appTogglePassword autocomplete="false">
                        <div *ngIf="signUpFormSubmitted" class="text-danger" role="alert">
                            <div *ngIf="formControl.confirmPassword.errors?.required">Password is mandatory.</div>
                            <div *ngIf="formControl.confirmPassword.errors?.invalidMatch">Password does not match.</div>
                        </div>
                    </div>
                    <label class="form-label text-uppercase">Password must contain the following:</label>
                    <div>
                        <i class="fa fa-check color-green" *ngIf="!signUpForm.controls['password'].hasError('hasSmallCase')" aria-hidden="true"></i>
                        <i class="fa fa-times color-red mr-2" *ngIf="signUpForm.controls['password'].hasError('hasSmallCase')" aria-hidden="true"></i>
                        <label [ngClass]="{'color-red':signUpForm.controls['password'].hasError('hasSmallCase'), 'color-green':!signUpForm.controls['password'].hasError('hasSmallCase')}">A <b>lowercase</b> letter</label>
                    </div>
                    <div>
                        <i class="fa fa-check color-green" *ngIf="!signUpForm.controls['password'].hasError('hasCapitalCase')" aria-hidden="true"></i>
                        <i class="fa fa-times color-red mr-2" *ngIf="signUpForm.controls['password'].hasError('hasCapitalCase')" aria-hidden="true"></i>
                        <label [ngClass]="{'color-red':signUpForm.controls['password'].hasError('hasCapitalCase'), 'color-green':!signUpForm.controls['password'].hasError('hasCapitalCase')}">An <b>uppercase</b> letter</label>
                    </div>
                    <div>
                        <i class="fa fa-check color-green" *ngIf="!signUpForm.controls['password'].hasError('hasNumber')" aria-hidden="true"></i>
                        <i class="fa fa-times color-red mr-2" *ngIf="signUpForm.controls['password'].hasError('hasNumber')" aria-hidden="true"></i>
                        <label [ngClass]="{'color-red':signUpForm.controls['password'].hasError('hasNumber'), 'color-green':!signUpForm.controls['password'].hasError('hasNumber')}">A <b>number</b></label>
                    </div>
                    <div>
                        <i class="fa fa-check color-green" *ngIf="!signUpForm.controls['password'].hasError('hasSpecialCharacters')" aria-hidden="true"></i>
                        <i class="fa fa-times color-red mr-2" *ngIf="signUpForm.controls['password'].hasError('hasSpecialCharacters')" aria-hidden="true"></i>
                        <label [ngClass]="{'color-red':signUpForm.controls['password'].hasError('hasSpecialCharacters'), 'color-green':!signUpForm.controls['password'].hasError('hasSpecialCharacters')}">A <b>special</b> character</label>
                    </div>
                    <div>
                        <i class="fa fa-check color-green" *ngIf="!signUpForm.controls['password'].hasError('minlength') && signup.password" aria-hidden="true"></i>
                        <i class="fa fa-times color-red mr-2" *ngIf="signUpForm.controls['password'].hasError('minlength') || !signup.password" aria-hidden="true"></i>
                        <label  [ngClass]="{'color-red':signUpForm.controls['password'].hasError('minlength') || !signup.password, 'color-green':!signUpForm.controls['password'].hasError('minlength') && signup.password}">Minimum <b>8 characters</b></label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row pb-3">
            <div class="col-md-6 col-sm-6 col-xs-6  m-auto pt-3 pl-0">
                <input class="styled-checkbox" id="styled-checkbox-1" formControlName="terms"
                    [(ngModel)]="signup.terms_and_conditions" type="checkbox" required>Terms and conditions
                must be acknowledged
                <label for="styled-checkbox-1">I acknowledge that I have read and agree to the
                    <a href="{{firm.terms_of_service_url}}" target="_blank">Terms of Service</a>, <a
                        href="{{firm.form_adv_url}}" target="_blank">Form ADV,</a> and <a
                        href="{{firm.disclosures_url}}" target="_blank">Disclosures.</a></label>
                <div *ngIf="signUpFormSubmitted" class="text-danger" role="alert">
                    <div *ngIf="formControl.terms.errors?.required">Terms and conditions must be acknowledged.</div>
                </div>
            </div>
        </div>

        <div class="text-center border-top p-4">
            <button class="btn btn-primary rounded-pill btn-lg-custom" (click)="submitSignUp()">
                Establish your profile
                <i class="fa fa-long-arrow-right btn-arrow-right"></i>
            </button>
        </div>

    </form>
</div>