import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AssetsAndLiabilitiesComponent } from './assets-and-liabilities/assets-and-liabilities.component';
import { BasicInfoComponent } from './basic-info/basic-info.component';
import { PersonalInformationComponent } from './basic-info/personal-information/personal-information.component';
import { CashFlowComponent } from './cash-flow/cash-flow.component';
import { GetStartedComponent } from './get-started/get-started.component';
import { RiskToleranceComponent } from './risk-tolerance/risk-tolerance.component';
import { SignupComponent } from './signup/signup.component';
import { InvestmentInfoComponent } from './investment-info/investment-info.component';
import { AdvisorContactComponent } from './advisor-contact/advisor-contact.component';
import { InformationSubmittedComponent } from './information-submitted/information-submitted.component';
import { SignupCompleteComponent } from './signup-complete/signup-complete.component';
import { AccountsComponent } from './assets-and-liabilities/accounts/accounts.component';
import { OtherAssetsComponent } from './assets-and-liabilities/other-assets/other-assets.component';
import { AuthGuard } from '../services/auth.guard';
import { ExternalRiskToleranceComponent } from './external/external-risk-tolerance/external-risk-tolerance.component';
import { ExternalAdvisorContactComponent } from './external/external-advisor-contact/external-advisor-contact.component';


const routes: Routes = [
	{
		path: 'get-started',
		component: GetStartedComponent
	},
	{
		path: 'tpp/risk-tolerance',
		component: ExternalRiskToleranceComponent
	},
	{
		path: 'tpp/advisor-contact',
		component: ExternalAdvisorContactComponent
	},
	{
		path: ':type/signup',
		component: SignupComponent
	},
	{
		path: ':type/basic-info',
		component: BasicInfoComponent,
		canActivate: [AuthGuard]
	},
	{
		path: ':type/basic-info/personal-info',
		component: PersonalInformationComponent,
		canActivate: [AuthGuard]

	},
	{
		path: ':type/basic-info/investment-info',
		component: InvestmentInfoComponent,
		canActivate: [AuthGuard]

	},
	{
		path: ':type/risk-tolerance',
		component: RiskToleranceComponent,
		canActivate: [AuthGuard]

	},
	{
		path: ':type/information-submitted',
		component: InformationSubmittedComponent,
		canActivate: [AuthGuard]

	},
	{
		path: ':type/assets-and-liabilities',
		component: AssetsAndLiabilitiesComponent,
		canActivate: [AuthGuard]

	},
	{
		path: ':type/accounts',
		component: AccountsComponent,
		canActivate: [AuthGuard]

	},
	{
		path: ':type/other-assets',
		component: OtherAssetsComponent,
		canActivate: [AuthGuard]

	},
	{
		path: ':type/cash-flow',
		component: CashFlowComponent,
		canActivate: [AuthGuard]

	},
	{
		path: ':type/advisor-contact',
		component: AdvisorContactComponent,
		canActivate: [AuthGuard]

	},
	{
		path: ':type/signup-complete',
		component: SignupCompleteComponent,
		canActivate: [AuthGuard]

	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class GetStartedRoutingModule { }
