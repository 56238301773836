<nav class="navbar navbar-expand-lg bg-white top-nav d-none d-md-block d-lg-block mb-5" [ngClass]="{'fixed-top':currentPage == 'dashboard' || currentPage == 'profile' || currentPage == 'documents' || currentPage == 'tasks'}">
    <div class="w-100 d-inline-flex" [ngClass]="{'px-5':currentPage !== 'dashboard' && currentPage !== 'profile' && currentPage !== 'documents' && currentPage !== 'tasks','ml-2':currentPage == 'dashboard' || currentPage == 'profile' || currentPage == 'documents' || currentPage == 'tasks'}">
        <a class="navbar-brand" [attr.href]="websiteUrl" target="_blank">
            <img class="navbar-img-center logo-img-size" [src]="websiteLogo">
        </a>
        <div class="collapse navbar-collapse" *ngIf="access_token">
            <ul class="navbar-nav ml-auto"
                *ngIf="currentPage !== 'dashboard' && currentPage !== 'profile' && currentPage !== 'documents' && currentPage !== 'tasks'">
                <li class="nav-item active">
                    <button type="button" class="btn btn-link text-uppercase font-13 color-gray font-weight-light"
                        (click)="openContactUsModal();sendMixPanelEvent({'event': 'Client Onboarding - Get Started - Contact My Advisor'})">
                        <i class="fa fa-envelope-o"></i> Contact Us</button>
                </li>
                <li class="nav-item active">
                    <button type="button" class="btn btn-link text-uppercase font-13 color-gray font-weight-light"
                        (click)="logOut();sendMixPanelEvent({'event': 'Client Onboarding - Get Started - Logout'});">
                        <i class="fa fa-sign-out"></i> Logout</button>
                </li>
            </ul>
            <ul class="navbar-nav ml-auto"
                *ngIf="currentPage == 'dashboard' || currentPage == 'profile' || currentPage == 'documents' || currentPage == 'tasks'">
                <!-- dashboard -->
                <li class="nav-item active pr-2 py-3" *ngIf="currentUser.firm.show_contact_my_advisor_icon">
                    <a class="font-18 color-gray cursor-pointer" (click)="openContactUsModal();sendMixPanelEvent({'event': 'Dashboard - Contact My Advisor'})" title="Contact My Advisor"><i class="fa fa-envelope-o"></i></a>
                </li>
                <li class="nav-item active pl-3 pr-2 py-3" *ngIf="currentUser.firm.show_schedule_meeting_icon">
                    <a class="font-18 color-gray cursor-pointer" [ngClass]="{'pointer-events-none':disabledSchBtn}" (click)="getSchedule()" title="Schedule a Meeting" [attr.disabled]="disabledSchBtn"><i class="fa fa-calendar-check-o"></i></a>
                </li>
              <!--   <li class="nav-item active px-3 py-3" *ngIf="showScheduleOnce && currentUser.firm.show_schedule_meeting_icon">
                    <a class="font-18 color-gray cursor-pointer" id="{{advisorData.advisorId}}" title="Schedule a Meeting" ><i class="fa fa-calendar-check-o"></i></a>
                </li> -->
                <li class="nav-item active pl-3 pr-2 py-3" *ngIf="currentUser.firm.show_send_files_icon">
                    <a class="font-18 color-gray cursor-pointer" (click)="openSendFilesModal()" title="Send Files"><i class="fa fa-paperclip iconPaperClip"></i></a>
                </li>
            </ul>
        </div>
    </div>
</nav>



<!--Navbar-->
<nav class="navbar  bg-white top-nav mb-5 hamburger">
    <div class="container px-0">

        <!-- Navbar brand -->
        <a class="navbar-brand" [attr.href]="websiteUrl" target="_blank">
            <img class="navbar-img-center logo-img-size" [src]="websiteLogo">
        </a>

        <!-- Collapse button -->
        <button class="navbar-toggler first-button" type="button" data-toggle="collapse" *ngIf="access_token && rtqEmail == null"
            data-target="#navbarSupportedContent20" aria-controls="navbarSupportedContent20" aria-expanded="false"
            aria-label="Toggle navigation">
            <i class="fa fa-bars"></i>
        </button>

        <!-- Collapsible content -->
        <div class="collapse navbar-collapse" id="navbarSupportedContent20" *ngIf="access_token">

            <ul class="navbar-nav mr-auto ">
                <li class="nav-item"><a [ngClass]="{'active': currentPage == 'dashboard'}" title="Dashboard" data-toggle="collapse" data-target=".navbar-collapse"
                        (click)="setCurrentNav('Dashboard')" class="nav-link font-15"><i
                            class="fa fa-briefcase pr-2"></i>Dashboard </a>
                </li>
                <li class="nav-item"><a [ngClass]="{'active': currentPage == 'tasks'}" title="TODO" data-toggle="collapse" data-target=".navbar-collapse"
                    (click)="setCurrentNav('Tasks')" class="nav-link font-15"><i
                        class="fa fa-briefcase pr-2"></i>TODO </a>
                </li>
                <li class="nav-item"> <a [ngClass]="{'active': currentPage == 'profile'}" title="Profile" data-toggle="collapse" data-target=".navbar-collapse"
                        class="nav-link font-15" (click)="setCurrentNav('Profile')"><i
                            class="fa fa-user-circle-o pr-2"></i>Profile</a>
                </li>
                <li class="nav-item">
                    <a [ngClass]="{'selected':currentPage == 'documents'}" title="Documents" class="nav-link" data-toggle="collapse" data-target=".navbar-collapse"
                        (click)="setCurrentNav('Documents')"><i class="fa fa-file-text pr-2"></i>Documents</a>
                </li>

                <li class="nav-item"><a [ngClass]]="{'active':currentIndex == 3}" title="Portfolio" class="nav-link" data-toggle="collapse" data-target=".navbar-collapse" 
                        *ngIf="tamaracObj.length>0" ng-click="openPortfolio()"><i
                            class="fa fa-pie-chart pr-2"></i>Portfolio</a></li>
                <li class="nav-item" *ngIf="showNewsIcon"><a [ngClass]="{'selected':currentIndex == 4}" class="nav-link" data-toggle="collapse" data-target=".navbar-collapse"
                        title="News & Insights" ng-click="openKnowledgeBase()"><i
                            class="fa fa-newspaper-o pr-2"></i>News & Insights</a>
                </li>
                <li class="nav-item" *ngIf="currentUser.firm.show_contact_my_advisor_icon"><a
                        (click)="openContactUsModal()" class="nav-link" title="Contact My Advisor" data-toggle="collapse" data-target=".navbar-collapse"><i
                            class="fa fa-envelope-o pr-2"></i>Contact My
                        Advisor</a></li>
                <li class="nav-item"
                    *ngIf="currentUser.firm.show_schedule_meeting_icon"><a
                        class="nav-link" (click)="getSchedule()" title="Schedule a Meeting" data-toggle="collapse" data-target=".navbar-collapse"><i
                            class="fa fa-calendar-check-o pr-2"></i>Schedule a Meeting</a>
                </li>
                <li class="nav-item" *ngIf="currentUser.firm.show_send_files_icon"><a (click)="openSendFilesModal()" data-toggle="collapse" data-target=".navbar-collapse"
                        class="nav-link" title="Send Files"><i class="fa fa-paperclip iconPaperClip pr-2"></i>Send
                        Files</a>
                </li>
                <li class="nav-item"><a (click)="logOut()" class="nav-link" title="Sign out" data-toggle="collapse" data-target=".navbar-collapse"><i
                            class="fa fa-sign-out pr-2"></i>Sign out</a>
                </li>
            </ul>
            <!-- Links -->

        </div>
        <!-- Collapsible content -->
    </div>
</nav>