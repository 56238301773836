<!-- Remove Manual Modal -->
<div class="modal-header">
    <div class="row w-100">
        <div class="col-md-11">           
            <h5 class="modal-title pull-left" *ngIf="removeInstitution === true">Remove Account</h5>
            <h5 class="modal-title pull-left" *ngIf="removeInstitution === false">Remove Manually Added Account</h5>
        </div>
        <div class="col-md-1 pr-0">
            <button type="button" class="close float-right text-white pr-0" aria-label="Close"
                (click)="activeModal.dismiss()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
</div>

<div class="modal-body">
    <div *ngIf="removeInstitution === true">
        <div class="col-md-12 col-sm-12 pl-0">
            <span class="color-red">Removing the account will also remove the below associated accounts from same institution. Are you sure you would like to continue this action?
            </span>                      
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12 margin-20" *ngIf="selectedRow.isManual === false">
            <div class="font-weight-bold mt-2">{{selectedRow.bankDescription}}</div>
            <div *ngFor="let account of removalAccount" class="m-2">
                <div>{{account.account_description}} - {{account.type}}</div>
            </div>
        </div>
    </div>

    <div *ngIf="removeInstitution === false">
        <div class="col-md-12 col-sm-12 col-xs-12 pl-0">
            <span class="color-red">Are you sure you would like to remove {{selectedRow.accountName}}?</span>
        </div>
    </div>
</div>

<div class="modal-footer">   
        <button type="button" class="btn btn-link" (click)="closeModal()">CANCEL</button>
        <button type="button" class="btn btn-primary btn-sm rounded-pill" (click)="removeAccount()" *ngIf="removeInstitution === true" 
        (disabled)="showRemoveAccLoader">Remove Account 
          <!--   <span *ngIf="showRemoveAccLoader">&nbsp;</span>
            <span *ngIf="showRemoveAccLoader" class="loader-status position-absolute">
                <div class="loader loader-spinner top-5"></div>&nbsp;
            </span> -->
        </button>
        <button type="button" class="btn btn-primary btn-sm rounded-pill" (click)="removeAccount()" *ngIf="removeInstitution === false" 
        (disabled)="showRemoveAccLoader">Remove 
           <!--  <span *ngIf="showRemoveAccLoader">&nbsp;</span>
            <span *ngIf="showRemoveAccLoader" class="loader-status position-absolute">
                <div class="loader loader-spinner top-5"></div>&nbsp;
            </span> -->
        </button>
    
</div>

