export const melissacodes = [
            {
                "code": "AE01",
                "description": "Postal Code Error/General Error"
            },
            {
                "code": "AE02",
                "description": "Unknown Street"
            },
            {
                "code": "AE03",
                "description": "Component Mismatch Error"
            },
            {
                "code": "AE04",
                "description": "Non-Deliverable Address"
            },
            {
                "code": "AE05",
                "description": "Multiple Match"
            },
            {
                "code": "AE06",
                "description": "Early Warning System"
            },
            {
                "code": "AE07",
                "description": "Missing Minimum Address"
            },
            {
                "code": "AE08",
                "description": "Sub Premise Number Invalid"
            },
            {
                "code": "AE09",
                "description": "Sub Premise Number Missing"
            },
            {
                "code": "AE10",
                "description": "Premise Number Invalid"
            },
            {
                "code": "AE11",
                "description": "Premise Number Missing"
            },
            {
                "code": "AE12",
                "description": "Box Number Invalid"
            },
            {
                "code": "AE13",
                "description": "Box Number Missing"
            },
            {
                "code": "AE14",
                "description": "PMB Number Missing"
            },
            {
                "code": "AE15",
                "description": "Demo Mode"
            },
            {
                "code": "AE16",
                "description": "Expired Database"
            },
            {
                "code": "AE17",
                "description": "Sub Premise Not Required"
            },
            {
                "code": "AE19",
                "description": "Find Suggestion Timeout"
            },
            {
                "code": "AE20",
                "description": "Find Suggestion Disabled"
            },
            {
                "code": "AE21",
                "description": "MAK Not Found"
            },
            {
                "code": "AS02",
                "description": "Valid Building Address"
            },
            {
                "code": "AS03",
                "description": "Non USPS Address Match"
            },
            {
                "code": "AS09",
                "description": "Foreign Address"
            },
            {
                "code": "AS10",
                "description": "CMRA Address"
            },
            {
                "code": "AS11",
                "description": "PBSA Address"
            },
            {
                "code": "AS12",
                "description": "Moved to New Address"
            },
            {
                "code": "AS13",
                "description": "Address Updated By LACS"
            },
            {
                "code": "AS14",
                "description": "Suite Appended"
            },
            {
                "code": "AS15",
                "description": "Apartment Appended"
            },
            {
                "code": "AS16",
                "description": "Vacant Address"
            },
            {
                "code": "AS17",
                "description": "No USPS Mail Delivery"
            },
            {
                "code": "AS18",
                "description": "DPV Locked Out"
            },
            {
                "code": "AS20",
                "description": "Deliverable only by USPS"
            },
            {
                "code": "AS22",
                "description": "No Alternate Address Suggestion Found"
            },
            {
                "code": "AS23",
                "description": "Extraneous Information"
            },
            {
                "code": "AS24",
                "description": "USPS Door Not Accessible"
            }
        ];