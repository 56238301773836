import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventEmitterService } from 'src/app/services/event-emitter.service';

@Component({
	selector: 'app-bread-crumb-options',
	templateUrl: './bread-crumb-options.component.html',
	styleUrls: ['./bread-crumb-options.component.scss']
})
export class BreadCrumbOptionsComponent implements OnInit {

	currentIndex: number;
	infoType: any;
	rtqEmail: any = null;

	constructor(public router: Router,
		private activatedRoute: ActivatedRoute,
		private eventEmitter: EventEmitterService) { }

	ngOnInit(): void {
		this.rtqEmail = sessionStorage.getItem("rtq_redirect");
		this.eventEmitter.subscribe("Rtqemail", (flag) => { 
			this.rtqEmail = flag;
		});
		this.infoType = this.activatedRoute.snapshot.paramMap.get('type');
		let pageUrl = document.URL.split('/');
		let currentActiveTab = pageUrl[pageUrl.length - 1];
		if(currentActiveTab.includes("?")){
			currentActiveTab = currentActiveTab.split('?')[0];
		}
		if (currentActiveTab === "personal-info" || currentActiveTab === "investment-info") {
			currentActiveTab = "basic-info";
		} else if (this.infoType !== "basic-flow" && currentActiveTab === "information-submitted") {
			currentActiveTab = "risk-tolerance";
		}
		if (currentActiveTab === "accounts" || currentActiveTab === "manual-accounts" || currentActiveTab === "other-assets" || currentActiveTab === "add-assets" || currentActiveTab === "list-assets-and-liabilities") {
			currentActiveTab = "assets-and-liabilities";
		}
		if (this.infoType == "basic-flow" && currentActiveTab === "information-submitted") {
			currentActiveTab = "preliminary-analysis";
		}
		this.pageNavigation(currentActiveTab);
	}

	pageNavigation(type) {
		switch (type) {
			case "signup": this.currentIndex = 0; break;
			case "basic-info": this.currentIndex = 1; break;
			case "risk-tolerance": this.currentIndex = 2; break;
			case "assets-and-liabilities": this.currentIndex = 3; break;
			case "cash-flow": this.currentIndex = 4; break;
			case "preliminary-analysis": this.currentIndex = 3; break;
		}
	}

	redirectToPage(index) {
		switch (index) {
			case 0: this.router.navigate([this.infoType+"/signup"]); break;
			case 1: this.router.navigate([ this.infoType+"/basic-info"]); break;
			case 2: this.router.navigate([ this.infoType+"/risk-tolerance"]); break;
			case 3: this.router.navigate([ this.infoType+"/assets-and-liabilities"]); break;
			case 4: this.router.navigate([ this.infoType+"/cash-flow"]); break;
		}
	}

}
