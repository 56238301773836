import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const httpOptions = {
    headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
    })
};


@Injectable({
  providedIn: 'root'
})
export class LoginService {

   constructor(private http: HttpClient) { }

    public getCall(apiURl, params): Observable<any> {
        return this.http
            .get(apiURl + params, httpOptions)
            .pipe(map((response: any) => {
                return response;
            }));

    }

    public postCall(url, data): Observable<any> {
        return this.http.post(url, data, httpOptions)
            .pipe(map((response: any) => {
                return response;
            }));
    }

    public postCallAutoLogin(url, body, httpOptionsAuto): Observable<any> {
        return this.http.post(url, body, httpOptionsAuto)
            .pipe(map((response: any) => {
                return response;
            }));
    }

    public updateCall(apiURl, data): Observable<any> {
        return this.http.put(apiURl, data, httpOptions);
    }
}
