<div class="modal-header">
    <div class="row w-100">
        <div class="col-md-11">
            <h5 class="modal-title pull-left">
                Link External Account</h5>
        </div>
        <div class="col-md-1 pr-0">
            <button type="button" class="close float-right text-white pr-0" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
</div>

<div class="modal-body link-ext-acc-btn-body">
    <div class="pb-2">
        <p>
            Link your external financial account using the links below.
        </p>
        <p>
            1. Search for and select your financial institution.
        </p>
        <p>
            2. Enter your credentials in their secure popup. NOTE: We do not see nor retain your
            credential information.
        </p>
        <p>
            3. Depending on your financial institution, you may need to provide two-factor authentication to complete the linking process.
        </p>
        <p class="padding-top-10 pb-3 border-bottom-black">
            Prior to attempting linking, please ensure you can log in directly to your financial
            institution.
        </p>
        <div class="col-md-12 col-sm-12 col-xs-12 px-0 row">
            <div class="col-md-6 col-sm-6 col-xs-6">
                <button
                    class="btn btn-primary link-acc-auto text-decoration-none linkAccBtn font-11 margin-top-10 font-weight-600"
                    shape="round" (click)="openExternal('plaid')">Link Account with Plaid
                </button>
                <div class="mt-3">Specifically use Plaid for the following providers: </div>
                <div *ngFor="let item of suggestedProviders?.plaid">
                    {{item.description}}
                </div>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-6">
                <button
                    class="btn btn-primary link-acc-auto text-decoration-none linkAccBtn font-11 margin-top-10 font-weight-600"
                    shape="round" (click)="openExternal('yodlee');">Link Account with Yodlee
                </button>
                <div class="mt-3">Specifically use Yodlee for the following providers: </div>
                <div *ngFor="let item of suggestedProviders?.yodlee">
                    {{item.description}}
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-link" (click)="closeModal()">
        CLOSE
    </button>
</div>