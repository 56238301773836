<div class="container">
    <div class="row">
        <div class="card w-50 m-auto border-radius-10 border-0 shadow-sm p-2">
            <div class="card-body pb-1">
                <form [formGroup]="changePasswordForm" novalidate>
                    <h2 class="text-left mb-0 mt-2">{{reset_password_message}}</h2>
                    <hr class="header-hr my-1">
                    <div class="pt-3 pb-3">
                        <label class="text-uppercase" for="password">New Password</label>
                        <input id="password" name="password" formControlName="changePasswordNew" [(ngModel)]="password" type="password"
                            autocomplete="off" class="form-control form-input p-2" appTogglePassword
                            (input)="($event.charCode==13)? changePassword() : ''" required>
                          
                        <div *ngIf="changePasswordFormSubmitted && formControl.changePasswordNew.errors"
                            class="text-danger">
                            <div *ngIf="formControl.changePasswordNew.errors?.required">New Password is required</div>
                            <div *ngIf="formControl.changePasswordNew.errors?.invalidMatch">Passwords must match</div>
                            <div *ngIf="formControl.changePasswordNew.errors?.spaceNotAllowed">Space is not allowed.
                            </div>
                        </div>
                        <div *ngIf="changePasswordFormSubmitted && invalid.length>0" class="error-div col-md-12"
                            role="alert">
                            <!-- <div *ngFor="error in invalid" class="error-text">{{error}}</div> -->
                        </div>
                    </div>
                    <div class="pt-3 pb-5">
                        <label class="text-uppercase" for="confirmPassword">Confirm Password</label>
                        <input id="confirmPassword" name="confirmPassword" formControlName="confirmChangePassword" appTogglePassword
                        [(ngModel)]="confirmPassword"  type="password" autocomplete="off" class="form-control form-input p-2" required>
                     
                        <div *ngIf="changePasswordFormSubmitted && formControl.confirmChangePassword.errors"
                            class="text-danger">
                            <div *ngIf="formControl.confirmChangePassword.errors?.required">Confirm Password is
                                required</div>
                            <div *ngIf="formControl.confirmChangePassword.errors?.invalidMatch">Passwords must match
                            </div>
                            <div *ngIf="formControl.confirmChangePassword.errors?.spaceNotAllowed">Space is not
                                allowed.</div>

                        </div>
                    </div>
                    <label class="form-label text-uppercase">Password must contain the following:</label>
                        <div>
                            <i class="fa fa-check color-green" *ngIf="!changePasswordForm.controls['changePasswordNew'].hasError('hasSmallCase')" aria-hidden="true"></i>
                            <i class="fa fa-times color-red mr-2" *ngIf="changePasswordForm.controls['changePasswordNew'].hasError('hasSmallCase')" aria-hidden="true"></i>
                            <label [ngClass]="{'color-red':changePasswordForm.controls['changePasswordNew'].hasError('hasSmallCase'), 'color-green':!changePasswordForm.controls['changePasswordNew'].hasError('hasSmallCase')}">A <b>lowercase</b> letter</label>
                        </div>
                        <div>
                            <i class="fa fa-check color-green" *ngIf="!changePasswordForm.controls['changePasswordNew'].hasError('hasCapitalCase')" aria-hidden="true"></i>
                            <i class="fa fa-times color-red mr-2" *ngIf="changePasswordForm.controls['changePasswordNew'].hasError('hasCapitalCase')" aria-hidden="true"></i>
                            <label [ngClass]="{'color-red':changePasswordForm.controls['changePasswordNew'].hasError('hasCapitalCase'), 'color-green':!changePasswordForm.controls['changePasswordNew'].hasError('hasCapitalCase')}">An <b>uppercase</b> letter</label>
                        </div>
                        <div>
                            <i class="fa fa-check color-green" *ngIf="!changePasswordForm.controls['changePasswordNew'].hasError('hasNumber')" aria-hidden="true"></i>
                            <i class="fa fa-times color-red mr-2" *ngIf="changePasswordForm.controls['changePasswordNew'].hasError('hasNumber')" aria-hidden="true"></i>
                            <label [ngClass]="{'color-red':changePasswordForm.controls['changePasswordNew'].hasError('hasNumber'), 'color-green':!changePasswordForm.controls['changePasswordNew'].hasError('hasNumber')}">A <b>number</b></label>
                        </div>
                        <div>
                            <i class="fa fa-check color-green" *ngIf="!changePasswordForm.controls['changePasswordNew'].hasError('hasSpecialCharacters')" aria-hidden="true"></i>
                            <i class="fa fa-times color-red mr-2" *ngIf="changePasswordForm.controls['changePasswordNew'].hasError('hasSpecialCharacters')" aria-hidden="true"></i>
                            <label [ngClass]="{'color-red':changePasswordForm.controls['changePasswordNew'].hasError('hasSpecialCharacters'), 'color-green':!changePasswordForm.controls['changePasswordNew'].hasError('hasSpecialCharacters')}">A <b>special</b> character</label>
                        </div>
                        <div>
                            <i class="fa fa-check color-green" *ngIf="!changePasswordForm.controls['changePasswordNew'].hasError('minlength') && password" aria-hidden="true"></i>
                            <i class="fa fa-times color-red mr-2" *ngIf="changePasswordForm.controls['changePasswordNew'].hasError('minlength') || !password" aria-hidden="true"></i>
                            <label  [ngClass]="{'color-red':changePasswordForm.controls['changePasswordNew'].hasError('minlength') || !password, 'color-green':!changePasswordForm.controls['changePasswordNew'].hasError('minlength') && password}">Minimum <b>8 characters</b></label>
                        </div>
                    <div class="pb-5">
                        <div class="float-right">
                            <button class="btn btn-primary btn-sm rounded-pill" (click)="changePassword()"
                                 [formvalid]="changePasswordForm.valid" appButtonDisable>
                                {{reset_password_button}}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>