<div class="modal-header">
    <div class="row w-100">
        <div class="col-md-11">
            <h5 class="modal-title" *ngIf="currentPage !== 'dashboard' && currentPage !== 'profile' && currentPage !== 'documents'">Contact Us</h5>
            <h5 class="modal-title" *ngIf="currentPage == 'dashboard' || currentPage == 'profile' || currentPage == 'documents'">Contact My Advisor</h5>
        </div>
        <div class="col-md-1 pr-0">
            <button type="button" class="close float-right text-white pr-0" aria-label="Close"
                (click)="activeModal.dismiss()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
</div>
<div class="modal-body">
    <div class="font-18 pb-2" *ngIf="currentPage !== 'dashboard' && currentPage !== 'profile' && currentPage !== 'documents'">Please enter your message below. We will reply to your email shortly.</div>
    <div class="font-18 pb-2" *ngIf="currentPage == 'dashboard' || currentPage == 'profile' || currentPage == 'documents'">Please enter your message below. Whoever you select to receive the message will reply shortly.</div>
    <form [formGroup]="contactUsForm" novalidate>
        <div class="pb-4"  *ngIf="currentPage == 'dashboard' || currentPage == 'profile' || currentPage == 'documents'">
            <select  class="form-control" [(ngModel)]="contact_advisor" formControlName="contactAdvisor" >
                <option value='' selected>Select Recipient</option>
                <option *ngFor="let option of advisoryTeam" value="{{option.id}}">{{option.name}} -
                    {{option.user_type}} </option>
            </select>
        </div>
        <div class="pb-4">
            <input type="text" #subject class="form-control" placeholder="Subject" [(ngModel)]="contact_subject"
                formControlName="contactSubject" autofocus="true">
            <div *ngIf="contactUsFormSubmitted" class="text-danger" role="alert">
                <div *ngIf="formControl.contactSubject.errors?.required">Subject is mandatory.</div>
            </div>
        </div>
        <div class="pb-4">
            <textarea #message class="form-control" placeholder="Type your Message here" rows="8" columns="12"
                [(ngModel)]="contact_message" formControlName="contactMessage"></textarea>
            <div *ngIf="contactUsFormSubmitted" class="text-danger" role="alert">
                <div *ngIf="formControl.contactMessage.errors?.required">Message is mandatory.</div>
            </div>
        </div>
    </form>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-link" (click)="closeModal()" [disabled]="disableBtn">
        CANCEL
    </button>
    <button type="button" class="btn btn-primary btn-sm rounded-pill" (click)="sendMessage()" [formvalid]="contactUsForm.valid" appButtonDisable>
        Send Message
    </button>
</div>