import { Directive, Input, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import * as d3 from 'd3';
import * as _ from 'lodash';

@Directive({
	selector: '[appCashflowChart]'
})
export class CashflowChartDirective {
	@Input('appCashflowChart') appCashFlowData: any;
	width;
	height;
	x: any;
	y: any;
	svg: any;
	params: string;
	margin: any;
	subheight: any;
	@ViewChild('accordion') widthdiv: any;

	constructor(public cp: CurrencyPipe) {

	}



	ngOnChanges(changes: SimpleChanges) {
		this.generateCashFlowChart(this.appCashFlowData);
	}

	public generateCashFlowChart(data) {
		this.widthdiv = document.getElementById('accordion').offsetWidth;

		function wrap(text, width) {
			text.each(function () {
				var text = d3.select(this),
					words = text.text().split(/\s+/).reverse(),
					word,
					line = [],
					lineNumber = 0,
					lineHeight = 1.1, // ems
					x = text.attr("x"),
					y = text.attr("y"),
					dy = 0, //parseFloat(text.attr("dy")),
					tspan = text.text(null)
						.append("tspan")
						.attr("x", x)
						.attr("y", y)
						.attr("dy", dy + "em");
				while (word = words.pop()) {
					line.push(word);
					tspan.text(line.join(" "));
					if (tspan.node().getComputedTextLength() > width) {
						line.pop();
						tspan.text(line.join(" "));
						line = [word];
						tspan = text.append("tspan")
							.attr("x", x)
							.attr("y", y)
							.attr("dy", ++lineNumber * lineHeight + dy + "em")
							.text(word);
					}
				}
			});
		}

		function checkValues(start_age, end_age, name, graphStartAge) {
			if(name=='Target Savings'){
				return x(end_age) - x(start_age); 
			}
			else {
				if (end_age === graphStartAge || data.start_age === end_age) {
					end_age = end_age + 1;
				} 
				return x(end_age) - x(graphStartAge);
			}
		}
		function checkEndAge(end_age, start_age, name, graphStartAge) {
			if(name !=='Target Savings'){
				if(end_age === data.investment_horizon && end_age == graphStartAge){
					return x(graphStartAge-1);
				}else{
					return x(graphStartAge);
				}
			}
			else{
				if(end_age === data.investment_horizon && end_age == start_age){
					return x(start_age-1);
				}
				else{
					return x(start_age);
				}
			}
		}
		d3.select('#cashFlowChart1 svg').remove();
		var items = [];
		if (data.scenario_cash_flows) {

			if (data.scenario_cash_flows.length > 0) {
				for (let l = 0; l < data.scenario_cash_flows.length; l++) {
					if (data.scenario_cash_flows[l].lane === 0 || data.scenario_cash_flows[l].lane === 1 || data.scenario_cash_flows[l].end_age < data.start_age) {
						data.scenario_cash_flows.splice(l, 1);
						l--;
					}
				}
			}
			items = data.scenario_cash_flows;
			items = _.filter(items, function (val) {
				if (data.is_retired) {
					return val.end_age > data.retirement_age && val.active === true && val.end_age > data.start_age;
				} else {
					return val.active === true;
				}
			});

			var xmin = data.start_age;
			var xmax = data.investment_horizon;

			var middle = data.retirement_age;
			var years = xmax - xmin;

			if (!data.target_savings_deleted && !data.is_retired) {
				items.push(
					{
						"start_age": xmin,
						"end_age": middle,
						"years": years,
						"name": "Target Savings",
						"amount": data.target_savings_amount,
						"inflation_rate": data.target_savings_inflation_rate,
						"lane": 0,
						"delete_flag": data.target_savings_deleted
					}
				);
			}
			var isLane = _.find(items, function (val) {
				return val.lane === 0;
			});


			var lessWidth = this.widthdiv;
			let m = [20, 20, 15, 0], //top right bottom left
				h = 600 - m[0] - m[2],
				laneLength = items.length,
				miniHeight: any = laneLength * 55,
				//mainHeight = h - miniHeight - 50,
				width;
			var totalWidth = this.widthdiv - m[1] - m[3];
			
			// if (attr.isdevice === 'false') {
			width = (totalWidth * 66.66666667) / 100;
			// } else {
			//	width = totalWidth;
			//}

			//Retirement year range calculating

			var currentYear = new Date().getFullYear();
			var retirementYear = currentYear + (data.retirement_age - data.start_age);
			var endYear = currentYear + (data.investment_horizon - data.start_age);

			var textWidth = lessWidth - width;
			var nameWidth = (textWidth * 60) / 100;

			if (data.is_retired) {
				if (data.start_age > data.retirement_age) {
					middle = data.start_age;
				}
			}
			//var x = !data.is_retired ? d3.scale.linear().domain([xmin, xmax]).range([50, width - 50]) : d3.scale.linear().domain([middle, xmax]).range([50, width - 50]);
			var widthHalf = width/2;
			var x = !data.retired?d3.scale.linear().domain([xmin, middle, xmax]).range([0, widthHalf,width - 40]):d3.scale.linear().domain([middle, xmax]).range([0,width]);
			var y2 = d3.scale.linear()
				.domain([0, laneLength])
				.range([0, miniHeight + 10]);

			var max_y = y2(laneLength + 0.5);
			if (max_y > miniHeight) {
				miniHeight = max_y;
			}

			var chart = d3.select("#cashFlowChart1")
				.append("svg")
				.attr("width", lessWidth+ m[1] + m[3])
				.attr("height", miniHeight + 10)
				.attr("class", "chart");

			chart.append("defs").append("clipPath")
				.attr("id", "clip")
				.append("rect")
				.attr("width", '100%')
				.attr("height", miniHeight);
			var mini = chart.append("g");

			var tickData = [];
			if (data.is_retired) {
				if (data.start_age > data.retirement_age) {
					tickData = [data.start_age, data.investment_horizon];
				} else {
					tickData = [data.retirement_age, data.investment_horizon];
				}
			} else {
				tickData = [data.start_age, data.retirement_age, data.investment_horizon];
			}
			var xAxis = d3.svg.axis()
				.scale(x)
				.orient("bottom")
				.ticks(3)
				.tickValues(tickData)
				.tickFormat(function (d) {
					return "Age " + d;
				});

			//var heightCount = 2.5;   Add this if Target withdrawal is shown
			var heightCount = 1.5;
			if (data.target_savings_deleted || data.is_retired) {
				heightCount = heightCount - 1;
			}


			//Function to wrap the text


			//mini item rects
			mini.append("g").selectAll("miniItems")
				.data(items)
				.enter().append("rect")
				.attr("class", function (d) { return (d.class == null && d.amount >= 0) ? "miniItem0" : "miniItem1"; })
				.attr("x", function (d) { return checkEndAge(d.end_age, d.start_age, d.name, d.graph_start_timeline); })
				.attr("y", function (d) { return y2(d.lane || d.lane === 0 ? d.lane + 0.5 : (items.indexOf(d) + heightCount)) - 20; })
				.attr("width", function (d) { return checkValues(d.start_age, d.end_age, d.name, d.graph_start_timeline) })
				.attr("height", 30);
			//	if (attr.isdevice === 'false') {
			mini.append("g").selectAll(".miniLabels")
				.data(items)
				.enter().append("text")
				.text(function (d) {
					var size = (d.end_age - d.start_age) / years;
					return d.name;
				})
				.attr("x", function (d) { return width + 10; })
				.attr("y", function (d) { return y2(d.lane || d.lane === 0 ? d.lane + 0.75 - 0.1 : (items.indexOf(d) + heightCount) - 0.1); })
				.attr("dy", "0ex")
				.call(wrap, 100);

			mini.append("g").selectAll(".miniLabels")
				.data(items)
				.enter().append("text")
				.text(function (d) {
					var size = (d.end_age - d.start_age) / years;
					if (d.from_retirement || d.until_retirement || d.for_life) {
						return d.inflation_rate + '%';
					} else {
						return '';
					}
				})
				.attr("x", function (d) { return width + nameWidth + 80 })
				.attr("y", function (d) { return y2(d.lane || d.lane === 0 ? d.lane + 0.75 - 0.1 : (items.indexOf(d) + heightCount) - 0.1); })
				.attr("dy", "0ex")
				.attr("fill", "black")
				.attr("text-anchor", "end");

			let self = this;
			mini.append("g").selectAll(".miniLabels")
				.data(items)
				.enter().append("text")
				.text(function (d) {
					let amount = self.cp.transform(d.amount, 'USD', 'symbol', '1.0-0');
					return amount;
				})
				.attr("x", function (d) { return lessWidth - ((textWidth * 5) / 100); })
				.attr("y", function (d) { return y2(d.lane || d.lane === 0 ? d.lane + 0.75 - 0.1 : (items.indexOf(d) + heightCount) - 0.1); })
				.attr("dy", "0ex")
				.attr("text-anchor", "end")
				.attr("fill", function (d) { return d.amount < 0 ? "red" : "black" });

			//}
			var tickHeight = parseFloat(miniHeight) - 30;

			var tooltip = d3.select("body")
				.append("div")
				.style("background", "#0a0a0a")
				.style("box-shadow", "2px 2px 2px #888888")
				.style("padding", "6px")
				.style("font-size", "11px")
				.style("color", "#ffffff")
				.style("position", "absolute")
				.style("z-index", "10")
				.style("visibility", "hidden");

			var focus = chart.append("g")
				.style("display", "none");
			// Add the X Axis
			chart.append("g")
				.attr("class", "x axis")
				.attr("transform", "translate(0," + tickHeight + ")")
				.attr("fill", "#999")// changing axis font colour
				.call(xAxis)
				.selectAll("text")
				.style("text-anchor", "start");

			//Vertical lines
			var startY = d3.min(y2.domain()),
				endY = d3.max(y2.domain());
			var lines = [];
			if (data.is_retired && data.retirement_age < data.start_age) {
				lines = [
					{ x1: data.start_age, x2: data.start_age, y1: startY, y2: endY },
					{ x1: data.investment_horizon, x2: data.investment_horizon, y1: startY, y2: endY }
				];
			} else {
				lines = [
					{ x1: data.start_age, x2: data.start_age, y1: startY, y2: endY },
					{ x1: data.retirement_age, x2: data.retirement_age, y1: startY, y2: endY },
					{ x1: data.investment_horizon, x2: data.investment_horizon, y1: startY, y2: endY }
				];
			}

			chart.selectAll(".grid-line")
				.data(lines).enter()
				.append("line")
				.attr("x1", function (d) { return x(d.x1); })
				.attr("x2", function (d) { return x(d.x2); })
				.attr("y1", function (d) { return y2(d.y1); })
				.attr("y2", function (d) { return y2(d.y2); })
				.style("stroke", "#b3bde1")
				.style("stroke-width", 2);

			//if (attr.isdevice === 'false') {
			chart.append("text")
				.text(function (d) {
					return "Description";
				})
				.attr("x", width)
				.attr("y", 10)
				.attr("font-weight", "bold");

			chart.append("text")
				.text(function (d) {
					return "Growth";
				})
				.attr("x", width + nameWidth + 80)
				.attr("y", 10)
				.attr("font-weight", "bold")
				.attr("text-anchor", "end");

			chart.append("text")
				.text(function (d) {
					return "Amount";
				})
				.attr("x", lessWidth - ((textWidth * 5) / 100))
				.attr("y", 10)
				.attr("font-weight", "bold")
				.attr("text-anchor", "end");
			//}

		}
	}
}
