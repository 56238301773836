import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../services/common.service';
import { UtilityService } from '../services/utility.service';
import { EventEmitterService } from '../services/event-emitter.service';

@Component({
	selector: 'app-email-link',
	templateUrl: './email-link.component.html',
	styleUrls: ['./email-link.component.scss']
})
export class EmailLinkComponent implements OnInit {

	constructor(
		private activatedRoute: ActivatedRoute,
		private utilService: UtilityService,
		public router: Router,
		public commonService: CommonService,
		private eventEmitter: EventEmitterService
	) { }

	ngOnInit(): void {
		let params = this.activatedRoute.snapshot.queryParams;
		// set root firm setting based on url firm code
		if (this.activatedRoute.snapshot.paramMap.get('firmCode')) {
			this.utilService.setRootScopeFirm(this.activatedRoute.snapshot.paramMap.get('firmCode'));
		}

		if (params.rtq_id) {
			sessionStorage.setItem('login_rtq_id', params.rtq_id);
		}

		if (params.household_id) {
			sessionStorage.setItem('current_selected_household', params.household_id);
		}

		if (params.advisor_admin_user_id) {
			sessionStorage.setItem('advisor_admin_user_id', params.advisor_admin_user_id);
		}
		if(params.task_id){
			sessionStorage.setItem('task_id', params.task_id);
		}

		if (params.state === 'rtq') {
			sessionStorage.setItem('rtq_redirect', 'true');
			this.eventEmitter.broadcast("Rtqemail", true);
		} else if (params.state === 'docusign') {
			sessionStorage.setItem('docusign', 'true');
		} else if (params.state === 'onboarding_invitation') {
			sessionStorage.setItem('onboarding_invitation', 'true');
		} else if (params.state === 'identify_info_required') {
			sessionStorage.setItem('identify_info_required', 'true');
		} else if (params.state === 'request_account_info') {
			sessionStorage.setItem('request_account_info', 'true');
		}else if (params.state === 'task') {
			sessionStorage.setItem('task', 'true');
		}

		if (params.is_questionnaire_required) {
			sessionStorage.setItem('is_questionnaire_required', params.is_questionnaire_required);
		}
		if (params.email) {
			sessionStorage.setItem('is_questionnaire_required_email', params.email);
		}

		switch (params.state) {
			case 'resetPassword':
				this.router.navigateByUrl('resetPassword?token=' + params.token);
				break;

			case 'change_password':
				this.utilService.setRootScopeFirm(params.firm_short_name);
				this.router.navigateByUrl('changePassword/firm/' + params.firm_short_name + '?state=' + params.state + '&token=' + params.token);
				sessionStorage.setItem('change_pwd_redirect', 'true');
				break;

			default:
				switch (params.login_type) {
					case 'reset_password':
						this.router.navigateByUrl('resetPassword?state=' + params.state + '&token=' + params.token);
						sessionStorage.setItem('reset_pwd_redirect', 'true');
						break;

					case 'auto_login':
						if (params.state == 'rtq' || params.state == 'onboarding_invitation') {
							if (params.is_signup_complete) {
								sessionStorage.setItem('signUpComplete', params.is_signup_complete);
							}						

							if (this.commonService.getDomainDetails().firm_code) {
								this.router.navigateByUrl('/login/firm/' + this.commonService.getDomainDetails().firm_code + '?auto_login=true&state=' + params.state + '&token=' + params.token);
							} else {
								this.router.navigateByUrl('/login?auto_login=true&state=' + params.state + '&token=' + params.token);
							}
						} else {
							this.router.navigateByUrl('financial-info/risk-tolerance?auto_login=true&state=' + params.state + '&token=' + params.token);
						}
						break;

					case 'login_required':
						if (this.commonService.getDomainDetails().firm_code) {
							this.router.navigateByUrl('/login/firm/' + this.commonService.getDomainDetails().firm_code + '?state=' + params.state + '&token=' + params.token);
						} else {
							this.router.navigateByUrl('/login?state=' + params.state + '&token=' + params.token);
						}

						if (params.state == 'posted_document') {
							sessionStorage.setItem('posted_document', 'true');
						}
						break;

					case 'new_user':
						if (params.rtq_title) {
							sessionStorage.setItem('rtq_title', params.rtq_title);
						}
						this.router.navigateByUrl('financial-info/signup?state=' + params.state);
						break;

					default:
						if (this.commonService.getDomainDetails().firm_code) {
							this.router.navigateByUrl('/login/firm/' + this.commonService.getDomainDetails().firm_code + '?state=' + params.state);
						} else {
							this.router.navigateByUrl('/login?state=' + params.state);
						}
						break;
				}

		}
	}

}
