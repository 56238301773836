<app-navbar></app-navbar>
<div resize-window>
    <div class="container-fluid container-spacing">
        <div class="margin-top-90 pt-4">
            <ngb-tabset [destroyOnHide]="false">
                <ngb-tab title="Security Settings">
                    <ng-template ngbTabContent>
                        <div class="row">
                            <div class="col-md-6 col-xs-12 col-sm-12 pt-4 ">
                                <div class="font-18 m-3">
                                    <div class="font-18 mb-3 font-weight-light">Change Password
                                    </div>
                                </div>
                                <div
                                    class="card col-md-12 col-sm-12 col-xs-12  border-radius-10 border-0 shadow-sm p-2">
                                    <div class="card-body pb-1">
                                        <form [formGroup]="changePasswordForm" novalidate>
                                            <div class="pt-3 pb-3">
                                                <label class="form-label text-uppercase">Enter your current
                                                    password</label>
                                                <input name="password" type="password"
                                                    autocomplete="off" class="form-control form-input p-2" appTogglePassword
                                                    autofocus="true" (trim)="true"
                                                    [(ngModel)]="changePassword.passwordOld"
                                                    formControlName="passwordOld">
                                              
                                                <div *ngIf="changePasswordFormSubmitted" class="text-danger pt-1"
                                                    role="alert">
                                                    <div *ngIf="formControl.passwordOld.errors?.required">Please enter
                                                        the old password.</div>
                                                    <div *ngIf="formControl.passwordOld.errors?.spaceNotAllowed">
                                                        Space is not allowed.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="pt-3 pb-3">
                                                <label class="form-label text-uppercase">Enter your new password</label>
                                                <input name="password" type="password"
                                                    autocomplete="off" class="form-control form-input p-2"
                                                    autofocus="true" (trim)="true"
                                                    [(ngModel)]="changePassword.passwordNew" appTogglePassword
                                                    formControlName="passwordNew">
                                                <!-- <i class="fa fa-eye cursor-pointer" aria-hidden="true"
                                                    (click)="showPassword('newPassword')"></i> -->
                                                <div *ngIf="changePasswordFormSubmitted" class="text-danger pt-1"
                                                    role="alert">
                                                    <div *ngIf="formControl.passwordNew.errors?.required">Please enter
                                                        the new password.</div>
                                                    <div *ngIf="formControl.passwordNew.errors?.spaceNotAllowed">
                                                        Space is not allowed.
                                                    </div>
                                                </div>
                                                <div *ngIf="changePasswordFormSubmitted && invalid.length>0"
                                                    class="error-div col-md-12" role="alert">
                                                    <div *ngFor="let error of invalid" class="error-text">{{error}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="pt-3 pb-3">
                                                <label class="form-label text-uppercase">Re-enter your new password
                                                </label>
                                                <input name="password" type="password" autocomplete="off"
                                                    class="form-control form-input p-2" autofocus="true" (trim)="true" appTogglePassword
                                                    [(ngModel)]="changePassword.passwordReenter"
                                                    formControlName="passwordReenter">
                                                <div *ngIf="changePasswordFormSubmitted" class="text-danger pt-1"
                                                    role="alert">
                                                    <div *ngIf="formControl.passwordReenter.errors?.required">Please
                                                        Re-enter the new password.</div>
                                                    <div *ngIf="formControl.passwordReenter.errors?.invalidMatch">
                                                        Passwords must match
                                                    </div>
                                                    <div *ngIf="formControl.passwordReenter.errors?.spaceNotAllowed">
                                                        Space is not allowed.
                                                    </div>
                                                </div>
                                            </div>
                                            <label class="form-label text-uppercase">Password must contain the following:</label>
                                            <div>
                                                <i class="fa fa-check color-green" *ngIf="!changePasswordForm.controls['passwordNew'].hasError('hasSmallCase')" aria-hidden="true"></i>
                                                <i class="fa fa-times color-red mr-2" *ngIf="changePasswordForm.controls['passwordNew'].hasError('hasSmallCase')" aria-hidden="true"></i>
                                                <label [ngClass]="{'color-red':changePasswordForm.controls['passwordNew'].hasError('hasSmallCase'), 'color-green':!changePasswordForm.controls['passwordNew'].hasError('hasSmallCase')}">A <b>lowercase</b> letter</label>
                                            </div>
                                            <div>
                                                <i class="fa fa-check color-green" *ngIf="!changePasswordForm.controls['passwordNew'].hasError('hasCapitalCase')" aria-hidden="true"></i>
                                                <i class="fa fa-times color-red mr-2" *ngIf="changePasswordForm.controls['passwordNew'].hasError('hasCapitalCase')" aria-hidden="true"></i>
                                                <label [ngClass]="{'color-red':changePasswordForm.controls['passwordNew'].hasError('hasCapitalCase'), 'color-green':!changePasswordForm.controls['passwordNew'].hasError('hasCapitalCase')}">An <b>uppercase</b> letter</label>
                                            </div>
                                            <div>
                                                <i class="fa fa-check color-green" *ngIf="!changePasswordForm.controls['passwordNew'].hasError('hasNumber')" aria-hidden="true"></i>
                                                <i class="fa fa-times color-red mr-2" *ngIf="changePasswordForm.controls['passwordNew'].hasError('hasNumber')" aria-hidden="true"></i>
                                                <label [ngClass]="{'color-red':changePasswordForm.controls['passwordNew'].hasError('hasNumber'), 'color-green':!changePasswordForm.controls['passwordNew'].hasError('hasNumber')}">A <b>number</b></label>
                                            </div>
                                            <div>
                                                <i class="fa fa-check color-green" *ngIf="!changePasswordForm.controls['passwordNew'].hasError('hasSpecialCharacters')" aria-hidden="true"></i>
                                                <i class="fa fa-times color-red mr-2" *ngIf="changePasswordForm.controls['passwordNew'].hasError('hasSpecialCharacters')" aria-hidden="true"></i>
                                                <label [ngClass]="{'color-red':changePasswordForm.controls['passwordNew'].hasError('hasSpecialCharacters'), 'color-green':!changePasswordForm.controls['passwordNew'].hasError('hasSpecialCharacters')}">A <b>special</b> character</label>
                                            </div>
                                            <div>
                                                <i class="fa fa-check color-green" *ngIf="!changePasswordForm.controls['passwordNew'].hasError('minlength') && changePassword.passwordNew" aria-hidden="true"></i>
                                                <i class="fa fa-times color-red mr-2" *ngIf="changePasswordForm.controls['passwordNew'].hasError('minlength') || !changePassword.passwordNew" aria-hidden="true"></i>
                                                <label  [ngClass]="{'color-red':changePasswordForm.controls['passwordNew'].hasError('minlength') || !changePassword.passwordNew, 'color-green':!changePasswordForm.controls['passwordNew'].hasError('minlength') && changePassword.passwordNew}">Minimum <b>8 characters</b></label>
                                            </div>
                                            
                                            <div class="pt-2">
                                                <div class="float-left font-weight-light">
                                                    Last Changed on
                                                    {{this.currentUser.password_last_changed_at | date: 'medium'}}
                                                </div>
                                            </div>
                                            <div class="pt-5">
                                                <div class="float-right">
                                                    <button type="button" [disabled]="disable.disablePwdBtn"
                                                        class="btn-primary btn-sm rounded-pill font-14 float-left text-uppercase border-0"
                                                        (click)="changeProfilePassword()">
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 pt-4 pl-3">
                                <div class="font-18 m-3">
                                    <div class="font-18 mb-3 font-weight-light">Two-Factor Authentication

                                    </div>
                                </div>
                                <div class="card w-100 m-auto border-radius-10 border-0 shadow-sm p-2">
                                    <div class="card-body pb-1">
                                        <div *ngIf="!authyStatus.showMfaStatus && !authyStatus.enterToken">
                                            <form [formGroup]="authyDetailForm" novalidate *ngIf="!showQrCode">
                                                <div class="pt-2">
                                                    <div class="pb-3 dropdown">
                                                        <button type="button"
                                                            class="btn btn-outline-secondary dropdown-toggle rounded-pill select-wrp-country open pl-3 pr-3 font-14"
                                                            data-toggle="dropdown" id="dropdownMenuButton"
                                                            data-toggle="dropdown" aria-haspopup="true"
                                                            aria-expanded="false">
                                                            {{authyDetail.countrylabel?authyDetail.countrylabel:"Select Country"}}
                                                        </button>
                                                        <div class="dropdown-menu  select-list scroll-list"
                                                            aria-haspopup="true" aria-expanded="false">
                                                            <a class="dropdown-item cursor-pointer font-14 p-2"
                                                                *ngFor="let country of countries"
                                                                (click)="setCountry(country)">{{country.label}}</a>
                                                                {{authyDetail.countryCode}}
                                                        </div>
                                                        <div *ngIf="enableAuthyFormSubmitted && !authyDetail.countryCode"
                                                            class="text-danger pt-1" role="alert">
                                                            <div *ngIf="authyFormControl.countryCode.errors?.required">
                                                                Country code
                                                                is mandatory.
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="pt-3 pb-2">
                                                        <label class="text-uppercase" for="authy-cellphone">Phone
                                                            Number</label>
                                                        <input id="authy-cellphone" type="text" name="cellphone"
                                                            formControlName="phoneNumber" placeholder="(000)000-0000"  appPhoneMask 
                                                            class="form-control form-input p-2"
                                                            [(ngModel)]="authyDetail.cellPhone" >
                                                        <div *ngIf="enableAuthyFormSubmitted"
                                                            class="text-danger pt-1" role="alert">
                                                            <div *ngIf="authyFormControl.phoneNumber.errors?.required">
                                                                Phone number is mandatory.</div>
                                                                <div  *ngIf="authyFormControl.phoneNumber.errors?.incorrect">
                                                                 Phone number must be at least 10 characters long.</div>
                                                                
                                                               
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="pt-4">
                                                    <div class="float-left">
                                                        <a class="cursor-pointer pt-2" (click)="getQrcode()" tooltipClass="tooltipData"
                                                        ngbTooltip="Authy is an alternative Two-Factor Authentication service."
                                                        tooltip>Use Authy Instead</a>
                                                    </div>
                                                    <div class="float-right">
                                                        <button type="button"
                                                            class="btn-primary btn-sm rounded-pill font-14 float-left text-uppercase border-0"
                                                            (click)="activateMfa(true, false)"
                                                            [disabled]="disable.disableActivateBtn">
                                                            Submit
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                            <div *ngIf="showQrCode">
                                                <div class="row">
                                                    <div class="col-md-12 col-xs-12 col-sm-12 m-auto">
                                                        <div class="pb-3">
                                                            <span class="font-18 mb-3 font-weight-light">AUTHY SETUP</span>
                                                            <hr class="header-hr my-1">
                                                            <div class="text-center">
                                                                <qrcode [qrdata]="qrcode" [width]="256"></qrcode>
                                                            </div>
                                                            <hr class="header-hr my-1">
                                                            <div class="mt-3">
                                                                <b>Key</b> <br />
                                                                <span (click)="copyLinkToaster()" title="Click to copy text" ngxClipboard
                                                                    [cbContent]="secretKey">
                                                                    {{secretKey}}<i class="fa fa-clone cursor-pointer ml-3" aria-hidden="true"></i>
                                                                </span>
                                                            </div>
                                                            <hr class="header-hr my-3">
                                                            <div class="pb-5">
                                                                <div class="float-left mt-3">
                                                                    <a class="cursor-pointer" (click)="openAuthyLink()">
                                                                        DOWNLOAD AUTHY APP
                                                                    </a>
                                                                </div>
                                                                <div class="float-right">
                                                                    <button type="button"
                                                                        class="btn btn-outline-secondary font-14 float-left text-uppercase row btn-sm rounded-pill mr-1"
                                                                        (click)="hideQrcodeSection()">
                                                                        Cancel
                                                                    </button>
                                                                    <button class="btn btn-primary btn-sm rounded-pill" (click)="activateQrcodeMfa(true)" [disabled]="disable.disableActivateBtn">
                                                                        Enable
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="authyStatus.showMfaStatus">
                                            <div class="float-left font-weight-light">
                                                You have Two-Factor Authentication enabled <span *ngIf="authy_type=='sms'"> with phone number
                                               <span class="text-bold"> {{authyStatus.maskedPhoneNumber}}</span></span><span *ngIf="authy_type=='qr_code'">via the Authy app</span>
                                            </div>

                                            <div class="pt-5">
                                                <div class="float-right">
                                                    <button type="button"
                                                        class="btn-primary btn-sm rounded-pill font-14 float-left text-uppercase border-0"
                                                        [disabled]="disable.deactivatedMfabutton"
                                                        (click)="openDeactivateMFAConfirmationDialog()">
                                                        Disable
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="!authyStatus.showMfaStatus && authyStatus.enterToken">
                                            <form [formGroup]="tokenForm" novalidate>
                                                <div class="pb-2 pt-3">
                                                    Please enter the code below.
                                                </div>
                                                <div class="pb-3 pt-3">
                                                    <label class="text-uppercase">Security Code</label>
                                                    <input #myinput id="security" value='' type="text" name="security"
                                                        placeholder="" [(ngModel)]="codeEntry.phoneToken"
                                                        formControlName="security" class="form-control form-input p-2"
                                                        required />
                                                    <div *ngIf="tokenFormSubmitted" class="text-danger" role="alert">
                                                        <div *ngIf="this.tokenForm.controls.security.errors?.required">
                                                            Security Code
                                                            is mandatory.</div>
                                                    </div>
                                                </div>
                                                <div *ngIf="tokenFormSubmitted && invalid" class="text-danger"
                                                    role="alert">
                                                    <div class="error-text">{{invalid}}</div>
                                                </div>
                                                <div class="pt-3 pb-3">
                                                    <div class="input-group mb-3">
                                                        <div>
                                                            <input type="checkbox" formControlName="rememberDevice"
                                                                [(ngModel)]="codeEntry.rememberDevice"
                                                                aria-label="Checkbox for following text input">
                                                            <!-- <span class="ml-1 text-uppercase">Remember this Device </span> -->
                                                            <label class="text-uppercase ml-2">Remember this
                                                                Device</label>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div *ngIf="!showQrCode">Didn't receive a code?</div>
                                                <div class="pb-5" *ngIf="!showQrCode">
                                                    <div class="float-left">
                                                        <button type="button"
                                                            class="btn btn-link font-14 float-left text-uppercase row color-purple"
                                                            (click)="resendToken(false)">
                                                            <u>TEXT ME</u>
                                                        </button>
                                                        <button type="button"
                                                            class="btn btn-link font-14 float-left text-uppercase row color-purple ml-1"
                                                            (click)="resendToken(true)">
                                                            <u>CALL ME</u>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="pb-5">
                                                    <div class="float-left">
                                                        <!-- <button type="button"
                                                            class="btn btn-link font-14 float-left text-uppercase row color-purple"
                                                            (click)="openAuthyApp()">
                                                            <u>DOWNLOAD AUTHY APP.</u>
                                                        </button> -->
                                                    </div>
                                                    <div class="float-right">
                                                        <button class="btn btn-primary btn-sm rounded-pill border-0"
                                                            (click)="verifyToken(codeEntry.phoneToken)">
                                                            SUBMIT
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div class="clearfix"></div>
                                <div class="font-18 m-3">
                                    <div class="font-18 mb-3 font-weight-light">Authorized Devices
                                    </div>
                                </div>
                                <div class="card w-100 m-auto border-radius-10 border-0 shadow-sm p-2">
                                    <div class="card-body pb-1">
                                        <div class="pt-2">
                                            <div class="float-left font-weight-light w-100 auth-device">
                                                <table class="table w-100">
                                                    <thead>
                                                        <tr>
                                                            <th class="text-muted">Date</th>
                                                            <th class="text-muted">Device Name</th>
                                                            <th class="text-muted">IP</th>
                                                            <th class="text-muted">Remember</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let device of userDevices">
                                                            <td class="whitespace-nowrap">
                                                                {{device.last_login | date:'medium'}}
                                                            </td>
                                                            <td>{{device.user_agent}}</td>
                                                            <td>{{device.ip_address}}
                                                                <span
                                                                    *ngIf="device.api_level">({{device.api_level}})</span>
                                                            </td>
                                                            <td>{{device.remember_me}}</td>
                                                            <td><i class="fa fa-trash-o cursor-pointer"
                                                                    (click)="openDeleteDeviceConfirmationDialog(device.id)"></i>
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </ngb-tab>
                <ngb-tab>
                    <ng-template ngbTabTitle>Personal Information</ng-template>
                    <ng-template ngbTabContent>
                        <div class="row">
                            <div class="col-md-12 mt-3">
                                <div class="mt-2"><b>{{firmName}}</b></div>
                                <app-additional-information></app-additional-information>
                            </div>
                        </div>
                    </ng-template>
                </ngb-tab>
            </ngb-tabset>
        </div>
    </div>
</div>