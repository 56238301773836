import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators';


const httpOptions = {
	headers: new HttpHeaders({
		'Accept': 'application/json',
		'Content-Type': 'application/json; charset=UTF-8',
		'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
		'Cache-Control': 'no-cache',
		'Pragma': 'no-cache'
	})
};

const httpMultipartOptions = {
    headers: new HttpHeaders({
        'Accept': 'application/json',
    })
};

@Injectable({
	providedIn: 'root'
})
export class ApiService {
	access_token: string;
	fullPath: string;

	constructor(
		private http: HttpClient) {
			
	}

	public getCall(apiURl, params): Observable<any> {
		this.fullPath = apiURl + '?access_token=' + this.getToken();
		if (params !== 'undefined' && params !== undefined) {
			this.fullPath = this.fullPath + params;
		}
		return this.http
			.get(this.fullPath, httpOptions)
			.pipe(map((response: any) => {
				return response;
			}));

	}

	public getAddress(apiURl, params, searchText): Observable<any> {
		this.fullPath = apiURl + '?access_token=' + this.getToken() + '&search=' + searchText;
		if (params !== 'undefined' && params !== undefined) {
			this.fullPath = this.fullPath + params;
		}
		return this.http
			.get(this.fullPath, httpOptions)
			.pipe(map((response: any) => {
				return response;
			}));
	}

	public validateAddress(apiURl, data): Observable<any> {
		return this.http.post(apiURl + '?access_token=' + this.getToken(), data, httpOptions)
			.pipe(map((response: any) => {
				return response;
			}));
	}

	public postCall(url, data): Observable<any> {
		return this.http.post(url + '?access_token=' + this.getToken(), data, httpOptions)
			.pipe(map((response: any) => {
				return response;
			}));
	}

	public postCallQuery(url, data): Observable<any> {
        return this.http.post(url + '&access_token=' + this.getToken(), data, httpOptions)
            .pipe(map((response: any) => {
                return response;
            }));
    }



	public updateCall(apiURl, data, id = ''): Observable<any> {
		if (!id) {
			return this.http.put(apiURl + '?access_token=' + this.getToken(), data, httpOptions);
		} else {
			return this.http.put(apiURl + '&access_token=' + this.getToken(), data, httpOptions);
		}
	}

	public deleteCall(apiURl, id = ''): Observable<any> {
		if (!id) {
			return this.http.delete(apiURl + '?access_token=' + this.getToken(), httpOptions);
		} else {
			return this.http.delete(apiURl + '&access_token=' + this.getToken(), httpOptions);
		}
	}	

	public getCallWithoutToken(apiURl, params): Observable<any> {
        this.fullPath = apiURl;
        if (params !== 'undefined' && params !== undefined) {
            this.fullPath = this.fullPath + params;
        }
        return this.http
            .get(this.fullPath, httpOptions)
            .pipe(map((response: any) => {
                return response;
            }));

    }

	public getToken(): any {
        return sessionStorage.getItem('token');
	}

	public postMultipartFormCall(url, formData,params=''): Observable<any> {
        if (params) {
            return this.http.post(url + '&access_token=' + this.getToken(), formData, httpMultipartOptions)
            .pipe(map((response: any) => {
                return response;
            }));
        }else{
            return this.http.post(url + '?access_token=' + this.getToken(), formData, httpMultipartOptions)
                .pipe(map((response: any) => {
                    return response;
                }));
        }
    }
	public updateMultipartFormCall(url, formData,params=''): Observable<any> {
        if (params) {
            return this.http.put(url + '&access_token=' + this.getToken(), formData, httpMultipartOptions)
            .pipe(map((response: any) => {
                return response;
            }));
        }else{
            return this.http.put(url + '?access_token=' + this.getToken(), formData, httpMultipartOptions)
                .pipe(map((response: any) => {
                    return response;
                }));
        }
    }

	public externalGetCall(apiURl, params): Observable<any> {
		this.fullPath = apiURl;
		if (params !== 'undefined' && params !== undefined) {
			this.fullPath = this.fullPath + params;
		}
		return this.http
			.get(this.fullPath, httpOptions)
			.pipe(map((response: any) => {
				return response;
			}));
	}

	public externalPostCallQuery(url, data): Observable<any> {
        return this.http.post(url, data, httpOptions)
            .pipe(map((response: any) => {
                return response;
            }));
    }
}
