import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppRestEndPoint } from 'src/app/app-restEndPoint';
import { ToastService } from 'src/app/components/toaster/toast-service';
import { ApiService } from 'src/app/services/api.service';
import { UtilityService } from 'src/app/services/utility.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-send-files-modal',
	templateUrl: './send-files-modal.component.html',
	styleUrls: ['./send-files-modal.component.scss']
})
export class SendFilesModalComponent implements OnInit {

	@Input() advisorData;
	@Input() advisoryTeam;
	@ViewChild("filesubject") filesubject: ElementRef;
	sendFilesForm: FormGroup;
	sendFilesFormSubmitted: boolean = false;
	disableBtn: boolean = false;
	showError: boolean = false;
	files_message: any;
	files_subject: any;
	files: any = [];
	currentUser: any;
	contact_advisor:any = '';
	
	constructor(
		private formBuilder: FormBuilder,
		private apiService: ApiService,
		private toastService: ToastService,
		public activeModal: NgbActiveModal,
		public utilityService: UtilityService
	) { 
		this.sendFilesForm = this.formBuilder.group({
			filesSubject: ['', Validators.required],
			filesMessage: ['', Validators.required],
			contactAdvisor: ['']
		});
	}

	ngOnInit(): void {
		this.currentUser = JSON.parse(sessionStorage.getItem('current_user'));
	}

	ngAfterViewInit(){
		this.filesubject.nativeElement.focus();
	}

	onFileDropped(files) {
		for (const item of files) {
			this.files.push(item);
		}
	}

	removeFiles(index) {
		this.files.splice(index, 1);
	}

	getFileDetails(e) {
		let files = e.target.files;
		for (let i = 0; i < files.length; i++) {
			this.files.push(files[i]);
		}
		this.files = this.utilityService.unique(this.files);
	}

	saveFiles(element){
		let files = [];
		this.sendFilesFormSubmitted = true;
		if (this.sendFilesForm.valid) {
			if (element.files[0]) {
				for (var i in element.files) {
					files[i] = element.files[i];
				}
				var reqObj = {
					"household_id": this.currentUser.selected_household_id,
					"subject": this.files_subject,
					"description": this.files_message,
					"document_template_id": environment.statement_document_id,
					"files": files
				};
				this.disableBtn = true;
				
				this.apiService.postMultipartFormCall(AppRestEndPoint.DASHBOARD.SEND_FILES + '.json', this.formatData(reqObj)).subscribe(data => {
					if (data.success == true) {
						this.disableBtn = false;
						this.toastService.show("Message sent.", {
							classname: 'bg-success text-light', delay: 3000
						});
						this.activeModal.close();
					} else {
						this.toastService.show('Error in Sending Files.', {
							classname: 'bg-danger text-light', delay: 3000
						});
						this.activeModal.close();
					}
				});
			} else {
				this.showError = true;
			}
		}
	}

	formatData(data) {
		const formData = new FormData();
		formData.append('household_id', data.household_id);
		formData.append('subject', data.subject);
		formData.append('description', data.description);
		formData.append('document_template_id', data.document_template_id);
		if(this.contact_advisor){
			formData.append('admin_user_id', this.contact_advisor);
		}
		if (data.files.length > 0) {
			for (let i = 0; i < data.files.length; i++) {
				formData.append('files[' + i + ']', data.files[i]);
			}
		}
		return formData;
	}

	closeModal(){
		this.activeModal.close();
	}

	get formControl() {
		return this.sendFilesForm.controls;
	}

}
