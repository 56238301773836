import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppRestEndPoint } from 'src/app/app-restEndPoint';
import { ToastService } from 'src/app/components/toaster/toast-service';
import { ApiService } from 'src/app/services/api.service';
import { ConfirmationDialogService } from 'src/app/services/confirmation-dialog.service';
import * as _ from 'lodash';
import { BankStatusModalComponent } from '../../bank-status-modal/bank-status-modal.component';


@Component({
	selector: 'app-relink-account-modal',
	templateUrl: './relink-account-modal.component.html',
	styleUrls: ['./relink-account-modal.component.scss']
})
export class RelinkAccountModalComponent implements OnInit {
	@Input() clickedIndex
	@Input() editLinkBank
	@Input() bank;
	@Input() disableAddAccount;
	@Input() linkedBanks;

	fastLinkToken: any;
	rsessionToken: any;
	fastlinkUrl: any;
	callBackUrl: any;
	loadRefreshAll: any;
	hardRefreshFlag: boolean;	
	clickedIndexCopy: any;
	


	constructor(public activeModal: NgbActiveModal,
		private apiService: ApiService,		
		private confirmationDialogService: ConfirmationDialogService,
		private toastService: ToastService,
		private modalService: NgbModal

		) { }

	ngOnInit(): void {
	}

	closeModal() {
		this.activeModal.close();
	}

	fixBank(fixBank, type:any='') {		
        this.activeModal.close({bank: fixBank, type:type});
    }
	

	showBankStatus(bankStatus){
		const bankStatusModalRef = this.modalService.open(BankStatusModalComponent, { windowClass: 'modal-width-650', backdrop: 'static' });
		bankStatusModalRef.componentInstance.statusDetails = bankStatus;			
		bankStatusModalRef.result.then((data) => {		
			
		});
	}

	getSelectedLinkedAccount(bank){
		this.confirmationDialogService.confirm('CONFIRM', 'Are you sure you want to delete account '+bank.description+' ?')
			.then((confirmed) => {
				if (confirmed) {				
					this.removeLinkedBank(bank);
				}
			})
			.catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
	}

	removeLinkedBank(selectedLinkedBank) {
        const url = AppRestEndPoint.DASHBOARD.REMOVE_BANK + '/' + selectedLinkedBank.id;
        this.apiService.deleteCall(url).subscribe(data => {
			if (data) {
				this.toastService.show('Account Deleted Successfully.', {
					classname: 'bg-success text-light', delay: 3000
				});				
            _.remove(this.linkedBanks, function (item: any) {
                return item.id == selectedLinkedBank.id;
            });
			this.closeModal();
		}
        });
    }


	hardRefreshLinkedBank(){
		this.loadRefreshAll=true; 
		this.apiService.getCall(AppRestEndPoint.DASHBOARD.REFRESH_ALL, '').subscribe(data => {		
			if (data) {        
			}
		});		
	}


}
