import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { EventEmitterService } from 'src/app/services/event-emitter.service';
import { UtilityService } from 'src/app/services/utility.service';
import { HttpParams } from '@angular/common/http';

@Component({
	selector: 'app-logon',
	templateUrl: './logon.component.html',
	styleUrls: ['./logon.component.scss']
})
export class LogonComponent implements OnInit {

	constructor(
		public router: Router,
		private activatedRoute: ActivatedRoute,
		private utilService: UtilityService,
		private commonService: CommonService,
		private eventEmitter: EventEmitterService
	
	) { }

	ngOnInit(): void {
		if(this.activatedRoute.snapshot.queryParams.data){
			let path = this.activatedRoute.snapshot.queryParams.data;
			let decodedUrl = decodeURIComponent(path);
			try {
                var urlData = JSON.parse(decodedUrl);
                let user = JSON.stringify(urlData.user);
                sessionStorage.setItem('token', urlData.access_token.token);
                sessionStorage.setItem('current_user', user);
                sessionStorage.setItem('environment', urlData.environment);
                sessionStorage.setItem('knowledge_base_url', urlData.user.firm.is_knowledge_base_url_present);

                if(urlData.user.firm.short_name){
					this.utilService.setRootScopeFirm(urlData.user.firm.short_name);
                }
                if(urlData.access_token.session_sleep_time){                    
					let idleTimeoutTimer;
					idleTimeoutTimer = parseInt(urlData.access_token.session_sleep_time) * 60000;
					sessionStorage.setItem('idleTimeoutTimer', idleTimeoutTimer);
					this.commonService.setIdleTimeout();
                }
        
				this.router.navigate(['/dashboard']); 

				
				setTimeout(() => {
					this.utilService.gtmIdentify(urlData.user.id,{});   
					this.utilService.gtmProfile({
						'userEmail' : urlData.user.email,
						'userFirmId': urlData.user.firm.id,
						'userName' :  urlData.user.name,
						'userId' : urlData.user.id,
						'userFirm' :  urlData.user.firm.description                           
					});    
					this.utilService.gtmPush({'event': 'Login Success', 'method': 'username_password'});
                    this.utilService.gtmPush({'event': 'Login Redirected to Home Page'})   

				}, 100);    
				
            }catch (e) {
				this.redirectToLogin();
            }
		}else{
			this.redirectToLogin();
		}
	}

	redirectToLogin(){
		if (this.commonService.getDomainDetails().firm_id) {
			if (this.commonService.getDomainDetails().firm_code) {
				this.router.navigate(['/login/firm/'+this.commonService.getDomainDetails().firm_code]);
			}else{
				this.router.navigate(['/login']);
			}
		}else{
			this.eventEmitter.subscribe("Domain", (domainObj) => { 
				if (domainObj && domainObj.firm_code){
					this.router.navigate(['/login/firm/'+domainObj.firm_code]);
				}else{
					this.router.navigate(['/login']);
				}
			});
		}
	}

}
