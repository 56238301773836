import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppRestEndPoint } from 'src/app/app-restEndPoint';
import { ApiService } from 'src/app/services/api.service';
import { TaskModalComponent } from './task-modal/task-modal.component';
import { EventEmitterService } from 'src/app/services/event-emitter.service';
import { TaskNotesModalComponent } from './task-notes-modal/task-notes-modal.component';
import * as _ from 'lodash';

@Component({
	selector: 'app-tasks',
	templateUrl: './tasks.component.html',
	styleUrls: ['./tasks.component.scss']
})
export class TasksComponent implements OnInit {
	taskList: any=[];
	taskData: any={};
	emailTaskId = '';

	constructor(
		private apiService: ApiService,
		private modalService: NgbModal,
		public eventEmitter: EventEmitterService
	) { }

	ngOnInit(): void {
		if (sessionStorage.getItem('task')){
			this.emailTaskId = sessionStorage.getItem('task_id');
		}
		this.loadTasks();
	}

	setTaskPendingCount(data){
		this.eventEmitter.broadcast("unreadTaskCount", data.unread_task_count);
		sessionStorage.setItem('unread_task_count', data.unread_task_count);
	}

	loadTasks() {
		let url = AppRestEndPoint.DASHBOARD.TASKS;
		this.apiService.getCall(url, '').subscribe(data => {
			this.taskList = data.tasks;
			this.setTaskPendingCount(data);
			if(this.emailTaskId){
				let selectedTaskIndex = _.findIndex(this.taskList, function(item){
					return item.id == this.emailTaskId;
				}.bind(this));
				let selectedTaskData = _.find(this.taskList, function(item){
					return item.id == this.emailTaskId;
				}.bind(this));
				if(selectedTaskData){
					this.openTaskModal(selectedTaskData,selectedTaskIndex);
					sessionStorage.removeItem('task_id');
				}
			}
		});
	}

	openTaskModal(taskData, index) {
		let url = AppRestEndPoint.DASHBOARD.TASKS + '/' + taskData.id;
		this.apiService.getCall(url, '').subscribe(response => {
			const openTaskModalRef = this.modalService.open(TaskModalComponent, { windowClass: 'modal-width-850', backdrop: 'static' });
			openTaskModalRef.componentInstance.taskData = response.task;
			openTaskModalRef.result.then((data) => {
				if (data) {
					if (data.task_status) {
						this.taskList[index].status = data.task_status;
					}
					if (data.unread_task_count) {
						this.setTaskPendingCount(data);
					}
				}
			});
		});
	}

}
