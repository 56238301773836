import { Component, OnInit } from '@angular/core';
import { AppRestEndPoint } from 'src/app/app-restEndPoint';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';

@Component({
	selector: 'app-advisor-contact',
	templateUrl: './advisor-contact.component.html',
	styleUrls: ['./advisor-contact.component.scss']
})
export class AdvisorContactComponent implements OnInit {
	signupInstitution: any;
	advisor: any = {};
	currentPage: any;
	selectedType: any;
	currentUser: any;

	constructor(
		private apiService: ApiService,
		private router: Router,
		public commonService: CommonService,
		private activatedRoute: ActivatedRoute
	) { }

	ngOnInit(): void {
		if (sessionStorage.getItem('signupInstitution')) {
			this.signupInstitution = true;
			sessionStorage.removeItem('signupInstitution');
		} else {
			this.signupInstitution = false;
		}
		this.selectedType = this.activatedRoute.snapshot.paramMap.get('type');
		this.currentUser = JSON.parse(sessionStorage.getItem('current_user'));
		let pageUrl = this.router.url.split('/');
		this.currentPage = pageUrl[pageUrl.length - 1];
		if (this.currentPage.includes("?")) {
			this.currentPage = this.currentPage.split('?')[0];
		}
		if (this.currentPage == 'advisor-contact') {
			this.getFamilyInfo();
			var reqObj;
			reqObj = {
				'user': { "gettingstarted_step": '{"info_type" :"' + this.selectedType + '","state" :"advisorContact"}' }
			};
			reqObj.user.signup_complete = true;
			let url = AppRestEndPoint.LOGIN.SAVE_STATE + '/' + this.currentUser.id;
			this.apiService.updateCall(url, reqObj, '').subscribe(data => {
			});
			//sendCompletedEmail();

		}
	}

	getFamilyInfo() {
		let url = AppRestEndPoint.BASIC_INFO.FAMILY_INFO, param = '';
		this.apiService.getCall(url, param).subscribe(data => {
			if (data) {
				let familyData = data.household_users.household;
				this.advisor = {
					fullname: familyData.advisor_user_attributes.admin_user_fullname,
					email: familyData.advisor_user_attributes.admin_user_email
				};
			}
		});
	}

	gotoLogin() {
		if (this.commonService.getDomainDetails().firm_code) {
			this.router.navigate(['/login/firm/' + this.commonService.getDomainDetails().firm_code]);
		} else {
			this.router.navigate(['/login']);
		}
	};

	logOut() {
		this.commonService.logOut();
	};

}
