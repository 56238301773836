<div class="modal-header">
    <div class="row w-100">
        <div class="col-md-11">
            <h5 class="modal-title">Income and Expenses</h5>
        </div>
        <div class="col-md-1 pr-0">
            <button type="button" class="close float-right text-white pr-0" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
</div>
<div class="modal-body">
    <form [formGroup]="incomeForm" novalidate>
        <table class="incometable">
            <thead>
                <tr>
                    <th>Income</th>
                    <th>Annual Gross</th>
                    <th>Monthly After-Tax</th>
                    <th></th>
                </tr>
            </thead>
            <tbody formArrayName="incomeArr">
                <ng-container *ngFor="let income of incomeExpenseData.monthly_income.income let i=index" [formGroupName]="i">
                    <tr *ngIf="!income._destroy">
                        <td width="40%">
                            <input type="text" class="form-control" name="description" formControlName="description"
                                [(ngModel)]="income.description" />
                        </td>
                        <td>
                            <input type="text" class="form-control" formControlName="annual_amount"
                                [(ngModel)]="income.annual_amount" appFormatter="currency" />
                            <div *ngIf="incomeFormSubmitted" class="text-danger" role="alert">
                                <div *ngIf="formControlIncomeArr[i].controls.annual_amount.errors?.required"> Please enter Annual Gross.</div>
                            </div>
                        </td>
                        <td>
                            <input type="text" class="form-control" formControlName="amount"
                                [(ngModel)]="income.amount" appFormatter="currency" />
                            <div *ngIf="incomeFormSubmitted" class="text-danger" role="alert">
                                <div *ngIf="formControlIncomeArr[i].controls.amount.errors?.required"> Please enter Monthly After-Tax.</div>
                            </div>
                        </td>
                        <td>
                            <i class="fa fa-times crossIcon pl-1 cursor-pointer text-danger" aria-hidden="true"
                                (click)="deleteSelected('income',i)"></i>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
            <tfoot>
                <tr>
                    <td>
                        <a class="cursor-pointer" (click)="addRow('income')">Add </a>
                    </td>
                </tr>
            </tfoot>
        </table>
        <table class="incometable mt-2">
            <thead>
                <tr>
                    <th>Monthly Expense</th>
                    <th>Monthly Amount</th>
                    <th></th>
                </tr>
            </thead>
            <tbody formArrayName="expenseArr">
                <ng-container *ngFor="let expense of incomeExpenseData.monthly_expense.expense let i=index" [formGroupName]="i">
                    <tr *ngIf="!expense._destroy">
                        <td>
                            <input type="text" class="form-control" name="expense_description" formControlName="expense_description"
                                [(ngModel)]="expense.description" />
                        </td>
                        <td>
                            <input type="text" class="form-control" formControlName="expense_amount"
                                [(ngModel)]="expense.amount" appFormatter="currency" />
                            <div *ngIf="incomeFormSubmitted" class="text-danger" role="alert">
                                <div *ngIf="formControlExpenseArr[i].controls.expense_amount.errors?.required"> Please enter Monthly Expense Amount.</div>
                            </div>
                        </td>
                        <td>
                            <i class="fa fa-times crossIcon pl-1 cursor-pointer text-danger" aria-hidden="true"
                                (click)="deleteSelected('expense',i)"></i>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
            <tfoot>
                <tr>
                    <td>
                        <a class="cursor-pointer" (click)="addRow('expense')">Add </a>
                    </td>
                </tr>
            </tfoot>
        </table>
    </form>
</div>


<div class="modal-footer">
    <button type="button" class="btn btn-link" (click)="closeModal()">
        CANCEL
    </button>
    <button type="button" class="btn btn-primary btn-sm rounded-pill" (click)="saveIncomeExpense()" [disabled]="disableBtn">
        Save
    </button>
</div>