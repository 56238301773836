import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'phoneFormatter'
})
export class PhoneFormatterPipe implements PipeTransform {

	transform(input: any) {
		if (input != null && input != '') {
			let newVal = input.toString().replace(/\D/g, '');
			if (newVal.length === 0) {
				newVal = '';
			} else if (newVal.length <= 3) {
				newVal = newVal.replace(/^(\d{0,3})/, '($1');
			} else if (newVal.length <= 6) {
				newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
			} else if (newVal.length <= 10) {
				newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
			} else {
				newVal = newVal.substring(0, 13);
				newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,4})/, '($1) $2-$3x$4');
			}
			return newVal;
		} else {
			return "";
		}

	}

}
