import { Component, OnInit } from '@angular/core';
import { AppRestEndPoint } from 'src/app/app-restEndPoint';
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment';
import { ToastService } from 'src/app/components/toaster/toast-service';
import { PusherService } from 'src/app/services/pusher.service';
import { ActivatedRoute } from '@angular/router';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
	selector: 'app-docusign-faa',
	templateUrl: './docusign-faa.component.html',
	styleUrls: ['./docusign-faa.component.scss']
})
export class DocusignFaaComponent implements OnInit {

	documentData: any = [];
	currentUser: any = {};
	selectedType
	constructor(
		private apiService: ApiService,
		private toastService: ToastService,
		private pusherService: PusherService,
		private activatedRoute: ActivatedRoute,
		private navigationService: NavigationService
	) { }

	ngOnInit(): void {
		this.currentUser = JSON.parse(sessionStorage.getItem('current_user'));
		this.selectedType = this.activatedRoute.snapshot.paramMap.get('type');
		console.log(this.selectedType);
		this.bindPusher();
		this.loadDocuments();
	}

	loadDocuments() {
		let reqObj = {
			"user_document_type_id": environment.docusign_user_document_type_id
		};

		let url = AppRestEndPoint.DOCUSIGN.DOCUMENT_LIST;
		this.apiService.getCall(url, "&user_document_type_id=" + environment.docusign_user_document_type_id + "&scope=client_visible").subscribe(data => {
			if (data && data.user_documents) {
				this.documentData = data.user_documents;
			}
		});

	}

	signDocument(docId) {
		let url = AppRestEndPoint.DOCUSIGN.DOCUMENT_LIST + "/" + docId + "/signing_url";
		this.apiService.getCall(url, "&scope=client_visible").subscribe(data => {
			if (data && data.url) {
				window.open(data.url, '_blank');
			} else if (data && !data.status) {
				this.toastService.show(data.message, {
					classname: 'bg-danger text-light', delay: 3000
				});
			}
		});

	};


	saveState() {
		var reqObj = {
			'user': { "gettingstarted_step": '{"info_type" :"' + this.selectedType + '","state" :"personal"}' }
		};
		let url = AppRestEndPoint.LOGIN.SAVE_STATE + '/' + this.currentUser.id;
		this.apiService.updateCall(url, reqObj, '').subscribe(data => {
		});
	}



	bindPusher() {
		let household_id = this.currentUser.selected_household_id;
		const self = this;

		this.pusherService.subscribeDocusign(household_id);
		this.pusherService.bind('update_documents', function (data) {
			console.log(new Date().getTime(), 'update_documents', data);
			self.loadDocuments();
		});

	}



	gotoNextState() {
		var reqObj = { "info_type": this.selectedType, "state": "docusign" };
		this.navigationService.getNextPage(reqObj);
	}

}
