import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';

@Component({
	selector: 'app-buttonbar',
	templateUrl: './buttonbar.component.html',
	styleUrls: ['./buttonbar.component.scss']
})
export class ButtonbarComponent implements OnInit {
	@Output() clickedprev = new EventEmitter<boolean>();
	@Output() clickednext = new EventEmitter<boolean>();
	@Output() clickeddone = new EventEmitter<boolean>();
	@Output() clickedstop = new EventEmitter<boolean>();
	@Output() clickedupload = new EventEmitter<boolean>();
	@Input() hidenextbtn: Boolean;
	@Input() hidebackbtn: Boolean;
	@Input() showdonebtn: Boolean;
	@Input() showstopbtn: Boolean;
	@Input() showuploadbtn: Boolean;

	constructor() { }

	ngOnInit(): void {
	}

	handleprevpage(){
		this.clickedprev.emit(true);
	}

	handlenextpage(){
		this.clickednext.emit(true);
	}

	handledone(){
		this.clickeddone.emit(true);
	}

	handlestoppage(){
		this.clickedstop.emit(true);
	}

	handleupload(){
		this.clickedupload.emit(true);
	}

}
