<app-bread-crumb-options *ngIf="!isDocusignBreadCrumb"></app-bread-crumb-options>
<app-breadcrumb-options-docu-sign *ngIf="isDocusignBreadCrumb"></app-breadcrumb-options-docu-sign>
<div class="container">
    <div class="panel panel-default mt-5">
        <div class="panel-body">
            <div class="ques-block-row">
                <div class="ques-block-inr w-100 d-lg-flex d-md-flex  text-muted">
                    <div class="des">
                        ASSETS
                        <span class="cur pt-2">{{netWorth.totalAssets | currency:'USD':'symbol':'1.0-0'}}</span>
                    </div>
                    <div class="des">
                        LIABILITIES
                        <span class="cur pt-2">{{netWorth.totalLiabilities | currency:'USD':'symbol':'1.0-0'}}</span>
                    </div>
                    <div class="des">
                        NET WORTH
                        <span class="cur pt-2">{{netWorth.netWorth | currency:'USD':'symbol':'1.0-0'}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="panel-heading pt-4">
            <h2 class="nohr">Other Assets & Liabilities</h2>
        </div>

    </div>
    <!-- <form> -->
    <div class="mt-4 ques-box w-35 m-auto" *ngIf="isShowOptions">
        <div class="assets-div cursor-pointer " (click)="goToAddAssets('House')">
            <i class="fa fa-plus-square-o fa-2x grey-icon"></i>
            <span class="assets-text-div">Real Estate </span>
        </div>
        <br />
        <div class="assets-div cursor-pointer asset-div-margin" (click)="goToAddAssets('Car')">
            <i class="fa fa-plus-square-o fa-2x grey-icon"></i>
            <span class="assets-text-div">Automobile </span>
        </div>
        <br />
        <div class="assets-div cursor-pointer asset-div-margin" (click)="goToAddAssets('Other Asset')">
            <i class="fa fa-plus-square-o fa-2x grey-icon"></i>
            <span class="assets-text-div">Other Assets </span>
        </div>
        <br />
        <div class="assets-div cursor-pointer asset-div-margin" (click)="goToAddAssets('Other Liability')">
            <i class="fa fa-plus-square-o fa-2x grey-icon"></i>
            <span class="assets-text-div">Other Liabilities </span>
        </div>
    </div>
    <div class="row mt-4 ques-box" *ngIf="!showAddAsset && !isShowOptions">

        <div class="col-md-6 col-sm-6 col-xs-6" *ngIf="!showAddAsset && !isShowOptions">
            <button class="submit btn btn-default btn-lg font-14 pull-left" (click)="showAddAsset=true"><i
                    class="fa fa-plus color-purple"></i>&nbsp; Add Another Asset or Liability</button>
        </div>
        <div class="clear"></div>
        <div class="link-block w-100 pt-3">
            <div class="link-row" *ngFor="let acc of assetsList | keyvalue">
                <div class="link-mast col-md-7 font-15 cursor-pointer" *ngIf="!acc.nameEdit" (click)="acc.nameEdit=true">
                    <span class="font-12 color-999">{{acc.value.bank_name}}</span>
                    <div>{{acc.value.description}}</div>
                </div>
                <div class="link-mast col-md-7" *ngIf="acc.nameEdit">
                    <span class="color-999 font-12">{{acc.value.bank_name}}</span>
                    <input type="text" name="assetName" class="form-control" [(ngModel)]="acc.value.description"
                        placeholder="Account name" (blur)="updateAccount(acc.value);acc.nameEdit = false">
                </div>
                <div class="val col-md-4" *ngIf="!acc.balanceEdit" (click)="acc.balanceEdit = true">
                    {{acc.value.position_balance_net}}
                </div>
                <div class="val col-md-4" *ngIf="acc.balanceEdit">
                    <input type="text" name="assetBalance" class="form-control"
                        [(ngModel)]="acc.value.position_balance_net" placeholder="Balance"
                        (blur)="updateAccount(acc.value);acc.balanceEdit = false">
                </div>
                <div class="col-md-1">
                    <i class="fa fa-trash-o cursor-pointer" (click)="getSelectedAccLiability(acc)"></i>
                </div>
            </div>

        </div>
    </div>
    <div class="add-acc-box" *ngIf="showAddAsset">
        <div class="tab-form-inr clearfix mb-5">
            <div class="form-label form-css-label1">
                <fieldset class="form-group">
                    <label class="select-label">Asset Name</label>
                    <div class="option-select">
                        <div class="dropdown select-wrp">
                            <input type="hidden" [(ngModel)]="otherAsset.id">
                            <button type="button"
                                class="btn btn-outline-secondary dropdown-toggle rounded-pill border-0  open pl-3 pr-3 font-14  w-100 text-align-left"
                                data-toggle="dropdown" id="dropdownMenuButton"
                                aria-haspopup="true" aria-expanded="false">
                                <span *ngIf="otherAsset.name">{{otherAsset.name}}</span>
                                <span *ngIf="!otherAsset.name">Select
                                    Asset Type</span>
                            </button>
                            <div class="dropdown-menu select-list scroll-list">
                                <div *ngFor="let assetType of  assetTypes | keyvalue;let i=index">
                                    <a class="dropdown-header font-16 font-weight-700 color-555">{{assetType.key}}</a>
                                    <a class="dropdown-item" *ngFor="let option of assetType.value"
                                        (click)="getSelectedAsset(option)">
                                        {{option.name}}
                                    </a><br />
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
                <fieldset class="form-group">
                    <label for="assetValue">Current Value</label>
                    <input id="assetValue" name="assetValue" type="text" [(ngModel)]="assetValue" class="form-control"
                        format="currency" placeholder="$0">
                </fieldset>
            </div>
        </div>
        <div class="text-center mb-4 mt-3">
            <button class="btn btn-primary-outline btn-md rounded-pill font-14 pl-4 pr-4 btn-color"
                (click)="createOtherAssets()" [disabled]="showEditPosition">
                Add
            </button>
            <button class="btn btn-primary-outline btn-md rounded-pill ml-3 font-14 pl-4 pr-4 btn-color"
                (click)="changeView()">
                Cancel
            </button>
        </div>
    </div>
    <div class="pt-5">
        <app-buttonbar (clickedprev)="gotoPreviousState()" (clickednext)="gotoNextState()"></app-buttonbar>
    </div>
</div>