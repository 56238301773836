import { Injectable } from '@angular/core';
import * as Pusher from 'pusher-js';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class PusherService {

	pusherObj: any;
	pusherDeactivateObj: any;

	constructor() {
		this.pusherObj = new Pusher(environment.pusher_app_key, { cluster: environment.pusher_app_cluster, encrypted: true });
		this.pusherDeactivate = new Pusher(environment.pusher_app_key + "xx", { cluster: environment.pusher_app_cluster, encrypted: true });

	}

	pusher = function () {
		return this.pusherObj;
	}

	pusherDeactivate = function () {
		console.log('Calling the deactivated pusher' + this.pusherDeactivateObj);
		return this.pusherDeactivateObj;
	}

	bind = function (channel, func) {
		console.log('Calling the original pusher' + this.pusherObj);
		this.pusherObj.unbind(channel);
		this.pusherObj.bind(channel, func);
	}


	subscribeAuthyServer = function (authyId) {
		this.unsub(environment.environment + "_user_" + authyId);
	}

	subscribeBankRefresh = function (household_id) {
		this.unsub(household_id);
	}

	subscribeDocusign = function (household_id) {
		this.unsub(environment.environment + "_household_" + household_id);
	}


	unsub = function (channel) {
		this.pusherObj.unsubscribe(channel);
		this.pusherObj.subscribe(channel);
	}
}
