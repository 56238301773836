import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SharedModule } from '../shared/shared.module';
import { FusionChartsModule } from "angular-fusioncharts";

// Import FusionCharts library and chart modules
import * as FusionCharts from "fusioncharts";
import * as charts from "fusioncharts/fusioncharts.charts";
import * as FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import { CashflowChartDirective } from '../directives/cashflow-chart/cashflow-chart.directive';
import { DocumentsComponent } from './documents/documents.component';
import { NgbTabsetModule, NgbTooltipModule, NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DropfileDirective } from '../directives/dropfile/dropfile.directive';
import { LinkAccountModalComponent } from './link-account-modal/link-account-modal.component';
import { ProfileComponent } from './profile/profile.component';
import { SendFilesModalComponent } from './send-files-modal/send-files-modal.component';
import { UploadDocumentModalComponent } from './upload-document-modal/upload-document-modal.component';
import { AddAccountModalComponent } from './add-account-modal/add-account-modal.component';
import { AddPositionModalComponent } from './add-position-modal/add-position-modal.component';
import { RemoveAccountModalComponent } from './remove-account-modal/remove-account-modal.component';
import { PlaidComponent } from './plaid/plaid/plaid.component';
import { RelinkAccountModalComponent } from './relink-account-modal/relink-account-modal/relink-account-modal.component';
import { BankStatusModalComponent } from './bank-status-modal/bank-status-modal.component';
import { ScheduleOnceMeetingModalComponent } from './schedule-once-meeting-modal/schedule-once-meeting-modal/schedule-once-meeting-modal.component';
import { ScheduleMeetingModalComponent } from './schedule-meeting-modal/schedule-meeting-modal.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { QuovoAccountModalComponent } from './quovo-account-modal/quovo-account-modal.component';
import { ScenarioAccountAndGroupModalComponent } from './scenario-account-and-group-modal/scenario-account-and-group-modal.component';
import { LinkExternalAccountModalComponent } from './link-external-account-modal/link-external-account-modal.component';
import { IncomeExpenseModalComponent } from './income-expense-modal/income-expense-modal.component';
import { TasksComponent } from './tasks/tasks.component';
import { TaskModalComponent } from './tasks/task-modal/task-modal.component';
import { TreeViewComponent } from '../components/tree-view/tree-view.component';
import { TaskNotesModalComponent } from './tasks/task-notes-modal/task-notes-modal.component';
import { ClipboardModule } from 'ngx-clipboard';
import { QRCodeModule } from 'angularx-qrcode';
import { environment } from 'src/environments/environment';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';



FusionChartsModule.fcRoot(FusionCharts, charts, FusionTheme);
FusionCharts.options['license']({
	key: environment.fusion_chart_key,
	creditLabel: false,
});

@NgModule({
	declarations: [
		CashflowChartDirective,
		DropfileDirective,
		DashboardComponent,
		DocumentsComponent,
		ProfileComponent,
		ScenarioAccountAndGroupModalComponent,
		SendFilesModalComponent,
		UploadDocumentModalComponent,
		AddAccountModalComponent,
		AddPositionModalComponent,
		RemoveAccountModalComponent,
		PlaidComponent,
		RelinkAccountModalComponent,
		BankStatusModalComponent,
		ScheduleOnceMeetingModalComponent,
		ScheduleMeetingModalComponent,
		QuovoAccountModalComponent,
		ScheduleMeetingModalComponent,
		LinkExternalAccountModalComponent,
		IncomeExpenseModalComponent,
		TasksComponent,
		TaskModalComponent,
		TreeViewComponent,
		TaskNotesModalComponent,
		LinkAccountModalComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		NgbModule,
		NgbTabsetModule,
		NgbTooltipModule,
		SharedModule,
		DashboardRoutingModule,
		FusionChartsModule,
		QRCodeModule,
		ClipboardModule,
		BsDatepickerModule.forRoot(),
		TimepickerModule.forRoot(),
		EditorModule


	],
	providers: [
		NgbActiveModal,
		{ provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
	],
	entryComponents: [LinkAccountModalComponent]
})
export class DashboardModule { }
