<div class="modal-header">
    <div class="row w-100">
        <div class="col-md-11">
            <h5 class="modal-title">Schedule A Meeting</h5>
        </div>
        <div class="col-md-1 pr-0">
            <button type="button" class="close float-right text-white pr-0" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
</div>

<div class="modal-body">
    <form [formGroup]="scheduleMeetingForm" novalidate>
        <div class="form-group col-md-12 col-sm-12 col-xs-12">
            <h4 class="font-18">If you wish to schedule a meeting with {{advisorFullName}}, please complete the
                information below. <span class="capitalize">{{advisorFirstName}}</span> will contact you to confirm the
                appointment.</h4>
            <!-- <div class="col-md-12 col-sm-12 pl-0 pr-0">
                <select class="form-control" [(ngModel)]="contact_advisor" formControlName="contactAdvisor">
                    <option value='' selected>Select Recipient</option>
                    <option *ngFor="let option of advisoryTeam" value="{{option.id}}">{{option.name}} -
                        {{option.user_type}} </option>
                </select>
            </div> -->
        </div>
        <div class="form-group col-md-12 col-sm-12 col-xs-12">
            <div class="col-md-12 col-sm-12 pl-0 pr-0">
                <input class="form-control contact-us-com" [(ngModel)]="meeting_subject" type="text"
                    name="meetingSubject" formControlName="meetingSubject" placeholder="Subject" autofocus="true" />
            </div>
        </div>
        
        <div class="form-group col-md-12 col-sm-12 col-xs-12">
            <div class="col-md-12 col-sm-12 pl-0 pr-0">
                <textarea class="form-control contact-us-bordernone" rows=6 columns=12 type="text" name="meetingMessage"
                    placeholder="Type your message here. Please include the best way to be contacted." autofocus="true"
                    [(ngModel)]="meeting_message" formControlName="meetingMessage">
                </textarea>
            </div>

        </div>

        <div class="form-group col-md-12 col-sm-12 col-xs-12 row">
            <div class="col-md-4 meetingGroup">
                <label for="name">Suggested Meeting Time</label>
                <p class="input-group ">
                    <input type="text" placeholder="MM/DD/YYYY" class="form-control" #dp="bsDatepicker" bsDatepicker
                        [required]="true" [bsConfig]="bsConfig" formControlName="meetingDate" [(ngModel)]="meeting_date"
                        [minDate]="minDate" [maxDate]="maxDate" (blur)="setMaxDate()" />
                    <span class="cal-icon" (click)="dp.toggle()"><i class="fa fa-calendar"
                            aria-hidden="true"></i></span>

                </p>


            </div>

            <div class="col-md-3 col-sm-3 col-xs-3">
                <timepicker *ngIf="eventList.length <= 0" name="meetingTime1" formControlName="meetingTime1"
                    [(ngModel)]="time" required></timepicker>
                <div *ngIf="eventList.length > 0" class="meetingDate pl-0 pr-0">
                    <select [(ngModel)]="meeting_time" class="selectTimezonelist" name="meetingTime"
                        formControlName="meetingTime" required>
                        <option value="" selected>Select Time</option>
                        <option *ngFor="let eventTime of eventList" value="{{eventTime[0]}}">{{eventTime[0]}} -
                            {{eventTime[1]}}</option>
                    </select>
                </div>
            </div>

            <div class="col-md-4 col-sm-4 col-xs-4 meetingZone pl-0 pr-0">
                <select [(ngModel)]="selectedTimeZone" class="selectTimezonelist" formControlName="meetingTimezone">
                    <option [ngValue]="undefined" selected>Select Timezone</option>
                    <option value="Eastern Standard Time">Eastern Standard Time</option>
                    <option value="Central Standard Time">Central Standard Time</option>
                    <option value="Mountain Standard Time">Mountain Standard Time</option>
                    <option value="Pacific Standard Time">Pacific Standard Time</option>
                </select>

            </div>

        </div>

    </form>
</div>
<div class="clear"> </div>

<div class="modal-footer">
    <button type="button" class="btn btn-link" (click)="closeModal()">
        CANCEL
    </button>
    <button type="button" class="btn btn-primary btn-sm rounded-pill" (click)="scheduleMeeting()" [formvalid]="scheduleMeetingForm.valid" appButtonDisable>
        Schedule Meeting
    </button>
</div>