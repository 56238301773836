import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EmailLinkComponent } from './email-link/email-link.component';
import { GetStartedComponent } from './get-started/get-started/get-started.component';
import { LoginFormComponent } from './login/login-form/login-form.component';
import { LogonComponent } from './login/logon/logon.component';
import { AuthGuard } from './services/auth.guard';


const routes: Routes = [

	{
		path: '',
		component: GetStartedComponent

	},
	{
		path: 'login',
		component: LoginFormComponent

	},
	{
		path: 'login/firm/:firmCode',
		component: LoginFormComponent
	},
	{
		path: 'logon',
		component: LogonComponent

	},
	{
		path: 'email',
		component: EmailLinkComponent

	},
	{
		path: 'email/firm/:firmCode',
		component: EmailLinkComponent

	}
]

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
	providers: [AuthGuard]
})
export class AppRoutingModule { }
