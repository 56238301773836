import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/services/api.service';
import { ToastService } from 'src/app/components/toaster/toast-service';
import { UtilityService } from 'src/app/services/utility.service';
import { AppRestEndPoint } from 'src/app/app-restEndPoint';

@Component({
	selector: 'app-upload-document-modal',
	templateUrl: './upload-document-modal.component.html',
	styleUrls: ['./upload-document-modal.component.scss']
})
export class UploadDocumentModalComponent implements OnInit {


	files: any = [];
	flagObj: any = {};


	constructor(
		public activeModal: NgbActiveModal,
		private apiService: ApiService,
		private toastService: ToastService,
		public utilityService: UtilityService,
	) { }

	ngOnInit(): void {
	}

	onFileDropped(files) {
		for (const item of files) {
			this.files.push(item);
		}
	}

	closeModal(flag) {
		this.activeModal.close(flag);
	}

	getFileDetails(e) {
		let files = e.target.files;
		for (let i = 0; i < files.length; i++) {
			this.files.push(files[i]);
		}
		this.files = this.utilityService.unique(this.files);
	};


	removeFiles(index) {
		this.files.splice(index, 1);
	};

	saveDocument() {
		this.flagObj.uploadDocFormSubmitted = true;

		if (this.files.length > 0) {
			this.flagObj.disableBtn = true;
			this.flagObj.uploadDocumentLoader = true;
			this.flagObj.showUploadError = false;
			let reqObj = {
				"household_id": sessionStorage.getItem('current_selected_household'),
				"user_document_type_id": 99,
				"document_template_id": 1,
				'files': this.files,

			};
			let houseHoldId = sessionStorage.getItem('current_selected_household');
			this.apiService.postMultipartFormCall(AppRestEndPoint.DASHBOARD.SAVE_MULTIPLE_DOCUMENT + '?household_id=' + houseHoldId, this.formatData(reqObj), houseHoldId).subscribe(data => {
				if (data) {
					this.flagObj.uploadDocumentLoader = false;
					this.toastService.show(data.message, {
						classname: 'bg-success text-light', delay: 3000
					});
					this.files = [];
					this.flagObj.disableBtn = false;
					this.closeModal('docUpload');

				}
			},(err) => {      
				this.flagObj.uploadDocumentLoader = false;
				this.flagObj.disableBtn = false;
				this.toastService.show('Households with Advisor can only Upload Documents.', {
					classname: 'bg-danger text-light', delay: 3000
				});
				
			});
		} else {
			this.flagObj.uploadDocumentLoader = false;
			this.flagObj.showUploadError = true;
			return;
		}
	}

	formatData(data) {
		const formData = new FormData();
		formData.append('user_document[user_document_type_id]', '99');
		formData.append('user_document[document_template_id]', '1');
		formData.append('user_document[household_id]', data.household_id);
		if (data.files.length > 0) {
			for (let i = 0; i < data.files.length; i++) {
				formData.append('files[' + i + ']', data.files[i]);
			}
		}
		return formData;
	}
}
