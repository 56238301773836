<div class="modal-header">
    <div class="row w-100">
        <div class="col-md-11">
            <h5 class="modal-title">Send Files</h5>
        </div>
        <div class="col-md-1 pr-0">
            <button type="button" class="close float-right text-white pr-0" aria-label="Close"
                (click)="activeModal.dismiss()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
</div>
<div class="modal-body">
    <div class="font-18 pb-2">Please attach your files to this message and provide a description of them. Please select who should receive an email with the files attached.</div>
    <form [formGroup]="sendFilesForm" novalidate>
        <div class="pb-4">
            <select  class="form-control" [(ngModel)]="contact_advisor" formControlName="contactAdvisor" >
                <option value='' selected>Select Recipient</option>
                <option *ngFor="let option of advisoryTeam" value="{{option.id}}">{{option.name}} -
                    {{option.user_type}} </option>
            </select>
           
        </div>
        <div class="pb-4">
            <input type="text" #filesubject class="form-control" placeholder="Subject" [(ngModel)]="files_subject"
                formControlName="filesSubject" autofocus="true">
            <div *ngIf="sendFilesFormSubmitted" class="text-danger pt-1" role="alert">
                <div *ngIf="formControl.filesSubject.errors?.required">Subject is mandatory.</div>
            </div>
        </div>
        <div class="pb-4">
            <textarea class="form-control" placeholder="Type your Message here" rows="6" columns="12"
                [(ngModel)]="files_message" formControlName="filesMessage"></textarea>
            <div *ngIf="sendFilesFormSubmitted" class="text-danger pt-1" role="alert">
                <div *ngIf="formControl.filesMessage.errors?.required">Message is mandatory.</div>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-12 col-sm-12">
                <div class="upload-file-drop-zone" appDropfile (fileDropped)="onFileDropped($event)">
                    <div class="text-center mt-4 text-secondary pt-2" *ngIf="files.length==0">Drop Files</div>
                    <div *ngFor="let file of files ; let i = index" class="float-left">
                        <div class="filesList text-dark p-2">
                            <span>{{file.name}}</span>
                            <i class="fa fa-times crossIcon pl-1 cursor-pointer text-danger" aria-hidden="true"
                                (click)="removeFiles(i)"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-12 col-sm-12 no-padding-left-right">
                <div class="float-left mr-2 font-14 text-secondary"><label>Attach Files</label></div>
                <label class="float-left padding paperClip">
                    <input type="file" class="d-none" ng-model="uploadFile" id="fileUpload" name="uploadFile"
                        multiple="multiple" (change)="getFileDetails($event)" />
                    <i class="fa fa-paperclip font-14 color-brown cursor-pointer"></i>
                </label>
            </div>
            <div *ngIf="sendFilesFormSubmitted && showError" class="text-danger ml-3 pt-1">Please attach a file.</div>
        </div>
    </form>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-link" (click)="closeModal()" [disabled]="disableBtn">
        CANCEL
    </button>
    <button type="button" class="btn btn-primary btn-sm rounded-pill" (click)="saveFiles(this)" [formvalid]="sendFilesForm.valid" appButtonDisable>
        Send Files
    </button>
</div>
