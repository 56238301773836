<app-navbar></app-navbar>
<div resize-window>
    <div class="container-fluid ml-5 mr-5 margin-top-90">
        <div class="pt-5 ml-5 mr-5">
            <ngb-tabset [destroyOnHide]="false">
                <ngb-tab title="Pending Items">
                    <ng-template ngbTabContent>
                        <div class="row">
                            <div class="container col-md-12 mt-3">
                                <div class="card">
                                    <div aria-labelledby="headingOne">
                                        <div class="card-body">
                                            <div class="container col-md-12 mt-2 text-center"
                                                *ngIf="taskList.length==0">
                                                No records returned
                                            </div>
                                            <table class="table">
                                                <tbody>
                                                    <tr *ngFor="let task of taskList;let i = index">
                                                        <td width="2%" class="border-top-0 border-bottom">
                                                            <span
                                                                *ngIf="task.priority == 'High' && task.status != 'Completed'"><i
                                                                    class="fa fa-exclamation-circle color-red"
                                                                    aria-hidden="true"></i></span>
                                                            <span
                                                                *ngIf="task.status == 'Completed'"><i
                                                                    class="fa fa-check color-green" aria-hidden="true"></i></span>
                                                        </td>
                                                        <td class="border-top-0 border-bottom pl-0">
                                                            {{task.due_date | date:'MM/dd/yyyy'}}
                                                        </td>
                                                        <td width="80%" class="border-top-0 border-bottom">
                                                            <span class="cursor-pointer taskName" (click)="openTaskModal(task, i)">{{task.name}}</span>
                                                        </td>
                                                        <td class="border-top-0 border-bottom">
                                                            {{task.status.replace('_', ' ')}}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </ngb-tab>
            </ngb-tabset>
        </div>
    </div>
</div>