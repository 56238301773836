import { HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastService } from './components/toaster/toast-service';
import { ConfirmationDialogService } from './services/confirmation-dialog.service';
import { environment } from 'src/environments/environment';
import { Notifier } from '@airbrake/browser';
import { UtilityService } from './services/utility.service';
import { CommonService } from './services/common.service';
import { Router } from '@angular/router';

@Injectable()
export class AppInterceptor implements HttpInterceptor {
    airbrake: Notifier;
    sessionTimeoutModalInstance: any;
    constructor(
        private http: HttpClient,
        private toastService: ToastService,
        private confirmationDialogService: ConfirmationDialogService,
        private commonService: CommonService,
        public router: Router
    ) { 
        this.airbrake = new Notifier({
            projectId: 389369,
            projectKey: '7b4976469e9654ce2d247f02c926a0cd',
            environment: environment.environment
        });
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // reset timer of idle time
        let access_token = sessionStorage.getItem('token');
        if(access_token != null){                      
            let idleTimeoutTimer = sessionStorage.getItem('idleTimeoutTimer');
            if(idleTimeoutTimer != null){                      
                let url = request.url;
                if(!url.includes('sign_out') && !url.includes('html') && !url.includes('airbrake') && idleTimeoutTimer != '' && idleTimeoutTimer != null && access_token != null){
                    this.commonService.resetTimer(true);
                }
                if(url.includes('sign_out')){
                    this.commonService.cleartimeoutthread(true);
                }
            }
        }

        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error && error.status == -1) {
                    let msg = 'Unable to connect service, please check your internet connection.';
                    if(environment.environment != 'production'){
                        msg = error.status + ': ' + msg;
                    }
                    this.toastService.show(msg, {
                        classname: 'bg-danger text-light', delay: 3000
                    });
                  //  this.airbrakeErrorNotify(error);
                } else if (error && error.status == 401) {
                    if (error.error) {
                        let msg1 = 'Unauthorized.';
                        if(environment.environment != 'production'){
                            msg1 = error.status + ': ' + msg1;
                        }
                        this.toastService.show(msg1, {
                            classname: 'bg-danger text-light', delay: 3000
                        });
                      // this.airbrakeErrorNotify(error);
                    } else {
                        let token = sessionStorage.getItem('token');
                        if (!this.sessionTimeoutModalInstance && token != null) {
                            this.commonService.cleartimeoutthread(true);
                            this.openSessionTimeoutModal();
                        }
                       // this.airbrakeErrorNotify(error);
                    }
                }else{
                    let msg2 = 'Unfortunately Something went wrong. Please try again.';
                    if(environment.environment != 'production' && error.status){
                        msg2 = error.status + ': ' + msg2;
                    }
                    this.toastService.show(msg2, {
                        classname: 'bg-danger text-light', delay: 3000
                    });
                    this.airbrakeErrorNotify(error);
                }
                return throwError(error);
            })
        );
    }

    openSessionTimeoutModal() {
        this.sessionTimeoutModalInstance = this.confirmationDialogService.confirm('Your session has timed out', "You'll be redirected to login page.",'Continue to Login page','')
			.then((confirmed) => {
				if (confirmed) { this.closeModal(); }
			});
    }

    closeModal(){
        this.commonService.clearStorage();
        if(this.commonService.getDomainDetails().firm_code){
            this.router.navigate(['/login/firm/'+this.commonService.getDomainDetails().firm_code]);
        }else{
            this.router.navigate(['/login']);
        }
        this.toastService.show('Your session has timed out.', {
            classname: 'bg-danger text-light', delay: 3000
        });
    }

    airbrakeErrorNotify(response) {
        let responseString = {};
        let sessionString = {};
        responseString = JSON.stringify(response);
        sessionString = JSON.stringify(sessionStorage.getItem('current_user')); 
        let promise = this.airbrake.notify("Response Object: "+responseString+"--"+"User: "+sessionString+"--"+"Environment: "+environment.environment);
        promise.then(function(notice) {
            console.log('notice id', notice.id);
        });
        promise.catch(function(err) {
            console.log('airbrake error', err);
        });
    }
}
