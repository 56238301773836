<div class="modal-header">
    <div class="row w-100">
        <div class="col-md-11">
            <h5 class="modal-title">Quovo Link Account</h5>
        </div>
        <div class="col-md-1 pr-0">
            <button type="button" class="close float-right text-white pr-0" aria-label="Close" (click)="cancel()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
</div>

<div class="modal-body">
    <form name="quovoForm" novalidate>
       <div class="form-group mt-5 col-md-12 col-sm-12 col-xs-12">
            <h4>This feature is coming soon!</h4>              
        </div>
    </form>
</div>


<div class="modal-footer">
    <button type="button" class="btn btn-link" (click)="cancel()">
        CANCEL
    </button>
  
</div>


