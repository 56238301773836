<app-breadcrumb-options-docu-sign></app-breadcrumb-options-docu-sign>
<div class="tab-pane active" role="tabpanel" id="step1">
    <div class="container tab-form">
        <div class="ml-2 mr-2">
            <div class="docusign-text-div border-bottom">
                Below are the documents you need to Docusign. Click on the Sign button to electronically sign the
                documents.
            </div>
            <div class="docusign-text-div border-top" *ngFor="let document of documentData |keyvalue">
                <div class="font-16 font-weight-600">{{document.value.description}}</div>
                <div class="d-flex pt-2">
                    <div class="col-md-8">{{document.value.document_template_description}}</div>
                    <div class="col-md-2 text-right"><a class="btn btn-primary btn-docusign"
                            href="{{document.value.url}}" target="_blank"
                            [ngClass]="{'disabled':!document.value.can_view}">View </a></div>

                    <div class="col-md-2 text-left" *ngIf="!document.value.has_signed"><a
                            class="btn btn-primary btn-docusign" (click)="signDocument(document.value.id)"
                            [ngClass]="{'disabled':!document.value.can_sign}">Sign </a></div>

                    <div *ngIf="document.value.has_signed"
                        class="col-md-2 text-left color-green display-flex align-item-center"><i
                            class="fa fa-check-circle-o fa-2x" aria-hidden="true"></i>
                        <div class="ml-2">Signed!</div>
                    </div>
                </div>
            </div>
            <div>
                <app-buttonbar hidebackbtn = "true" (clickednext)="gotoNextState()"></app-buttonbar>
            </div>
        </div>
    </div>
</div>