<div class="modal-header">
    <h4 class="modal-title">{{ title }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" [innerHtml]="message"></div>
<div class="modal-footer">
    <button type="button" class="btn btn-link" (click)="decline()">
        {{ btnCancelText }}
    </button>
    <button type="button" class="btn btn-primary rounded-pill pl-3 pr-3" (click)="accept()">
        {{ btnOkText }}
    </button>
</div>